import React, {Component} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/sk';
import {connect} from "react-redux";
import 'rc-tree-select/assets/index.css';
import 'rc-slider/assets/index.css';
import {bindActionCreators} from "redux";
import {getUserTypeNumber} from '../../client/Helper';
import {showDialogForCreationOfEvent, closeDialogForCreationOfEvent,
    setLoadingGetChosenEvents,getChosenEventsSuccess, getChosenEventsError, getChosenEvents,
    setLoadingGetAllEvents,getAllEventsSuccess, getAllEventsError, getAllEvents
} from '../../actions/EventActions';
import { toggleModalCreateEvent } from '../../actions/ModalActions';
import '../../components/Dashboard/Calendar/Calendar.css';
import {getFilterEventCategories, getFilterEventMusicGenres} from '../../components/Header/CreateEventModal/filters/filters'; 
import { LOADING_EVENTS, RESET_FILTERS} from '../../config/TextContent';
import ReactLoading from 'react-loading';
import Fade from 'react-reveal/Fade';
import EventList from '../../components/EventList/EventList';
import ProfileEventFilters from '../../components/Dashboard/Event/ProfileEventFilters/ProfileEventFilters';
import { isLoggedInUserInLocalStorage } from '../../actions/UserActions';

class ProfileListOfEvents extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            typingTimeout: 0,
            date: new Date(),
            selectedEvent: null,
            filter: {
                name: '',
                radius: '',
                genre: null,
                childrenGenre: null,
                lat: '',
                lng: '',
                chosen_date: '',
                category: null,
                show_user_events: '',
                isFlagMusicGenres: false,
                show_current_user_events: false,
            },
        };
        this.onDateChange = this.onDateChange.bind(this);
    }

    componentDidMount() {
        this.setState(prevState => {    
            let filter = {...prevState.filter}; 
            if (this.props.selectedUser) {
                filter.show_user_events = this.props.selectedUser.id;
            } else if (this.props.user) {
                filter.show_user_events = this.props.user.id;
            }
            if(this.props.match && this.props.match.params && this.props.match.params.category_id){
                filter.category = this.props.match.params.category_id;
            }
            if(this.props.match && this.props.match.params && this.props.match.params.chosen_date){
                filter.chosen_date = new Date(this.props.match.params.chosen_date);
            }
            return {
                ...prevState,
                filter,
            };
        }, () => {
            this.getEvents();
        });
    }

    isNotActiveFilteringEvents = () => {
        if(this.state.filter.name === '' && this.state.filter.radius === '' && this.state.filter.genre === null && this.state.filter.childrenGenre === null &&
        this.state.filter.lat === '' && this.state.filter.lng === '' && this.state.filter.chosen_date === '' && this.state.filter.category === null &&
        this.state.filter.show_user_events === '' && this.state.filter.show_current_user_events === false){
            return true;
        }
        return false;
    }

    onClickResetFilters = () => {
        this.setState((prevState) => {
            let filter = {
                name: '',
                radius: '',
                genre: null,
                childrenGenre: null,
                lat: '',
                lng: '',
                chosen_date: '',
                category: null,
                show_user_events: '',
                isFlagMusicGenres: false,
                show_current_user_events: false,
            };
            return{
                ...prevState,
                filter
            }
        }, () => {
            this.getEvents();
        })
    }

    handleNameChange = (event) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        let filter = this.state.filter;
        filter.name = event.target.value;

        this.setState({
            filter,
            typingTimeout: setTimeout(() => {
                this.getEvents();
            }, 500)
        });
    };

    handleRadiusChange = (event, latitude, longitude) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        let filter = this.state.filter;
        filter.radius = event.target.value;

        if(filter.radius && latitude && longitude){
            filter.lat = latitude;
            filter.lng = longitude;
        }else{
            filter.lat = '';
            filter.lng = '';
        }

        let that = this;
        this.setState({
            filter,
            typingTimeout: setTimeout(function () {
                that.getEvents();
            }, 500)
        });
    };

    getCategoryByCategoryId = (id) => {
        if(!id)
            return null;

        let found = null;
        this.props.allEventCategoriesList.forEach(element => {
            if(element.id === id)
                found = element;
        });
        return found;
    }

    onEventCategoryChange = (value) => {
        this.setState((prevState) => {
            let filter = {...prevState.filter};
            const category = this.getCategoryByCategoryId(value);
            filter.category = value; 
            filter['genre'] = null; 
            filter['childrenGenre'] = null; 

            if(category && category.flag === 1)
                filter['isFlagMusicGenres'] = true;
            else{
                filter['isFlagMusicGenres'] = false;
            }
            return {
                ...prevState,
                filter
            };
        }, () => {
            this.getEvents();
        });
    };

    onEventMusicGenresChange = (value) => {
        this.setState((prevState) => {
            let filter = {...prevState.filter};
            filter['genre'] = value;
            filter['childrenGenre'] = null;
            return {
                ...prevState,
                filter
            };
        }, () => {
            this.getEvents();
        });
    };

    onEventMusicChildrenGenresChange = (value) => {
        this.setState((prevState) => {
            let filter = {...prevState.filter};
            filter['childrenGenre'] = value;
            return {
                ...prevState,
                filter
            };
        }, () => {
            this.getEvents();
        });
    };

    onDateChange = (date) => {
        this.setState(prevState => {
            let filter = {...prevState.filter};
            filter.chosen_date = date;
            return {
                ...prevState,
                filter
            }
        }, () => {
            this.getEvents();
        });
    }

    render() {
        return (
            <div className="calendar__content">
                <div className="container">
                    <div className="calendar__core"> 
                        <div id="events">
                            {isLoggedInUserInLocalStorage() && (!this.isNotActiveFilteringEvents() || this.props.chosenEvents.length > 0) ? (
                                <div className="profile-section-filters">
                                    {!this.isNotActiveFilteringEvents() ? (
                                        <div className="profile-section-filters-reset">
                                            <button onClick={this.onClickResetFilters}>{RESET_FILTERS}</button>
                                        </div>
                                    ) : null}
                                    <ProfileEventFilters 
                                        handleNameChange={this.handleNameChange}
                                        handleRadiusChange={this.handleRadiusChange}
                                        onEventCategoryChange={this.onEventCategoryChange}
                                        onEventMusicGenresChange={this.onEventMusicGenresChange}
                                        onEventMusicChildrenGenresChange={this.onEventMusicChildrenGenresChange}
                                        eventCategoriesList={this.props.allEventCategoriesList}
                                        eventMusicGenresList={this.props.eventMusicGenresList}
                                        selectedUser={this.props.selectedUser}
                                        modalType={this.props.modalType}
                                        onDateChange={this.onDateChange}
                                        user={this.props.user}
                                        filter={this.state.filter}
                                    />
                                </div>
                            ) : null}
                            {this.props.loadingChosenEvents ?
                                <Fade duration={500} delay={250} >
                                    <div className="photo-update-modal__content">
                                        <div className="photo-update-modal__content-loading-wrapper">
                                            <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                                            <p className="photo-update-modal__loading-content">{LOADING_EVENTS}</p>
                                        </div>
                                    </div>
                                </Fade>
                                : <EventList />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getEvents() {
        let filter = this.state.filter;
        let query_params = {};
        query_params.name = filter.name;
        query_params.genre = filter.genre;
        if (filter.childrenGenre) {
            query_params.genre = filter.childrenGenre;
        }
        query_params.radius = filter.radius;
        query_params.lat = filter.lat;
        query_params.lng = filter.lng;
        query_params.category = filter.category;
        query_params.chosen_date = filter.chosen_date;
        if(filter.show_user_events !== ''){
            query_params.show_user_events = filter.show_user_events;
        }
        if(filter.show_current_user_events){
            query_params.show_current_user_events = filter.show_current_user_events;
        }
        this.props.setLoadingGetChosenEvents();
        this.props.getChosenEvents(query_params).then(() => {
            this.props.getChosenEventsSuccess();
        }).catch(() => {
            this.props.getChosenEventsError();
        });

        query_params.except_user = filter.show_user_events;
        query_params.show_user_events = null;

        this.props.setLoadingGetAllEvents();
        this.props.getAllEvents(query_params).then(() => {
            this.props.getAllEventsSuccess();
        }).catch(() => {
            this.props.getAllEventsError();
        });
    }


}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        loading: state.userReducer.loading,
        loadingSelectedUser: state.userReducer.loadingSelectedUser,
        selectedUser: state.userReducer.selectedUser,
        eventReducer: state.eventReducer,  
        loadingChosenEvents: state.eventReducer.loadingChosenEvents,
        loadingAllEvents: state.eventReducer.loadingAllEvents,
        chosenEvents: state.eventReducer.chosenEvents,
        events: state.eventReducer.events,
        allEventCategoriesList: getFilterEventCategories(state.eventReducer.allEventCategoriesList),
        eventMusicGenresList: getFilterEventMusicGenres(state.eventReducer.eventMusicGenresList),
        modalType: getUserTypeNumber(state.userReducer.selectedUser)
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleModalCreateEvent,
        showDialogForCreationOfEvent,
        closeDialogForCreationOfEvent,
        setLoadingGetChosenEvents,
        getChosenEvents,
        getChosenEventsSuccess,
        getChosenEventsError,
        setLoadingGetAllEvents,
        getAllEvents,
        getAllEventsSuccess,
        getAllEventsError
    }, dispatch);
}

ProfileListOfEvents.defaultProps = {
    eventReducer: {
        eventsList: [],
        allEventCategoriesList: [],
        eventMusicGenresList: []
    },
};
  

export default connect(mapStateToProps, matchDispatchToProps)(ProfileListOfEvents);
