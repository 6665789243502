import React from 'react';
import {connect} from "react-redux";
import './LoggedUserDashboard.css';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import CoverElement from '../CoverElement/CoverElement';
import MenuElement from '../MenuElement/MenuElement';
import LoggedUserRoutesDashboard from '../LoggedUserRoutesDashboard/LoggedUserRoutesDashboard';

const LoggedUserDashboard = (props) => {
    return (
        <div>
            <CoverElement selectedUserDash={false} loggedUserDash={true} user={props.user} loadingUser={props.loadingUser} eventMusicGenresList={props.eventMusicGenresList} />
            <MenuElement selectedUserDash={false} loggedUserDash={true} user={props.user} loadingUser={props.loadingUser} />
            <LoggedUserRoutesDashboard user={props.user} />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        eventMusicGenresList: state.eventReducer.eventMusicGenresList
    }
}

LoggedUserDashboard.propTypes = {
    user: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps, null)(LoggedUserDashboard));
