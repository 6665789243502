import EndpointsUrl from "../client/EndpointsUrl";
import axiosManager from '../client/AxiosManager/AxiosManager';
import { GET, POST } from '../client/AxiosManager/AxiosManagerConstants';

export const SAVE_EVENT = 'SAVE_EVENT';
export const SAVE_EVENT_SUCCESS = 'SAVE_EVENT_SUCCESS';
export const SAVE_EVENT_ERROR = 'SAVE_EVENT_ERROR';

export const SAVE_ORDINARY_EVENT = 'SAVE_ORDINARY_EVENT';
export const SAVE_ORDINARY_EVENT_SUCCESS = 'SAVE_ORDINARY_EVENT_SUCCESS';
export const SAVE_ORDINARY_EVENT_ERROR = 'SAVE_ORDINARY_EVENT_ERROR';

export const EDIT_EVENT = 'EDIT_EVENT';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_ERROR = 'EDIT_EVENT_ERROR';

export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const SET_LOADING_GET_EVENTS_BY_USER = 'SET_LOADING_GET_EVENTS_BY_USER';
export const GET_EVENTS_BY_USER = 'GET_EVENTS_BY_USER';
export const GET_EVENTS_BY_USER_SUCCESS = 'GET_EVENTS_BY_USER_SUCCESS';
export const GET_EVENTS_BY_USER_ERROR = 'GET_EVENTS_BY_USER_ERROR';

export const SET_LOADING_GET_EVENTS_BY_PERFORMER = 'SET_LOADING_GET_EVENTS_BY_PERFORMER';
export const GET_EVENTS_BY_PERFORMER = 'GET_EVENTS_BY_PERFORMER';
export const GET_EVENTS_BY_PERFORMER_SUCCESS = 'GET_EVENTS_BY_PERFORMER_SUCCESS';
export const GET_EVENTS_BY_PERFORMER_ERROR = 'GET_EVENTS_BY_PERFORMER_ERROR';

export const CANCEL_EVENT = 'CANCEL_EVENT';
export const CANCEL_EVENT_SUCCESS = 'CANCEL_EVENT_SUCCESS';
export const CANCEL_EVENT_ERROR = 'CANCEL_EVENT_ERROR';

export const ADD_INTERPRET = 'ADD_INTERPRET';
export const ADD_INTERPRET_SUCCESS = 'ADD_INTERPRET_SUCCESS';
export const ADD_INTERPRET_ERROR = 'ADD_INTERPRET_ERROR';

export const SHOW_FORM_CREATE_EVENT = 'SHOW_FORM_CREATE_EVENT';
export const CLOSE_FORM_CREATE_EVENT = 'CLOSE_FORM_CREATE_EVENT';

export const GET_ALL_EVENT_CATEGORIES = 'GET_ALL_EVENT_CATEGORIES';
export const GET_ALL_EVENT_CATEGORIES_SUCCESS = 'GET_ALL_EVENT_CATEGORIES_SUCCESS';
export const GET_ALL_EVENT_CATEGORIES_ERROR = 'GET_ALL_EVENT_CATEGORIES_ERROR';

export const GET_EVENT_CATEGORIES = 'GET_EVENT_CATEGORIES';
export const GET_EVENT_CATEGORIES_SUCCESS = 'GET_EVENT_CATEGORIES_SUCCESS';
export const GET_EVENT_CATEGORIES_ERROR = 'GET_EVENT_CATEGORIES_ERROR';

export const GET_EVENT_MUSIC_GENRES = 'GET_EVENT_MUSIC_GENRES';
export const GET_EVENT_MUSIC_GENRES_SUCCESS = 'GET_EVENT_MUSIC_GENRES_SUCCESS';
export const GET_EVENT_MUSIC_GENRES_ERROR = 'GET_EVENT_MUSIC_GENRES_ERROR';

export const GET_CLUB_TYPES = 'GET_CLUB_TYPES';
export const GET_CLUB_TYPES_SUCCESS = 'GET_CLUB_TYPES_SUCCESS';
export const GET_CLUB_TYPES_ERROR = 'GET_CLUB_TYPES_ERROR';

export const SET_LOADING_GET_TOP_EVENTS = 'SET_LOADING_GET_TOP_EVENTS';
export const GET_TOP_EVENTS = 'GET_TOP_EVENTS';
export const GET_TOP_EVENTS_SUCCESS = 'GET_TOP_EVENTS_SUCCESS';
export const GET_TOP_EVENTS_ERROR = 'GET_TOP_EVENTS_ERROR';

export const SET_LOADING_GET_CHOSEN_EVENTS = 'SET_LOADING_GET_CHOSEN_EVENTS';
export const GET_CHOSEN_EVENTS = 'GET_CHOSEN_EVENTS';
export const GET_CHOSEN_EVENTS_SUCCESS = 'GET_CHOSEN_EVENTS_SUCCESS';
export const GET_CHOSEN_EVENTS_ERROR = 'GET_CHOSEN_EVENTS_ERROR';

export const SET_LOADING_GET_ALL_EVENTS = 'SET_LOADING_GET_ALL_EVENTS';
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_ERROR = 'GET_ALL_EVENTS_ERROR';

export const SET_LOADING_GET_EVENTS_FOR_CALENDAR = 'SET_LOADING_GET_EVENTS_FOR_CALENDAR';
export const GET_EVENTS_FOR_CALENDAR = 'GET_EVENTS_FOR_CALENDAR';
export const GET_EVENTS_FOR_CALENDAR_SUCCESS = 'GET_EVENTS_FOR_CALENDAR_SUCCESS';
export const GET_EVENTS_FOR_CALENDAR_ERROR = 'GET_EVENTS_FOR_CALENDAR_ERROR';

export const SET_LOADING_GET_PREMIUM_EVENTS = 'SET_LOADING_GET_PREMIUM_EVENTS';
export const GET_PREMIUM_EVENTS = 'GET_PREMIUM_EVENTS';
export const GET_PREMIUM_EVENTS_SUCCESS = 'GET_PREMIUM_EVENTS_SUCCESS';
export const GET_PREMIUM_EVENTS_ERROR = 'GET_PREMIUM_EVENTS_ERROR';

export const SET_LOADING_GET_SELECTED_EVENT = 'SET_LOADING_GET_SELECTED_EVENT';
export const GET_SELECTED_EVENT = 'GET_SELECTED_EVENT';
export const GET_SELECTED_EVENT_SUCCESS = 'GET_SELECTED_EVENT_SUCCESS';
export const GET_SELECTED_EVENT_ERROR = 'GET_SELECTED_EVENT_ERROR';

export const SET_LOADING_GET_SELECTED_EVENT_TO_UPDATE = 'SET_LOADING_GET_SELECTED_EVENT_TO_UPDATE';
export const GET_SELECTED_EVENT_TO_UPDATE = 'GET_SELECTED_EVENT_TO_UPDATE';
export const GET_SELECTED_EVENT_TO_UPDATE_SUCCESS = 'GET_SELECTED_EVENT_TO_UPDATE_SUCCESS';
export const GET_SELECTED_EVENT_TO_UPDATE_ERROR = 'GET_SELECTED_EVENT_TO_UPDATE_ERROR';

export const SET_LOADING_LIKE_OR_DISLIKE_EVENT = 'SET_LOADING_LIKE_OR_DISLIKE_EVENT';
export const LIKE_OR_DISLIKE_EVENT = 'LIKE_OR_DISLIKE_EVENT';
export const LIKE_OR_DISLIKE_EVENT_SUCCESS = 'LIKE_OR_DISLIKE_EVENT_SUCCESS';
export const LIKE_OR_DISLIKE_EVENT_ERROR = 'LIKE_OR_DISLIKE_EVENT_ERROR';

export const GET_USERS_FILTER_SUCCESS = 'GET_USERS_FILTER_SUCCESS';
export const GET_USERS_FILTER_ERROR = 'GET_USERS_FILTER_ERROR';
export const GET_USERS_FILTER = 'GET_USERS_FILTER';
export const SET_LOADING_GET_USERS_FILTER = 'SET_LOADING_GET_USERS_FILTER';

export const REMOVE_EVENT_PERFORMER_SUCCESS = 'REMOVE_EVENT_PERFORMER_SUCCESS';
export const REMOVE_EVENT_PERFORMER_ERROR = 'REMOVE_EVENT_PERFORMER_ERROR';
export const REMOVE_EVENT_PERFORMER = 'REMOVE_EVENT_PERFORMER';
export const SET_LOADING_REMOVE_EVENT_PERFORMER = 'SET_LOADING_REMOVE_EVENT_PERFORMER';

export const REMOVE_EVENT_SUCCESS = 'REMOVE_EVENT_SUCCESS';
export const REMOVE_EVENT_ERROR = 'REMOVE_EVENT_ERROR';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const SET_LOADING_REMOVE_EVENT = 'SET_LOADING_REMOVE_EVENT';

export const GET_SIMILAR_EVENTS_SUCCESS = 'GET_SIMILAR_EVENTS_SUCCESS';
export const GET_SIMILAR_EVENTS_ERROR = 'GET_SIMILAR_EVENTS_ERROR';
export const GET_SIMILAR_EVENTS = 'GET_SIMILAR_EVENTS';
export const SET_LOADING_GET_SIMILAR_EVENTS = 'SET_LOADING_GET_SIMILAR_EVENTS';

export function saveEvent(data, ordinary=false) {
    let form_data = new FormData();
    form_data.append('document', JSON.stringify(data));
    form_data.append('main_photo', data.main_photo);
    form_data.append('offer_photo_1', data.offer_photo_1);
    form_data.append('offer_photo_2', data.offer_photo_2);
    form_data.append('offer_photo_3', data.offer_photo_3);
    let endpoint = !ordinary ? EndpointsUrl.SAVE_EVENT : EndpointsUrl.SAVE_ORDINARY_EVENT;
    const request = axiosManager(POST, endpoint, form_data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return {
        type: !ordinary ? SAVE_EVENT : SAVE_ORDINARY_EVENT,
        payload: request
    }
}

export function saveEventSuccess() {
    return {
        type: SAVE_EVENT_SUCCESS,
        payload: "Event was saved successfully."
    }
}

export function saveEventError() {
    return {
        type: SAVE_EVENT_ERROR,
        payload: "Error during saving event."
    }
}

export function saveOrdinaryEventSuccess() {
    return {
        type: SAVE_ORDINARY_EVENT_SUCCESS,
        payload: "Event was saved successfully."
    }
}

export function saveOrdinaryEventError() {
    return {
        type: SAVE_ORDINARY_EVENT_ERROR,
        payload: "Error during saving event."
    }
}

export function editEvent(data) {
    let form_data = new FormData();
    form_data.append('document', JSON.stringify(data));
    form_data.append('main_photo', data.main_photo);
    form_data.append('offer_photo_1', data.offer_photo_1);
    form_data.append('offer_photo_2', data.offer_photo_2);
    form_data.append('offer_photo_3', data.offer_photo_3);
    const request = axiosManager(POST, EndpointsUrl.SET_EVENT_DATA, form_data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return {
        type: EDIT_EVENT,
        payload: request
    }
}

export function editEventSuccess() {
    return {
        type: EDIT_EVENT_SUCCESS,
        payload: "Event was edited successfully."
    }
}

export function editEventError() {
    return {
        type: EDIT_EVENT_ERROR,
        payload: "Error during editing event."
    }
}

export function getEvent(id) {
    return {
        type: GET_EVENT,
        payload: axiosManager(GET, EndpointsUrl.GET_EVENT, {id})
    }
}

export function getEventSuccess() {
    return {
        type: GET_EVENT_SUCCESS,
        payload: "Event successfully loaded."
    }
}

export function getEventError() {
    return {
        type: GET_EVENT_ERROR,
        payload: "Error while loading event."
    }
}

export function cancelEvent(data) {
    return {
        type: CANCEL_EVENT,
        payload: axiosManager(POST, EndpointsUrl.CANCEL_EVENT, data)
    }
}

export function cancelEventSuccess() {
    return {
        type: CANCEL_EVENT_SUCCESS,
        payload: "Event was canceled successfully."
    }
}

export function cancelEventError() {
    return {
        type: CANCEL_EVENT_ERROR,
        payload: "Error during canceling event."
    }
}

export function addInterpret(data) {
    return {
        type: ADD_INTERPRET,
        payload: axiosManager(POST, EndpointsUrl.ADD_INTERPRET, data)
    }
}

export function addInterpretSuccess() {
    return {
        type: ADD_INTERPRET_SUCCESS,
        payload: "Interpret added to event successfully."
    }
}

export function addInterpretError() {
    return {
        type: ADD_INTERPRET_ERROR,
        payload: "Error while adding interpret to event."
    }
}

export function showDialogForCreationOfEvent() {
    return {
        type: SHOW_FORM_CREATE_EVENT,
        payload: "Form for creation of event opened."
    }
}

export function closeDialogForCreationOfEvent() {
    return {
        type: CLOSE_FORM_CREATE_EVENT,
        payload: "Form for creation of event closed."
    }
}

export function getEvents(params='') {
    return {
        type: GET_EVENTS,
        payload: axiosManager(GET, EndpointsUrl.GET_EVENTS, params)
    }
}

export function getEventsSuccess() {
    return {
        type: GET_EVENTS_SUCCESS,
        payload: "Events successfully loaded."
    }
}

export function getEventsError() {
    return {
        type: GET_EVENTS_ERROR,
        payload: "Error while loading events."
    }
}


export function setLoadingGetEventsByUser() {
    return {
        type: SET_LOADING_GET_EVENTS_BY_USER,
        payload: "Loading set before action"
    }
}

export function getEventsByUser(params='') {
    return {
        type: GET_EVENTS_BY_USER,
        payload: axiosManager(GET, EndpointsUrl.GET_EVENTS_BY_USER, params)
    }
}

export function getEventsByUserSuccess() {
    return {
        type: GET_EVENTS_BY_USER_SUCCESS,
        payload: "Events successfully loaded."
    }
}

export function getEventsByUserError() {
    return {
        type: GET_EVENTS_BY_USER_ERROR,
        payload: "Error while loading events."
    }
}

export function setLoadingGetEventsByPerformer() {
    return {
        type: SET_LOADING_GET_EVENTS_BY_PERFORMER,
        payload: "Loading set before action"
    }
}

export function getEventsByPerformer() {
    return {
        type: GET_EVENTS_BY_PERFORMER,
        payload: axiosManager(GET, EndpointsUrl.GET_EVENTS_BY_PERFORMER)
    }
}

export function getEventsByPerformerSuccess() {
    return {
        type: GET_EVENTS_BY_PERFORMER_SUCCESS,
        payload: "Events successfully loaded."
    }
}

export function getEventsByPerformerError() {
    return {
        type: GET_EVENTS_BY_PERFORMER_ERROR,
        payload: "Error while loading events."
    }
}

export function getAllEventCategories() {
    return {
        type: GET_ALL_EVENT_CATEGORIES,
        payload: axiosManager(GET, EndpointsUrl.GET_ALL_EVENT_CATEGORIES)
    }
}

export function getAllEventCategoriesSuccess() {
    return {
        type: GET_ALL_EVENT_CATEGORIES_SUCCESS,
        payload: "All Event categories successfully loaded."
    }
}

export function getAllEventCategoriesError() {
    return {
        type: GET_ALL_EVENT_CATEGORIES_ERROR,
        payload: "Error while loading all event categories."
    }
}

export function getEventCategories() {
    return {
        type: GET_EVENT_CATEGORIES,
        payload: axiosManager(GET, EndpointsUrl.GET_EVENT_CATEGORIES)
    }
}

export function getEventCategoriesSuccess() {
    return {
        type: GET_EVENT_CATEGORIES_SUCCESS,
        payload: "Event categories successfully loaded."
    }
}

export function getEventCategoriesError() {
    return {
        type: GET_EVENT_CATEGORIES_ERROR,
        payload: "Error while loading event categories."
    }
}

export function getEventMusicGenres() {
    return {
        type: GET_EVENT_MUSIC_GENRES,
        payload: axiosManager(GET, EndpointsUrl.GET_EVENT_MUSIC_GENRES)
    }
}

export function getEventMusicGenresSuccess() {
    return {
        type: GET_EVENT_MUSIC_GENRES_SUCCESS,
        payload: "Event music genres successfully loaded."
    }
}

export function getEventMusicGenresError() {
    return {
        type: GET_EVENT_MUSIC_GENRES_ERROR,
        payload: "Error while loading event music genres."
    }
}


export function setLoadingGetTopEvents() {
    return {
        type: SET_LOADING_GET_TOP_EVENTS,
        payload: "Loading set before action"
    }
}

export function getTopEvents(params='') {
    return {
        type: GET_TOP_EVENTS,
        payload: axiosManager(GET, EndpointsUrl.GET_TOP_EVENTS, params)
    }
}

export function getTopEventsSuccess() {
    return {
        type: GET_TOP_EVENTS_SUCCESS,
        payload: "Event music genres successfully loaded."
    }
}

export function getTopEventsError() {
    return {
        type: GET_TOP_EVENTS_ERROR,
        payload: "Error while loading event music genres."
    }
}


export function setLoadingGetChosenEvents() {
    return {
        type: SET_LOADING_GET_CHOSEN_EVENTS,
        payload: "Loading set before action"
    }
}

export function getChosenEvents(params='') {
    return {
        type: GET_CHOSEN_EVENTS,
        payload: axiosManager(GET, EndpointsUrl.GET_EVENTS, params)
    }
}

export function getChosenEventsSuccess() {
    return {
        type: GET_CHOSEN_EVENTS_SUCCESS,
        payload: "Events successfully loaded."
    }
}

export function getChosenEventsError() {
    return {
        type: GET_CHOSEN_EVENTS_ERROR,
        payload: "Error while loading events."
    }
}

export function setLoadingGetPremiumEvents() {
    return {
        type: SET_LOADING_GET_PREMIUM_EVENTS,
        payload: "Loading set before action"
    }
}

export function getPremiumEvents() {
    return {
        type: GET_PREMIUM_EVENTS,
        payload: axiosManager(GET, EndpointsUrl.GET_PREMIUM_EVENTS)
    }
}

export function getPremiumEventsSuccess() {
    return {
        type: GET_PREMIUM_EVENTS_SUCCESS,
        payload: "Events successfully loaded."
    }
}

export function getPremiumEventsError() {
    return {
        type: GET_PREMIUM_EVENTS_ERROR,
        payload: "Error while loading events."
    }
}


export function setLoadingSelectedEvent() {
    return {
        type: SET_LOADING_GET_SELECTED_EVENT,
        payload: "Loading set before action"
    }
}

export function getSelectedEvent(params='') {
    return {
        type: GET_SELECTED_EVENT,
        payload: axiosManager(GET, EndpointsUrl.GET_SELECTED_EVENT, params)
    }
}

export function getSelectedEventSuccess() {
    return {
        type: GET_SELECTED_EVENT_SUCCESS,
        payload: "Event successfully loaded."
    }
}

export function getSelectedEventError() {
    return {
        type: GET_SELECTED_EVENT_ERROR,
        payload: "Error while loading event."
    }
}


export function setLoadingSelectedEventToUpdate() {
    return {
        type: SET_LOADING_GET_SELECTED_EVENT_TO_UPDATE,
        payload: "Loading set before action"
    }
}

export function getSelectedEventToUpdate(params='') {
    return {
        type: GET_SELECTED_EVENT_TO_UPDATE,
        payload: axiosManager(GET, EndpointsUrl.GET_SELECTED_EVENT_TO_UPDATE, params)
    }
}

export function getSelectedEventToUpdateSuccess() {
    return {
        type: GET_SELECTED_EVENT_TO_UPDATE_SUCCESS,
        payload: "Event successfully loaded."
    }
}

export function getSelectedEventToUpdateError() {
    return {
        type: GET_SELECTED_EVENT_TO_UPDATE_ERROR,
        payload: "Error while loading event."
    }
}

export function setLoadingLikeOrDislikeEvent() {
    return {
        type: SET_LOADING_LIKE_OR_DISLIKE_EVENT,
        payload: "Loading set before action"
    }
}

export function likeOrDislikeEvent(id='') {
    let form_data = new FormData();
    form_data.append('event_id', id);
    return {
        type: LIKE_OR_DISLIKE_EVENT,
        payload: axiosManager(POST, EndpointsUrl.LIKE_OR_DISLIKE_EVENT, form_data)
    }
}

export function likeOrDislikeEventSuccess() {
    return {
        type: LIKE_OR_DISLIKE_EVENT_SUCCESS,
        payload: "Event successfully liked."
    }
}

export function likeOrDislikeEventError() {
    return {
        type: LIKE_OR_DISLIKE_EVENT_ERROR,
        payload: "Error."
    }
}

export function setLoadingGetUsersFilter() {
    return {
        type: SET_LOADING_GET_USERS_FILTER,
        payload: "Loading set before action"
    }
}

export function getUsersFilter(name) {
    return {
        type: GET_USERS_FILTER,
        payload: axiosManager(GET, EndpointsUrl.GET_USERS_FILTER, {name})
    }
}

export function getUsersFilterSuccess() {
    return {
        type: GET_USERS_FILTER_SUCCESS,
        payload: null
    }
}

export function getUsersFilterError() {
    return {
        type: GET_USERS_FILTER_ERROR,
        payload: null
    }
}

export function setLoadingRemoveEventPerformer() {
    return {
        type: SET_LOADING_REMOVE_EVENT_PERFORMER,
        payload: "Loading set before action"
    }
}

export function removeEventPerformer(event_id) {
    return {
        type: REMOVE_EVENT_PERFORMER,
        payload: axiosManager(GET, EndpointsUrl.REMOVE_EVENT_PERFORMER, {event_id})
    }
}

export function removeEventPerformerSuccess() {
    return {
        type: REMOVE_EVENT_PERFORMER_SUCCESS,
        payload: null
    }
}

export function removeEventPerformerError() {
    return {
        type: REMOVE_EVENT_PERFORMER_ERROR,
        payload: null
    }
}

export function setLoadingGetSimilarEvents() {
    return {
        type: SET_LOADING_GET_SIMILAR_EVENTS,
        payload: "Loading set before action"
    }
}

export function getSimilarEvents(event_id, ofset, limit) {
    return {
        type: GET_SIMILAR_EVENTS,
        payload: axiosManager(GET, EndpointsUrl.GET_SIMILAR_EVENTS, {event_id, ofset, limit})
    }
}

export function getSimilarEventsSuccess() {
    return {
        type: GET_SIMILAR_EVENTS_SUCCESS,
        payload: null
    }
}

export function getSimilarEventsError() {
    return {
        type: GET_SIMILAR_EVENTS_ERROR,
        payload: null
    }
}

export function setLoadingRemoveEvent() {
    return {
        type: SET_LOADING_REMOVE_EVENT,
        payload: "Loading set before action"
    }
}

export function removeEvent(id) {
    return {
        type: REMOVE_EVENT,
        payload: axiosManager(POST, EndpointsUrl.REMOVE_EVENT, {id})
    }
}

export function removeEventSuccess() {
    return {
        type: REMOVE_EVENT_SUCCESS,
        payload: null
    }
}

export function removeEventError() {
    return {
        type: REMOVE_EVENT_ERROR,
        payload: null
    }
}

export function setLoadingGetEventsForCalendar() {
    return {
        type: SET_LOADING_GET_EVENTS_FOR_CALENDAR,
        payload: "Loading set before action"
    }
}

export function getEventsForCalendar(params='') {
    return {
        type: GET_EVENTS_FOR_CALENDAR,
        payload: axiosManager(GET, EndpointsUrl.GET_EVENTS_FOR_CALENDAR, params)
    }
}

export function getEventsForCalendarSuccess() {
    return {
        type: GET_EVENTS_FOR_CALENDAR_SUCCESS,
        payload: "Events successfully loaded."
    }
}

export function getEventsForCalendarError() {
    return {
        type: GET_EVENTS_FOR_CALENDAR_ERROR,
        payload: "Error while loading events."
    }
}


export function setLoadingGetAllEvents() {
    return {
        type: SET_LOADING_GET_ALL_EVENTS,
        payload: "Loading set before action"
    }
}

export function getAllEvents(params='') {
    return {
        type: GET_ALL_EVENTS,
        payload: axiosManager(GET, EndpointsUrl.GET_EVENTS, params)
    }
}

export function getAllEventsSuccess() {
    return {
        type: GET_ALL_EVENTS_SUCCESS,
        payload: "Events successfully loaded."
    }
}

export function getAllEventsError() {
    return {
        type: GET_ALL_EVENTS_ERROR,
        payload: "Error while loading events."
    }
}