import React from 'react';
import './InformationBanner.css';
import {INFORMATION_BANNER_SENT_EMAIL, INFORMATION_BANNER_SUCCESSFUL_NOTIFICATION, INFORMATION_BANNER_SUCCESSFUL_PASSWORD_CHANGED, ALT_ICON, INFORMATION_BANNER_ACTIVATION_LINK} from '../../config/TextContent';
import {Link} from 'react-router-dom';

const InformationBanner = (props) => {
    return (
        <div className="information-banner">
            <div className="information-banner__content">
                <div className="container">
                    <div className="col-md-12 col-xs-11">
                        {props.banner === "uspesna-registracia" ?
                        <p>{INFORMATION_BANNER_SENT_EMAIL}</p>
                        : null}
                        {props.banner === "uspesne-potvrdenie" ?
                        <p>{INFORMATION_BANNER_SUCCESSFUL_NOTIFICATION}</p>
                        : null}
                        {props.banner === "aktivacny-link" ?
                        <p>{INFORMATION_BANNER_ACTIVATION_LINK}</p>
                        : null}
                        {props.banner === "uspesna-zmena-hesla" ?
                        <p>{INFORMATION_BANNER_SUCCESSFUL_PASSWORD_CHANGED}</p>
                        : null}
                    </div>
                </div>
            </div>
            {props.banner === "uspesna-registracia" ? 
                <Link to="/" className="information-banner__close information-banner__close__img-successful-registration">
                    <img src="/img/icons/close-cross.svg" alt={ALT_ICON}/>
                </Link> : 
                <Link to="/" className="information-banner__close">
                    <img src="/img/icons/close-cross.svg" alt={ALT_ICON}/>
                </Link>
            }
        </div>
    )
}

export default InformationBanner;
