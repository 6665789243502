import React from 'react';
import Registration from '../components/Homepage/Registration/Registration';
import PreRegisterModal from '../components/Header/RegistrationModal/PreRegisterModal';
import IntroPage from '../components/Homepage/IntroPage/IntroPage';
import CollectionOfEventSliders from '../components/CollectionOfEventSliders/CollectionOfEventSliders';
import InformationBanner from '../components/InformationBanner/InformationBanner';
import { isLoggedInUserInLocalStorage } from '../actions/UserActions';
import FixedButtonAddEvent from '../components/FixedButtonAddEvent/FixedButtonAddEvent';
import EventFilters from '../components/Dashboard/EventFilters/EventFilters';

const Homepage = (props) => {
    return (
        <div>
            {props.match.params && props.match.params.banner && props.match.params.banner === "uspesna-registracia" ?
                <InformationBanner banner="uspesna-registracia" />    
            : null}
            {props.match.params && props.match.params.banner && props.match.params.banner === "uspesne-potvrdenie" ?
                <InformationBanner banner="uspesne-potvrdenie" />    
            : null}
            {props.match.params && props.match.params.banner && props.match.params.banner === "aktivacny-link" ?
                <InformationBanner banner="aktivacny-link" />    
            : null}
            <PreRegisterModal />
            {isLoggedInUserInLocalStorage() ? <FixedButtonAddEvent/> : null}
            <IntroPage />
            {!isLoggedInUserInLocalStorage() ? <Registration /> : null}
            <div className="container" id="events">
                <div className="row">
                    <div className="col-md-12 col-xs-12">
                        {isLoggedInUserInLocalStorage() ? <EventFilters /> : null}
                        <CollectionOfEventSliders /> 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homepage;
