import React from 'react';
import {DELETE, PERFORMER_DATE_AND_TIME} from '../../../../config/TextContent';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import DatePicker, {registerLocale} from "react-datepicker";
import sk from "date-fns/locale/sk";
import './ProgramList.css';

registerLocale("sk", sk);

const ProgramList = (props) => {
    return(
        props.programList ? (
            props.programList.map((element) => {
                return (
                    <ListItem className="performer-list__item program-list__item" key={element.id}>
                        <TextField
                            required
                            spellCheck="false"
                            autoComplete="off"
                            autoCorrect="off"
                            type="text"
                            name="programTitle"
                            value={element.name}
                            className={element.errors.name ? "create-event-modal-content__modal-step__input-name label_color_red" : "create-event-modal-content__modal-step__input-name"}
                            label={element.errors.name ? "Názov (" + element.errors.name + ")" : "Názov"}
                            fullWidth={false}
                            placeholder="..."
                            onChange={(event) => props.onNameOfProgramItemChange(event, element.id)}
                        />
                        <p className={element.errors && element.errors.date ? "createEventModalContent__textfield label_color_red" : "createEventModalContent__textfield"}>
                            {element.errors && element.errors.date ? (PERFORMER_DATE_AND_TIME + " (" + element.errors.date + ")") : PERFORMER_DATE_AND_TIME}
                        </p>
                        <DatePicker
                            openToDate={element.date ? element.date : props.eventDate}
                            dateFormat="dd.MM.yyyy HH:mm"
                            disabledKeyboardNavigation
                            selected={element.date}
                            minDate={props.eventDate}
                            maxDate={props.eventDateEnd ? props.eventDateEnd : null}
                            onChange={(date) => props.onDateOfProgramItemChange(date, element.id)}
                            showTimeSelect
                            timeIntervals="30"
                            timeFormat="HH:mm"
                            locale="sk"
                        />
                        <ListItemIcon className="performer-list__item__button">
                            <button type="button" className="performer-list__item__delete-button" onClick={() => props.deleteProgramItem(element.id)}>
                                {DELETE}
                            </button>
                        </ListItemIcon>
                    </ListItem>
                );
            })
        ) : null
    )
}
    
export default ProgramList;
