import EndpointsUrl from "../client/EndpointsUrl";
import {loggedIn, saveToken, fullLogout} from '../actions/AuthActions';
import {toggleModalLogin} from '../actions/ModalActions';
import axiosManager from '../client/AxiosManager/AxiosManager';
import {GET, POST} from '../client/AxiosManager/AxiosManagerConstants';
import { GET_USER_ROUTE_TO_USER_EVENTS } from "../config/RoutesConstants";

export const SET_LOADING_SET_SELECTED_USER = 'SET_LOADING_SET_SELECTED_USER';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SET_SELECTED_USER_SUCCESS = 'SET_SELECTED_USER_SUCCESS';
export const SET_SELECTED_USER_ERROR = 'SET_SELECTED_USER_ERROR';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_ERROR = 'GET_USER_BY_ID_ERROR';

export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const SAVE_USER_DATA_SUCCESS = 'SAVE_USER_DATA_SUCCESS';
export const SAVE_USER_DATA_ERROR = 'SAVE_USER_DATA_ERROR';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_ERROR = 'UPDATE_USER_DATA_ERROR';

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';

export const DELETE_USER_DATA = 'DELETE_USER_DATA';
export const DELETE_USER_DATA_SUCCESS = 'DELETE_USER_DATA_SUCCESS';
export const DELETE_USER_DATA_ERROR = 'DELETE_USER_DATA_ERROR';

export const SAVE_TITLE_IMAGE = 'SAVE_TITLE_IMAGE';
export const SAVE_TITLE_IMAGE_SUCCESS = 'SAVE_TITLE_IMAGE_SUCCESS';
export const SAVE_TITLE_IMAGE_ERROR = 'SAVE_TITLE_IMAGE_ERROR';

export const SAVE_COVER_IMAGE = 'SAVE_COVER_IMAGE';
export const SAVE_COVER_IMAGE_SUCCESS = 'SAVE_COVER_IMAGE_SUCCESS';
export const SAVE_COVER_IMAGE_ERROR = 'SAVE_COVER_IMAGE_ERROR';

export const SET_LOADING_REMOVE_USER = 'SET_LOADING_REMOVE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const SET_LOADING_GET_LIST_OF_CLUBS = 'SET_LOADING_GET_LIST_OF_CLUBS';
export const GET_LIST_OF_CLUBS = 'GET_LIST_OF_CLUBS';
export const GET_LIST_OF_CLUBS_SUCCESS = 'GET_LIST_OF_CLUBS_SUCCESS';
export const GET_LIST_OF_CLUBS_ERROR = 'GET_LIST_OF_CLUBS_ERROR';

export const SET_LOADING_GET_LIST_OF_BANDS = 'SET_LOADING_GET_LIST_OF_BANDS';
export const GET_LIST_OF_BANDS = 'GET_LIST_OF_BANDS';
export const GET_LIST_OF_BANDS_SUCCESS = 'GET_LIST_OF_BANDS_SUCCESS';
export const GET_LIST_OF_BANDS_ERROR = 'GET_LIST_OF_BANDS_ERROR';


const setLoadingSetSelectedUser = () => {
    return {
        type: SET_LOADING_SET_SELECTED_USER,
        payload: "Loading set before action"
    }
}

const setSelectedUser = (username) => {
    return {
        type: SET_SELECTED_USER,
        payload: axiosManager(GET, EndpointsUrl.GET_USER_PROFILE, {user_id: username})
    }
}

const setSelectedUserSuccess = () => {
    return {
        type: SET_SELECTED_USER_SUCCESS,
        payload: 'Selected user successfully loaded.'
    }
}

const setSelectedUserError = () => {
    return {
        type: SET_SELECTED_USER_ERROR,
        payload: 'Selected user loaded wrong.'
    }
}


const createUser = (userData) => {
    return {
        type: CREATE_USER,
        payload: axiosManager(POST, EndpointsUrl.CREATE_USER, userData)
    }
}

const createUserSuccess = () => {
    return {
        type: CREATE_USER_SUCCESS,
        payload: "User successfully created."
    }
}

const createUserError = () => {
    return {
        type: CREATE_USER_ERROR,
        payload: "Error while creating user"
    }
}

const saveUserData = (userData) => {
    return {
        type: SAVE_USER_DATA,
        payload: axiosManager(POST, EndpointsUrl.POST_USER_DATA, userData)
    }
}

const saveUserDataSuccess = () => {
    return {
        type: SAVE_USER_DATA_SUCCESS,
        payload: "User data successfully saved."
    }
}

const saveUserDataError = () => {
    return {
        type: SAVE_USER_DATA_ERROR,
        payload: "Error while saving user data."
    }
}

const updateUserData = (userData) => {
    return {
        type: UPDATE_USER_DATA,
        payload: axiosManager(POST, EndpointsUrl.SET_USER_DATA, userData)
    }
}

const updateUserDataSuccess = () => {
    return {
        type: UPDATE_USER_DATA_SUCCESS,
        payload: "User data successfully updated."
    }
}

const updateUserDataError = () => {
    return {
        type: UPDATE_USER_DATA_ERROR,
        payload: "Error while updating user data."
    }
}

const getUser = (user_id=0) => {
    return {
        type: GET_USER,
        payload: axiosManager(GET, EndpointsUrl.GET_USER_PROFILE, {user_id})
    }
}

const getUserSuccess = () => {
    return {
        type: GET_USER_SUCCESS,
        payload: "User successfully loaded"
    }
}

const getUserError = () => {
    return {
        type: GET_USER_ERROR,
        payload: "Error while loading user"
    }
}

const getUserById = (id) => {
    return {
        type: GET_USER_BY_ID
    }
}

const getUserByIdSuccess = () => {
    return {
        type: GET_USER_BY_ID_SUCCESS,
        payload: "User successfully loaded"
    }
}

const getUserByIdError = () => {
    return {
        type: GET_USER_BY_ID_ERROR,
        payload: "Error while getting user"
    }
}

const getUserData = (id) => {
    return {
        type: GET_USER_DATA,
        payload: axiosManager(GET, EndpointsUrl.GET_USER_DATA, {id})
    }
}

const getUserDataSuccess = () => {
    return {
        type: GET_USER_DATA_SUCCESS,
        payload: "User data found"
    }
}

const getUserDataError = () => {
    return {
        type: GET_USER_DATA_ERROR,
        payload: "User data not found"
    }
}


const deleteUserData = () => {
    return {
        type: DELETE_USER_DATA,
        payload: "Deleting user data"
    }
}

const deleteUserDataSuccess = () => {
    return {
        type: DELETE_USER_DATA_SUCCESS,
        payload: "User data successfully deleted"
    }
}

const deleteUserDataError = () => {
    return {
        type: DELETE_USER_DATA_ERROR,
        payload: "Error while deleting user data"
    }
}

const getUserHandling = (then_func = () => {}, err_func = () => {}) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(getUser()).then((res) => {
                if(res.error && res.payload.response && res.payload.response.status && res.payload.response.status === 401 && res.payload.response.data && res.payload.response.data.error && res.payload.response.data.error_description && res.payload.response.data.error === "access_denied" && res.payload.response.data.error_description === "User account is disabled."){
                    dispatch(getUserErrorWithLogout());
                    dispatch(toggleModalLogin(false, true));
                    if(!window.location.pathname !== "/home/aktivacny-link"){
                        window.scrollTo(0, 0);
                        window.location.replace("/home/aktivacny-link");
                    }
                }else{
                    if(res.payload.data){
                        dispatch(getUserSuccess());
                    }else{
                        dispatch(getUserErrorWithLogout());                    
                    }
                }
                then_func(res.payload.data);
                resolve();
            }).catch(() => {
                err_func();
                dispatch(getUserErrorWithLogout()); 
                reject();
            });
        });
    }
}

const isLoggedInUserInLocalStorage = () => {
    if(localStorage && localStorage.getItem("token")){
        let parsedToken = JSON.parse(localStorage.getItem("token"));
        let actual_timestamp = new Date().getTime();
        if(parsedToken.timestamp > actual_timestamp && parsedToken.value && parsedToken.re_token){
            return true;
        }
    }
    return false;
}

const loginSuccessfull = (res) => {
    return dispatch => {
        dispatch(loggedIn());
        dispatch(saveToken(res.payload.data));
        dispatch(getUserHandling((data) => {
            if(data && data.user && !data.user_data){
                window.scrollTo(0, 0);
                window.location.replace(GET_USER_ROUTE_TO_USER_EVENTS(data.user));
            }
        }, () => {

        }));
        dispatch(toggleModalLogin(false, true));
    }
}

const getUserErrorWithLogout = () => {
    return dispatch => {
        dispatch(getUserError());
        dispatch(fullLogout());
    }
}

const saveTitlePhoto = (photo) => {
    let form_data = new FormData();
    form_data.append('title_photo', photo);

    const request = axiosManager(POST, EndpointsUrl.FILE_TITLE_PICTURE, form_data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return {
        type: SAVE_TITLE_IMAGE,
        payload: request
    }
}

const saveTitlePhotoSuccess = () => {
    return {
        type: SAVE_TITLE_IMAGE_SUCCESS,
        payload: "Successfuly saved title picture"
    }
}

const saveTitlePhotoError = () => {
    return {
        type: SAVE_TITLE_IMAGE_ERROR,
        payload: "Error while publishing new title picture"
    }
}

const saveCoverPhoto = (photo) => {
    let form_data = new FormData();
    form_data.append('cover_photo', photo);

    const request = axiosManager(POST, EndpointsUrl.FILE_COVER_PICTURE, form_data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return {
        type: SAVE_COVER_IMAGE,
        payload: request
    }
}

const saveCoverPhotoSuccess = () => {
    return {
        type: SAVE_COVER_IMAGE_SUCCESS,
        payload: "Successfuly saved cover picture"
    }
}

const saveCoverPhotoError = () => {
    return {
        type: SAVE_COVER_IMAGE_ERROR,
        payload: "Error while publishing new cover picture"
    }
}

const isLoggedInUser = (user) => {
    if(user && user.logged_in){
        return true;
    }
    return false;
}


const setLoadingRemoveUser = () => {
    return {
        type: SET_LOADING_REMOVE_USER,
        payload: "Loading set before action"
    }
}

const removeUser = () => {
    return {
        type: REMOVE_USER,
        payload: axiosManager(POST, EndpointsUrl.REMOVE_USER)
    }
}

const removeUserSuccess = () => {
    return {
        type: REMOVE_USER_SUCCESS,
        payload: "User successfully removed."
    }
}

const removeUserError = () => {
    return {
        type: REMOVE_USER_ERROR,
        payload: "Error while removing user."
    }
}

const changePassword = (data) => {
    let form_data = new FormData();
    form_data.append('current_password', data.current_password);
    form_data.append('plainPassword', data.plainPassword);
    const request = axiosManager(POST, EndpointsUrl.CHANGE_PASSWORD, form_data);

    return {
        type: CHANGE_PASSWORD,
        payload: request
    }
}

const changePasswordSuccess = () => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload: "Successfuly saved cover picture."
    }
}

const changePasswordError = (data) => {
    return {
        type: CHANGE_PASSWORD_ERROR,
        payload: "Error while changing password."
    }
}

const setLoadingGetListOfClubs = () => {
    return {
        type: SET_LOADING_GET_LIST_OF_CLUBS,
        payload: "Loading set before action"
    }
}

const getListOfClubs = (params='') => {
    return {
        type: GET_LIST_OF_CLUBS,
        payload: axiosManager(GET, EndpointsUrl.GET_CLUBS, params)   
    }
}

const getListOfClubsSuccess = () => {
    return {
        type: GET_LIST_OF_CLUBS_SUCCESS,
        payload: "Clubs were successfuly loaded."
    }
}

const getListOfClubsError = () => {
    return {
        type: GET_LIST_OF_CLUBS_ERROR,
        payload: "Error while loading clubs."
    }
}


const setLoadingGetListOfBands = () => {
    return {
        type: SET_LOADING_GET_LIST_OF_BANDS,
        payload: "Loading set before action"
    }
}

const getListOfBands = (params='') => {
    return {
        type: GET_LIST_OF_BANDS,
        payload: axiosManager(GET, EndpointsUrl.GET_BANDS, params)   
    }
}

const getListOfBandsSuccess = () => {
    return {
        type: GET_LIST_OF_BANDS_SUCCESS,
        payload: "Bands were successfuly loaded."
    }
}

const getListOfBandsError = () => {
    return {
        type: GET_LIST_OF_BANDS_ERROR,
        payload: "Error while loading bands."
    }
}

export { 
    isLoggedInUserInLocalStorage,
    setLoadingSetSelectedUser,
    setSelectedUser,
    createUser,
    createUserSuccess,
    createUserError,
    saveUserData,
    saveUserDataSuccess,
    saveUserDataError,
    updateUserData,
    updateUserDataSuccess,
    updateUserDataError,
    getUser,
    getUserSuccess,
    getUserError,
    getUserById,
    getUserByIdSuccess,
    getUserByIdError,
    getUserData,
    getUserDataError,
    getUserDataSuccess,
    deleteUserData,
    deleteUserDataSuccess,
    deleteUserDataError,
    getUserHandling,
    loginSuccessfull,
    getUserErrorWithLogout,
    setSelectedUserSuccess,
    setSelectedUserError,
    saveTitlePhoto,
    saveTitlePhotoError,
    saveTitlePhotoSuccess,
    saveCoverPhoto,
    saveCoverPhotoError,
    saveCoverPhotoSuccess,
    isLoggedInUser,
    setLoadingRemoveUser,
    removeUser,
    removeUserError,
    removeUserSuccess,
    changePassword,
    changePasswordError,
    changePasswordSuccess,
    setLoadingGetListOfClubs,
    getListOfClubs,
    getListOfClubsSuccess,
    getListOfClubsError,
    setLoadingGetListOfBands,
    getListOfBands,
    getListOfBandsSuccess,
    getListOfBandsError
};
