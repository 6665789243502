import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import './MobileNavigation.css';
import {LOGIN_TEXT, REGISTER_TEXT, ALT_ICON, MENU_LOGOUT, UPDATE_PROFILE, MENU_CLUBS, MY_PROFILE, CREATE_EVENT_BUTTON, MENU_BANDS, MENU_EVENTS} from '../../../../config/TextContent';
import {toggleModalLogin, toggleModalRegister} from '../../../../actions/ModalActions';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import { Config } from '../../../../config/Constants';
import {GET_USER_ROUTE, GET_USER_ROUTE_TO_CLUBS, GET_USER_ROUTE_TO_BANDS, GET_USER_ROUTE_TO_USER_EVENTS} from '../../../../config/RoutesConstants';
import {fullLogout} from '../../../../actions/AuthActions';
import {Redirect} from 'react-router-dom';
import {getUserTypeNumber} from '../../../../client/Helper';

const logout = (props) => {
    props.fullLogout();
    return <Redirect to="/" />
}

class MobileNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
        };

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
    }

    toggleNavbar() {
        this.setState((prevState) => {
            return {
                collapsed: !prevState.collapsed,
            }
        });
    }

    closeNavbar() {
        this.setState({collapsed: false});
    }

    openLogin = () => {
        this.setState(() => {
            return {
                collapsed: false,
            }
        }, () => {
            this.props.toggleModalLogin(true, true);
        })
    }

    openRegister = () => {
        this.setState(() => {
            return {
                collapsed: false,
            }
        }, () => {
            this.props.toggleModalRegister(true, true);
        })
    }

    render() {
        /*
        <li className="padding-top-8">
            <Link to="" target="_blank" className="navbar-body-link-social-site-fb">
                <img src={'/img/icons/facebook.svg'} alt={ALT_ICON} />
            </Link>
            <Link to="" target="_blank" className="navbar-body-link-social-site-instagram">
                <img src={'/img/icons/instagram.svg'} alt={ALT_ICON} />
            </Link>
        </li>
        
        <li>
            <Link to="" target="_blank" className="navbar-body-link-social-site-fb">
                <img src={'/img/icons/facebook.svg'} alt={ALT_ICON} />
            </Link>
            <Link to="" target="_blank" className="navbar-body-link-social-site-instagram">
                <img src={'/img/icons/instagram.svg'} alt={ALT_ICON} />
            </Link>
        </li>
        */
        const collapsed  = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse show' : 'collapse navbar-collapse';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        return ( 
            <div className="header__navigation__mobile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav className="navbar">
                                <div className="navigation__mobile__navbar">    
                                    <Link to='/' className="navigation__mobile__navbar__logo-wrapper">Event<span>eeko</span></Link>
                                    <button onClick={this.toggleNavbar} className={`${classTwo} navigation__mobile__navbar__button`} type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                                        {
                                            this.state.collapsed ? 
                                                <img src={'/img/icons/close-cross.svg'} alt={ALT_ICON} className="navbar-toggler-icon" />
                                            : 
                                                <img src={'/img/icons/menu.svg'} alt={ALT_ICON} className="navbar-toggler-icon" />
                                        }
                                    </button>
                                </div>
                                <div className={`${classOne}`} id="navbarResponsive">
                                    {this.props.isAuthenticatedNavigation ? (
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item nav-item__profile">
                                            <Link to={GET_USER_ROUTE(this.props.user)} onClick={this.closeNavbar} className="navbar-body-link">
                                                <div className="nav-item__profile__content__image" style={{backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url('+ Config.getUserPhoto(this.props.user, Config.CONSTANT_TITLE_PICTURE) +')'}}></div>
                                                <span>
                                                    {MY_PROFILE}
                                                </span>
                                            </Link>
                                        </li>
                                        {this.props.role !== null && this.props.role !== 4 ? (
                                             <li className="nav-item nav-item__button-add-event">
                                                <Link to={Config.ADD_EVENT_ROOT} onClick={this.closeNavbar} className="navbar-body-link">
                                                    {CREATE_EVENT_BUTTON}
                                                </Link>
                                            </li>
                                        ) : null}
                                        <li className="nav-item padding-top-8">
                                            <Link to={GET_USER_ROUTE_TO_USER_EVENTS(this.props.user)} onClick={this.closeNavbar} className="navbar-body-link">
                                                {MENU_EVENTS}
                                            </Link>
                                        </li>
                                        {
                                            [2, 3, 4].includes(this.props.role) ? 
                                            <li className="nav-item">
                                                <Link to={GET_USER_ROUTE_TO_BANDS(this.props.user)} onClick={this.closeNavbar} className="navbar-body-link">
                                                    {MENU_BANDS}
                                                </Link>
                                            </li> : null
                                        }
                                        {
                                            [1, 3, 4].includes(this.props.role) ? 
                                            <li className="nav-item">
                                                <Link to={GET_USER_ROUTE_TO_CLUBS(this.props.user)} onClick={this.closeNavbar} className="navbar-body-link">
                                                    {MENU_CLUBS}
                                                </Link>
                                            </li> : null
                                        }
                                        <li className="nav-item padding-top-8">
                                            <Link to={Config.UPDATE_USER_DATA_ROOT} onClick={this.closeNavbar} className="navbar-body-link navbar-body-link-first">
                                                {UPDATE_PROFILE}
                                            </Link>
                                        </li>
                                        <li className="nav-item padding-top-8">
                                            <Link to="/" onClick={() => logout(this.props)} className="navbar-body-link">
                                                {MENU_LOGOUT}
                                            </Link>
                                        </li>
                                    </ul>
                                    ) : (
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item active">
                                            <Link to={Config.LOGIN_ROOT} onClick={this.openLogin} className="navbar-body-link navbar-body-link-first">
                                                <span>{LOGIN_TEXT}</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <HashLink smooth to="#registracia" className="navbar-body-link">
                                                <span>{REGISTER_TEXT}</span>
                                            </HashLink>
                                        </li>
                                    </ul>
                                    )}
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MobileNavigation.propTypes = {
    toggleModalLogin: PropTypes.func.isRequired,
    toggleModalRegister: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        role: getUserTypeNumber(state.userReducer.user)
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleModalLogin,
        toggleModalRegister,
        fullLogout
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(MobileNavigation);
