import React from 'react';
import './ArticleHeader.css';
import { Link } from 'react-router-dom';
import {ALT_ICON} from '../../../../../config/TextContent';

const ArticleHeader = () => {
    return (
            <div>
                <div className="contribution__table__content__title">
                    <Link to="/about">
                        <p>Koncert v Prahe bude plne obsadený</p>
                    </Link>
                </div>
                <div className="contribution__table__content__info">
                    <div className="contribution__table__content__time__date">
                        <img src={'/img/icons/calendar.svg'} className="contribution__table__icon-calendar" alt={ALT_ICON}/>
                        <p className="contribution__table__time">15:55</p>
                        <p className="contribution__table__date">20.08.2019</p>
                    </div>
                    <div className="contribution__table__content__author">
                        <p>Peter Sabo</p>
                    </div>
                </div>
            </div>
    )
}

export default ArticleHeader;
