import React, {Component} from 'react';
import List from '@material-ui/core/List';
import {GROUP_TITLE_PAYMENT, PRICE_PAYMENT, NEW_FINANCE_ITEM, DELETE, REQUIRED_TEXT_FIELD, ALT_ICON} from '../../../../../config/TextContent';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';

class FinanceList extends Component {
    render(){
        return (
            <List>
                {this.props.type_of_finance === 2 && this.props.financeList.length === 1 ? (
                    <ListItem className="finance-list-item">
                        <TextField
                            required
                            className={this.props.financeList[0].errors && this.props.financeList[0].errors.price ? "label_color_red finance-list-item__text-field display-inline-block-important" : "finance-list-item__text-field display-inline-block-important"}
                            name="price"
                            label={this.props.financeList[0].errors && this.props.financeList[0].errors.price ? PRICE_PAYMENT + " (" + REQUIRED_TEXT_FIELD + ")" : PRICE_PAYMENT}
                            value={this.props.financeList[0].price}
                            onChange={this.props.onSinglePriceAsFinanceItemChange}
                        />
                    </ListItem>
                ) : null}
                {this.props.financeList.length > 0 ? (
                    this.props.financeList.map((element, index) => {
                        return (
                            <ListItem className="finance-list-item" key={index}>
                                {this.props.type_of_finance === 3 ? (
                                    <div>
                                        <TextField
                                            required
                                            className={element.errors && element.errors.name ? "label_color_red finance-list-item__text-field display-inline-block-important" : "finance-list-item__text-field display-inline-block-important"}
                                            name="name"
                                            label={element.errors && element.errors.name ? PRICE_PAYMENT + " (" + REQUIRED_TEXT_FIELD + ")" : GROUP_TITLE_PAYMENT}
                                            value={element.name}
                                            onChange={(event) => {this.props.onFinanceListItemTextChange(event, element.id)}}
                                        />
                                        <TextField
                                            required
                                            className={element.errors && element.errors.price ? "label_color_red finance-list-item__text-field display-inline-block-important" : "finance-list-item__text-field display-inline-block-important"}
                                            name="price"
                                            label={element.errors && element.errors.price ? PRICE_PAYMENT + " (" + REQUIRED_TEXT_FIELD + ")" : PRICE_PAYMENT}
                                            value={element.price}
                                            onChange={(event) => {this.props.onFinanceListItemTextChange(event, element.id)}}
                                        />
                                        <button type="button" className="finance-list-item__add-button finance-list-item__delete-bottom" onClick={() => this.props.deleteFinanceItem(element.id)}>
                                            <span className="finance-list-item__add-button-text">{DELETE}</span>
                                            <span className="finance-list-item__delete-button-icon">
                                                <img src="/img/icons/close-cross.svg" alt={ALT_ICON}/>
                                            </span>
                                        </button>
                                    </div>
                                ) : null}
                            </ListItem>
                        )
                    })
                ) : null}

                <ListItem className="finance-list-item new-finance-list-item">
                    {this.props.type_of_finance === 3 && this.props.showButtonNewFinanceItem ? (
                        <button type="button" className="finance-list-item__show-new-finance-item" onClick={this.props.onShowNewFinanceItem}>
                            <span className="finance-list-item__show-new-finance-item__image">
                                <img src="/img/icons/close-cross.svg" alt={ALT_ICON}/>
                            </span>
                            <span className="finance-list-item__show-new-finance-item__text">{NEW_FINANCE_ITEM}</span>
                        </button>
                    ) : null }
                    {this.props.type_of_finance === 3 && this.props.showNewFinanceItem ? (
                        <div>
                            <TextField
                                required
                                className={this.props.newFinanceItem.errors && this.props.newFinanceItem.errors.name ? "label_color_red finance-list-item__text-field display-inline-block-important" : "finance-list-item__text-field display-inline-block-important"}
                                name="name"
                                label={this.props.newFinanceItem.errors && this.props.newFinanceItem.errors.name ? GROUP_TITLE_PAYMENT + " (" + REQUIRED_TEXT_FIELD + ")" : GROUP_TITLE_PAYMENT}
                                value={this.props.newFinanceItem.name}
                                onChange={this.props.onNewFinanceItemTextChange}
                            />
                            <TextField
                                required
                                className={this.props.newFinanceItem.errors && this.props.newFinanceItem.errors.price ? "label_color_red finance-list-item__text-field display-inline-block-important" : "finance-list-item__text-field display-inline-block-important"}
                                name="price"
                                label={this.props.newFinanceItem.errors && this.props.newFinanceItem.errors.price ? PRICE_PAYMENT + " (" + REQUIRED_TEXT_FIELD + ")" : PRICE_PAYMENT}
                                value={this.props.newFinanceItem.price}
                                onChange={this.props.onNewFinanceItemTextChange}
                            />
                        </div>
                    ) : null }
                </ListItem>
            </List>
        );
    }
}

export default FinanceList;
