
class EndpointsUrl {
    static GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
    static GET_CITIES = 'GET_CITIES';
    static GET_REGIONS = 'GET_REGIONS';
    static GET_GENRES = 'GET_GENRES';
    static GET_SUB_GENRES = 'GET_SUB_GENRES';
    static GET_MAIN_GENRES = 'GET_MAIN_GENRES';
    static GET_CLUBS = 'GET_CLUBS';
    static GET_BANDS = 'GET_BANDS';
    static GET_CLUB_TYPES = 'GET_CLUB_TYPES';

    static CREATE_USER = 'CREATE_USER';
    static GET_USERS = 'GET_USERS';
    static GET_USER_PROFILE = 'GET_USER_PROFILE';
    static GET_USER_DATA = 'GET_USER_DATA';
    static POST_USER_DATA = 'POST_USER_DATA';

    static ADD_PHOTO = 'ADD_PHOTO';
    static DELETE_PHOTO_UUID = 'DELETE_PHOTO_UUID';
    static DELETE_PHOTO_ID = 'DELETE_PHOTO_ID';

    static SETTING_USERNAME = 'SETTING_USERNAME';
    static SET_USER_DATA = 'SET_USER_DATA';
    static SET_EVENT_DATA = 'SET_EVENT_DATA';
    static SETTING_USER_PROFILE = 'SETTING_USER_PROFILE';
    static SETTING_PASSWORD = 'SETTING_PASSWORD';

    static GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
    static DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

    static SEND_COMMUNICATION = 'SEND_COMMUNICATION';
    static EDIT_COMMUNICATION = 'EDIT_COMMUNICATION';
    static GET_COMMUNICATION = 'GET_COMMUNICATION';
    static REFUSE_COMMUNICATION = 'REFUSE_COMMUNICATION';
    static NOT_INTERESTED_COMMUNICATION = 'NOT_INTERESTED_COMMUNICATION';
    static ACCEPT_COMMUNICATION = 'ACCEPT_COMMUNICATION';

    static SAVE_EVENT = 'SAVE_EVENT';
    static SAVE_ORDINARY_EVENT = 'SAVE_ORDINARY_EVENT';
    static GET_EVENT = 'GET_EVENT';
    static CANCEL_EVENT = 'CANCEL_EVENT';
    static GET_EVENT_LIST = 'GET_EVENT_LIST';
    static GET_EVENT_LIST_BY_USER = 'GET_EVENT_LIST_BY_USER';
    static GET_EVENTS_MY = 'GET_EVENTS_MY';
    static ADD_INTERPRET = 'ADD_INTERPRET';
    static GET_EVENTS = 'GET_EVENTS';
    static GET_EVENTS_FOR_CALENDAR = 'GET_EVENTS_FOR_CALENDAR';
    static GET_EVENTS_BY_USER = 'GET_EVENTS_BY_USER';
    static GET_EVENTS_BY_PERFORMER = 'GET_EVENTS_BY_PERFORMER';

    static GET_POSTS = 'GET_POSTS';
    static LIKE_POST = 'LIKE_POST';

    static FILE = 'FILE';
    static FILE_TITLE_PICTURE = 'FILE_TITLE_PICTURE';

    static FILE_COVER_PICTURE = 'FILE_COVER_PICTURE';

    static CHANGE_PASSWORD = 'CHANGE_PASSWORD';

    static FILE_STAGE_PLAN = 'FILE_STAGE_PLAN';

	static GET_GALLERY = 'GET_GALLERY';
	static MODIFY_GALLERY = 'MODIFY_GALLERY';

    static GET_ALL_EVENT_CATEGORIES = 'GET_ALL_EVENT_CATEGORIES';

    static GET_EVENT_CATEGORIES = 'GET_EVENT_CATEGORIES';

    static GET_EVENT_MUSIC_GENRES = 'GET_EVENT_MUSIC_GENRES';

    static GET_TOP_EVENTS = 'GET_TOP_EVENTS';

    static INSERT_GALLERY_IMAGE = 'INSERT_GALLERY_IMAGE';
    static DELETE_GALLERY_IMAGE_UUID = 'DELETE_GALLERY_IMAGE_UUID';
    static DELETE_GALLERY_IMAGE_ID = 'DELETE_GALLERY_IMAGE_ID';

    static GET_COUNTRIES = 'GET_COUNTRIES';
    static GET_USERS_FILTER = 'GET_USERS_FILTER';

    static GET_PREMIUM_EVENTS = 'GET_PREMIUM_EVENTS';

    static GET_SELECTED_EVENT = 'GET_SELECTED_EVENT';

    static GET_SELECTED_EVENT_TO_UPDATE = 'GET_SELECTED_EVENT_TO_UPDATE';

    static REMOVE_USER = 'REMOVE_USER';

    static LIKE_OR_DISLIKE_EVENT = 'LIKE_OR_DISLIKE_EVENT';

    static REMOVE_EVENT_PERFORMER = 'REMOVE_EVENT_PERFORMER';

    static REMOVE_EVENT = 'REMOVE_EVENT';

    static GET_SIMILAR_EVENTS = 'GET_SIMILAR_EVENTS';

    static ADD_CLUB_PHOTO = 'ADD_CLUB_PHOTO';
    static DELETE_CLUB_GALLERY_IMAGE_UUID = 'DELETE_CLUB_GALLERY_IMAGE_UUID';
    static DELETE_CLUB_GALLERY_IMAGE_ID = 'DELETE_CLUB_GALLERY_IMAGE_ID';
    
    static getEndpointUrl(operation, params) {

        const ROOT_URL = 'https://event-portal-backend.eventeeko.com/';
        //const ROOT_URL = 'http://127.0.0.1:8000/';

        const GET_AUTH_TOKEN_URL = ROOT_URL + 'oauth/v2/token';
        const GET_CITIES_URL = ROOT_URL + 'public/api/cities';
        const GET_REGIONS_URL = ROOT_URL + 'public/api/regions';
        const GET_GENRES_URL = ROOT_URL + 'public/api/genres';
        const GET_SUB_GENRES_URL = ROOT_URL + 'public/api/genres/sub';
        const GET_MAIN_GENRES_URL = ROOT_URL + 'public/api/genres/main';
        const GET_CLUBS_URL = ROOT_URL + 'api/users/clubs';
        const GET_BANDS_URL = ROOT_URL + 'api/users/bands';
        const GET_CLUB_TYPES_URL = ROOT_URL + 'public/api/club-types';
        const GET_COUNTRIES_URL = ROOT_URL + 'public/api/countries';
        const CREATE_USER_URL = ROOT_URL + 'public/api/users/register';
        const GET_EVENT_MUSIC_GENRES_URL = ROOT_URL + 'public/api/genres';
        const GET_ALL_EVENT_CATEGORIES_URL = ROOT_URL + 'public/api/event/categories';
        const GET_EVENT_CATEGORIES_URL = ROOT_URL + 'global/api/event/categories';
        const GET_PREMIUM_EVENTS_URL = ROOT_URL + 'public/api/event/premium-events';
        const GET_TOP_EVENTS_URL = ROOT_URL + 'global/api/event/top-events';
        const GET_EVENTS_FOR_CALENDAR_URL = ROOT_URL + 'global/api/event/events-for-calendar';
        const GET_EVENT_URL = ROOT_URL + 'public/api/event';

        const GET_USER_PROFILE_URL = ROOT_URL + 'global/api/users/profiledata';
        const GET_EVENT_LIST_BY_USER_URL = ROOT_URL + 'api/events-by-user';
        const SAVE_EVENT_URL = ROOT_URL + 'global/api/event';
        const GET_EVENT_LIST_URL = ROOT_URL + 'global/api/events';
        const GET_USER_DATA_URL = ROOT_URL + 'global/api/users/data';
        const GET_USERS_FILTER_URL = ROOT_URL + 'global/api/users/filter';

        //const CANCEL_EVENT_URL = ROOT_URL + 'api/event/cancel';
        const POST_USER_DATA_URL = ROOT_URL + 'api/users/data';
        const GET_EVENTS_BY_PERFORMER_URL = ROOT_URL + 'api/events-by-performer';
        const SAVE_ORDINARY_EVENT_URL = ROOT_URL + 'api/ordinary-event';
        const SET_EVENT_DATA_URL = ROOT_URL + 'api/events/update';
        const CHANGE_PASSWORD_URL = ROOT_URL + 'api/users/changepass';
        const FILE_TITLE_PICTURE_URL = ROOT_URL + 'api/files/titlepicture';
        const FILE_COVER_PICTURE_URL = ROOT_URL + 'api/files/coverpicture';
        const SET_USER_DATA_URL = ROOT_URL + 'api/users/setting/userdata';
        const INSERT_GALLERY_IMAGE_URL = ROOT_URL + 'api/event/gallery';
        const DELETE_GALLERY_IMAGE_UUID_URL = ROOT_URL + 'api/event/gallery/delete/uuid';
        const DELETE_GALLERY_IMAGE_ID_URL = ROOT_URL + 'api/event/gallery/delete/id';
        const REMOVE_USER_URL = ROOT_URL + 'api/users/delete';
        const LIKE_OR_DISLIKE_EVENT_URL = ROOT_URL + 'api/event/like';
        const REMOVE_EVENT_PERFORMER_URL = ROOT_URL + 'api/event/performers/delete';
        const REMOVE_EVENT_URL = ROOT_URL + 'api/event/delete';
        const GET_SIMILAR_EVENTS_URL = ROOT_URL + 'public/api/event/similar-events';
        const ADD_CLUB_PHOTO_URL = ROOT_URL + 'api/club/gallery';
        const DELETE_CLUB_GALLERY_IMAGE_UUID_URL = ROOT_URL + 'api/club/gallery/delete/uuid';
        const DELETE_CLUB_GALLERY_IMAGE_ID_URL = ROOT_URL + 'api/club/gallery/delete/id';
        const ADD_PHOTO_URL = ROOT_URL + 'api/user/photo';
        const DELETE_PHOTO_ID_URL = ROOT_URL + 'api/user/photo/delete/id';
        const DELETE_PHOTO_ID_UUID_URL = ROOT_URL + 'api/user/photo/delete/uuid';

        switch (operation) {
            case 'GET_AUTH_TOKEN':
                return GET_AUTH_TOKEN_URL;
            case 'GET_CITIES':
                return GET_CITIES_URL;
            case 'GET_REGIONS':
                return GET_REGIONS_URL;
            case 'GET_GENRES':
                return GET_GENRES_URL;
            case 'GET_SUB_GENRES':
                return GET_SUB_GENRES_URL;
            case 'GET_MAIN_GENRES':
                return GET_MAIN_GENRES_URL;
            case 'GET_CLUBS':
                return GET_CLUBS_URL;
            case 'GET_BANDS':
                return GET_BANDS_URL;
            case 'GET_CLUB_TYPES':
                return GET_CLUB_TYPES_URL;
            case 'CREATE_USER':
                return CREATE_USER_URL;
            case 'GET_USER_PROFILE':
                return GET_USER_PROFILE_URL;
            case 'GET_USER_DATA':
                return GET_USER_DATA_URL;
            case 'POST_USER_DATA':
                return POST_USER_DATA_URL;
            case 'SET_USER_DATA':
                return SET_USER_DATA_URL;
            case 'SET_EVENT_DATA':
                return SET_EVENT_DATA_URL;
            case 'SAVE_EVENT':
                return SAVE_EVENT_URL;
            case 'SAVE_ORDINARY_EVENT':
                return SAVE_ORDINARY_EVENT_URL;
            case 'GET_SELECTED_EVENT':
                return GET_EVENT_URL;
            case 'GET_EVENTS_FOR_CALENDAR':
                return GET_EVENTS_FOR_CALENDAR_URL;
            case 'GET_SELECTED_EVENT_TO_UPDATE':
                return GET_EVENT_URL;
            /*case 'CANCEL_EVENT':
                return CANCEL_EVENT_URL;
            */
           case 'GET_EVENT_LIST':
                return GET_EVENT_LIST_URL;
            case 'GET_EVENT_LIST_BY_USER':
                return GET_EVENT_LIST_BY_USER_URL;
            case 'FILE_TITLE_PICTURE':
                return FILE_TITLE_PICTURE_URL;
            case 'FILE_COVER_PICTURE':
                return FILE_COVER_PICTURE_URL;
            case 'CHANGE_PASSWORD':
                return CHANGE_PASSWORD_URL;
            case 'GET_EVENTS':
                return GET_EVENT_LIST_URL;
            case 'GET_EVENTS_BY_USER':
                return GET_EVENT_LIST_BY_USER_URL;
            case 'GET_EVENTS_BY_PERFORMER':
                return GET_EVENTS_BY_PERFORMER_URL;
            case 'GET_ALL_EVENT_CATEGORIES':
                return GET_ALL_EVENT_CATEGORIES_URL;
            case 'GET_EVENT_CATEGORIES':
                return GET_EVENT_CATEGORIES_URL;
            case 'GET_EVENT_MUSIC_GENRES':
                return GET_EVENT_MUSIC_GENRES_URL;
            case 'GET_TOP_EVENTS':
                return GET_TOP_EVENTS_URL;
            case 'GET_PREMIUM_EVENTS':
                return GET_PREMIUM_EVENTS_URL;
            case 'INSERT_GALLERY_IMAGE':
                return INSERT_GALLERY_IMAGE_URL;
            case 'DELETE_GALLERY_IMAGE_UUID':
                return DELETE_GALLERY_IMAGE_UUID_URL;
            case 'DELETE_GALLERY_IMAGE_ID':
                return DELETE_GALLERY_IMAGE_ID_URL;
            case 'GET_COUNTRIES':
                return GET_COUNTRIES_URL;
            case 'GET_USERS_FILTER':    
                return GET_USERS_FILTER_URL;
            case 'REMOVE_USER':
                return REMOVE_USER_URL;
            case 'LIKE_OR_DISLIKE_EVENT':
                return LIKE_OR_DISLIKE_EVENT_URL;
            case 'REMOVE_EVENT_PERFORMER':
                    return REMOVE_EVENT_PERFORMER_URL;
            case 'REMOVE_EVENT':
                return REMOVE_EVENT_URL;
            case 'GET_SIMILAR_EVENTS':
                return GET_SIMILAR_EVENTS_URL;
            case 'ADD_CLUB_PHOTO':
                return ADD_CLUB_PHOTO_URL;
            case 'DELETE_CLUB_GALLERY_IMAGE_UUID':
                return DELETE_CLUB_GALLERY_IMAGE_UUID_URL;
            case 'DELETE_CLUB_GALLERY_IMAGE_ID':
                return DELETE_CLUB_GALLERY_IMAGE_ID_URL;
            case 'ADD_PHOTO':
                return ADD_PHOTO_URL;
            case 'DELETE_PHOTO_ID':
                return DELETE_PHOTO_ID_URL;
            case 'DELETE_PHOTO_UUID':
                return DELETE_PHOTO_ID_UUID_URL;
            default:
                return null;
        }
    }

}

export default EndpointsUrl;
