import React, {Component} from 'react';
import ProfileListOfEvents from '../../views/Dashboard/ProfileListOfEvents';
import ReactLoading from 'react-loading';
import {connect} from "react-redux";
import Fade from 'react-reveal/Fade';
import { LOADING_CONTENT } from '../../config/TextContent';
import { isLoggedInUserInLocalStorage } from '../../actions/UserActions';

class ProfileEventsContent extends Component {
    render(){
        return(
            <div className="profile-list-of-events">
            {!((this.props.user && this.props.selectedUser) || (!isLoggedInUserInLocalStorage() && this.props.selectedUser)) ?
                <Fade duration={500} delay={250} >
                    <div className="photo-update-modal__content">
                        <div className="photo-update-modal__content-loading-wrapper">
                            <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                            <p className="photo-update-modal__loading-content loading-content__black">{LOADING_CONTENT}</p>
                        </div>
                    </div>
                </Fade> 
            : <ProfileListOfEvents {...this.props}/>
            }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        selectedUser: state.userReducer.selectedUser,
    }
}

export default connect(mapStateToProps)(ProfileEventsContent);
