import React from 'react';
import {connect} from "react-redux";
import {CLOSE_MODAL, USER_HAS_NOT_PERMISSION_MODAL} from '../../../config/TextContent';
import {toggleModalUserHasNotPermission} from '../../../actions/ModalActions';
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import './UserHasNotPermissionModal.css';
import Dialog from '@material-ui/core/Dialog';

const UserHasNotPermissionModal = (props) => {
    return (
        <Dialog
                open={props.userHasNotPermissionModalOpened}
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                className='user-has-not-permission-modal'
                onClose={() => props.toggleModalUserHasNotPermission(false, true)}
            >
            <div className="user-has-not-permission-modal__content">
                <Link to="/" onClick={() => props.toggleModalUserHasNotPermission(false, true)} className="user-data-update-modal__close-button">
                    <img src={'/img/icons/close-cross.svg'} alt={CLOSE_MODAL}/>
                    <span className="modal__close-button__text">Zavrieť</span>
                </Link>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="user-has-not-permission-modal__content__text">{USER_HAS_NOT_PERMISSION_MODAL}</p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    return {
        userHasNotPermissionModalOpened: state.modalReducer.userHasNotPermissionModalOpened
    } 
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalUserHasNotPermission
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(UserHasNotPermissionModal);
