import React, {Component} from 'react';
import './ProfilePhoto.css';
import Lightbox from 'react-images';
import {CHANGE_PHOTO, ALT_ICON} from '../../../config/TextContent';
import {toggleModalPhotoUpdate} from '../../../actions/ModalActions';
import {isLoggedInUser} from '../../../actions/UserActions';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from 'react-router-dom';
import {Config} from '../../../config/Constants';
import { Link } from 'react-router-dom';

class ProfilePhoto extends Component {
    constructor(props, context){
        super(props, context);

        this.state = {
            currentImage: 0,
            lightboxIsOpen: false
        };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
    }
    
    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    render() {
        let title_photo = null, title_photo_lightbox_object = null;
        title_photo = Config.getUserPhoto(this.props.user, Config.CONSTANT_TITLE_PICTURE);
        title_photo_lightbox_object = {
            index: 0,
            width: 1, 
            height: 1, 
            id: 1, 
            src: title_photo
        };
        return (
            <div className="profile-photo">
                <div className="profile-photo__link">
                    {this.props.loggedUserDash && !this.props.selectedUserDash ? (
                    <Link to={Config.CHANGE_PROFILE_PHOTO_ROOT} className="profile-photo__background">
                        <div className="profile-photo__gradient">
                            <img src={'/img/icons/attach.svg'} alt=""/>
                            <p>{CHANGE_PHOTO}</p>
                        </div>
                        <img src={title_photo} className="profile-photo__user" alt={ALT_ICON}/>
                    </Link>
                    ) : (
                        <div className="profile-photo__background" onClick={
                            value => 
                            this.openLightbox(value, 
                                {
                                    index: 0,
                                    photo: title_photo_lightbox_object, 
                                }
                            )
                        } >
                            <img src={title_photo} className="profile-photo__user" alt={ALT_ICON}/>
                        </div>
                    )}         
                </div>
                <Lightbox 
                    images={[title_photo_lightbox_object]}
                    onClose={this.closeLightbox}
                    currentImage={this.state.currentImage}
                    isOpen={this.state.lightboxIsOpen}
                />
            </div>
        );
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalPhotoUpdate,
        isLoggedInUser,
    }, dispatch);
}

export default withRouter(connect(null, matchDispatchToProps)(ProfilePhoto));
