import {SHOW_FORM_CREATE_EVENT, CLOSE_FORM_CREATE_EVENT, 
    SET_LOADING_GET_EVENTS_BY_USER, GET_EVENTS, GET_EVENTS_SUCCESS, GET_EVENTS_ERROR, 
    GET_EVENTS_BY_USER, GET_EVENTS_BY_USER_SUCCESS, GET_EVENTS_BY_USER_ERROR, 
    GET_ALL_EVENT_CATEGORIES, GET_ALL_EVENT_CATEGORIES_SUCCESS, GET_ALL_EVENT_CATEGORIES_ERROR,
    GET_EVENT_CATEGORIES, GET_EVENT_CATEGORIES_SUCCESS, GET_EVENT_CATEGORIES_ERROR,
    SAVE_ORDINARY_EVENT, SAVE_ORDINARY_EVENT_SUCCESS, SAVE_ORDINARY_EVENT_ERROR, 
    GET_EVENT_MUSIC_GENRES, GET_EVENT_MUSIC_GENRES_SUCCESS, GET_EVENT_MUSIC_GENRES_ERROR,
    GET_CLUB_TYPES, GET_CLUB_TYPES_SUCCESS, GET_CLUB_TYPES_ERROR,
    SET_LOADING_GET_TOP_EVENTS, GET_TOP_EVENTS, GET_TOP_EVENTS_SUCCESS, GET_TOP_EVENTS_ERROR, GET_CHOSEN_EVENTS,
    GET_CHOSEN_EVENTS_ERROR, GET_CHOSEN_EVENTS_SUCCESS, SET_LOADING_GET_CHOSEN_EVENTS,
    GET_ALL_EVENTS_ERROR, GET_ALL_EVENTS_SUCCESS, SET_LOADING_GET_ALL_EVENTS, GET_ALL_EVENTS,
    SET_LOADING_GET_EVENTS_FOR_CALENDAR, GET_EVENTS_FOR_CALENDAR, GET_EVENTS_FOR_CALENDAR_SUCCESS, GET_EVENTS_FOR_CALENDAR_ERROR,
    GET_PREMIUM_EVENTS, GET_PREMIUM_EVENTS_ERROR, GET_PREMIUM_EVENTS_SUCCESS, SET_LOADING_GET_PREMIUM_EVENTS,
    SET_LOADING_GET_SELECTED_EVENT, GET_SELECTED_EVENT, GET_SELECTED_EVENT_SUCCESS, GET_SELECTED_EVENT_ERROR,
    SET_LOADING_GET_SELECTED_EVENT_TO_UPDATE, GET_SELECTED_EVENT_TO_UPDATE, GET_SELECTED_EVENT_TO_UPDATE_SUCCESS, GET_SELECTED_EVENT_TO_UPDATE_ERROR,    
    SET_LOADING_LIKE_OR_DISLIKE_EVENT, LIKE_OR_DISLIKE_EVENT, LIKE_OR_DISLIKE_EVENT_ERROR, LIKE_OR_DISLIKE_EVENT_SUCCESS,
    SET_LOADING_GET_USERS_FILTER, GET_USERS_FILTER, GET_USERS_FILTER_SUCCESS, GET_USERS_FILTER_ERROR,
    SET_LOADING_GET_EVENTS_BY_PERFORMER, GET_EVENTS_BY_PERFORMER, GET_EVENTS_BY_PERFORMER_SUCCESS, GET_EVENTS_BY_PERFORMER_ERROR,
    SET_LOADING_REMOVE_EVENT_PERFORMER, REMOVE_EVENT_PERFORMER, REMOVE_EVENT_PERFORMER_SUCCESS, REMOVE_EVENT_PERFORMER_ERROR, 
    SET_LOADING_REMOVE_EVENT, REMOVE_EVENT, REMOVE_EVENT_SUCCESS, REMOVE_EVENT_ERROR, SET_LOADING_GET_SIMILAR_EVENTS, GET_SIMILAR_EVENTS, GET_SIMILAR_EVENTS_SUCCESS, GET_SIMILAR_EVENTS_ERROR
} from '../actions/EventActions';

const INITIAL_STATE = {
    visibleDialogCreateEvent: false,
    eventsList: [],
    eventsByUserList: [],
    eventsByPerformerList: [],
    allEventCategoriesList: [],
    eventCategoriesList: [],
    eventMusicGenresList: [],
    clubTypeList: [],
    topEventsList: [],
    similarEventsList: [],
    chosenEvents: [],
    events: [],
    premiumEventsList: [],
    loadingEvents: false,
    loadingEventsByUser: false,
    loadingEventsByPerformer: false,
    loadingAllEventCategories: false,
    loadingEventCategories: false,
    loadingEventMusicGenres: false,
    loadingTopEvents: false,
    savingEvent: false,
    loadingChosenEvents: false,
    loadingAllEvents: false,
    premiumEventsListLoading: false,
    loadingSelectedEvent: false,    
    loadingSelectedEventToUpdate: false,
    selectedEvent: null,
    selectedEventToUpdate: [],
    loadingLikeOrDislikeEvent: false,
    loadingAvailableUsers: false,
    loadingRemoveEventPerformer: false, 
    loadingRemoveEvent: false, 
    loadingSimilarEvents: false, 
    loadingEventsForCalendar: false,
    loadingClubTypesList: false,
};

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {  
        
        case SHOW_FORM_CREATE_EVENT:
            return {...state, visibleDialogCreateEvent: true};
        case CLOSE_FORM_CREATE_EVENT:
            return {...state, visibleDialogCreateEvent: false};
        case GET_EVENTS:
            return {...state, eventsList: action.payload.data || [], loadingEvents: true};
        case GET_EVENTS_SUCCESS:
            return {...state, loadingEvents: false};
        case GET_EVENTS_ERROR:
            return {...state, loadingEvents: false};

        case SET_LOADING_GET_EVENTS_BY_USER: 
            return {...state, loadingEventsByUser: true};
        case GET_EVENTS_BY_USER:
            return {...state, eventsByUserList: action.payload.data || [], loadingEventsByUser: false};
        case GET_EVENTS_BY_USER_SUCCESS:
            return {...state, loadingEventsByUser: false};
        case GET_EVENTS_BY_USER_ERROR:
            return {...state, loadingEventsByUser: false};

        case SET_LOADING_GET_EVENTS_BY_PERFORMER: 
            return {...state, loadingEventsByPerformer: true};
        case GET_EVENTS_BY_PERFORMER:
            return {...state, eventsByPerformerList: action.payload.data || [], loadingEventsByPerformer: false};
        case GET_EVENTS_BY_PERFORMER_SUCCESS:
            return {...state, loadingEventsByPerformer: false};
        case GET_EVENTS_BY_PERFORMER_ERROR:
            return {...state, loadingEventsByPerformer: false};

        case GET_ALL_EVENT_CATEGORIES: 
            return {...state, allEventCategoriesList: action.payload.data || [], loadingAllEventCategories: true};
        case GET_ALL_EVENT_CATEGORIES_SUCCESS:
            return {...state, loadingAllEventCategories: false};
        case GET_ALL_EVENT_CATEGORIES_ERROR:
            return {...state, loadingAllEventCategories: false};

        case GET_EVENT_CATEGORIES: 
            return {...state, eventCategoriesList: action.payload.data || [], loadingEventCategories: true};
        case GET_EVENT_CATEGORIES_SUCCESS:
            return {...state, loadingEventCategories: false};
        case GET_EVENT_CATEGORIES_ERROR:
            return {...state, loadingEventCategories: false};

        case SAVE_ORDINARY_EVENT:
            return {...state, savingEvent: true};
        case SAVE_ORDINARY_EVENT_SUCCESS:
            return {...state, visibleDialogCreateEvent: false, savingEvent: false};
        case SAVE_ORDINARY_EVENT_ERROR:
            return {...state, visibleDialogCreateEvent: false, savingEvent: false};

        case GET_EVENT_MUSIC_GENRES: 
            return {...state, eventMusicGenresList: action.payload.data || [], loadingEventMusicGenres: true};
        case GET_EVENT_MUSIC_GENRES_SUCCESS:
            return {...state, loadingEventMusicGenres: false};
        case GET_EVENT_MUSIC_GENRES_ERROR:
            return {...state, loadingEventMusicGenres: false};

        case GET_CLUB_TYPES: 
            return {...state, clubTypeList: action.payload.data || [], loadingClubTypesList: true};
        case GET_CLUB_TYPES_SUCCESS:
            return {...state, loadingClubTypesList: false};
        case GET_CLUB_TYPES_ERROR:
            return {...state, loadingClubTypesList: false};
        
        case SET_LOADING_GET_TOP_EVENTS: 
            return {...state, loadingTopEvents: true};
        case GET_TOP_EVENTS: 
            return {...state, topEventsList: action.payload.data || [], loadingTopEvents: true};
        case GET_TOP_EVENTS_SUCCESS:
            return {...state, loadingTopEvents: false};
        case GET_TOP_EVENTS_ERROR:
            return {...state, loadingTopEvents: false};

        case SET_LOADING_GET_CHOSEN_EVENTS: 
            return {...state, loadingChosenEvents: true};
        case GET_CHOSEN_EVENTS: 
            return {...state, chosenEvents: action.payload.data || []};
        case GET_CHOSEN_EVENTS_SUCCESS:
            return {...state, loadingChosenEvents: false};
        case GET_CHOSEN_EVENTS_ERROR:
            return {...state, loadingChosenEvents: false};

        case SET_LOADING_GET_ALL_EVENTS: 
            return {...state, loadingAllEvents: true};
        case GET_ALL_EVENTS:
            return {...state, events: action.payload.data || []};
        case GET_ALL_EVENTS_SUCCESS:
            return {...state, loadingAllEvents: false};
        case GET_ALL_EVENTS_ERROR:
            return {...state, loadingAllEvents: false};

        case SET_LOADING_GET_EVENTS_FOR_CALENDAR: 
            return {...state, loadingEventsForCalendar: true};
        case GET_EVENTS_FOR_CALENDAR: 
            return {...state};
        case GET_EVENTS_FOR_CALENDAR_SUCCESS:
            return {...state, loadingEventsForCalendar: false};
        case GET_EVENTS_FOR_CALENDAR_ERROR:
            return {...state, loadingEventsForCalendar: false};
        
        case SET_LOADING_GET_PREMIUM_EVENTS: 
            return {...state, premiumEventsListLoading: true};
        case GET_PREMIUM_EVENTS: {
            return {...state, premiumEventsList: action.payload.data || []};
        }
        case GET_PREMIUM_EVENTS_SUCCESS:
            return {...state, premiumEventsListLoading: false};
        case GET_PREMIUM_EVENTS_ERROR:
            return {...state, premiumEventsListLoading: false};

        case SET_LOADING_GET_SELECTED_EVENT: 
            return {...state, loadingSelectedEvent: true, selectedEvent: null};
        case GET_SELECTED_EVENT: {
            return {...state, selectedEvent: action.payload.data || []};
        }
        case GET_SELECTED_EVENT_SUCCESS:
            return {...state, loadingSelectedEvent: false};
        case GET_SELECTED_EVENT_ERROR:
            return {...state, loadingSelectedEvent: false};

        case SET_LOADING_GET_SELECTED_EVENT_TO_UPDATE: 
            return {...state, loadingSelectedEventToUpdate: true, selectedEventToUpdate: null};
        case GET_SELECTED_EVENT_TO_UPDATE: {
            return {...state, selectedEventToUpdate: action.payload.data || []};
        }
        case GET_SELECTED_EVENT_TO_UPDATE_SUCCESS:
            return {...state, loadingSelectedEventToUpdate: false};
        case GET_SELECTED_EVENT_TO_UPDATE_ERROR:
            return {...state, loadingSelectedEventToUpdate: false};

        case SET_LOADING_LIKE_OR_DISLIKE_EVENT: 
            return {...state, loadingLikeOrDislikeEvent: true};
        case LIKE_OR_DISLIKE_EVENT: {
            return {...state};
        }
        case LIKE_OR_DISLIKE_EVENT_SUCCESS:
            return {...state, loadingLikeOrDislikeEvent: false};
        case LIKE_OR_DISLIKE_EVENT_ERROR:
            return {...state, loadingLikeOrDislikeEvent: false};

        case SET_LOADING_GET_USERS_FILTER: 
            return {...state, loadingAvailableUsers: true};
        case GET_USERS_FILTER:
            return {...state};
        case GET_USERS_FILTER_SUCCESS:
            return {...state, loadingAvailableUsers: false};
        case GET_USERS_FILTER_ERROR:
            return {...state, loadingAvailableUsers: false};

        case SET_LOADING_REMOVE_EVENT_PERFORMER: 
            return {...state, loadingRemoveEventPerformer: true};
        case REMOVE_EVENT_PERFORMER:
            return {...state};
        case REMOVE_EVENT_PERFORMER_SUCCESS:
            return {...state, loadingRemoveEventPerformer: false};
        case REMOVE_EVENT_PERFORMER_ERROR:
            return {...state, loadingRemoveEventPerformer: false};

        case SET_LOADING_REMOVE_EVENT: 
            return {...state, loadingRemoveEvent: true};
        case REMOVE_EVENT:
            return {...state};
        case REMOVE_EVENT_SUCCESS:
            return {...state, loadingRemoveEvent: false};
        case REMOVE_EVENT_ERROR:
            return {...state, loadingRemoveEvent: false};

        case SET_LOADING_GET_SIMILAR_EVENTS: 
            return {...state, loadingSimilarEvents: true};
        case GET_SIMILAR_EVENTS:
            return {...state, similarEventsList: action.payload.data || [], loadingSimilarEvents: false};
        case GET_SIMILAR_EVENTS_SUCCESS:
            return {...state, loadingSimilarEvents: false};
        case GET_SIMILAR_EVENTS_ERROR:
            return {...state, loadingSimilarEvents: false};

        default:
            return state;
    }
}
