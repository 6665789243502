import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { CLUB_NAME, FLOATING_GENRE_LABEL, NO_CONTENT_AVAILABLE, CLUB_TYPE, ALT_ICON, USER_DATA_UPDATE_STREET,
    USER_DATA_UPDATE_CLUB_TITLE, USER_DATA_UPDATE_GENRE, USER_DATA_UPDATE_CLUB_CITY, LOADING,
    REGISTRATION_CHANGE_MAP_LOCATION, REGISTRATION_ADD_MAP_LOCATION, USER_DATA_UPDATE_COUNTRY, USER_DATA_UPDATE_CLUB_ADDRESS,
    USER_DATA_UPDATE_CLUB_DESCRIPTION, REQUIRED_TEXT_FIELD_LOWERCASE, CLUB_DESCRIPTION
} from '../../../../config/TextContent';
import {transformPropsWithLabelAndValue, onForeignObjectChange,
    onChangeHelper, getAttributeFromAddressArray, isAttributeInAddressArray, transformObjectToTreeSelectObject,
    getAttributeFromAddressArrayWithSeparator} from '../../../Header/RegistrationModal/RegistrationModal/RegistrationModalHelper';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { cloneObject, getComposedStreet } from '../../../../client/Helper';
import PropTypes from 'prop-types';
import UserDataUpdateModalSubmitButton from '../../UserDataUpdateModal/UserDataUpdateModal/UserDataUpdateModalSubmitButton';
import {toggleModalMapUserDataUpdate} from '../../../../actions/ModalActions';
import customReactSelectStyles from '../../../../config/ReactSelectStyle';
import { listToTreeUniqueValues } from '../../../../client/Helper';
import TreeSelect from 'rc-tree-select';
import {getFilterEventMusicGenres} from '../../CreateEventModal/filters/filters';
import UserDataUpdateModalMapDialog from '../../UserDataUpdateModal/UserDataUpdateModalMapDialog/UserDataUpdateModalMapDialog';

class RoleUserDataManagementModalClubContent extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            user: {
                name: props.user.user_data.name,
                genre: props.user.user_data.genre && props.user.user_data.genre.hasOwnProperty('id') ? props.user.user_data.genre.id : null,
                type: props.user.user_data.type && props.user.user_data.type.hasOwnProperty('id') ? props.user.user_data.type.id : null,
                type_obj: transformObjectToTreeSelectObject(props.user.user_data.type),
                lng: props.user.user_data.lng,
                lat: props.user.user_data.lat,
                address_components: [],
                place_string: props.user.user_data.place_string,
                country_string: props.user.user_data.country_string, 
                street_string: props.user.user_data.street_string,
                place_full_string: props.user.user_data.place_full_string,
                club_description: props.user.user_data.club_description
            },
            errors: props.errors ? props.errors : {},
            tsGenresOpen: false
        };
        this.markerPositionChanged = this.markerPositionChanged.bind(this);
        this.onSelectAddressChange = this.onSelectAddressChange.bind(this);
        this.onSelectAddressComponents = this.onSelectAddressComponents.bind(this);
    }

    componentWillReceiveProps(props){
        this.setState({
            errors: props.errors
        });
    }
    
    onFieldChange = (event, field_name, required=true) => {
        const event_value = event.target.value;
        this.setState((prevState) => {
            let user = {...prevState.user};
            let errors = {...prevState.errors};
            user[field_name] = event_value;
            if(event_value.length === 0 && required === true){
                errors[field_name] = REQUIRED_TEXT_FIELD_LOWERCASE; 
            }else{
                errors[field_name] = null;
            }
            return {errors, user};
        });
    }

    onGenreChange = (value) => {
        this.setState((prevState) => {
            let user = {...prevState.user};
            let errors = {...prevState.errors};
            user['genre'] = value;
            if(value.length === 0){
                errors['genre'] = REQUIRED_TEXT_FIELD_LOWERCASE;
            }else{
                errors['genre'] = null;
            }
            return {errors, user};
        });
    }

    onChange = (event, name) => {
        onChangeHelper(this, event, name);
    };

    onClubTypeChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'type');
    }

    onCityChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'city');
    }

    onSelectAddressChange = (value, latlng) => {
        this.setState((prevState) => {
            let user = prevState.user;
            let errors = prevState.errors;
            user.lat = latlng.lat;
            user.lng = latlng.lng;
            user.place_full_string = value;
            errors.lat = null;
            errors.lng = null;

            return {
                errors,
                user
            };
        });
    }

    onSelectAddressComponents = (address_components) => {
        this.setState(prevState => {
            const user = cloneObject(prevState.user);
            const errors = cloneObject(prevState.errors);
            user.address_components = address_components;
            user.street_string = getComposedStreet(address_components);
            user.country_string = getAttributeFromAddressArray(address_components, 'country');
            user.place_string = getAttributeFromAddressArrayWithSeparator(
                address_components, 
                ',', 
                'sublocality', 
                'locality', 
                'postal_town', 
                'administrative_area_level_3',
                'administrative_area_level_2', 
                'administrative_area_level_1');
            errors.place_string = null;
            errors.street_string = null;
            errors.country_string = null;

            return {
                user,
                errors
            }
        })
    }

    markerPositionChanged = (lat, lng) => {
        this.setState(prevState => {
            const user = cloneObject(prevState.user);
            user.lat = lat;
            user.lng = lng;
            return {
                user
            }
        })
    }

    render() {
        return (
            <div>
                <UserDataUpdateModalMapDialog 
                    step={4}
                    place={this.state.user.place_full_string}
                    onSelectAddressChange={this.onSelectAddressChange}
                    onSelectAddressComponents={this.onSelectAddressComponents}
                    markerPositionChanged={this.markerPositionChanged}
                    markerLatLng={{ lat: this.state.user.lat, lng: this.state.user.lng}}
                    centerLatLng={{ lat: this.state.user.lat, lng: this.state.user.lng}}
                />
                <div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/1.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{USER_DATA_UPDATE_CLUB_TITLE}</p>                                         
                            </div>
                        </div>
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10">
                                <TextField
                                    name="name"
                                    required
                                    value={this.state.user.name}
                                    className={this.state.errors.name ? 'user-data-management-modal__step__body__element error-textfield' : 'user-data-management-modal__step__body__element'}
                                    label={this.state.errors.name ? this.state.errors.name : CLUB_NAME}
                                    placeholder="..."
                                    onChange={(event) => {this.onFieldChange(event, 'name')}}
                                    autoComplete="off"
                                    autoCorrect="off"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/2.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{USER_DATA_UPDATE_GENRE}</p>                                         
                            </div>
                        </div>
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10">
                                <div className={this.state.errors.genre ? 'user-data-management-modal__step__body__element error-selectfield' : 'user-data-management-modal__step__body__element'}>
                                    <label className="required">{this.state.errors.genre ? this.state.errors.genre : FLOATING_GENRE_LABEL}</label>
                                    <div className="tree-select-wrapper">
                                        <TreeSelect
                                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                            transitionName="rc-tree-select-dropdown-slide-up"
                                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                            dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                            placeholder="..."
                                            showSearch={false} 
                                            allowClear treeLine
                                            value={this.state.user.genre}
                                            inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate-white.svg'} 
                                            className="treeselect-arrow-down" alt={ALT_ICON} />}
                                            treeData={listToTreeUniqueValues(this.props.genres)}
                                            treeNodeFilterProp="genre"
                                            filterTreeNode={false}
                                            treeDefaultExpandAll={false}
                                            open={this.state.tsGenresOpen}
                                            notFoundContent={LOADING}
                                            onChange={(value) => {
                                                if (value === this.state.user.genre)
                                                    this.setState({ tsGenresOpen: true });
                                                else
                                                    this.setState({ tsGenresOpen: false });
                                                this.onGenreChange(value);
                                            }}
                                            onDropdownVisibleChange={(v) => {
                                                this.setState({
                                                    tsGenresOpen: v,
                                                });
                                                return true;
                                            }}
                                        /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/3.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{CLUB_TYPE}</p>                                         
                            </div>
                        </div>
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10">
                                <div className={this.state.errors.type ? 'user-data-management-modal__step__body__element error-selectfield' : 'user-data-management-modal__step__body__element'}>
                                    <label className="required">{this.state.errors.type ? this.state.errors.type : CLUB_TYPE}</label>
                                    <Select
                                        styles={customReactSelectStyles}
                                        name="type"
                                        placeholder="..."
                                        duration={200}
                                        classNamePrefix="styled-tree-select"
                                        className="styled-tree-select"
                                        value={this.state.user.type_obj}
                                        onChange={selectedOption => {this.onClubTypeChange(selectedOption)}}
                                        options={this.props.club_types}
                                        noOptionsMessage={() => {return NO_CONTENT_AVAILABLE}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/4.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{USER_DATA_UPDATE_CLUB_ADDRESS}</p>                                         
                            </div>
                        </div>
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10">
                                <div className="user-data-management-modal__step__body__parts">
                                    <div className="user-data-management-modal__step__body__part">
                                        <div className="row">
                                            <div className="col-md-4 no-padding-left">
                                                <img src={'/img/icons/street.svg'} alt={ALT_ICON} className="user-data-management-modal__step__body__part__icon"/>
                                                <p className="user-data-management-modal__step__body__part__title">{USER_DATA_UPDATE_STREET} </p>
                                            </div>
                                            <div className="col-md-7 no-padding-left">
                                                {this.state.errors.street_string ?
                                                    <p className="user-data-management-modal__step__body__part__error">{this.state.errors.street_string} *</p> :
                                                    (
                                                        this.state.user.street_string && this.state.user.street_string.length > 0 && !isAttributeInAddressArray(this.state.user.address_components, 'route', 'premise', 'street_number') ?
                                                            <p className="user-data-management-modal__step__body__part__location-name">{this.state.user.street_string}</p> :
                                                            (
                                                            <p className="user-data-management-modal__step__body__part__location-name">
                                                                { getComposedStreet(this.state.user.address_components) }
                                                            </p>
                                                            )
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>
                                    <div className="user-data-management-modal__step__body__part padding-top-10">
                                        <div className="row">
                                            <div className="col-md-4 no-padding-left">
                                                <img src={'/img/icons/city.svg'} alt={ALT_ICON} className="user-data-management-modal__step__body__part__icon"/>
                                                <p className="user-data-management-modal__step__body__part__title">{USER_DATA_UPDATE_CLUB_CITY} </p>
                                            </div>
                                            <div className="col-md-7 no-padding-left">
                                                {this.state.errors.place_string ?
                                                    <p className="user-data-management-modal__step__body__part__error">{this.state.errors.place_string} *</p> :
                                                    (
                                                        this.state.user.place_string && this.state.user.place_string.length > 0 && !isAttributeInAddressArray(this.state.user.address_components, 'administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3', 'postal_town', 'locality', 'sublocality') ?
                                                            <p className="user-data-management-modal__step__body__part__location-name">{this.state.user.place_string}</p> :
                                                            (
                                                            <p className="user-data-management-modal__step__body__part__location-name">
                                                                { getAttributeFromAddressArrayWithSeparator(this.state.user.address_components, ',', 'sublocality', 'locality', 'postal_town', 'administrative_area_level_3', 'administrative_area_level_2', 'administrative_area_level_1') }
                                                            </p>
                                                            )
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>
                                    <div className="user-data-management-modal__step__body__part padding-top-10">
                                        <div className="row">
                                            <div className="col-md-4 no-padding-left">
                                                <img src={'/img/icons/flag.svg'} alt={ALT_ICON} className="user-data-management-modal__step__body__part__icon"/>
                                                <p className="user-data-management-modal__step__body__part__title">{USER_DATA_UPDATE_COUNTRY} </p>
                                            </div>
                                            <div className="col-md-7 no-padding-left">
                                                {this.state.errors.country_string ?
                                                    <p className="user-data-management-modal__step__body__part__error">{this.state.errors.country_string} *</p> :
                                                    (
                                                        this.state.user.country_string && this.state.user.country_string.length > 0 && !isAttributeInAddressArray(this.state.user.address_components, 'country') ? 
                                                            <p className="user-data-management-modal__step__body__part__location-name">{this.state.user.country_string}</p> :
                                                            (
                                                            <p className="user-data-management-modal__step__body__part__location-name">
                                                                {getAttributeFromAddressArray(this.state.user.address_components, 'country')}
                                                            </p>
                                                            )
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row role_user_data_management_button">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10 no-padding-left">
                                <button                                         
                                    type="button"
                                    onClick={() => this.props.toggleModalMapUserDataUpdate(true, true)}
                                    className="default_transparent_white_border_button"
                                >
                                    <img src={'/img/icons/marker-green.svg'} alt={ALT_ICON}/>
                                    <span>{ this.state.user.place_full_string ? REGISTRATION_CHANGE_MAP_LOCATION : REGISTRATION_ADD_MAP_LOCATION}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/5.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{USER_DATA_UPDATE_CLUB_DESCRIPTION}</p>                                         
                            </div>
                        </div>
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10">
                                <TextField
                                    name="club_description"
                                    className={this.state.errors.club_description ? 'user-data-management-modal__step__body__element error-textfield' : 'user-data-management-modal__step__body__element'}
                                    label={this.state.errors.club_description ? CLUB_DESCRIPTION + " (" + this.state.errors.club_description + ")" : CLUB_DESCRIPTION}
                                    value={this.state.user.club_description}
                                    error={this.state.errors.club_description ? true : false}
                                    onChange={(event) => {this.onFieldChange(event, "club_description", false)}}
                                    multiline
                                    rows={5}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <UserDataUpdateModalSubmitButton 
                        loading={this.props.loading}
                        errors={this.props.errors}
                        onSubmit={() => this.props.onSubmit(this.state.user)}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        genres: getFilterEventMusicGenres(state.eventReducer.eventMusicGenresList),
        club_types: transformPropsWithLabelAndValue(state.clubTypesReducer.clubTypeList)
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleModalMapUserDataUpdate,
    }, dispatch);
}

RoleUserDataManagementModalClubContent.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    club_types: PropTypes.array.isRequired,
    genres: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired
};

export default connect(mapStateToProps, matchDispatchToProps)(RoleUserDataManagementModalClubContent);
