import React from 'react';
import LoadingButton from '../../../LoadingButton/LoadingButton';
import {CONTENT_SAVE_AND_CONTINUE, CONTENT_SAVING, REGISTRATION_SUCCESS_TEXT,
    REGISTRATION_SUCCESS_SUBTITLE, ALT_ICON, REGISTRATION_ERROR_SUBTITLE, REGISTRATION_ERROR_TEXT} from '../../../../config/TextContent';
import Fade from 'react-reveal/Fade';

const RegistrationSubmitButton = (props) => {
    let success = true;
    for(let el in props.errors){
        if(props.errors[el] !== null)
            success = false;
    }

    return (
        <Fade when={!props.hidden} bottom>
            <div>
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <div className="register-modal__form__helper">
                            {
                                success ? 
                                (
                                    <div>
                                        <img src={'/img/icons/checked.svg'} className="register-modal__form__helper__success-icon" alt={ALT_ICON}/>                                                        
                                        <p className="register-modal__form__helper-submit-success">{REGISTRATION_SUCCESS_TEXT}</p>
                                    </div>   
                                ):
                                (
                                    <div>
                                        <img src={'/img/icons/danger.svg'} className="register-modal__form__helper__error-icon" alt={ALT_ICON}/>                                                        
                                        <p className="register-modal__form__helper-submit-success">{REGISTRATION_ERROR_TEXT}</p>
                                    </div>   
                                )
                            }
                            <div>
                                { 
                                    success ? 
                                        <p className="register-modal__form__helper-submit" dangerouslySetInnerHTML={{__html: REGISTRATION_SUCCESS_SUBTITLE}}></p>
                                    :
                                        <p className="register-modal__form__helper-submit">{REGISTRATION_ERROR_SUBTITLE}</p>
                                }

                                <LoadingButton 
                                    paragraphClass="register-form__form__button__intro-title"
                                    loaderClass="register-modal__form__button__react-loading"
                                    classes="register-modal__form__button"
                                    loading={props.loading}
                                    content={CONTENT_SAVE_AND_CONTINUE}
                                    loadingContent={CONTENT_SAVING}
                                    onClick={props.onClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </Fade>
    );
}

export default RegistrationSubmitButton;

