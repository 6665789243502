import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toggleModalRegister} from '../../../actions/ModalActions';
import {CLOSE_MODAL, ALT_ICON} from '../../../config/TextContent';
import PropTypes from 'prop-types';
import PreRegisterContent from '../../PreRegisterContent/PreRegisterContent';
import './PreRegisterModal.css';
import {Link} from 'react-router-dom';

const PreRegisterModal = (props) => {
    return (
        <Dialog
            fullWidth={true}
            fullScreen={true}
            scroll='body'
            open={props.preRegisterModalOpened}
            onClose={() => props.toggleModalRegister(false, true)}
            className='pre-register-modal'
        >
            <Link to="/" onClick={() => props.toggleModalRegister(false, true)} className="pre-register-modal__close-button">
                <img src={'/img/icons/close-cross.svg'} alt={ALT_ICON}/>
                {CLOSE_MODAL}
            </Link>
            <div className="container"> 
                <div className="row">
                    <div className="col-md-6">
                        <div className="pre-register-modal__left-side-map">
                            <img src={'/img/map-svk-cze-bold.svg'} alt={ALT_ICON} />
                            {/*
                            <p className="pre-register-modal__left-side-map__country_counter">
                                Budeš náš <strong>522.</strong> používateľ zo
                                <img src={'/img/icons/slovakia.svg'} className="pre-register-modal__left-side-map__flag" alt={ALT_ICON}/>
                            </p>
                            */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <PreRegisterContent />
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

PreRegisterModal.propTypes = {
    toggleModalRegister: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        preRegisterModalOpened: state.modalReducer.registerModal,
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleModalRegister,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(PreRegisterModal);
