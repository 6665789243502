import React from 'react';
import './AuthenticatedNavigation.css';
import AuthenticatedDesktopNavigation from './AuthenticatedDesktopNavigation/AuthenticatedDesktopNavigation';
import MobileNavigation from '../MobileNavigation/MobileNavigation';

const AuthenticatedNavigation = () => {
    return (
        <div className="header__navigation__authenticated-navigation">
            <AuthenticatedDesktopNavigation />
            <MobileNavigation isAuthenticatedNavigation={true} />
        </div>
    )
}

export default AuthenticatedNavigation;
