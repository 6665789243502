export const TOGGLE_MODAL_LOGIN = 'TOGGLE_MODAL_LOGIN';
export const TOGGLE_MODAL_REGISTER = 'TOGGLE_MODAL_REGISTER';
export const TOGGLE_MODAL_MAP_REGISTER = 'TOGGLE_MODAL_MAP_REGISTER';
export const TOGGLE_MODAL_REGISTER_LEAVE_QUESTION = 'TOGGLE_MODAL_REGISTER_LEAVE_QUESTION';
export const TOGGLE_MODAL_PHOTO_UPDATE = 'TOGGLE_MODAL_PHOTO_UPDATE';
export const TOGGLE_MODAL_COVER_PHOTO_UPDATE = 'TOGGLE_MODAL_COVER_PHOTO_UPDATE';
export const TOGGLE_MODAL_CREATE_EVENT = 'TOGGLE_MODAL_CREATE_EVENT';
export const TOGGLE_MODAL_EVENT_MAP = 'TOGGLE_MODAL_EVENT_MAP';
export const TOGGLE_MODAL_EVENT_GALLERY = 'TOGGLE_MODAL_EVENT_GALLERY';
export const TOGGLE_MODAL_USER_DATA_UPDATE = 'TOGGLE_MODAL_USER_DATA_UPDATE';
export const TOGGLE_MODAL_MAP_USER_DATA_UPDATE = 'TOGGLE_MODAL_MAP_USER_DATA_UPDATE';
export const TOGGLE_MODAL_USER_DATA_UPDATE_LEAVE_QUESTION = 'TOGGLE_MODAL_USER_DATA_UPDATE_LEAVE_QUESTION';
export const TOGGLE_MODAL_USER_DATA_MANAGEMENT = 'TOGGLE_MODAL_USER_DATA_MANAGEMENT';
export const TOGGLE_MODAL_EVENTS_BY_USER = 'TOGGLE_MODAL_EVENTS_BY_USER';
export const TOGGLE_MODAL_EVENT_DATA_MANAGEMENT = 'TOGGLE_MODAL_EVENT_DATA_MANAGEMENT';
export const TOGGLE_MODAL_USER_AS_EVENT_PERFORMER_REMOVAL = 'TOGGLE_MODAL_USER_AS_EVENT_PERFORMER_REMOVAL';
export const TOGGLE_MODAL_CHANGE_PASSWORD = 'TOGGLE_MODAL_CHANGE_PASSWORD';
export const TOGGLE_MODAL_ACCOUNT_SETTINGS = 'TOGGLE_MODAL_ACCOUNT_SETTINGS';
export const TOGGLE_MODAL_ADD_CLUB_PHOTOS = 'TOGGLE_MODAL_ADD_CLUB_PHOTOS';
export const TOGGLE_MODAL_USER_HAS_NOT_PERMISSION = 'TOGGLE_MODAL_USER_HAS_NOT_PERMISSION';
export const TOGGLE_MODAL_ADD_PHOTOS = 'TOGGLE_MODAL_ADD_PHOTOS';

export function toggleModalLogin(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_LOGIN,
        payload: {open, force}
    }
}

export function toggleModalRegister(open=false, force=false, modalType=1) {
    return {
        type: TOGGLE_MODAL_REGISTER,
        payload: {open, force, modalType}
    }
}

export function toggleModalMapRegister(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_MAP_REGISTER,
        payload: {open, force}
    }
}

export function toggleModalRegisterLeaveQuestion(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_REGISTER_LEAVE_QUESTION,
        payload: {open, force}
    }
}

export function toggleModalPhotoUpdate(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_PHOTO_UPDATE,
        payload: {open, force}
    }
}

export function toggleModalEventGallery(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_EVENT_GALLERY,
        payload: {open, force}
    }
}

export function toggleModalCoverPhotoUpdate(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_COVER_PHOTO_UPDATE,
        payload: {open, force}
    }
}

export function toggleModalEventsByUser(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_EVENTS_BY_USER,
        payload: {open, force}
    }
}

export function toggleModalCreateEvent(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_CREATE_EVENT,
        payload: {open, force}
    }
}

export function toggleModalMapEvent(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_EVENT_MAP,
        payload: {open, force}
    }
}

export function toggleModalUserDataUpdate(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_USER_DATA_UPDATE,
        payload: {open, force}
    }
}

export function toggleModalUserDataManagement(open=false, force=false, modalType=1) {
    return {
        type: TOGGLE_MODAL_USER_DATA_MANAGEMENT,
        payload: {open, force, modalType}
    }
}

export function toggleModalMapUserDataUpdate(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_MAP_USER_DATA_UPDATE,
        payload: {open, force}
    }
}

export function toggleModalEventDataManagement(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_EVENT_DATA_MANAGEMENT,
        payload: {open, force}
    }
}

export function toggleModalUserAsEventPerformerRemoval(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_USER_AS_EVENT_PERFORMER_REMOVAL,
        payload: {open, force}
    }
}

export function toggleModalChangePassword(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_CHANGE_PASSWORD,
        payload: {open, force}
    }
}

export function toggleModalAccountSettings(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_ACCOUNT_SETTINGS,
        payload: {open, force}
    }
}

export function toggleModalAddClubPhotos(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_ADD_CLUB_PHOTOS,
        payload: {open, force}
    }
}

export function toggleModalUserHasNotPermission(open=false, force=false) {
    return {
    type: TOGGLE_MODAL_USER_HAS_NOT_PERMISSION,
        payload: {open, force}
    }
}

export function toggleModalAddPhotos(open=false, force=false) {
    return {
        type: TOGGLE_MODAL_ADD_PHOTOS,
        payload: {open, force}
    }
}