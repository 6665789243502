import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import Homepage from "./Homepage";
import Dashboard from "./Dashboard/Dashboard";
import StateLoader from '../components/StateLoader/StateLoader';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Alert from 'react-s-alert';
import {Config} from '../config/Constants';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import InfoAlertTemplate from '../components/InfoAlertTemplate/InfoAlertTemplate';
import EventDetail from './EventDetail';
import UserInfo from './UserInfo';
import RouteProcessor from '../components/RouteProcessor/RouteProcessor';
import GeneralStatement from '../components/CustomPages/GeneralStatement';
import PrivacyPolicies from '../components/CustomPages/PrivacyPolicies';

class App extends Component {
    render() {
        return (
            <div>
                <StateLoader/>
                <MuiThemeProvider
                    theme={createMuiTheme({
                        typography: {
                          useNextVariants: true,
                        },
                    })}
                >
                    <div className="project-wrapper">
                        <Alert 
                            stack={true} 
                            timeout={7000} 
                            className="custom-alert"
                            contentTemplate={InfoAlertTemplate} 
                        />
                        <Header {...this.props} />
                        <Route path={Config.LOGIN_ROOT} component={RouteProcessor}/>
                        <Route path={Config.REGISTRATION_USER_ROOT} component={RouteProcessor}/>
                        <Route path={Config.REGISTRATION_CLUB_ROOT} component={RouteProcessor}/>
                        <Route path={Config.REGISTRATION_INTERPRET_ROOT} component={RouteProcessor}/>
                        <Route path={Config.REGISTRATION_PROMOTER_ROOT} component={RouteProcessor}/>
                        <Route path={Config.ADD_EVENT_ROOT} component={RouteProcessor} {...this.props} />
                        <Route path="/uprava-eventu/:id" component={RouteProcessor}/>
                        <Route path={Config.CHANGE_COVER_PHOTO_ROOT} component={RouteProcessor}/>
                        <Route path={Config.CHANGE_PROFILE_PHOTO_ROOT} component={RouteProcessor}/>
                        <Route path={Config.USER_DATA_MANAGEMENT_ROOT} component={RouteProcessor}/>
                        <Route path={Config.ATTENDING_EVENTS_ROOT} component={RouteProcessor}/>
                        <Route path={Config.EVENTS_BY_USER_MANAGEMENT_ROOT} component={RouteProcessor}/>
                        <Route path={Config.UPDATE_USER_DATA_ROOT} component={RouteProcessor}/>
                        <Route path={Config.CHANGE_PASSWORD_ROOT} component={RouteProcessor}/>
                        <Route path={Config.ACCOUNT_SETTINGS_ROOT} component={RouteProcessor}/>
                        <Route path={Config.ADD_CLUB_PHOTOS_ROOT} component={RouteProcessor} {...this.props} />
                        <Route path={Config.USER_HAS_NOT_PERMISSION_ROOT} component={RouteProcessor}/>
                        <Route path={Config.ADD_PHOTOS_ROOT} component={RouteProcessor} {...this.props} />
                        <Switch>
                            <Route path={Config.GENERAL_STATEMENT_ROOT} component={GeneralStatement}/>
                            <Route path={Config.PRIVACY_POLICIES_ROOT} component={PrivacyPolicies}/>
                            <Route path="/home/:banner" component={Homepage} {...this.props} />
                            <Route path="(/home|/)" component={Homepage} {...this.props}/>
                            <Route path="/event/:eventslug" component={EventDetail} {...this.props} />
                            <Route path="/uzivatel/:username/info" component={UserInfo} {...this.props} />
                            <Route path="/uzivatel/:username" component={Dashboard} {...this.props} />
                            <Route path="/" component={Homepage} {...this.props} />
                        </Switch>
                        <Footer/>
                    </div>
                </MuiThemeProvider>
            </div>
        );
    }
}


export default App;
