const ROUTE_USER_EVENTS = '';
const ROUTE_USER = '/uzivatel';
const ROUTE_DASHBOARD = '/dashboard';
const ROUTE_CLUBS = '/kluby';
const ROUTE_BANDS = '/interpreti'
const ROUTE_EVENT = '/event';
const ROUTE_CALENDAR = '/kalendar';
const ROUTE_USER_INFO = '/info';

const GET_USER_ROUTE = (user) => {
    let userPath = ROUTE_USER;
    if(user && user.hasOwnProperty('username'))
        userPath += '/' + user.username;
    return userPath;
}

const GET_USER_ROUTE_TO_USER_EVENTS = (user) => {
    return GET_USER_ROUTE(user) + ROUTE_USER_EVENTS;    
}

const GET_USER_ROUTE_TO_DASHBOARD = (user) => {
    return GET_USER_ROUTE(user);
}

const GET_USER_ROUTE_TO_CLUBS = (user) => {
    return GET_USER_ROUTE(user) + ROUTE_CLUBS;
}

const GET_USER_ROUTE_TO_BANDS = (user) => {
    return GET_USER_ROUTE(user) + ROUTE_BANDS;
}

const GET_USER_ROUTE_TO_SPECIFIC_EVENT = (event) => {
    if(event && event.hasOwnProperty('event_name_slug'))
        return ROUTE_EVENT + '/' + event.event_name_slug;
    return '/';
}

const GET_USER_ROUTE_TO_CALENDAR = (user) => {
    return GET_USER_ROUTE(user) + ROUTE_CALENDAR;
}

const GET_USER_ROUTE_TO_USER_INFO = (user) => {
    return GET_USER_ROUTE(user) + ROUTE_USER_INFO;
}

export {
    ROUTE_USER,
    ROUTE_USER_EVENTS,
    ROUTE_DASHBOARD,
    ROUTE_EVENT,
    ROUTE_CLUBS,
    ROUTE_BANDS,
    GET_USER_ROUTE,
    GET_USER_ROUTE_TO_USER_EVENTS,
    GET_USER_ROUTE_TO_DASHBOARD,
    GET_USER_ROUTE_TO_CLUBS,
    GET_USER_ROUTE_TO_BANDS,
    GET_USER_ROUTE_TO_SPECIFIC_EVENT,
    GET_USER_ROUTE_TO_CALENDAR,
    GET_USER_ROUTE_TO_USER_INFO
}
