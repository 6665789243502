import React, {Component} from 'react';
import './FooterStats.css';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Config} from '../../../config/Constants';
import {Link} from 'react-router-dom';
import {isLoggedInUser} from '../../../actions/UserActions';
import {getUserTypeNumber} from '../../../client/Helper';
import {toggleModalLogin, toggleModalCreateEvent} from '../../../actions/ModalActions';
import {ALT_ICON, ADD_EVENT, FOOTER_STATS_TITLE, FOOTER_STATS_TITLE_USER_ROLE, FOOTER_STATS_STAT_1_NUMBER, FOOTER_STATS_STAT_2_NUMBER, FOOTER_STATS_STAT_3_NUMBER, FOOTER_STATS_STAT_1_TEXT, FOOTER_STATS_STAT_2_TEXT, FOOTER_STATS_STAT_3_TEXT} from '../../../config/TextContent';

class FooterStats extends Component {
    onChangeButtonAddEvent = () => {
        if(isLoggedInUser(this.props.user)){
            this.props.toggleModalCreateEvent(true, true);    
        }else{
            this.props.toggleModalLogin(true, true);
        }
    }

    render() {
        return (
            <div className="footer-stats-component">
                <div className="container">
                    <div className="footer-stats__content">
                        <div className="row">
                            <div className="col-md-4">
                            {this.props.role !== null && this.props.role !== 4 ? (
                                <div className="footer-stats__content__intro">
                                    <p className="footer-stats__content__intro__title">{FOOTER_STATS_TITLE}</p>
                                    <Link to={Config.ADD_EVENT_ROOT} className="default_transparent_link">{ADD_EVENT}</Link>
                                </div>
                            ) : (
                                <div className="footer-stats__content__intro">
                                    <p className="footer-stats__content__intro__title">{FOOTER_STATS_TITLE_USER_ROLE}</p>
                                </div>
                            )}
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="footer-stats__content_statistics">
                                            <img src={'/img/icons/favorites.svg'} className="footer-stats__content__image-1" alt={ALT_ICON}/>
                                            <p>
                                                <strong>{FOOTER_STATS_STAT_1_NUMBER}</strong>
                                                {FOOTER_STATS_STAT_1_TEXT}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="footer-stats__content_statistics">
                                            <img src={'/img/icons/team-green.svg'} className="footer-stats__content__image-2" alt={ALT_ICON}/>
                                            <p>
                                                <strong>{FOOTER_STATS_STAT_2_NUMBER}</strong>
                                                {FOOTER_STATS_STAT_2_TEXT}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="footer-stats__content_statistics">
                                            <img src={'/img/icons/quality-green.svg'} className="footer-stats__content__image-3" alt={ALT_ICON}/>
                                            <p>
                                                <strong>{FOOTER_STATS_STAT_3_NUMBER}</strong>
                                                {FOOTER_STATS_STAT_3_TEXT}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        isLoggedInUser,
        toggleModalLogin,
        toggleModalCreateEvent
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        role: getUserTypeNumber(state.userReducer.user)
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(FooterStats);
