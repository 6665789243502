import React from 'react';
import MenuElementContent from './MenuElementContent/MenuElementContent';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import './MenuElement.css';

const MenuElement = (props) => {
    return (
        <div className="menu-element">
            {props.loggedUserDash && !props.selectedUserDash ? (
                <div className="container">
                    <ProfilePhoto selectedUserDash={false} loggedUserDash={true} user={props.user} loadingUser={props.loadingUser} />
                    <MenuElementContent user={props.user} loggedUserDash={true} />
                </div>
            ) : (
                <div className="container">
                    <ProfilePhoto selectedUserDash={true} loggedUserDash={false} user={props.user} loadingUser={props.loadingUser} />
                    <MenuElementContent user={props.user} loggedUserDash={false} />
                </div>
            )}
        </div>
    );
}

export default MenuElement;
