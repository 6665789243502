import React, { Component } from 'react';
import './CoverPhotoUpdateModal.css';
import Fade from 'react-reveal/Fade';
import Alert from 'react-s-alert';
import {ALERT_SETTINGS} from '../../../config/Config';
import {toggleModalCoverPhotoUpdate, toggleModalUserDataUpdate} from '../../../actions/ModalActions';
import {ALT_ICON, IMAGE_INSERTION, COVER_PICTURE_CHANGE_HEADER, 
    COVER_PHOTO_UPDATE_MODAL_CONFIRMATION, COVER_PHOTO_UPLOAD_DESCRIPTION, INSERT_ANOTHER_IMAGE, PREPARING_PHOTO_PLEASE_WAIT,
    UPDATE_COVER_PHOTO_SUCCESS,
    CLOSE_MODAL} from '../../../config/TextContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from 'react-router-dom';
import {PropTypes} from 'prop-types';
import { Link } from 'react-router-dom';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {GET_USER_ROUTE_TO_DASHBOARD} from '../../../config/RoutesConstants';
import {saveCoverPhoto, getUserHandling, saveCoverPhotoSuccess, saveCoverPhotoError} from '../../../actions/UserActions';
import { Config } from '../../../config/Constants';
import ReactLoading from 'react-loading';
import CoverPhotoUpdateModalHOC from './CoverPhotoUpdateModalHOC/CoverPhotoUpdateModalHOC';
import {GET_USER_ROUTE} from '../../../config/RoutesConstants';

class CoverPhotoUpdateModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
          src: null,
          cropResult: null,
          loading: false
        };

        this.cropAndSave = this.cropAndSave.bind(this);
        this.onChange = this.onChange.bind(this);
        this.useDefaultImage = this.useDefaultImage.bind(this);
    }
    
    onChange(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer)
            files = e.dataTransfer.files;
        else if (e.target)
            files = e.target.files;
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ src: reader.result, file: files[0] });
        };
        reader.readAsDataURL(files[0]);
    }

    cropAndSave() {
        if (typeof this.cropper.getCroppedCanvas() === 'undefined' || !this.cropper.getCroppedCanvas())
            return;

        this.setState({
            cropResult: this.cropper.getCroppedCanvas().toDataURL('image/jpeg')
        }, () => {
            fetch(this.state.cropResult)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], this.state.file.name)
                this.saveCoverPhoto(file);
            })
        });
    }

    useDefaultImage() {
        this.setState({ src: null, cropResult: null, file: null, loading: false });
    }

    saveCoverPhoto(file){
        this.setState({
            loading: true
        }, () => {
            this.props.saveCoverPhoto(file).then((data) => {
                this.props.saveCoverPhotoSuccess();
                this.props.getUserHandling().then(() => {
                    this.useDefaultImage();
                    Alert.success(UPDATE_COVER_PHOTO_SUCCESS, ALERT_SETTINGS);
                    this.props.toggleModalUserDataUpdate(false, true);
                    this.props.toggleModalCoverPhotoUpdate(false, true);
                    this.props.history.push(GET_USER_ROUTE_TO_DASHBOARD(this.props.user));
                });
            }).catch((err) => {
                this.props.saveCoverPhotoError();
            });
        });
    }

    onCloseCoverPhotoUpdateModal = () => {
        this.useDefaultImage(); 
        this.props.toggleModalCoverPhotoUpdate(false, true);
    }
    
    render(){
        return (
            <Dialog
            scroll='body'
            fullWidth={true}
            fullScreen={true}
            open={this.props.coverPhotoUpdateModal}
            onClose={() => this.props.toggleModalCoverPhotoUpdate(false, true)}
            className='photo-update-modal'
        >
            {
                this.state.loading ? 
                <Fade duration={500} delay={250} >
                    <div className="photo-update-modal__content">
                        <div className="photo-update-modal__content-loading-wrapper">
                            <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                            <p className="photo-update-modal__loading-content">{PREPARING_PHOTO_PLEASE_WAIT}</p>
                        </div>
                    </div>
                </Fade> :
                <CoverPhotoUpdateModalHOC>
                    <Link to={GET_USER_ROUTE(this.props.user)} onClick={this.onCloseCoverPhotoUpdateModal} className="photo-update-modal__close-button">
                        <img src={'/img/icons/close-cross.svg'} alt={CLOSE_MODAL}/>
                        <span className="modal__close-button__text">{CLOSE_MODAL}</span>
                    </Link>
                    <Fade duration={500} delay={250}>
                        <div className="photo-update-modal__content cover-photo-update-modal">
                            <div className="photo-update-modal__wrapper"> 
                                <div className="container">
                                    <div className="row cover-photo-update-modal__header">
                                        <div className="col-md-1 cover-photo-update-modal__header-col-md-1"></div>
                                        <div className="col-md-5 photo-update-modal__left-side-wrapper">
                                            <div className="row photo-update-modal__left-side-wrapper__title">
                                                <div className="col-md-1">
                                                    <img className="photo-update-modal__step-icon" src={'/img/icons/image.svg'} alt={ALT_ICON}/>
                                                </div>
                                                <div className="col-md-11 col-xs-12">
                                                    <p className="photo-update-modal__left-side__header">{COVER_PICTURE_CHANGE_HEADER}</p>                                         
                                                </div>
                                            </div>
                                            <div className="row cover-photo-update-modal__header__text">
                                                <div className="col-md-1"></div>
                                                <div className="col-md-11">
                                                    <div className="photo-update-modal__left-side__intro-title">
                                                        <p>{COVER_PHOTO_UPLOAD_DESCRIPTION}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-5 cover-photo-update-modal__right-side-wrapper " + (this.state.src ? 'photo__cover-user-element-wrapper-active' : '') }>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='cover-photo-cropper-wrapper'>
                                                        {
                                                            this.state.src ? 
                                                                <Cropper
                                                                    style={{ height: 250, 
                                                                        width: '100%',
                                                                        borderRadius: '4px',
                                                                        overflow: 'hidden',
                                                                        marginTop: '20px',
                                                                        maxWidth: '400px',
                                                                        marginLeft: '15px',
                                                                        boxShadow: '0px 0px 0px 7px rgba(255,255,255, .1)' 
                                                                    }}
                                                                    preview=".photo__cover-user-element__intro__background-preview"
                                                                    guides={false}
                                                                    src={this.state.src}
                                                                    ref={cropper => { this.cropper = cropper; }}
                                                                    aspectRatio={6 / 1}
                                                                    autoCropArea={1}
                                                                    zoomOnWheel={false}
                                                                />
                                                            :
                                                        <label className="file-input"> 
                                                            <span>{IMAGE_INSERTION}</span>
                                                            <input type="file" className="file" onChange={this.onChange}  />
                                                        </label>
                                                        }
                                                    </div>
                                                </div>
                                                {this.state.src ? 
                                                    (
                                                        <Button onClick={() => this.cropAndSave()} className="photo-update-modal__button">
                                                            <p>{COVER_PHOTO_UPDATE_MODAL_CONFIRMATION}</p>
                                                        </Button>
                                                    ) : null
                                                }
                                                {
                                                    this.state.src ? 
                                                    <Button onClick={() => {this.useDefaultImage()}} className="photo-update-modal__reset-button">
                                                        <p>{INSERT_ANOTHER_IMAGE}</p>
                                                    </Button> : ''
                                                }
                                            </div>  
                                        </div>
                                        <div className="col-md-1 cover-photo-update-modal__header-col-md-1"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10 padding-left-right-0-media">
                                            <div className="photo__cover-user-element">
                                                <div className="photo__cover-user-element__intro">
                                                    <Link to="#" className="photo__cover-user-element__intro__link">
                                                        <div className="photo__cover-user-element__intro__background-preview">
                                                            {
                                                                <img src={Config.getUserPhoto(this.props.user, Config.CONSTANT_COVER_PICTURE)} className="photo__cover-user-element__intro__image" alt={ALT_ICON}/>
                                                            }
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </CoverPhotoUpdateModalHOC>
            }
        </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        coverPhotoUpdateModal: state.modalReducer.coverPhotoUpdateModal,
        user: state.userReducer.user
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalCoverPhotoUpdate,
        toggleModalUserDataUpdate,
        saveCoverPhoto,
        getUserHandling,
        saveCoverPhotoSuccess,
        saveCoverPhotoError
    }, dispatch);
}

CoverPhotoUpdateModal.propTypes = {
    toggleModalCoverPhotoUpdate: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(CoverPhotoUpdateModal));
