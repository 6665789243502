import React from 'react';
import { Link } from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import PropTypes from 'prop-types';
import {Config} from '../../../../../config/Constants';
import {LOGIN_TEXT, REGISTER_TEXT, FOOTER_PROJECT_NAME_PART_1, FOOTER_PROJECT_NAME_PART_2, ICON_TEXT_LOGIN_BUTTON, ICON_TEXT_REGISTER_BUTTON} from '../../../../../config/TextContent';
import './AnonymousDesktopNavigation.css';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {toggleModalLogin} from '../../../../../actions/ModalActions';

const AnonymousDesktopNavigation = (props) => {
    return (
        <div className="header__navigation__anonymous-navigation__desktop">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-xs-4">
                        <Link to='/' className="header__logo-wrapper">
                            {FOOTER_PROJECT_NAME_PART_1}
                            <span>{FOOTER_PROJECT_NAME_PART_2}</span>
                        </Link>
                    </div>
                    <div className="col-md-8 col-xs-8">
                        <div className="header__navigation__anonymous-navigation__desktop__buttons">
                            <Link to={Config.LOGIN_ROOT} className="header__notloggedin__log-in-button">
                                <img src={'/img/icons/login.svg'} alt={ICON_TEXT_LOGIN_BUTTON}/>
                                {LOGIN_TEXT}
                            </Link>
                            <HashLink smooth to="/#registracia" className="header__notloggedin__register-button">
                                <img src={'/img/icons/add-user.svg'} alt={ICON_TEXT_REGISTER_BUTTON}/>
                                {REGISTER_TEXT}
                            </HashLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginModal: state.modalReducer.loginModal,
    }
}

AnonymousDesktopNavigation.propTypes = {
    toggleModalLogin: PropTypes.func.isRequired,
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleModalLogin
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(AnonymousDesktopNavigation);
