import axios from "axios";
import Auth from "../Auth";
import EndpointsUrl from "../EndpointsUrl";
import {GET, PUT, POST, DELETE} from './AxiosManagerConstants';
import {addDataAxiosManager} from '../AxiosManager/AxiosManagerHelper';
import Alert from 'react-s-alert';
import {ALERT_SETTINGS} from '../../config/Config';

const axiosManager = (method, url, _data={}, _headers={}) => {
    _data = addDataAxiosManager(_data);
    let headers = _headers;

    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
    
    headers["Content-Type"] = "application/json";
    if(Auth.getToken()){
        headers["Authorization"] = `Bearer ${Auth.getToken()}`;
    }
    
    let request_data = {
        url: EndpointsUrl.getEndpointUrl(url),
    };
    let request_config = {};
    request_config.headers = headers;

    if(["GET", "DELETE"].includes(method))
        request_config.params = _data;

    switch(method){
        case GET: {
            let response = axios.get(request_data.url, request_config);
            return response;
        }
        case POST: {
            let response = axios.post(request_data.url, _data, request_config);
            let content = "";
            response.catch((err) => {
                let err_response = err.response;
                if(err_response.status === 400){
                    if(err_response.data && err_response.data.errors){
                        if(Object.keys(err_response.data.errors).length > 0){
                            let errors = err_response.data.errors;
                            for (let key in errors) {
                                if (errors.hasOwnProperty(key)) {
                                    for(let i in errors[key]){
                                        if(errors[key][i].length > 0){
                                            content += errors[key][i] + "\r\n";  
                                        }
                                    }
                                }
                            }
                            Alert.error(content, ALERT_SETTINGS);
                        }
                    }
                }
            });
            return response;
        }
        case PUT:
            return axios.put(...request_data);
        case DELETE: 
            return axios.delete(request_data.url, request_config);
        default:
            break;
    };
};

export default axiosManager;
