import { GET_CLUB_TYPES, GET_CLUB_TYPES_ERROR, GET_CLUB_TYPES_SUCCESS  } from '../actions/ClubTypeActions';

const INITIAL_STATE = {
    clubTypeList: [],
    loading: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {  
        case GET_CLUB_TYPES:
            return {...state, clubTypeList: action.payload.data || [], loading: true};
        case GET_CLUB_TYPES_ERROR: 
            return {...state, loading: false};
        case GET_CLUB_TYPES_SUCCESS:
            return {...state, loading: false};
        default:
            return state;
    }
}
