import React from 'react';
import './CoverUserData.css';
import {ALT_ICON, GET_USERTYPE_IN_NOMINATIV} from '../../../../config/TextContent';
import { getFullname, getUserTypeNumber } from '../../../../client/Helper';

const CoverUserData = (props) => {
    const user_fullname = getFullname(props.user, props.user && props.user.hasOwnProperty('user_data') ? props.user.user_data : null);
    const user_role = GET_USERTYPE_IN_NOMINATIV(getUserTypeNumber(props.user));

    return (
        <div className="cover-user-data">
            <div className="cover-desktop-user-data">
                <div className="cover-desktop-user-data__info">
                    <div className="cover-desktop-user-data__user-name-type">
                        <p className="cover-desktop-user-data__user-type">{user_role}</p>
                        <p className="cover-desktop-user-data__user-name">{user_fullname}</p>
                    </div>
                </div>
                <div className="cover-desktop-user-data__genres">
                    {
                        props.category ?
                        (
                            <div className="cover-desktop-user-data__genre-type">
                                <img src={'/img/icons/category.svg'} alt={ALT_ICON} />
                                <p className="cover-desktop-user-data__genre-type__title">{props.category}</p>
                            </div>
                        ) : ''
                    }
                    {
                        props.genre ?
                        (
                            <div className="cover-desktop-user-data__genre-type">
                                <img src={'/img/icons/play-button.svg'} alt={ALT_ICON} />
                                <p className="cover-desktop-user-data__genre-type__title">{props.main_genre ? props.main_genre + ", " + props.genre : props.genre}</p>
                            </div>
                        ) : ''
                    }
                </div>
            </div>

            <div className="cover-mobile-user-data">
                <div className="cover-mobile-user-data__vip-member display-none-important">
                    <p>VIP člen</p>
                </div>
                <div className="cover-mobile-user-data__name">
                    <p>{user_fullname}</p>
                </div>
                <div className="cover-mobile-user-data__job">
                    <p>{user_role}</p>
                </div>
            </div>
        </div>
    );
}

export default CoverUserData;
