import React from 'react';
import './ArticleElement.css';
import ArticleHeader from './ArticleHeader/ArticleHeader';
import ArticleBody from './ArticleBody/ArticleBody';

const ArticleElement = () => {
    return (
        <div className="contribution__table">
            <div className="contribution__table__content">
                <ArticleHeader />
                <ArticleBody />
            </div>
        </div>
    )
}

export default ArticleElement;
