import React from 'react';
import LoadingButton from '../../../LoadingButton/LoadingButton';
import {SAVE_DATA_BUTTON, CONTENT_SAVING} from '../../../../config/TextContent';

const UserDataUpdateModalSubmitButton = (props) => {
    return (
        <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <div className="user_data_update_modal_submit_button">
                    <LoadingButton 
                        paragraphClass="pre-register-content__form__button__intro-title"
                        loaderClass="pre-register-content__form__button__react-loading"
                        classes="default_transparent_button_important"
                        loading={props.loading}
                        content={SAVE_DATA_BUTTON}
                        loadingContent={CONTENT_SAVING}
                        onClick={props.onSubmit}
                    />
                </div>
            </div>
        </div>
    );
}


export default UserDataUpdateModalSubmitButton;

