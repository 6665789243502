import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import Dialog from '@material-ui/core/Dialog';
import {fullLogout} from "../../../actions/AuthActions";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {CLOSE_MODAL, CLOSE_EVENTS_BY_USER_MODAL, EVENTS_BY_USER_MODAL_TITLE, EVENTS_BY_USER_MODAL_TEXT, LOADING_EVENTS, USER_HAS_NO_EVENTS} from '../../../config/TextContent';
import {toggleModalEventsByUser, toggleModalUserDataUpdate} from '../../../actions/ModalActions';
import EventSlider from '../../EventSlider/EventSlider';
import './EventsByUserManagementModal.css';
import ReactLoading from 'react-loading';
import Fade from 'react-reveal/Fade';
import {Config} from '../../../config/Constants';
import {Link} from "react-router-dom";
import EventFilters from '../../Dashboard/EventFilters/EventFilters';

class EventsByUserManagementModal extends Component {
    onEventsByUserClose = () => {
        this.props.toggleModalEventsByUser(false, true);
    }

    render() {
        return (
            <Dialog
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                open={this.props.eventsByUserManagementModalOpened}
                onClose={this.onEventsByUserClose}
                className='events-by-user-modal'
            >
                
                <div>
                    <Link to={Config.UPDATE_USER_DATA_ROOT} onClick={this.onEventsByUserClose} className="photo-update-modal__close-button">
                        <img src={'/img/icons/close-cross.svg'} alt={CLOSE_MODAL}/>
                        <span className="modal__close-button__text">{CLOSE_EVENTS_BY_USER_MODAL}</span>
                    </Link>
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 padding-left-right-0">
                                    <p className="events-by-user-modal__header-title"><span>{EVENTS_BY_USER_MODAL_TITLE}</span></p>
                                    {this.props.eventsByUserList.length === 0 ? 
                                    <p className="events-by-user-modal__header-text"><span>{USER_HAS_NO_EVENTS}</span></p>
                                    : <p className="events-by-user-modal__header-text"><span>{EVENTS_BY_USER_MODAL_TEXT}</span></p>
                                    }
                                </div>
                            </div>
                            <EventFilters 
                                eventsByUserManagement={true}
                            />
                        </div> 
                    </div>
                    {this.props.loadingEventsByUser ? (
                        <Fade duration={500} delay={250} >
                            <div className="photo-update-modal__content loading-wrapper-part-page">
                                <div className="photo-update-modal__content-loading-wrapper">
                                    <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                                    <p className="photo-update-modal__loading-content">{LOADING_EVENTS}</p>
                                </div>
                            </div>
                        </Fade>
                    ) : (
                        <div className="events-by-user-modal__content">
                            <div className="events-by-user-modal__content__event_sliders">
                                <div className="container">
                                    {
                                        this.props.eventsByUserList.map((value, index) => {
                                            return (
                                                <EventSlider 
                                                    key={index} 
                                                    category={value.category}
                                                    events={value.events} 
                                                    slidesToShow="4"
                                                    specifier="eventManagement"
                                                />
                                            );
                                        })
                                    } 
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {  
    return {
        user: state.userReducer.user,
        eventsByUserManagementModalOpened: state.modalReducer.eventsByUserManagementModal,
        eventsByUserList: state.eventReducer.eventsByUserList,        
        loadingEventsByUser: state.eventReducer.loadingEventsByUser,
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fullLogout,
        toggleModalEventsByUser,
        toggleModalUserDataUpdate
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(EventsByUserManagementModal));
