import {REQUIRED_EMAIL, EMAIL_BAD_FORMAT, REQUIRED_PASSWORD, PASSWORD_REQUIRED, PASSWORD_NOT_SAME, INTERPRET_NAME_OR_BAND_NAME_REQUIRED, 
    CITY_NAME_REQUIRED, EVENT_GENRE_NAME_REQUIRED, COUNTRY_NAME_REQUIRED, 
    CLUB_NAME_REQUIRED, ADDRESS_REQUIRED, CLUB_TYPE_REQUIRED, PROMOTER_NAME_REQUIRED, NAME_REQUIRED, 
    SURNAME_REQUIRED, GENDER_REQUIRED, COORDINATES_REQUIRED, STREET_REQUIRED,
    COUNTRY_REQUIRED, PLACE_FULL_STRING_REQUIRED} from '../config/TextContent';
import {cloneObject} from '../client/Helper';
import {SLOVAK_COUNTRY_ID} from '../config/Constants';

export const isNotBlankAndNotNull = (input) => {
    if(input !== "" && input !== null)
        return true;
    return false;
}

export const isValidEmail = (email) => {
    let error = null;
    if (email.length === 0)
        error = REQUIRED_EMAIL;
    else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)))
        error = EMAIL_BAD_FORMAT;
    return error;
}

export const isValidPassword = (password) => {
    let error = null;
    if (password.length === 0)
        error = REQUIRED_PASSWORD;
    return error;
}


export const isValidPasswordConfirm = (password, confirmPassword) => {
    let error = null;
    if(password.length === 0 || !password)
        error = PASSWORD_REQUIRED;
    else if(password !== confirmPassword)
        error = PASSWORD_NOT_SAME; 
    return error;
}

export const isValidRequiredString = (value) => {
    if(value.length > 0){
        let newString = value.split(" ").join("");
        if(newString.length > 0){
            return true;
        }
    }
    return false;
}

export const validateInterpret = (userData, error) => {
    let errorObj = cloneObject(error);
    errorObj.name = !isValidRequiredString(userData.name) ? INTERPRET_NAME_OR_BAND_NAME_REQUIRED : null;
    errorObj.country = !userData.country ? COUNTRY_NAME_REQUIRED : null;
    if(userData.country){
        if(userData.country === SLOVAK_COUNTRY_ID)
            errorObj.city = !userData.city ? CITY_NAME_REQUIRED : null;
        else
            errorObj.city_string = !userData.city_string ? CITY_NAME_REQUIRED : null;
    }
    errorObj.genre = !userData.genre ? EVENT_GENRE_NAME_REQUIRED : null;
    return errorObj;
}

export const validateClub = (userData, error) => {
    let errorObj = cloneObject(error);
    errorObj.name = !isValidRequiredString(userData.name) ? errorObj.name = CLUB_NAME_REQUIRED : null;
    errorObj.place_full_string = !isValidRequiredString(userData.place_full_string) ? PLACE_FULL_STRING_REQUIRED : null;
    errorObj.place_string = !isValidRequiredString(userData.place_string) ? ADDRESS_REQUIRED : null;
    errorObj.country_string = !isValidRequiredString(userData.country_string) ? COUNTRY_REQUIRED : null;
    errorObj.street_string = !isValidRequiredString(userData.street_string) ? STREET_REQUIRED : null;
    errorObj.lat = !userData.lat ? COORDINATES_REQUIRED : null;
    errorObj.lng = !userData.lng ? COORDINATES_REQUIRED : null;
    errorObj.type = !userData.type ? CLUB_TYPE_REQUIRED : null;
    errorObj.genre = !userData.genre ? EVENT_GENRE_NAME_REQUIRED : null;
    return errorObj;
}

export const validatePromoter = (userData, error) => {
    let errorObj = cloneObject(error);
    errorObj.name = !isValidRequiredString(userData.name) ? PROMOTER_NAME_REQUIRED : null;
    errorObj.country = !userData.country ? COUNTRY_NAME_REQUIRED : null;
    if(userData.country){
        if(userData.country === SLOVAK_COUNTRY_ID)
            errorObj.city = !userData.city ? CITY_NAME_REQUIRED : null;
        else
            errorObj.city_string = !isValidRequiredString(userData.city_string) ? CITY_NAME_REQUIRED : null;
    }
    return errorObj;
}

export const validateUser = (userData, error) => {
    let errorObj = cloneObject(error);
    errorObj.name = !isValidRequiredString(userData.name) ? NAME_REQUIRED : null;
    errorObj.surname = !isValidRequiredString(userData.surname) ? SURNAME_REQUIRED : null;
    errorObj.gender = !userData.gender ? GENDER_REQUIRED : null;
    errorObj.country = !userData.country ? COUNTRY_NAME_REQUIRED : null;
    if(userData.country){
        if(userData.country === SLOVAK_COUNTRY_ID)
            errorObj.city = !userData.city ? CITY_NAME_REQUIRED : null;
        else
            errorObj.city_string = !isValidRequiredString(userData.city_string) ? CITY_NAME_REQUIRED : null;
    }
    errorObj.genre = !userData.genre ? EVENT_GENRE_NAME_REQUIRED : null;
    return errorObj;
}
