import React, {Component} from 'react';
import {connect} from "react-redux";
import { getUserTypeNumber, formatDate, filterFutureEvents, filterPastEvents } from '../client/Helper';
import '../components/Dashboard/ProfileUserInfo/ProfileUserInfo.css';
import FixedButtonAddEvent from '../components/FixedButtonAddEvent/FixedButtonAddEvent';
import { isLoggedInUserInLocalStorage, setLoadingSetSelectedUser, setSelectedUser, setSelectedUserSuccess, setSelectedUserError } from '../actions/UserActions';
import { ALT_ICON, LABEL_DATE_STARTED, LABEL_GENRE, LABEL_COUNTRY } from '../config/TextContent';
import { Config } from '../config/Constants';
import GalleryLightBox from 'react-photo-gallery';
import SimpleGalleryImageControls from '../components/Dashboard/Gallery/SimpleGalleryImageControls/SimpleGalleryImageControls';
import Lightbox from 'react-images';
import {bindActionCreators} from "redux";
import TableEvent from '../components/TableEvent/TableEvent';

class UserInfo extends Component{

    constructor(props, context){
        super(props, context);

        this.state = {
            mounted: false,
            currentImage: 0,
            lightboxIsOpen: false,
            displayMoreGalleryPhotos: false,
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setState({
            mounted: true
        });

        if (this.props.match.params.username){
            this.props.setLoadingSetSelectedUser();
            this.props.setSelectedUser(this.props.match.params.username).then(() => this.props.setSelectedUserSuccess()).catch(() => this.props.setSelectedUserError());
        }
    }

    componentWillReceiveProps = (props) => {
        if(props.selectedUser && props.match.params.username && props.selectedUser.username !== props.match.params.username){
            window.scrollTo(0, 0);
            props.setLoadingSetSelectedUser();
            props.setSelectedUser(props.match.params.username).then(() => {
                props.setSelectedUserSuccess();
            }).catch(() => {
                props.setSelectedUserError();
            });
        }
    }

    componentWillUnmount(){
        this.setState({
            mounted: false
        })
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage - 1
            }
        });
    }

    gotoNext() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage + 1
            }
        });
    }

    displayMoreGalleryPhotos() {
        this.setState({
            displayMoreGalleryPhotos: true 
        });
    }

    render(){
        const userName = this.props.selectedUser?.user_data?.name;
        const userCity = this.props.selectedUser?.user_data?.city?.name;
        const userPlace = this.props.selectedUser?.user_data?.state?.name;
        const dateStarted = this.props.selectedUser?.user_data?.date_started && formatDate(new Date(this.props.selectedUser?.user_data?.date_started));;
        const genre = this.props.selectedUser?.user_data?.genre?.name;
        const photos = this.props.selectedUser?.user_data?.photos ?? [];
        const futureEvents = this.props.selectedUser?.user_events?.length > 0 ? filterFutureEvents(this.props.selectedUser?.user_events) : [];
        const pastEvents = this.props.selectedUser?.user_events?.length > 0 ? filterPastEvents(this.props.selectedUser?.user_events) : [];

        const mainPhoto = Config.getUserPhoto(this.props.selectedUser, Config.CONSTANT_TITLE_PICTURE);
        const mainPhoto_lightbox_object = {
            index: 0,
            height: 1, 
            id: 1, 
            src: mainPhoto
        };
        const images_to_gallery_lightbox = [mainPhoto_lightbox_object].concat(Config.processPhotosToGallery(photos));

        return <div>
             <div>
                {isLoggedInUserInLocalStorage() ? <FixedButtonAddEvent/> : null}
                <div className="profileUserInfo__content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 padding-left-right-0-media">
                                <div className="profileUserInfo__background">
                                    <div className="row profileUserInfo__maininfo">
                                        <div className="col-md-6 profileUserInfo__maininfo__wrapper">
                                            <h2 className="profileUserInfo__maininfo__wrapper__eventName">{userName}</h2>
                                            <div className="profileUserInfo__maininfo__wrapper__genre event-detail__event-place">
                                                <div className="row no-padding padding-top-5">
                                                    <div className="col-md-1 col-xs-1 no-padding"> 
                                                        <img src={'/img/icons/address.svg'} alt={ALT_ICON} className="top-0-important" />
                                                    </div>
                                                    <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0"> 
                                                        <p className="profileUserInfo__maininfo__wrapper__eventDate__date">
                                                            {
                                                                userCity ? 
                                                                <span>{LABEL_COUNTRY}: <strong>{userCity}</strong>, <strong>{userPlace}</strong></span>
                                                                :
                                                                <span>{LABEL_COUNTRY}: <strong>{userPlace}</strong></span>   
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="profileUserInfo__maininfo__wrapper__genre">
                                                <div className="row no-padding padding-top-5">
                                                    <div className="col-md-1 col-xs-1 no-padding"> 
                                                        <img src={'/img/icons/passage-of-time.svg'} alt={ALT_ICON} />
                                                    </div>
                                                    <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0"> 
                                                        <p className="profileUserInfo__maininfo__wrapper__eventDate__date">
                                                            {LABEL_DATE_STARTED}: <strong>{dateStarted}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="profileUserInfo__maininfo__wrapper__genre">
                                                <div className="row no-padding padding-top-5">
                                                    <div className="col-md-1 col-xs-1 no-padding"> 
                                                        <img 
                                                            src={'/img/icons/list.svg'} 
                                                            alt={ALT_ICON}
                                                        />
                                                    </div>
                                                    <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0">
                                                        {
                                                            <p className='profileUserInfo_genre'>{LABEL_GENRE}: <strong>{genre}</strong></p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <br/>
                                            <hr/>
                                            <br/>
                                            <div className="profileUserInfo__maininfo__smallPhotos row">
                                                <div className={!this.state.displayMoreGalleryPhotos ? "col-md-9 no-padding" : "col-md-12 no-padding"}>
                                                    <div className={this.state.displayMoreGalleryPhotos ? "profileUserInfo__maininfo__smallPhotos__photos display_more_gallery_photos" : "eventDetail__maininfo__smallPhotos__photos"}>
                                                        <GalleryLightBox 
                                                            photos={Config.processPhotosToGallery(photos)} 
                                                            direction={"row"} 
                                                            onClick={this.openLightbox} 
                                                            ImageComponent={SimpleGalleryImageControls}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <Lightbox 
                                                images={images_to_gallery_lightbox}
                                                onClose={this.closeLightbox}
                                                onClickPrev={this.gotoPrevious}
                                                onClickNext={this.gotoNext}
                                                currentImage={this.state.currentImage}
                                                isOpen={this.state.lightboxIsOpen}
                                            />
                                        </div>
                                        <div className="col-md-6 no-padding">
                                            <div className="profileUserInfo__maininfo__bigPhoto">
                                                {
                                                    mainPhoto && 
                                                        <img onClick={
                                                                event => 
                                                                this.openLightbox(event, 
                                                                    {
                                                                        index: 0,
                                                                        photo: mainPhoto_lightbox_object, 
                                                                        previous: null,
                                                                        next: photos.length > 1 && images_to_gallery_lightbox[1] 
                                                                    }
                                                                )
                                                            } 
                                                            src={mainPhoto}
                                                            alt={ALT_ICON}
                                                        /> 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    {
                                        futureEvents.length > 0 ? (
                                            <div>
                                                <br/>
                                                <br/>
                                                <h2 className="profileUserInfo__maininfo__wrapper__eventName">Pripravované eventy</h2>
                                                <div className="profileUserInfo__maininfo__wrapper__genre event-detail__event-place">
                                                    <div className="row no-padding padding-top-5">
                                                        {
                                                            futureEvents.map((event, index) => {
                                                                return(
                                                                    <TableEvent 
                                                                        key={index}
                                                                        classEvent="big__table__event"
                                                                        event={event}
                                                                        loginModal={false}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <br/>
                                            </div>
                                        ) : ''
                                    }
                                    {
                                        pastEvents.length > 0 ? (
                                            <div>
                                                <br/>
                                                <br/>
                                                <h2 className="profileUserInfo__maininfo__wrapper__eventName">Historické eventy</h2>
                                                <div className="profileUserInfo__maininfo__wrapper__genre event-detail__event-place">
                                                    <div className="row no-padding padding-top-5">
                                                        {
                                                            pastEvents.map((event, index) => {
                                                                return(
                                                                    <TableEvent 
                                                                        key={index}
                                                                        classEvent="big__table__event"
                                                                        event={event}
                                                                        loginModal={false}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <br/>
                                            </div>
                                        ) : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        selectedUser: state.userReducer.selectedUser,
        role: getUserTypeNumber(state.userReducer.selectedUser)
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        setLoadingSetSelectedUser,
        setSelectedUser,
        setSelectedUserSuccess,
        setSelectedUserError
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(UserInfo);
