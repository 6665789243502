import React from 'react';
import '../UserDataUpdateModal.css';
import {Link} from "react-router-dom";
import { Config } from '../../../../../config/Constants';
import {getUserTypeNumber} from '../../../../../client/Helper';
import {USER_DATA_MANAGEMENT_UPDATE_MODAL, USER_DATA_MANAGEMENT_UPDATE_MODAL_TEXT_USER, ALT_ICON, COVER_PICTURE_CHANGE_HEADER, 
    COVER_PICTURE_CHANGE_TEXT, UPDATE_EVENTS_BY_USER_UPDATE_MODAL, UPDATE_EVENTS_BY_USER_UPDATE_MODAL_TEXT,
    CHANGE_PASSWORD_HEADER, CHANGE_PASSWORD_TEXT, ACCOUNT_SETTINGS_HEADER, ACCOUNT_SETTINGS_TEXT} from '../../../../../config/TextContent';
import { connect } from 'react-redux';

const UserDataUpdateModalUserContent = (props) => {
    return (
        <div className="row user-data-update-modal__links">
            <div className="col-md-6 no-padding-left-right-mobile">   
                <Link to={Config.USER_DATA_MANAGEMENT_ROOT} className="user-data-update-modal__links__link">
                    <div className="user-data-update-modal__links__change-element left">
                        <div className="row">
                            <div className="col-md-2 col-xs-2">
                                <img src={"/img/icons/identification.svg"} alt={ALT_ICON} />
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <h3>{USER_DATA_MANAGEMENT_UPDATE_MODAL}</h3>
                                <p>{USER_DATA_MANAGEMENT_UPDATE_MODAL_TEXT_USER}</p>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to={Config.CHANGE_COVER_PHOTO_ROOT} className="user-data-update-modal__links__link">
                    <div className="user-data-update-modal__links__change-element">
                        <div className="row">
                            <div className="col-md-2 col-xs-2">
                                <img src={"/img/icons/landscape-image.svg"} alt={ALT_ICON} />
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <h3>{COVER_PICTURE_CHANGE_HEADER}</h3>
                                <p>{COVER_PICTURE_CHANGE_TEXT}</p>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to={Config.ACCOUNT_SETTINGS_ROOT} className="user-data-update-modal__links__link">
                    <div className="user-data-update-modal__links__change-element">
                        <div className="row">
                            <div className="col-md-2 col-xs-2">
                                <img src={"/img/icons/account-settings-green.svg"} alt={ALT_ICON} />
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <h3>{ACCOUNT_SETTINGS_HEADER}</h3>
                                <p>{ACCOUNT_SETTINGS_TEXT}</p>
                            </div>
                        </div>
                    </div>
                </Link>  
            </div>
            <div className="col-md-6 no-padding-left-right-mobile">
                {props.role !== null && props.role !== 4 ? (
                <Link to={Config.EVENTS_BY_USER_MANAGEMENT_ROOT} className="user-data-update-modal__links__link">
                    <div className="user-data-update-modal__links__change-element right">
                        <div className="row">
                            <div className="col-md-2 col-xs-2">
                                <img src={"/img/icons/resume.svg"} alt={ALT_ICON} />
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <h3>{UPDATE_EVENTS_BY_USER_UPDATE_MODAL}</h3>
                                <p>{UPDATE_EVENTS_BY_USER_UPDATE_MODAL_TEXT}</p>
                            </div>
                        </div>
                    </div>
                </Link>) : null}
                <Link to={Config.CHANGE_PASSWORD_ROOT} className="user-data-update-modal__links__link">
                    <div className="user-data-update-modal__links__change-element">
                        <div className="row">
                            <div className="col-md-2 col-xs-2">
                                <img src={"/img/icons/reload-lock.svg"} alt={ALT_ICON} />
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <h3>{CHANGE_PASSWORD_HEADER}</h3>
                                <p>{CHANGE_PASSWORD_TEXT}</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        role: getUserTypeNumber(state.userReducer.user)
    }
}

export default connect(mapStateToProps, null)(UserDataUpdateModalUserContent);
