import React, {Component} from 'react';
import RoleRegisterModalInterpretContent from "./RegistrationModalContext/RoleRegisterModalInterpretContent";
import RoleRegisterModalClubContent from "./RegistrationModalContext/RoleRegisterModalClubContent";
import RoleRegisterModalPromoterContent from "./RegistrationModalContext/RoleRegisterModalPromoterContent";
import RoleRegisterModalUserContent from "./RegistrationModalContext/RoleRegisterModalUserContent";
import {bindActionCreators} from "redux";
import Dialog from '@material-ui/core/Dialog';
import {fullLogout} from "../../../../actions/AuthActions";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import './RegistrationModal.css';
import {REGISTER_CONTENT_CLOSE, REGISTRATION_SUBTITLE_1,
    REGISTRATION_HELLO, 
    USER_SET_DATA_ERROR, USER_SET_DATA_SUCCESS} from '../../../../config/TextContent';
import {cloneObject, getUserTypeNumber} from '../../../../client/Helper';
import User from "../../../../entities/User";
import Interpret from "../../../../entities/Interpret";
import Club from "../../../../entities/Club";
import Promoter from "../../../../entities/Promoter";
import {ALERT_SETTINGS} from '../../../../config/Config';
import {getUserHandling, saveUserData, saveUserDataSuccess, saveUserDataError} from '../../../../actions/UserActions';
import {validateInterpret, validateClub, validatePromoter, validateUser} from '../../../../client/Validation';
import Alert from 'react-s-alert';
import {toggleModalRegisterLeaveQuestion, toggleModalRegister, toggleModalMapRegister} from '../../../../actions/ModalActions';
import { ROUTE_DASHBOARD, GET_USER_ROUTE_TO_DASHBOARD } from '../../../../config/RoutesConstants';
import {Link} from 'react-router-dom';

class RegistrationModal extends Component {

    constructor(props, context){
        super(props, context);

        this.state = {
            loading: false,
            registered: false,
            registerModalErrors: {}
        }

        this.submitRegister = this.submitRegister.bind(this);
    }

    submitRegister = (userData) => {
        let user;
        let error = cloneObject(this.state.registerModalErrors);

        switch (this.props.modalType) {
            case 1:
                error = validateInterpret(userData, error);
                user = new Interpret(
                    userData.name,
                    userData.country,
                    userData.city,
                    userData.city_string,
                    userData.genre
                );
                break;
            case 2:
                error = validateClub(userData, error);
                user = new Club(
                    userData.name,
                    userData.lat,
                    userData.lng,
                    userData.type,
                    userData.genre,
                    userData.place_string,
                    userData.country_string,
                    userData.street_string,
                    userData.place_full_string,
                );
                break;
            case 3:
                error = validatePromoter(userData, error);
                user = new Promoter(
                    userData.name,
                    userData.country,
                    userData.city,
                    userData.city_string
                );
                break;
            case 4:
                error = validateUser(userData, error);
                user = new User(
                    userData.name, 
                    userData.surname, 
                    userData.gender, 
                    userData.country, 
                    userData.city, 
                    userData.city_string, 
                    userData.genre
                );
                break;
            default:
                return false;
        }

        this.setState({ registerModalErrors: error });

        for (let key in error) {
            if (error.hasOwnProperty(key) && error[key] !== null)
                return false;
        }
        
        this.setState({loading: true});
        this.props.saveUserData(user).then(data => {
            const user_data = data.payload.data;
            Alert.success(USER_SET_DATA_SUCCESS + `${user_data.name ? user_data.name : null} ${user_data.surname ? user_data.surname : ''}`, ALERT_SETTINGS);
            this.props.saveUserDataSuccess();
            this.props.getUserHandling();   
            this.props.history.push(ROUTE_DASHBOARD);
        }).catch((err) => {
            Alert.error(USER_SET_DATA_ERROR, ALERT_SETTINGS);
            this.props.saveUserDataError();
            this.props.fullLogout();
            this.props.history.push('/');
        });
    };
    
    getBody = () => {
        switch (this.props.modalType) {
            case 1:
                return (
                    <RoleRegisterModalInterpretContent
                        loading={this.state.loading}
                        errors={this.state.registerModalErrors} 
                        onSubmit={(user) => this.submitRegister(user)} 
                    />
                );
            case 2:
                return (
                    <RoleRegisterModalClubContent
                        loading={this.state.loading}
                        errors={this.state.registerModalErrors} 
                        onSubmit={(user) => this.submitRegister(user)} 
                        userTypeNumber={this.props.modalType}
                    />
                );
            case 3:
                return (
                    <RoleRegisterModalPromoterContent 
                        loading={this.state.loading}
                        errors={this.state.registerModalErrors} 
                        onSubmit={(user) => this.submitRegister(user)} 
                    />
                );
            case 4:
                return (
                    <RoleRegisterModalUserContent 
                        loading={this.state.loading}
                        errors={this.state.registerModalErrors} 
                        onSubmit={(user) => this.submitRegister(user)} 
                    />
                );
            default:
                return null;
        }
    };

    onRegistrationClose = () => {
        this.props.toggleModalRegister(false, true);
        this.props.toggleModalMapRegister(false, true);
        this.props.toggleModalRegisterLeaveQuestion(true, true);
    }

    render() {
        return (
            <Dialog
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                open={!this.props.user.user_data}
                onClose={this.onRegistrationClose}
                className='register-modal'
            >
                <Link to={GET_USER_ROUTE_TO_DASHBOARD(this.props.user)} onClick={this.onRegistrationClose} className="register-modal__close-button">
                    <img src={'/img/icons/close-cross.svg'} alt={REGISTER_CONTENT_CLOSE}/>
                    <span className="modal__close-button__text">{REGISTER_CONTENT_CLOSE}</span>
                </Link>
                <form action="post">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="register-modal__header-wrapper">
                                <p className="register-modal__header-title">{REGISTRATION_HELLO} {this.props.user && this.props.user.username ? this.props.user.username : null}.</p>
                                <p className="register-modal__header-subtitle" dangerouslySetInnerHTML={{__html: REGISTRATION_SUBTITLE_1}}></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="register-modal__content-wrapper row">
                                <div className="register-modal__content-wrapper__register-modal-content">
                                    { this.getBody() }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modalType: getUserTypeNumber(state.userReducer.user),
        user: state.userReducer.user,
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fullLogout,
        getUserHandling,
        saveUserData,
        saveUserDataSuccess,
        saveUserDataError,
        toggleModalRegisterLeaveQuestion,
        toggleModalRegister,
        toggleModalMapRegister
    }, dispatch);
}

RegistrationModal.propTypes = {
    fullLogout: PropTypes.func.isRequired,
    toggleModalMapRegister: PropTypes.func.isRequired,
    toggleModalRegisterLeaveQuestion: PropTypes.func.isRequired,
    toggleModalRegister: PropTypes.func.isRequired,
    getUserHandling: PropTypes.func.isRequired,
    saveUserData: PropTypes.func.isRequired,
    saveUserDataSuccess: PropTypes.func.isRequired,
    saveUserDataError: PropTypes.func.isRequired
};

RegistrationModal.defaultProps = {
    userData: false,
    user: null
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(RegistrationModal));
