import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {changePassword, changePasswordSuccess, changePasswordError} from '../../../actions/UserActions';
import {CLOSE_MODAL, LOADING_CONTENT, CHANGE_PASSWORD_MODAL_HEADER, CHANGE_PASSWORD_MODAL_TEXT, CURRENT_PASSWORD, PLAIN_PASSWORD, PLAIN_PASSWORD_AGAIN,
    CHANGE_PASSWORD_BUTTON, CONTENT_LOADING, PASSWORD_SUCCESSFULLY_CHANGED, CHANGING_PASSWORD_ERROR} from '../../../config/TextContent';
import {toggleModalChangePassword, toggleModalUserDataUpdate} from '../../../actions/ModalActions';
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {GET_USER_ROUTE_TO_DASHBOARD} from '../../../config/RoutesConstants';
import Fade from 'react-reveal/Fade';
import ReactLoading from 'react-loading';
import LoadingButton from '../../LoadingButton/LoadingButton';
import './ChangePasswordModal.css';
import {ALERT_SETTINGS} from '../../../config/Config';
import Alert from 'react-s-alert';
import {Config} from '../../../config/Constants';
import {isValidPassword, isValidPasswordConfirm} from '../../../client/Validation';


class ChangePasswordModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            errors: {},
            form: {
                current_password: '',
                plainPassword: '',
                plainPassword_again: '',
            }
        }
    }

    onChangePasswordModalClose = () => {
        this.deleteForm();
        this.props.toggleModalChangePassword(false, true);
    }

    onInputChange = (event, field_name) => {
        const event_value = event.target.value;
        this.setState((prevState) => {
            let form = prevState.form;
            let errors = prevState.errors;
            form[field_name] = event_value;
            errors[field_name] = null;
            return {
                errors,
                form
            };
        });
    }

    deleteForm = () => {
        this.setState({
            form: {
                current_password: '',
                plainPassword: '',
                plainPassword_again: ''
            },
            errors: {
                
            }
        });
    }

    submitChangePassword = (form) => {
        let errors = {};
        errors.current_password = isValidPassword(form.current_password);
        errors.plainPassword = isValidPassword(form.plainPassword);
        errors.plainPassword_again = isValidPasswordConfirm(form.plainPassword, form.plainPassword_again);
        if(!errors.current_password && !errors.plainPassword && !errors.plainPassword_again){
            this.setState({
                loading: true
            }, () => {
                this.props.changePassword(form).then(response => {
                    if (response.payload.data && response.payload.data.result){
                        this.deleteForm();
                        this.setState({loading: false});
                        Alert.success(PASSWORD_SUCCESSFULLY_CHANGED, ALERT_SETTINGS);
                        this.props.changePasswordSuccess();
                        this.props.toggleModalChangePassword(false, true);
                        this.props.toggleModalUserDataUpdate(false, true);
                        this.props.history.replace(GET_USER_ROUTE_TO_DASHBOARD(this.props.user));
                    }else {
                        Alert.success(CHANGING_PASSWORD_ERROR, ALERT_SETTINGS);
                        this.props.changePasswordError();
                        this.setState({loading: false});
                    }
                }).catch((err) => {
                    Alert.success(CHANGING_PASSWORD_ERROR, ALERT_SETTINGS);
                    this.props.changePasswordError();
                    this.setState({loading: false});
                });
            });
        }else{
            this.setState({ errors });
        }
    }

    render() {
        return (
            <Dialog
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                open={this.props.changePasswordModalOpened}
                onClose={this.onChangePasswordModalClose}
                className='user-data-update-modal'
            >
            {
            !this.props.user ?  
            <Fade duration={500} delay={250}> 
                <div className="photo-update-modal__content">
                    <div className="photo-update-modal__content-loading-wrapper">
                        <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                        <p className="photo-update-modal__loading-content">{LOADING_CONTENT}</p>
                    </div>
                </div>
            </Fade> :
            <div>
                <Link to={Config.UPDATE_USER_DATA_ROOT} onClick={this.onChangePasswordModalClose} className="user-data-update-modal__close-button">
                    <img src={'/img/icons/close-cross.svg'} alt={CLOSE_MODAL}/>
                    <span className="modal__close-button__text">Zavrieť</span>
                </Link>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="user-data-update-modal__header-wrapper">
                                    <p className="user-data-management-modal__header-title"><span>{CHANGE_PASSWORD_MODAL_HEADER}</span></p>
                                    <p className="user-data-management-modal__header-subtitle">{CHANGE_PASSWORD_MODAL_TEXT}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <form action="post">
                    <div className="container">
                        <div className="row change-password-modal">
                            <div className="col-md-12">
                                <div className="user-data-update-modal__content-wrapper row">
                                    <div className="row user-data-management-modal__step">
                                        <div className="row user-data-management-modal__step__body">
                                            <div className="col-md-1"></div>
                                            <div className="col-md-10 col-xs-10">
                                                <TextField
                                                    name="current_password"
                                                    required
                                                    type="password"
                                                    value={this.state.form.current_password}
                                                    className={this.state.errors.current_password ? 'user-data-management-modal__step__body__element error-textfield' : 'user-data-management-modal__step__body__element'}
                                                    label={this.state.errors.current_password ? this.state.errors.current_password : CURRENT_PASSWORD}
                                                    placeholder="..."
                                                    onChange={(event) => this.onInputChange(event, 'current_password')}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                />
                                                <TextField
                                                    name="plainPassword"
                                                    required
                                                    type="password"
                                                    value={this.state.form.plainPassword}
                                                    className={this.state.errors.plainPassword ? 'user-data-management-modal__step__body__element error-textfield' : 'user-data-management-modal__step__body__element'}
                                                    label={this.state.errors.plainPassword ? this.state.errors.plainPassword : PLAIN_PASSWORD}
                                                    placeholder="..."
                                                    onChange={(event) => this.onInputChange(event, 'plainPassword')}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                />
                                                <TextField
                                                    name="plainPassword_again"
                                                    required
                                                    type="password"
                                                    value={this.state.form.plainPassword_again}
                                                    className={this.state.errors.plainPassword_again ? 'user-data-management-modal__step__body__element modal__step__body__element__last error-textfield' : 'user-data-management-modal__step__body__element modal__step__body__element__last'}
                                                    label={this.state.errors.plainPassword_again ? this.state.errors.plainPassword_again : PLAIN_PASSWORD_AGAIN}
                                                    placeholder="..."
                                                    onChange={(event) => this.onInputChange(event, 'plainPassword_again')}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                />
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="change-password-modal__button_submit">
                                    <LoadingButton
                                        classes="default_transparent_button_important"
                                        paragraphClass="pre-register-content__form__button__intro-title"
                                        loaderClass="pre-register-content__form__button__react-loading"
                                        content={CHANGE_PASSWORD_BUTTON}
                                        loadingContent={CONTENT_LOADING}
                                        loading={this.state.loading}
                                        onClick={() => this.submitChangePassword(this.state.form)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            }
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        changePasswordModalOpened: state.modalReducer.changePasswordModalOpened
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalChangePassword,
        toggleModalUserDataUpdate,
        changePassword,
        changePasswordSuccess,
        changePasswordError
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(ChangePasswordModal));
