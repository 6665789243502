import EndpointsUrl from "../client/EndpointsUrl";
import axiosManager from '../client/AxiosManager/AxiosManager';
import { GET } from '../client/AxiosManager/AxiosManagerConstants';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';

export function getCountries() {
    return {
        type: GET_COUNTRIES,
        payload: axiosManager(GET, EndpointsUrl.GET_COUNTRIES)
    }
}

export function getCountriesSuccess() {
    return {
        type: GET_COUNTRIES_SUCCESS,
        payload: "Countries successfully loaded."
    }
}

export function getCountriesError() {
    return {
        type: GET_COUNTRIES_ERROR,
        payload: "Error while loading countries."
    }
}