import React, { Component } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import './Registration.css';
import { USER_TEXT_1,USER_TEXT_2, USER_TEXT_3,PROMOTER_TEXT_1,PROMOTER_TEXT_2, PROMOTER_TEXT_3,
    CLUB_TEXT_1,CLUB_TEXT_2, CLUB_TEXT_3,INTERPRET_TEXT_1, INTERPRET_TEXT_2, INTERPRET_TEXT_3,
    FREE_REGISTRATION_DESC, FREE_REGISTRATION, CLUB_TEXT, PROMOTER_TEXT, 
    USER_TEXT, INTERPRET_TEXT, VIAC_TEXT, REGISTER_BUTTON } from '../../../config/TextContent';
import {toggleModalRegister} from '../../../actions/ModalActions';
import {Config} from '../../../config/Constants';
import {Link} from 'react-router-dom';

class Registration extends Component {

    constructor(props, context){
        super(props, context);

        this.state = {
            active: 2
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(id) {
        this.setState({active: id});
    }

    render() {
            return (
                <div className="registration" id="registracia">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 registration__header-wrapper">
                                <h3 className="registration__header-title color-dark">{FREE_REGISTRATION}</h3>
                                <p className="registration__header-description color-dark">{FREE_REGISTRATION_DESC}</p>
                            </div>
                        </div>
                        <div className="registration__cards-wrapper text-align-center">
                            <div className={"registration__card registration__card-user " + ( this.state.active === 4 ? 'registration__card-active' : '' ) }>
                                <div className="registration__hidden-content">
                                    <img className="registration__hidden-content__icon-type" src={'/img/icons/team.svg'} alt="icon" />
                                    <h4 className="registration__content__person-type">{USER_TEXT}</h4>
                                    <ul className="registration__hidden-content__list-of-benefits">
                                    <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {USER_TEXT_1}
                                            </div>
                                        </li>
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {USER_TEXT_2}
                                            </div>
                                        </li>
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {USER_TEXT_3}
                                            </div>
                                        </li>
                                    </ul>
                                    <Link to={Config.REGISTRATION_USER_ROOT} className="default_transparent_link">{REGISTER_BUTTON}</Link>
                                </div>
                                <div className="registration__visible-content">
                                    <img className="registration__visible-content__icon-type" src={'/img/icons/team.svg'} alt="icon" />
                                    <h4 className="registration__content__person-type">{USER_TEXT}</h4>
                                    <button className="default_transparent_button" onClick={() => this.onChange(4)}>{VIAC_TEXT}</button>
                                </div>  
                            </div>
                            <div className={"registration__card registration__card-interpret registration__card-first " + ( this.state.active === 1 ? 'registration__card-active' : '' ) }>
                                <div className="registration__hidden-content">
                                    <img className="registration__hidden-content__icon-type" src={'/img/icons/singer.svg'} alt="icon"/>
                                    <h4 className="registration__content__person-type">{INTERPRET_TEXT}</h4>
                                    <ul className="registration__hidden-content__list-of-benefits">
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {INTERPRET_TEXT_1}
                                            </div>
                                        </li>
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {INTERPRET_TEXT_2}
                                            </div>
                                        </li>
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {INTERPRET_TEXT_3}
                                            </div>
                                        </li>
                                    </ul>
                                    <Link to={Config.REGISTRATION_INTERPRET_ROOT} className="default_transparent_link">{REGISTER_BUTTON}</Link>
                                </div>
                                <div className="registration__visible-content">
                                    <img className="registration__visible-content__icon-type" src={'/img/icons/singer.svg'} alt="icon"/>
                                    <h4 className="registration__content__person-type">{INTERPRET_TEXT}</h4>
                                    <button className="default_transparent_button" onClick={() => this.onChange(1)}>{VIAC_TEXT}</button>
                                </div>
                            </div>
                            <div className={"registration__card registration__card-club " + ( this.state.active === 2 ? 'registration__card-active' : '' ) }>
                                <div className="registration__hidden-content">
                                    <img className="registration__hidden-content__icon-type" src={'/img/icons/disco-ball.svg'} alt="icon"/>
                                    <h4 className="registration__content__person-type">{CLUB_TEXT}</h4>
                                    <ul className="registration__hidden-content__list-of-benefits">
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {CLUB_TEXT_1}
                                            </div>
                                        </li>
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {CLUB_TEXT_2}
                                            </div>
                                        </li>
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {CLUB_TEXT_3}
                                            </div>
                                        </li>
                                    </ul>
                                    <Link to={Config.REGISTRATION_CLUB_ROOT} className="default_transparent_link">{REGISTER_BUTTON}</Link>
                                </div>
                                <div className="registration__visible-content">
                                    <img className="registration__visible-content__icon-type" src={'/img/icons/disco-ball.svg'} alt="icon" />
                                    <h4 className="registration__content__person-type">{CLUB_TEXT}</h4>
                                    <button className="default_transparent_button" onClick={() => this.onChange(2)}>{VIAC_TEXT}</button>
                                </div>
                            </div>
                            <div className={"registration__card registration__card-promoter registration__card-last " + ( this.state.active === 3 ? 'registration__card-active' : '' ) }>
                                <div className="registration__hidden-content">
                                    <img className="registration__hidden-content__icon-type" src={'/img/icons/presentation.svg'} alt="icon" />
                                    <h4 className="registration__content__person-type">{PROMOTER_TEXT}</h4>
                                    <ul className="registration__hidden-content__list-of-benefits">
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {PROMOTER_TEXT_1}
                                            </div>
                                        </li>
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {PROMOTER_TEXT_2}
                                            </div>
                                        </li>
                                        <li>
                                            <img src={'/img/icons/arrow-pointing-to-right.svg'} alt="arrow"/>
                                            <div className="registration__list-of-benefits__text-holder">
                                                {PROMOTER_TEXT_3}
                                            </div>
                                        </li>
                                    </ul>
                                    <Link to={Config.REGISTRATION_PROMOTER_ROOT} className="default_transparent_link">{REGISTER_BUTTON}</Link>
                                </div>
                                <div className="registration__visible-content">
                                    <img className="registration__visible-content__icon-type" src={'/img/icons/presentation.svg'} alt="icon" />
                                    <h4 className="registration__content__person-type">{PROMOTER_TEXT}</h4>
                                    <button className="default_transparent_button" onClick={() => this.onChange(3)}>{VIAC_TEXT}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleModalRegister
    }, dispatch);
}

export default connect(null, matchDispatchToProps)(Registration);
