import React from 'react';
import './AdElement.css';

const AdElement = () => {
    return (
        <div className="ad-content-table">
            <div className="ad-content-table__icon-content">
                <div className="ad-content-table__icon">
                    <img src={'/img/icons/ad-green.svg'} alt="" />
                </div>
                <div className="ad-content-table__content">
                    <p>Ad content here</p>
                </div>
            </div>
        </div>
    )
}


export default AdElement;
