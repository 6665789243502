import EndpointsUrl from "../client/EndpointsUrl";
import {deleteUserData} from '../actions/UserActions';
import axiosManager from "../client/AxiosManager/AxiosManager";
import { POST } from "../client/AxiosManager/AxiosManagerConstants";

export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGGED_IN = 'LOGGED_IN';

export const SAVE_TOKEN = 'SAVE_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const GET_TOKEN = 'GET_TOKEN';

export const IS_USER_AUTHENTICATED = 'IS_USER_AUTHENTICATED';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGGED_OFF = 'LOGGED_OFF';

const login = (userData) => {
    const formData = {
        grant_type: "password",
        client_id: "1_613en37gj8cgkcw84kowowskgk88swkc44cwwog4g00cws8wcs",
        client_secret: "1xwrpsxnd85c8gwowg044ockg04csw0ogg4ogssgw0o0wsks8",
        username: userData.email,
        password: userData.password
    };

    return {
        type: LOGIN,
        payload: axiosManager(POST, EndpointsUrl.GET_AUTH_TOKEN, formData)
    }
}

const loginError = () => {
    return {
        type: LOGIN_ERROR,
        payload: "Error while login."
    }
}

const loggedIn = () => {
    return {
        type: LOGGED_IN,
        payload: "User successfully logged in."
    }
}

const logout = () => {
    localStorage.removeItem('token');
    
    return {
        type: LOGOUT,
        payload: "Logout in progress..."
    }
}

const logoutError = () => {
    return {
        type: LOGOUT_ERROR,
        payload: "Error while logout."
    }
}

const loggedOff = () => {
    return {
        type: LOGGED_OFF,
        payload: "User successfully logged off."
    }
}


const saveToken = (data) => {
    let record = {value: data.access_token, timestamp: (new Date().getTime() + data.expires_in*1000), re_token: data.refresh_token};
    localStorage.setItem('token', JSON.stringify(record));

    return {
        type: SAVE_TOKEN,
        payload: data
    }
}

const removeToken = () => {
    if (localStorage.getItem('token'))
        localStorage.removeItem('token');

    return {
        type: REMOVE_TOKEN
    }
}

const getToken = () => {
    let token = localStorage.getItem('token');

    let record  = JSON.parse(token);

    if (!record) {
        return "Token Expired!";
    }
    if (new Date().getTime() < record.timestamp) {
        return {
            type: GET_TOKEN,
            payload: record.value
        }
    }
}

const fullLogout = () => {
    return dispatch => {
        dispatch(logout());
        dispatch(loggedOff());
        dispatch(deleteUserData());
    }
}

export {
    fullLogout,
    login,
    loginError, 
    loggedIn, 
    logout, 
    logoutError,
    loggedOff,
    saveToken,
    removeToken,
    getToken
}
