import { GET_COUNTRIES, GET_COUNTRIES_ERROR, GET_COUNTRIES_SUCCESS } from '../actions/CountryActions';

const INITIAL_STATE = {
    countryList: [],
    loading: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {  
        case GET_COUNTRIES:
            return {...state, countryList: action.payload.data || [], loading: true};
        case GET_COUNTRIES_ERROR:
            return {...state, loading: false};
        case GET_COUNTRIES_SUCCESS:
            return {...state, loading: false};
        default:
            return state;
    }
}