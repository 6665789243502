import React, {Component, Fragment} from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {toggleModalMapRegister} from '../../../../actions/ModalActions';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import './RegistrationModalMapDialog.css';
import MapWithASearchBox from '../../../Map/MapWithSearchBox';
import {ALT_ICON, BACK_TO_REGISTER_FORM, SAVE_AND_CONTINUE_IN_REGISTRATION, CHOOSE_LOCATION_OF_CLUB,
    REGISTRATION_MODAL_MAP_INTRO_TEXT} from '../../../../config/TextContent';
import SearchBoxInput from '../../../Map/SearchboxInput/SearchboxInput';
import { withScriptjs } from 'react-google-maps';
import {GOOGLE_MAP_URL} from '../../../../config/Constants';

class RegistrationModalMapDialog extends Component {
    
    SearchBox =  withScriptjs(() =>{
        return (
            <SearchBoxInput 
                place={this.props.place}
                onSelectAddressChange={this.props.onSelectAddressChange}
                onSelectAddressComponents={this.props.onSelectAddressComponents}
            />
        );
    });

    render() {
        return (
            <Dialog
                fullScreen={true}
                fullWidth={true}
                scroll='body'
                open={this.props.registerMapModalOpened}
                className='register-map-modal'
                onClose={() => this.props.toggleModalMapRegister(false, true)}
            >
                <Button onClick={() => this.props.toggleModalMapRegister(false, true)} className="register-map-modal__close-button">
                    <img src={'/img/icons/arrow-pointing-to-right.svg'} alt={ALT_ICON}/>
                    {BACK_TO_REGISTER_FORM}
                </Button>
                <div className="row">
                    <div className="col-md-6 register-map-modal__left-side-wrapper">
                        <div className="row">
                            <div className="col-md-1 col-xs-1 register-modal__max-width-425-padding-right-0 register-map-modal__left-side-wrapper__img-number">
                                <img className="register-map-modal__step-icon" src={'/img/icons/numbers/'+ this.props.step +'.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-11 col-xs-11 register-modal__max-width-425-padding-left-20">
                                <p className="register-map-modal__left-side-wrapper__header">{CHOOSE_LOCATION_OF_CLUB}</p>                                         
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1">
                                <div className="register-map-modal__left-side-wrapper__intro-title">
                                    <p>{REGISTRATION_MODAL_MAP_INTRO_TEXT}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-offset-1 col-md-10">
                                <div className="register-map-modal__left-side-wrapper__concrete-place-wrapper-background">
                                    <div className="row register-map-modal__left-side-wrapper__concrete-place-wrapper">
                                        <div className="col-md-12">
                                            <Fragment>
                                                <this.SearchBox 
                                                    googleMapURL={GOOGLE_MAP_URL}
                                                    loadingElement={<div></div>}
                                                    containerElement={<div className="map-container-element-height" />}
                                                    onChange={this.props.onChange}
                                                />
                                            </Fragment>
                                            <p className="register-map-modal__left-side-wrapper__concrete-place-title">
                                                <span className="register-map-modal__left-side-wrapper__concrete-place"> {this.props.place}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-align-center">
                                            <button className="default_transparent_button" onClick={() => this.props.toggleModalMapRegister(false, true)}>
                                                { SAVE_AND_CONTINUE_IN_REGISTRATION }                                        
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 no-padding-right">
                        <MapWithASearchBox 
                            onSelectAddressComponents={this.props.onSelectAddressComponents}
                            onSelectAddressChange={this.props.onSelectAddressChange}
                            markerPositionChanged={this.props.markerPositionChanged}
                            markerLatLng={this.props.markerLatLng}
                            centerLatLng={this.props.centerLatLng}
                        />            
                    </div>
                </div>
            </Dialog>
        )
    }
}

RegistrationModalMapDialog.propTypes = {
    registerMapModalOpened: PropTypes.bool.isRequired,
    onSelectAddressChange: PropTypes.func.isRequired,
    onSelectAddressComponents: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        registerMapModalOpened: state.modalReducer.registerMapModal,
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalMapRegister,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(RegistrationModalMapDialog);
