import {LOGIN, LOGIN_ERROR, LOGGED_IN, LOGOUT, LOGOUT_ERROR, LOGGED_OFF} from '../actions/AuthActions';

const INITIAL_STATE = {token_saved: false, error:null};

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {
        case LOGIN:
            return {...state, token_saved: false, error: null};
        case LOGIN_ERROR:
            return {...state, token_saved: false, error: null};
        case LOGGED_IN:
            return {...state, token_saved: true, error: null};
        case LOGOUT:
            return {...state, token_saved: false, error: null};
        case LOGOUT_ERROR:
            return {...state, token_saved: false, error: null};
        case LOGGED_OFF:
            return {...state, token_saved: false, error: null};

        default:
            return state;
    }

}