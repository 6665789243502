import {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getEventMusicGenres, getAllEventCategoriesSuccess, getAllEventCategories, 
    getAllEventCategoriesError, getEventMusicGenresSuccess, getEventMusicGenresError, getTopEvents, getTopEventsSuccess, getTopEventsError,
    getPremiumEvents, getPremiumEventsSuccess, getPremiumEventsError, setLoadingGetPremiumEvents} from '../../actions/EventActions';
import {getCountries, getCountriesError, getCountriesSuccess} from '../../actions/CountryActions';
import {getCities, getCitiesError, getCitiesSuccess} from '../../actions/CitiesActions';
import {getClubTypes, getClubTypesError, getClubTypesSuccess} from '../../actions/ClubTypeActions';
import {getUserHandling} from '../../actions/UserActions';
import PropTypes from 'prop-types';

class StateLoader extends Component {

    componentDidMount(){
        this.props.getUserHandling().then(() => {
            this.props.setLoadingGetPremiumEvents();
            this.props.getPremiumEvents()
                .then(() => this.props.getPremiumEventsSuccess()).catch(() => this.props.getPremiumEventsError());
            this.props.getTopEvents()
                .then(() => this.props.getTopEventsSuccess()).catch(() => this.props.getTopEventsError());
            this.props.getAllEventCategories()
                .then(() => this.props.getAllEventCategoriesSuccess()).catch(() => this.props.getAllEventCategoriesError());
            this.props.getEventMusicGenres()
                .then(() => this.props.getEventMusicGenresSuccess()).catch(() => this.props.getEventMusicGenresError());
            this.props.getCountries()
                .then(() => this.props.getCountriesSuccess()).catch(() => this.props.getCountriesError());
            this.props.getCities()
                .then(() => this.props.getCitiesSuccess()).catch(() => this.props.getCitiesError());
            this.props.getClubTypes()
                .then(() => this.props.getClubTypesSuccess()).catch(() => this.props.getClubTypesError());

        });
    }

    render(){
        return (
            null
        );
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getEventMusicGenres,
        getEventMusicGenresError,
        getEventMusicGenresSuccess,

        getAllEventCategories, 
        getAllEventCategoriesError,
        getAllEventCategoriesSuccess,

        getCountries,
        getCountriesSuccess,
        getCountriesError,

        getCities,
        getCitiesError,
        getCitiesSuccess,

        getClubTypes,
        getClubTypesError,
        getClubTypesSuccess,

        getUserHandling,

        getTopEvents,
        getTopEventsSuccess,
        getTopEventsError,

        setLoadingGetPremiumEvents,
        getPremiumEvents,
        getPremiumEventsError,
        getPremiumEventsSuccess
    }, dispatch);
}

StateLoader.propTypes = {
    getUserHandling: PropTypes.func.isRequired
}

export default connect(null, matchDispatchToProps)(StateLoader);
