import React, {Component} from 'react';
import {connect} from "react-redux";
import CoverElement from '../../components/Dashboard/CoverElement/CoverElement';
import MenuElement from '../../components/Dashboard/MenuElement/MenuElement';
import Calendar from "../../views/Dashboard/Calendar";
import {withRouter} from 'react-router-dom';
import {Route, Switch} from 'react-router-dom';
import News from '../../components/Dashboard/News/News';
import {GET_USER_ROUTE_TO_DASHBOARD, ROUTE_DASHBOARD, GET_USER_ROUTE_TO_CALENDAR} from '../../config/RoutesConstants';
import { isLoggedInUserInLocalStorage } from '../../actions/UserActions';
import FixedButtonAddEvent from '../../components/FixedButtonAddEvent/FixedButtonAddEvent';
import ProfileEventsContent from './ProfileEventsContent';

class SelectedUserDashboard extends Component {
    componentDidMount = () => { 
        window.scrollTo(0, 0); 
    }

    render(){
        return (
            <div>
                <CoverElement selectedUserDash={true} loggedUserDash={false} user={this.props.selectedUser} loadingUser={this.props.loadingUser} eventMusicGenresList={this.props.eventMusicGenresList} />
                <MenuElement selectedUserDash={true} loggedUserDash={false} user={this.props.selectedUser} loadingUser={this.props.loadingUser} />
                {isLoggedInUserInLocalStorage() ? <FixedButtonAddEvent/> : null}
                <Switch>
                    <Route path={GET_USER_ROUTE_TO_DASHBOARD(this.props.selectedUser) + '/category/:category_id'} component={ProfileEventsContent} {...this.props} />
                    <Route path={GET_USER_ROUTE_TO_CALENDAR(this.props.selectedUser)} name="Calendar" component={Calendar} {...this.props} />
                    <Route path={GET_USER_ROUTE_TO_DASHBOARD(this.props.selectedUser) + '/:chosen_date'} component={ProfileEventsContent} {...this.props} />
                    <Route path={GET_USER_ROUTE_TO_DASHBOARD(this.props.selectedUser)} name="Events" component={ProfileEventsContent} />
                    {[GET_USER_ROUTE_TO_DASHBOARD(this.props.selectedUser), ROUTE_DASHBOARD].map((path, index) => 
                        <Route path={path} component={News} key={index} name="News" />
                    )}
                </Switch>
            </div>
        );
    } 
}

const mapStateToProps = state => {
    return {
        selectedUser: state.userReducer.selectedUser,
        eventMusicGenresList: state.eventReducer.eventMusicGenresList
    }
}

export default withRouter(connect(mapStateToProps, null)(SelectedUserDashboard));
