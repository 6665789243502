import * as React from "react";

class Auth extends React.Component {

    /**
     * Check if a user is authenticated - check if a token is saved in Local Storage and datas are available in redux store
     *
     * @returns {boolean}
     */
    static isUserAuthenticated(userReducer) {
        let timeNow = new Date().getTime();
        let token = localStorage.getItem('token');

        let record  = JSON.parse(token);

        if (record === null || timeNow > record.timestamp)
            return false;
        if(userReducer && userReducer.hasOwnProperty('user') && userReducer.user){
            if (timeNow <= record.timestamp && userReducer.user.hasOwnProperty('id') && userReducer.isAuthenticated){
                return true;
            }
        }
    
        return false;
    }

    /**
     * Deauthenticate a user. Remove a token from Local Storage.
     *
     */
    static deauthenticateUser() {
        localStorage.removeItem('token');
    }

    /**
     * Get a token value.
     *
     * @returns {string}
     */

    static getToken() {
        let token = localStorage.getItem('token');
        let record  = JSON.parse(token);

        if (record) {
            if (new Date().getTime() < record.timestamp) {
                return record.value;
            }
        }

        return '';
    }

    /**
     * Get true/false based on the user permissions in concrete object of user.
     *
     * @returns {boolean}
     */

    static hasObjectUserRole(user=null, role='') {
        if(user && user.hasOwnProperty("role") && typeof user.role[0] !== undefined){
            if(Array.isArray(role)){
                let returned_boolean = false;
                role.forEach((item) => {
                    returned_boolean = returned_boolean || user.role.includes(item);
                });
                return returned_boolean;
            }else{
                return user.role.includes(role);
            }
        }   
        return false;
    }

    /**
     * Get user roles.
     *
     * @returns {array}
     */

    static getUserRoles(user=null) {
        if(user){
            if(user.hasOwnProperty('roles'))
                return user.roles || [];
        }
        return [];
    }
}

export default Auth;
