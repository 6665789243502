class Promoter {
    constructor(name, country, city, city_string) {
        return (
            {
                'modalType': 3,
                'name': name,
                'country': country,
                'city': city,
                'city_string': city_string
            }
        )
    }
}

export default Promoter;
