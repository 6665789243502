import React from 'react';
import './MenuItem.css';
import {Link} from 'react-router-dom';
import { ALT_ICON } from '../../../../../config/TextContent';

const MenuItem = (props) => {
    return (
        <Link to={props.link || '#'} className={"menu-item " + (props.active ? 'active-menu-item' : '')} onClick={props.onClick}>
            <img src={props.img} alt={ALT_ICON}/>
            <p>{props.name}</p>
        </Link>
    );
}

export default MenuItem;
