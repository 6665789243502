import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import Dialog from '@material-ui/core/Dialog';
import {fullLogout} from "../../../actions/AuthActions";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {CLOSE_MODAL, EVENTS_BY_PERFORMER_MODAL_TITLE, EVENTS_BY_PERFORMER_MODAL_TEXT, 
    LOADING_EVENTS_WHERE_IS_USER_AS_PERFORMER, USER_HAS_NO_EVENTS_WHERE_IS_USER_AS_PERFORMER, SUCCESSFULLY_REMOVED_EVENT_PERFORMER, 
    ERROR_REMOVE_EVENT_PERFORMER} from '../../../config/TextContent';
import {toggleModalUserAsEventPerformerRemoval, toggleModalUserDataUpdate} from '../../../actions/ModalActions';
import {removeEventPerformerSuccess, removeEventPerformerError, removeEventPerformer, setLoadingRemoveEventPerformer} from '../../../actions/EventActions';
import EventSlider from '../../EventSlider/EventSlider';
import './UserAsEventPerformerRemovalModal.css';
import ReactLoading from 'react-loading';
import Fade from 'react-reveal/Fade';
import {getUserHandling} from '../../../actions/UserActions';
import { GET_USER_ROUTE_TO_DASHBOARD } from '../../../config/RoutesConstants';
import {Link} from "react-router-dom";
import {Config} from '../../../config/Constants';
import Alert from 'react-s-alert';
import {ALERT_SETTINGS} from '../../../config/Config';

class UserAsEventPerformerRemovalModal extends Component {
    constructor(props, context){
        super(props, context);
        this.state = {
            loading: false
        }
    } 

    onEventsByPerformerClose = () => {
        this.props.toggleModalUserAsEventPerformerRemoval(false, true); 
        this.props.toggleModalUserDataUpdate(true, true, this.props.modalType)
    }

    userAsEventPerformerRemoval = (event_id, event_name) => {
        let answer = window.confirm("Naozaj sa chcete odstrániť ako účastník eventu v názvom "+ event_name +"?");
        if(answer && event_id){
            this.props.setLoadingRemoveEventPerformer();
            this.props.removeEventPerformer(event_id).then(() => {
                this.props.removeEventPerformerSuccess();
                this.props.getUserHandling().then(() => {
                    Alert.success(SUCCESSFULLY_REMOVED_EVENT_PERFORMER, ALERT_SETTINGS);
                    this.props.toggleModalUserDataUpdate(false, true);
                    this.props.toggleModalUserAsEventPerformerRemoval(false, true);
                    this.props.history.push(GET_USER_ROUTE_TO_DASHBOARD(this.props.user));
                });
            }).catch(() => {
                this.props.removeEventPerformerError();
                Alert.error(ERROR_REMOVE_EVENT_PERFORMER, ALERT_SETTINGS);
            });
        }
    }

    render() {
        return (
            <Dialog
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                open={this.props.userAsEventPerformerRemovalModalOpened}
                onClose={this.onEventsByPerformerClose}
                className='events-by-user-modal'
            >
                {this.props.loadingEventsByPerformer ?
                <Fade duration={500} delay={250} >
                    <div className="photo-update-modal__content">
                        <div className="photo-update-modal__content-loading-wrapper">
                            <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                            <p className="photo-update-modal__loading-content">{LOADING_EVENTS_WHERE_IS_USER_AS_PERFORMER}</p>
                        </div>
                    </div>
                </Fade> :
                <div>
                    <Link to={Config.UPDATE_USER_DATA_ROOT} onClick={this.onEventsByPerformerClose} className="photo-update-modal__close-button">
                        <img src={'/img/icons/close-cross.svg'} alt={CLOSE_MODAL}/>
                        <span className="modal__close-button__text">{CLOSE_MODAL}</span>
                    </Link>
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 padding-left-right-0">
                                    <p className="events-by-user-modal__header-title"><span>{EVENTS_BY_PERFORMER_MODAL_TITLE}</span></p>
                                    {this.props.eventsByPerformerList.length === 0 ?                                     
                                    <p className="events-by-user-modal__header-text"><span>{USER_HAS_NO_EVENTS_WHERE_IS_USER_AS_PERFORMER}</span></p>
                                    : <p className="events-by-user-modal__header-text"><span>{EVENTS_BY_PERFORMER_MODAL_TEXT}</span></p>
                                    }
                                </div>
                            </div>
                        </div> 
                    </div> 
                    <div className="events-by-user-modal__content">
                        <div className="events-by-user-modal__content__event_sliders">
                            <div className="container">
                                {
                                    this.props.eventsByPerformerList.map((value, index) => {
                                        return (
                                            <EventSlider 
                                                key={index} 
                                                category={value.category}
                                                events={value.events} 
                                                slidesToShow="4"
                                                specifier="eventPerformerRemoval"
                                                userAsEventPerformerRemoval={this.userAsEventPerformerRemoval}
                                            />
                                        );
                                    })
                                } 
                            </div>
                        </div>
                    </div>
                </div>
                }
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {  
    return {
        user: state.userReducer.user,
        userAsEventPerformerRemovalModalOpened: state.modalReducer.userAsEventPerformerRemovalModalOpened,
        eventsByPerformerList: state.eventReducer.eventsByPerformerList,
        loadingEventsByPerformer: state.eventReducer.loadingEventsByPerformer,
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fullLogout,
        getUserHandling,
        toggleModalUserAsEventPerformerRemoval,
        setLoadingRemoveEventPerformer, 
        removeEventPerformer, 
        removeEventPerformerError,
        removeEventPerformerSuccess,
        toggleModalUserDataUpdate
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(UserAsEventPerformerRemovalModal));
