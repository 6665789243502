import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './views/App';
import register from './registerServiceWorker';
import {BrowserRouter} from "react-router-dom";
import {applyMiddleware, compose, createStore} from "redux";
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import RootReducer from './reducers/RootReducer';
import { Provider } from 'react-redux';

if(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
    window.devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
}

const store = createStore(
    RootReducer, 
    compose(applyMiddleware(thunk, promise)),
);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
    , document.getElementById('root'));
register();