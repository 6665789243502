import React, {Component} from 'react';
import moment from 'moment';
import '../components/EventDetail/EventDetail.css';
import EventDetailMap from "../components/Map/EventDetailMap";
import EventSlider from '../components/EventSlider/EventSlider';
import {connect} from "react-redux";
import {getUserHandling} from '../actions/UserActions';
import { getSelectedEvent, getSelectedEventSuccess, getSelectedEventError, setLoadingSelectedEvent,
    setLoadingSelectedEventToUpdate, getSelectedEventToUpdate, getSelectedEventToUpdateSuccess, getSelectedEventToUpdateError,
    removeEvent, removeEventSuccess, removeEventError, setLoadingRemoveEvent,
    setLoadingGetPremiumEvents,getPremiumEventsSuccess, getPremiumEventsError, getPremiumEvents } from '../actions/EventActions';
import {toggleModalEventDataManagement} from '../actions/ModalActions';
import {bindActionCreators} from "redux";
import GalleryLightBox from 'react-photo-gallery';
import SimpleGalleryImageControls from '../components/Dashboard/Gallery/SimpleGalleryImageControls/SimpleGalleryImageControls';
import {Config} from "../config/Constants";
import Lightbox from 'react-images';
import {Link} from 'react-router-dom';
import { Button } from '@material-ui/core';
import { formatDate, getUserTypeNumber, isSameDay, dateSortAsc } from '../client/Helper';
import { ALT_ICON, EVENT_DETAIL_SIMILAR_EVENTS, EVENT_DETAIL_EVENT_LOCATION, HIDE_MAP, PERFORMER, ADMISSION_TO_CONCERT_IS, FREE,
    SUCCESS_REMOVE_EVENT, ERROR_REMOVE_EVENT, TEXT_EVENT_HAS_ONE_LIKE, TEXT_EVENT_HAS_LIKES, SHOW_MAP, SINGLE_ENTRANCE_FEE,
    SHOW_PERFORMERS_DATETIME, HIDE_SHOWN_PERFORMERS_DATETIME, TOOLTIP_UPDATE_EVENT, TOOLTIP_DELETE_EVENT, PROGRAM, ORGANIZER } from '../config/TextContent';
import {GET_USER_ROUTE_TO_DASHBOARD, GET_USER_ROUTE_TO_SPECIFIC_EVENT} from '../config/RoutesConstants';
import {setLoadingGetSimilarEvents, getSimilarEvents, getSimilarEventsSuccess, getSimilarEventsError} from '../actions/EventActions';
import {ALERT_SETTINGS} from '../config/Config';
import Alert from 'react-s-alert';
import {isLoggedInUserInLocalStorage} from '../actions/UserActions';
import FixedButtonAddEvent from '../components/FixedButtonAddEvent/FixedButtonAddEvent';
import {HashLink} from 'react-router-hash-link';
import ReactTooltip from 'react-tooltip';

class EventDetail extends Component {

    constructor(props, context){
        super(props, context);

        this.state = {
            mounted: false,
            currentImage: 0,
            currentOfferImage: 0,
            lightboxIsOpen: false,
            offerLightboxIsOpen: false,
            displayMoreGalleryPhotos: false,
            offerPhotoPathLightbox: '',
            shownMap: false,
            shownDatetime: false
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.openOfferLightbox = this.openOfferLightbox.bind(this);
        this.closeOfferLightbox = this.closeOfferLightbox.bind(this);
        this.gotoNextOfferPhoto = this.gotoNextOfferPhoto.bind(this);
        this.gotoPreviousOfferPhoto = this.gotoPreviousOfferPhoto.bind(this);
    }
    
    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage - 1
            }
        });
    }

    gotoNext() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage + 1
            }
        });
    }

    displayMoreGalleryPhotos() {
        this.setState({
            displayMoreGalleryPhotos: true 
        });
    }

    getEventPerformersByDate = (performers) => {
        let unique_dates = [];
        let dates_array = [];
        if(performers.length > 0){
            for(let i = 0; i < performers.length; i++){
                if(unique_dates.length > 0){
                    let is_unique_date = true;
                    for(let j = 0; j < unique_dates.length; j++){
                        if(isSameDay(performers[i].event_interpret_time, unique_dates[j])){
                            is_unique_date = false;
                        }
                    }

                    if(is_unique_date){
                        unique_dates.push(performers[i].event_interpret_time);    
                    }
                }else{
                    unique_dates = [performers[i].event_interpret_time];
                }
            }
        } 
        unique_dates.sort(dateSortAsc);
        if(unique_dates.length > 0){
            for(let i = 0; i < unique_dates.length; i++){
                dates_array[i] = {
                    date: moment(new Date(unique_dates[i])).format("DD. MM. YYYY"),
                    performers: []
                };
                for(let j = 0; j < performers.length; j++){
                    if(isSameDay(unique_dates[i], performers[j].event_interpret_time)){
                        if(dates_array[i].performers.length > 0){
                            dates_array[i].performers.push(performers[j]);
                        }else{
                            dates_array[i].performers = [performers[j]];                            
                        }
                    }
                }     

                for(let j = 0; j < performers.length; j++){
                    dates_array[i].performers.sort((d1, d2) => {
                        d1 = new Date(d1.event_interpret_time);
                        d2 = new Date(d2.event_interpret_time);
                        if(d1 > d2){
                            return 1;
                        }
                        if(d1 < d2){
                            return -1;
                        }
                        return 0;
                    });           
                }
            }
        }
        return dates_array;
    }

    componentDidMount = () => {
        this.setState({
            mounted: true
        })

        window.scrollTo(0, 0);
        const query_params = { name: this.props.match.params.eventslug };
        this.props.setLoadingSelectedEvent();
        this.props.getSelectedEvent(query_params).then((result) => {
            if(result.payload.data){
                this.props.getSelectedEventSuccess();
                if(this.props.selectedEvent){
                    this.props.setLoadingGetSimilarEvents();
                    this.props.getSimilarEvents(this.props.selectedEvent.id, 0, 6).then((result) => {
                        if(result.payload.data){
                            this.props.getSimilarEventsSuccess();
                        }else{
                            this.props.getSimilarEventsError();    
                        }
                    }).catch(() => {
                        this.props.getSimilarEventsError();
                    });
                }
            }else{
                this.props.getSelectedEventError();
                window.location.replace("/");
            }
        }).catch(() => {
            this.props.getSelectedEventError();
            window.location.replace("/");
        });
    }
    componentWillUnmount(){
        this.setState({
            mounted: false
        })
    }

    componentWillReceiveProps = (props) => {
        if(props.selectedEvent && props.selectedEvent.event_name_slug && props.match.params && props.match.params.eventslug && props.selectedEvent.event_name_slug !== props.match.params.eventslug){
            window.scrollTo(0, 0);
            const query_params = { name: props.match.params.eventslug };
            this.props.setLoadingSelectedEvent();
            this.props.getSelectedEvent(query_params).then((result) => {
                if(result.payload.data){
                    this.props.getSelectedEventSuccess();
                    if(this.props.selectedEvent){
                        this.props.setLoadingGetSimilarEvents();
                        this.props.getSimilarEvents(this.props.selectedEvent.id, 0, 6).then((result) => {
                            if(result.payload.data){
                                this.props.getSimilarEventsSuccess();
                            }else{
                                this.props.getSimilarEventsError();    
                            }
                        }).catch(() => {
                            this.props.getSimilarEventsError();
                        });
                    }
                }else{
                    window.location.replace("/");
                    this.props.getSelectedEventError();
                }
            }).catch(() => {
                window.location.replace("/");
                this.props.getSelectedEventError();
            });
        }
    }

    onClickRemoveEvent = (event_id, event_name) => {
        let answer = window.confirm("Naozaj chcete vymazať event s názvom "+ event_name+"?");
        if(answer && event_id){
            this.props.setLoadingRemoveEvent();
            this.props.removeEvent(event_id).then(() => {
                Alert.success(SUCCESS_REMOVE_EVENT, ALERT_SETTINGS);
                this.props.removeEventSuccess();
                this.props.getUserHandling().then(() => {
                    this.props.history.push(GET_USER_ROUTE_TO_DASHBOARD(this.props.user));
                });
            }).catch(() => {
                Alert.error(ERROR_REMOVE_EVENT, ALERT_SETTINGS);
                this.props.removeEventError();
            });
        }
    }

    showMap = () => {
        this.setState({shownMap: true});
    }

    hideMap = () => {
        window.scrollTo(0, 0);
        this.setState({shownMap: false});
    }

    showDatetime = () => {
        this.setState({shownDatetime: true});
    }

    hideDatetime = () => {
        this.setState({shownDatetime: false});
    }

    openOfferLightbox = (index) => {
        this.setState({
            offerLightboxIsOpen: true,
            currentOfferImage: index 
        });
    }

    closeOfferLightbox = () => {
        this.setState({
            offerLightboxIsOpen: false,
            currentOfferImage: 0
        });
    }

    gotoPreviousOfferPhoto() {
        this.setState((prevState) => {
            return {
                currentOfferImage: prevState.currentOfferImage - 1
            }
        });
    }

    gotoNextOfferPhoto() {
        this.setState((prevState) => {
            return {
                currentOfferImage: prevState.currentOfferImage + 1
            }
        });
    }

    isFilledOnePerformerDate = (performers) => {
        let filled = false;
        if(performers){
            performers.forEach((performer) => {
                if(performer.hasOwnProperty("event_interpret_time") && performer.event_interpret_time.length > 0){
                    filled = true;
                }
            });
        }
        return filled;
    }

    getOfferPhotosInArray = (event_offer) => {
        let photos_array = [];
        let offer_photo_object = null;

        if(event_offer){
            if(event_offer.hasOwnProperty("offer_photo_1_path") && event_offer.offer_photo_1_path && event_offer.offer_photo_1_path.length > 0){
                offer_photo_object = {
                    index: 0,
                    width: 1, 
                    height: 1, 
                    id: 1, 
                    src: Config.IMAGE_EVENT_OFFER_PHOTO_ROOT + event_offer.offer_photo_1_path
                };
                photos_array.push(offer_photo_object);
            }
    
            if(event_offer.hasOwnProperty("offer_photo_2_path") && event_offer.offer_photo_2_path && event_offer.offer_photo_2_path.length > 0){
                offer_photo_object = {
                    index: 1,
                    width: 1, 
                    height: 1, 
                    id: 2, 
                    src: Config.IMAGE_EVENT_OFFER_PHOTO_ROOT + event_offer.offer_photo_2_path
                };
                photos_array.push(offer_photo_object);
            }
    
            if(event_offer.hasOwnProperty("offer_photo_3_path") && event_offer.offer_photo_3_path && event_offer.offer_photo_3_path.length > 0){
                offer_photo_object = {
                    index: 2,
                    width: 1, 
                    height: 1, 
                    id: 3, 
                    src: Config.IMAGE_EVENT_OFFER_PHOTO_ROOT + event_offer.offer_photo_3_path
                };
                photos_array.push(offer_photo_object);
            }
        }

        return photos_array;
    }

    render() {
        
        let eventUserTypeNumber = '',
            event_name='', 
            event_category_name='', 
            additional_information='',
            is_filled_one_performer_date=false,
            finance_type_id = null,
            lat='',
            lng='',
            event_performers = [],
            event_program_items = [],
            publish_performances_times = false,
            event_prices = [],
            event_offer = null,
            event_photos = [],
            offer_photos_lightbox = [],
            main_photo = null,
            event_place = '',
            images_to_gallery_lightbox = [],
            main_photo_lightbox_object = null,
            eventDate = '',
            eventDateEnd = '',
            club_name = '',
            organizer_name = '',
            likes = false;
        
        if(this.props.selectedEvent && this.props.selectedEvent.id){
            eventUserTypeNumber = getUserTypeNumber(this.props.selectedEvent.user);
            finance_type_id = this.props.selectedEvent.finance_type_id;
            publish_performances_times = this.props.selectedEvent.publish_performances_times;
            is_filled_one_performer_date = this.isFilledOnePerformerDate(this.props.selectedEvent.performers);
            event_name = this.props.selectedEvent.event_name;
            event_category_name = this.props.selectedEvent.category.name;
            additional_information = this.props.selectedEvent.additional_information;
            event_performers = this.props.selectedEvent.performers ? this.props.selectedEvent.performers : null;
            event_program_items = this.props.selectedEvent.program_items ? this.props.selectedEvent.program_items : null;
            event_prices = this.props.selectedEvent.prices;
            event_offer = this.props.selectedEvent.offer && this.props.selectedEvent.offer.hasOwnProperty("name") && this.props.selectedEvent.offer.name && this.props.selectedEvent.offer.name.length > 0 ? this.props.selectedEvent.offer : null;
            event_photos = this.props.selectedEvent.galleries;
            main_photo = Config.getEventMainPhoto(this.props.selectedEvent);
            main_photo_lightbox_object = {
                index: 0, //always first 
                width: 1, 
                height: 1, 
                id: 1, 
                src: main_photo
            };
            
            images_to_gallery_lightbox = [main_photo_lightbox_object].concat(Config.processPhotosToGallery(event_photos));
            offer_photos_lightbox = this.getOfferPhotosInArray(event_offer);
            eventDate = this.props.selectedEvent.event_date && formatDate(new Date(this.props.selectedEvent.event_date));
            eventDateEnd = this.props.selectedEvent.event_date_end && formatDate(new Date(this.props.selectedEvent.event_date_end));
            likes = this.props.selectedEvent.likes;
            
            if(eventUserTypeNumber === 2){
                lat = this.props.selectedEvent.user.user_data.lat;
                lng = this.props.selectedEvent.user.user_data.lng;
                event_place = this.props.selectedEvent.user.user_data.place_full_string;
                club_name = this.props.selectedEvent.user.user_data.name;
            }else{
                lat = this.props.selectedEvent.lat;
                lng = this.props.selectedEvent.lng;
                event_place = this.props.selectedEvent.place;

                if(eventUserTypeNumber === 1 || eventUserTypeNumber === 3){
                    organizer_name = this.props.selectedEvent.user.user_data.name;
                }
            }
        }
        
        return (
            <div>
                {isLoggedInUserInLocalStorage() ? <FixedButtonAddEvent/> : null}
                <div className={"eventDetail__content" + (this.props.loadingEvent ? 'loading-placeholder' : '')}>
                    <div className="container">
                        <div className="row paddingTopEventDetail">
                            <div className="col-md-6 col-xs-8 breadcrump">
                                { !this.props.loadingEvent && this.props.selectedEvent && this.props.selectedEvent.category && this.props.selectedEvent.category.id ? ( 
                                    <p>
                                        <HashLink smooth to={isLoggedInUserInLocalStorage() ? (GET_USER_ROUTE_TO_DASHBOARD(this.props.user) + '/#events') : '/#events'}>Eventy</HashLink>
                                        <span className="divider">/</span>
                                        <HashLink smooth to={isLoggedInUserInLocalStorage() ? (GET_USER_ROUTE_TO_DASHBOARD(this.props.user) + '/category/' + this.props.selectedEvent.category.id) : '/#category-' + this.props.selectedEvent.category.id}>{event_category_name}</HashLink>
                                    </p>
                                ) : null}
                            </div>
                            {this.props.selectedEvent && this.props.selectedEvent.id && this.props.user && this.props.selectedEvent.user.id === this.props.user.id && isLoggedInUserInLocalStorage() ? 
                            <div className="col-md-6 col-xs-4 eventDetail__content__update-delete-event">
                                <div className="eventDetail__content__update-event">
                                    <Link to={Config.EDIT_EVENT_ROOT + this.props.selectedEvent.id}>
                                        <img src={'/img/icons/settings.svg'} alt={ALT_ICON}  data-tip data-for="event-detail-tooltip-update-event" />
                                        <ReactTooltip id="event-detail-tooltip-update-event" place="bottom" effect="solid">{TOOLTIP_UPDATE_EVENT}</ReactTooltip>
                                    </Link> 
                                </div>
                                <div className="eventDetail__content__delete-event">
                                    <div onClick={() => this.onClickRemoveEvent(this.props.selectedEvent.id, this.props.selectedEvent.event_name)}>
                                        <img src={'/img/icons/delete-icon.svg'} alt={ALT_ICON} data-tip data-for="event-detail-tooltip-delete-event" />
                                        <ReactTooltip id="event-detail-tooltip-delete-event" place="bottom" effect="solid">{TOOLTIP_DELETE_EVENT}</ReactTooltip>
                                    </div> 
                                </div>
                            </div>
                            : <div className="col-md-6"></div> 
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-12 padding-left-right-0-media">
                                <div className="eventDetail__background">
                                    <div className="row eventDetail__maininfo">
                                        <div className="col-md-6 eventDetail__maininfo__wrapper">
                                            {!this.props.loadingEvent && this.props.selectedEvent ? (
                                                likes.length > 0 ? (
                                                    likes.length > 1 ? (
                                                    <p className="eventDetail__maininfo__wrapper__eventReviews">
                                                        <span>{likes.length}</span> {TEXT_EVENT_HAS_LIKES}
                                                    </p>
                                                    ) : (
                                                    <p className="eventDetail__maininfo__wrapper__eventReviews">
                                                        <span>{likes.length}</span> {TEXT_EVENT_HAS_ONE_LIKE}
                                                    </p>
                                                    )
                                                ) : null
                                                ) : (
                                                    <p className="eventDetail__maininfo__wrapper__eventReviews"></p>
                                                )
                                            }
                                            <h2 className="eventDetail__maininfo__wrapper__eventName">{event_name}</h2>
                                            {club_name.length > 0 ? (
                                                <div className="eventDetail__maininfo__wrapper__genre event-detail__club-name">
                                                    <div className="row no-padding padding-top-5">
                                                        <div className="col-md-1 col-xs-1 no-padding"> 
                                                            <img src={'/img/icons/disco-ball-green.svg'} alt={ALT_ICON} />
                                                        </div>
                                                        <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0"> 
                                                            <Link to={GET_USER_ROUTE_TO_DASHBOARD(this.props.selectedEvent.user)} target="_blank" className="eventDetail__maininfo__wrapper__club-name">
                                                                <strong>{club_name}</strong>
                                                            </Link>
                                                            <div className="eventDetail__maininfo__wrapper__eventDate">
                                                                <p className="eventDetail__maininfo__wrapper__eventDate__date">
                                                                    {event_place}
                                                                </p>
                                                                <HashLink smooth to={GET_USER_ROUTE_TO_SPECIFIC_EVENT(this.props.selectedEvent) + "/#map"} className="eventDetail__maininfo__wrapper__address-info" onClick={this.showMap}>
                                                                    <img src={'/img/icons/info-green.svg'} alt={ALT_ICON} data-tip data-for="event-detail__info-show-map" />
                                                                    <ReactTooltip id="event-detail__info-show-map" place="right" effect="solid">{SHOW_MAP}</ReactTooltip>
                                                                </HashLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="eventDetail__maininfo__wrapper__genre event-detail__event-place">
                                                    <div className="row no-padding padding-top-5">
                                                        <div className="col-md-1 col-xs-1 no-padding"> 
                                                            <img src={'/img/icons/address.svg'} alt={ALT_ICON} className="top-0-important" />
                                                        </div>
                                                        <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0"> 
                                                            <p className="eventDetail__maininfo__wrapper__eventDate__date">
                                                                <strong>{event_place}</strong>    
                                                            </p>
                                                            <HashLink smooth to={GET_USER_ROUTE_TO_SPECIFIC_EVENT(this.props.selectedEvent) + "/#map"} className="eventDetail__maininfo__wrapper__address-info" onClick={this.showMap}>
                                                                <img src={'/img/icons/info-green.svg'} alt={ALT_ICON} data-tip data-for="event-detail__info-show-map" />
                                                                <ReactTooltip id="event-detail__info-show-map" place="right" effect="solid">{SHOW_MAP}</ReactTooltip>
                                                            </HashLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="eventDetail__maininfo__wrapper__genre">
                                                <div className="row no-padding padding-top-5">
                                                    <div className="col-md-1 col-xs-1 no-padding"> 
                                                        <img src={'/img/icons/passage-of-time.svg'} alt={ALT_ICON} />
                                                    </div>
                                                    <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0"> 
                                                        <p className="eventDetail__maininfo__wrapper__eventDate__date">
                                                            <strong>{eventDate}</strong>
                                                            {eventDateEnd ? " - " : ""}
                                                            <strong>{eventDateEnd}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="eventDetail__maininfo__wrapper__genre">
                                                <div className="row no-padding padding-top-5">
                                                    <div className="col-md-1 col-xs-1 no-padding"> 
                                                        <img 
                                                            src={'/img/icons/list.svg'} 
                                                            alt={ALT_ICON}
                                                        />
                                                    </div>
                                                    <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0">
                                                        <p>{event_category_name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {organizer_name.length > 0 ? (
                                                <div className="eventDetail__maininfo__wrapper__genre">
                                                    <div className="row no-padding padding-top-5">
                                                        <div className="col-md-1 col-xs-1 no-padding"> 
                                                            <img 
                                                                src={'/img/icons/businessman.svg'} 
                                                                alt={ALT_ICON}
                                                                className="eventDetail__maininfo__wrapper__organizer-icon"
                                                            />
                                                        </div>
                                                        <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0">
                                                            <p className="eventDetail__maininfo__wrapper__eventDate__date">
                                                                {ORGANIZER + ': '}
                                                                <Link to={GET_USER_ROUTE_TO_DASHBOARD(this.props.selectedEvent.user)} target="_blank" className="eventDetail__maininfo__wrapper__organizer-name">
                                                                    <strong>{organizer_name}</strong>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="row eventDetail__performers">
                                            {
                                                event_performers.length > 0 ? (
                                                    <div>
                                                        <div className="eventDetail__maininfo__wrapper__genre event-detail__club-name">
                                                            <div className="row no-padding padding-top-5">
                                                                <div className="col-md-1 col-xs-1 no-padding"> 
                                                                    <img 
                                                                    src={'/img/icons/event-star.svg'} 
                                                                    alt={ALT_ICON}
                                                                    />
                                                                </div>
                                                                <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0">
                                                                    <p className="eventDetail_section_info__header">{PERFORMER}:</p>
                                                                    <div className="event-participants-by-date">
                                                                        <div className="event-participants-by-date__participants">
                                                                        {
                                                                            event_performers.map((event_performer, index) => {
                                                                                return (
                                                                                    event_performer.user ?
                                                                                        ( 
                                                                                        <div className="event-participants-by-date__participant" key={index}>
                                                                                            <div className="row event-participants-by-date__participant__content">
                                                                                                <div className="event-participants-by-date__participant__content__background-text">
                                                                                                    {event_performer.hasOwnProperty("is_main_band") && event_performer.is_main_band ? 
                                                                                                        <Link to={GET_USER_ROUTE_TO_DASHBOARD(event_performer.user)} target="_blank" className="event-participants-by-date__participant__content__name performer-name__is-main-band">{event_performer.name}</Link>
                                                                                                    : <Link to={GET_USER_ROUTE_TO_DASHBOARD(event_performer.user)} target="_blank" className="event-participants-by-date__participant__content__name">{event_performer.name}</Link>
                                                                                                    }
                                                                                                    {
                                                                                                        publish_performances_times && this.state.shownDatetime && event_performer.hasOwnProperty("event_interpret_time") && event_performer.event_interpret_time ? (
                                                                                                            <p className="event-participants-by-date__participant__time">{formatDate(new Date(event_performer.event_interpret_time))}</p>
                                                                                                        ) : null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        ) : 
                                                                                        (
                                                                                            <div className="event-participants-by-date__participant" key={index}>
                                                                                                <div className="row event-participants-by-date__participant__content">
                                                                                                    <div className="col-md-12 padding-left-right-10">
                                                                                                        {event_performer.hasOwnProperty("is_main_band") && event_performer.is_main_band ? 
                                                                                                        <p className="event-participants-by-date__participant__content__name performer-name__is-main-band">{event_performer.name}</p>
                                                                                                        : <p className="event-participants-by-date__participant__content__name">{event_performer.name}</p>
                                                                                                        }
                                                                                                        <br/>
                                                                                                        {
                                                                                                            publish_performances_times && this.state.shownDatetime && event_performer.hasOwnProperty("event_interpret_time") && event_performer.event_interpret_time ? (
                                                                                                                <p className="event-participants-by-date__participant__time">{formatDate(new Date(event_performer.event_interpret_time))}</p>
                                                                                                            ) : null
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                )
                                                                            })
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                    {publish_performances_times && is_filled_one_performer_date ? 
                                                                        this.state.shownDatetime ? (
                                                                            <div className="eventDetail__showDatetime" onClick={this.hideDatetime}>
                                                                                {HIDE_SHOWN_PERFORMERS_DATETIME}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="eventDetail__showDatetime" onClick={this.showDatetime}>
                                                                                {SHOW_PERFORMERS_DATETIME}
                                                                            </div>
                                                                        )
                                                                    : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ''
                                            }
                                            </div>
                                            <div className="row eventDetail__program-items">
                                                {event_program_items.length > 0 ? (
                                                    <div>
                                                        <div className="eventDetail__maininfo__wrapper__genre event-detail__program-items">
                                                            <div className="row no-padding padding-top-5">
                                                                <div className="col-md-1 col-xs-1 no-padding"> 
                                                                    <img 
                                                                    src={'/img/icons/calendar-star-green.svg'} 
                                                                    alt={ALT_ICON}
                                                                    />
                                                                </div>
                                                                <div className="col-md-11 col-xs-11 padding-right-0-media padding-left-10-media padding-left-right-0">
                                                                    <p className="eventDetail_section_info__header"><strong>{PROGRAM}:</strong></p>
                                                                    <div className="eventDetail__program-items__content">
                                                                        {event_program_items.map((program_item, index) => {
                                                                            return (
                                                                                <div className="eventDetail__program-items__content__item" key={index}>
                                                                                    <p className="eventDetail__program-items__content__item-name">{program_item.name}</p>
                                                                                    {program_item.date ? (
                                                                                        <p className="eventDetail__program-items__content__item-date">{formatDate(new Date(program_item.date))}</p>
                                                                                    ) : null}
                                                                                </div>
                                                                            )   
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 no-padding">
                                            <div className="eventDetail__maininfo__bigPhoto">
                                                {
                                                    main_photo && 
                                                        <img onClick={
                                                                event => 
                                                                this.openLightbox(event, 
                                                                    {
                                                                        index: 0,
                                                                        photo: main_photo_lightbox_object, 
                                                                        previous: null,
                                                                        next: event_photos.length > 1 && images_to_gallery_lightbox[1] 
                                                                    }
                                                                )
                                                            } 
                                                            src={main_photo}
                                                            alt={ALT_ICON}
                                                        /> 
                                                }
                                            </div>
                                            <div className="eventDetail__maininfo__smallPhotos row">
                                                <div className={!this.state.displayMoreGalleryPhotos ? "col-md-9 no-padding" : "col-md-12 no-padding"}>
                                                    <div className={this.state.displayMoreGalleryPhotos ? "eventDetail__maininfo__smallPhotos__photos display_more_gallery_photos" : "eventDetail__maininfo__smallPhotos__photos"}>
                                                        <GalleryLightBox 
                                                            photos={Config.processPhotosToGallery(event_photos)} 
                                                            direction={"row"} 
                                                            onClick={this.openLightbox} 
                                                            ImageComponent={SimpleGalleryImageControls}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={!this.state.displayMoreGalleryPhotos ? "col-md-3 no-padding" : "display-none"}>
                                                    {!this.state.displayMoreGalleryPhotos && event_photos.length > 3 ? 
                                                        <Button className="eventDetail__maininfo__smallPhotos__button" onClick={() => this.displayMoreGalleryPhotos()}>
                                                            <img src={'/img/icons/close-cross-green.svg'} 
                                                            alt={ALT_ICON}
                                                            />
                                                            <span><strong>{event_photos.length - 3}</strong> ďalších fotografií</span>
                                                        </Button>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                            <Lightbox 
                                                images={images_to_gallery_lightbox}
                                                onClose={this.closeLightbox}
                                                onClickPrev={this.gotoPrevious}
                                                onClickNext={this.gotoNext}
                                                currentImage={this.state.currentImage}
                                                isOpen={this.state.lightboxIsOpen}
                                            />
                                        </div>
                                    </div>
                                
                                    <div className="eventDetail_section_info">
                                        <div className="row">
                                            {finance_type_id && finance_type_id === 2 && event_prices.length === 1 ? (
                                                <div className="col-md-3 eventDetail_section_info__section padding-left-0">
                                                    <div>
                                                        <h3 className="eventDetail_section_info__header f-w-700-important">Poplatky</h3>
                                                        <div className="eventDetail_section-price__free-admission">
                                                            <p>{SINGLE_ENTRANCE_FEE}: <span>{event_prices[0].price}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (event_prices.length > 0 ? (
                                                    <div className="col-md-3 eventDetail_section_info__section padding-left-0">
                                                        <div>
                                                            <h3 className="eventDetail_section_info__header f-w-700-important">Poplatky</h3>
                                                            <div className="eventDetail_section_info__finances">
                                                                {
                                                                    event_prices.map((value, index) => {
                                                                        return (
                                                                            <div className="eventDetail_section_info__finance" key={index}>
                                                                                <div className="eventDetail_section_info__finances__price">
                                                                                    <p>{value.price}</p>
                                                                                </div>
                                                                                <div className="eventDetail_section_info__finances__title">
                                                                                    <p>{value.name}</p>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null
                                            )}
                                            {finance_type_id && finance_type_id === 1 ? (
                                                <div className="col-md-3 eventDetail_section_info__section padding-left-0">
                                                    <div>
                                                        <h3 className="eventDetail_section_info__header f-w-700-important">Poplatky</h3>
                                                        <div className="eventDetail_section-price__free-admission">
                                                            <p>{ADMISSION_TO_CONCERT_IS} <span>{FREE}</span>.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {event_prices.length > 0 ? (<div className="col-md-1"></div>) : null}
                                            <div className="col-md-8 eventDetail_section_info__section padding-left-right-0">
                                            {event_offer ? (
                                                <div>
                                                    <h3 className="eventDetail_section_info__header f-w-700-important">Ponuka</h3>
                                                    <p className="eventDetail_section_info__offer-name">{event_offer.name}</p>
                                                    {event_offer.price && event_offer.price.length > 0 ? (
                                                        <p className="eventDetail_section_info__offer-name">Cena za ponuku: <strong>{event_offer.price}</strong></p>
                                                    ) : null}
                                                    <div className="row eventDetail_section_info__offer-photos">
                                                        <div className="col-md-4 col-xs-4">
                                                            {event_offer.hasOwnProperty("offer_photo_1_path") && event_offer.offer_photo_1_path &&  event_offer.offer_photo_1_path.length > 0 ? (
                                                                <img 
                                                                    src={Config.IMAGE_EVENT_OFFER_PHOTO_ROOT + event_offer.offer_photo_1_path} 
                                                                    alt={ALT_ICON} 
                                                                    onClick={() => this.openOfferLightbox(0)}
                                                                />
                                                            ) : null}
                                                        </div>
                                                        <div className="col-md-4 col-xs-4">
                                                            {event_offer.hasOwnProperty("offer_photo_2_path") && event_offer.offer_photo_2_path &&  event_offer.offer_photo_2_path.length > 0 ? (
                                                                <img 
                                                                    src={Config.IMAGE_EVENT_OFFER_PHOTO_ROOT + event_offer.offer_photo_2_path} 
                                                                    alt={ALT_ICON} 
                                                                    onClick={() => this.openOfferLightbox(1)}
                                                                />
                                                            ) : null}
                                                        </div>
                                                        <div className="col-md-4 col-xs-4">
                                                            {event_offer.hasOwnProperty("offer_photo_3_path") && event_offer.offer_photo_3_path &&  event_offer.offer_photo_3_path.length > 0 ? (
                                                                <img 
                                                                    src={Config.IMAGE_EVENT_OFFER_PHOTO_ROOT + event_offer.offer_photo_3_path} 
                                                                    alt={ALT_ICON} 
                                                                    onClick={() => this.openOfferLightbox(2)}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <Lightbox 
                                                        images={offer_photos_lightbox}
                                                        onClose={this.closeOfferLightbox}
                                                        onClickPrev={this.gotoPreviousOfferPhoto}
                                                        onClickNext={this.gotoNextOfferPhoto}
                                                        currentImage={this.state.currentOfferImage}
                                                        isOpen={this.state.offerLightboxIsOpen}
                                                    />
                                                </div>
                                            ) : null}   
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row eventDetail__maininfo__wrapper__eventDescription">
                                        <div className="col-md-12 no-padding">
                                            <p>{additional_information}</p>
                                        </div>
                                    </div>
                                    {this.state.shownMap ? (
                                        <div className="eventDetail_section_locality" id="map">
                                            <div className="eventDetail_section_locality__details">
                                                <h3 className="eventDetail_section_info__header">{EVENT_DETAIL_EVENT_LOCATION}</h3>
                                                <Link to={GET_USER_ROUTE_TO_SPECIFIC_EVENT(this.props.selectedEvent)} className="eventDetail_section_locality__hide-map" onClick={this.hideMap}>
                                                    <p>{HIDE_MAP}</p>
                                                </Link>
                                                <EventDetailMap 
                                                    onSelectAddressComponents={() => {}}
                                                    onSelectAddressChange={() => {}}
                                                    markerPositionChanged={() => {}}
                                                    markerLatLng={{lat: lat, lng: lng}}
                                                    centerLatLng={{lat: lat, lng: lng}}
                                                />      
                                            </div>
                                        </div>
                                    ) : null}
                                    {!this.props.loadingSimilarEvents && this.props.similarEventsList && this.props.similarEventsList.length > 0 && !this.props.loadingEvent && this.props.selectedEvent ?
                                        <div className="eventDetail_section__eventSlider">
                                            <div>
                                                <h3 className="eventDetail_section_info__header">{EVENT_DETAIL_SIMILAR_EVENTS}</h3>
                                                <div className="eventDetail_section__eventSlider__intro">
                                                    <div className="row">
                                                        <div className="col-md-12 col-xs-12">
                                                            <EventSlider 
                                                                category={this.props.selectedEvent.category}
                                                                events={this.props.similarEventsList}
                                                                specifier="similar-events"
                                                                slidesToShow="3"
                                                            />
                                                        </div>  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    : null
                                    }
                                    <a className="report_event_button" href={'mailto:info@eventeeko.com?subject=Nahlásenie príspevku&body=Chcel by som nahlásiť príspevok: ' + window.location.href + ' ...'}>Nahlásiť príspevok</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
} 

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        loadingEvent: state.eventReducer.loadingSelectedEvent,
        loadingRemoveEvent: state.eventReducer.loadingRemoveEvent,
        selectedEvent: state.eventReducer.selectedEvent,
        similarEventsList: state.eventReducer.similarEventsList,
        loadingSimilarEvents: state.eventReducer.loadingSimilarEvents,
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getSelectedEvent,
        getSelectedEventSuccess,
        getSelectedEventError,
        setLoadingSelectedEvent,
        setLoadingSelectedEventToUpdate,
        getSelectedEventToUpdate,
        getSelectedEventToUpdateSuccess,
        getSelectedEventToUpdateError,
        toggleModalEventDataManagement,
        setLoadingRemoveEvent,
        removeEvent,
        removeEventSuccess,
        removeEventError,
        getUserHandling,
        setLoadingGetSimilarEvents,
        getSimilarEvents,
        getSimilarEventsSuccess,
        getSimilarEventsError,
        setLoadingGetPremiumEvents,
        getPremiumEvents,
        getPremiumEventsError,
        getPremiumEventsSuccess
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(EventDetail);
