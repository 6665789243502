import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import './ProfileBandFilters.css';
import '../../Event/ProfileEventFilters/ProfileEventFilters.css';
import {FILTERING_OPTIONS, ALT_ICON} from '../../../../config/TextContent';
import { listToTreeUniqueValues,filterBasicGenres, hasChildrenGenres, filterChildrenGenres } from '../../../../client/Helper';
import TreeSelect from 'rc-tree-select';

class ProfileBandFilters extends Component {
    constructor(props, context){
        super(props, context);
        
        this.state = {
            tsGenresOpen: false,
            tsChildrenGenresOpen: false,
        }
    }

    render() {
        return(
            <div className="filter-content">
                <p className="filter-content__title">{FILTERING_OPTIONS}</p>
                <div className="filters-content__elements">
                    <div className="filters-content__elements__filter-element">
                        <div className="custom-green-input-wrapper black-color-input">
                            <TextField
                                label="Meno interpreta"
                                name="title"
                                value={this.props.filter.name}
                                onChange={this.props.handleNameChange}
                            />
                        </div>
                    </div>
                    <div className="filters-content__elements__filter-element_tree_select tree-select-wrapper custom-white-tree-select-dropdown tree-select-black tree-select-wrapper-filter-genre tree-select-wrapper-filter-genre-band no-margin-left">
                        <p className="selectfield-label required">Žáner interpreta</p>
                        <br/>
                        <TreeSelect
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                            transitionName="rc-tree-select-dropdown-slide-up"
                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                            dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                            placeholder='Všetky'
                            showSearch={false} 
                            allowClear
                            treeLine
                            value={this.props.filter.genre}
                            treeData={filterBasicGenres(listToTreeUniqueValues(this.props.genres))}
                            inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate.svg'} className="treeselect-arrow-down" alt={ALT_ICON} />}
                            treeNodeFilterProp="title"
                            filterTreeNode={false}
                            treeDefaultExpandAll={true}
                            open={this.state.tsGenresOpen}
                            notFoundContent='Načítavam ...'
                            onChange={(value) => {
                                if (value === this.props.genres)
                                    this.setState({ tsGenresOpen: true });
                                else
                                    this.setState({ tsGenresOpen: false });

                                this.props.onGenreChange(value);
                            }}
                            onDropdownVisibleChange={(v) => {
                                this.setState({
                                    tsGenresOpen: v,
                                });

                                return true;
                            }}
                        />
                        {
                            hasChildrenGenres(listToTreeUniqueValues(this.props.genres), this.props.filter.genre) ? (
                                <div className="tree-select-wrapper custom-white-tree-select-dropdown tree-select-black no-margin-left">
                                    <br/>
                                    <TreeSelect
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        transitionName="rc-tree-select-dropdown-slide-up"
                                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                        dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                        placeholder='Podžáner'
                                        showSearch={false} 
                                        allowClear
                                        treeLine
                                        value={this.props.filter.childrenGenre}
                                        treeData={filterChildrenGenres(listToTreeUniqueValues(this.props.genres), this.props.filter.genre)}
                                        inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate.svg'} className="treeselect-arrow-down" alt={ALT_ICON} />}
                                        treeNodeFilterProp="title"
                                        filterTreeNode={false}
                                        treeDefaultExpandAll={true}
                                        open={this.state.tsChildrenGenresOpen}
                                        notFoundContent='Načítavam ...'
                                        onChange={(value) => {
                                            if (value === this.props.genres)
                                                this.setState({ tsChildrenGenresOpen: true });
                                            else
                                                this.setState({ tsChildrenGenresOpen: false });

                                            this.props.onEventMusicChildrenGenresChange(value);
                                        }}
                                        onDropdownVisibleChange={(v) => {
                                            this.setState({
                                                tsChildrenGenresOpen: v,
                                            });

                                            return true;
                                        }}
                                    />
                                </div> 
                            ) : ''
                        }
                    </div>
                    <div className="filters-content__elements__filter-element">
                        <div className="custom-green-input-wrapper black-color-input">
                            <TextField
                                label="Mesto"
                                name="city"
                                value={this.props.filter.city}
                                onChange={this.props.handleCityChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );   
    }
}

export default ProfileBandFilters;
