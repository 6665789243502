import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import Dialog from '@material-ui/core/Dialog';
import Alert from 'react-s-alert';
import {fullLogout} from "../../../actions/AuthActions";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {CLOSE_MODAL, UPDATE_USER_DATA_SUCCESS, USER_DATA_MANAGEMENT_UPDATE_MODAL, LOADING_CONTENT} from '../../../config/TextContent';
import {cloneObject, getUserTypeNumber} from '../../../client/Helper';
import User from "../../../entities/User";
import Interpret from "../../../entities/Interpret";
import Club from "../../../entities/Club";
import Promoter from "../../../entities/Promoter";
import {ALERT_SETTINGS} from '../../../config/Config';
import {getUserHandling, updateUserData, updateUserDataSuccess, updateUserDataError} from '../../../actions/UserActions';
import {toggleModalUserDataManagement, toggleModalUserDataUpdate} from '../../../actions/ModalActions';
import { GET_USER_ROUTE_TO_DASHBOARD } from '../../../config/RoutesConstants';
import {SLOVAK_COUNTRY_ID, Config} from '../../../config/Constants';
import './UserDataManagementModal.css';
import RoleUserDataManagementModalInterpretContent from "./UserDataManagementModalContent/RoleUserDataManagementModalInterpretContent";
import RoleUserDataManagementModalClubContent from "./UserDataManagementModalContent/RoleUserDataManagementModalClubContent";
import RoleUserDataManagementModalPromoterContent from "./UserDataManagementModalContent/RoleUserDataManagementModalPromoterContent";
import RoleUserDataManagementModalUserContent from "./UserDataManagementModalContent/RoleUserDataManagementModalUserContent";
import {validateInterpret, validateClub, validatePromoter, validateUser} from '../../../client/Validation';
import ReactLoading from 'react-loading';
import Fade from 'react-reveal/Fade';
import {Link} from "react-router-dom";


class UserDataManagementModal extends Component {
    constructor(props, context){
        super(props, context); 
        this.state = {
            loading: false,
            updated: false,
            updateModalErrors: {}
        }
        this.submitUpdate = this.submitUpdate.bind(this);
    }

    submitUpdate = (userData) => {
        let user;
        let error = cloneObject(this.state.updateModalErrors);
        let user_data_city;
        let user_data_city_string;
        if(userData.country === SLOVAK_COUNTRY_ID){
            user_data_city = userData.city;
            user_data_city_string = null;
        }else{
            user_data_city = null;
            user_data_city_string = userData.city_string;
        }
        switch (this.props.modalType) {
            case 1:
                error = validateInterpret(userData, error);
                user = new Interpret(
                    userData.name,
                    userData.country, 
                    user_data_city,
                    user_data_city_string,
                    userData.genre,
                    userData.date_started,
                );
                break;
            case 2:
                error = validateClub(userData, error);
                user = new Club(
                    userData.name,
                    userData.lat,
                    userData.lng,
                    userData.type,
                    userData.genre,
                    userData.place_string,
                    userData.country_string,
                    userData.street_string,
                    userData.place_full_string,
                    userData.club_description
                );
                break;
            case 3:
                error = validatePromoter(userData, error);
                user = new Promoter(
                    userData.name,
                    userData.country,
                    user_data_city,
                    user_data_city_string
                );
                break;
            case 4:
                error = validateUser(userData, error);
                user = new User(
                    userData.name, 
                    userData.surname, 
                    userData.gender, 
                    userData.country, 
                    user_data_city,
                    user_data_city_string, 
                    userData.genre
                );
                break;
            default:
                return false;
        }
        this.setState({updateModalErrors: error});

        for (let key in error) {
            if (error.hasOwnProperty(key) && error[key] !== null)
                return false;
        }

        this.setState({loading: true});
        this.props.updateUserData(user).then(data => {
            if (!data.error){
                Alert.success(UPDATE_USER_DATA_SUCCESS + `${userData.name ? userData.name : null} ${userData.surname ? userData.surname : ''}`, ALERT_SETTINGS);
                this.props.updateUserDataSuccess();
                this.setState({loading: false});
                this.props.getUserHandling().then(() => {
                    this.props.toggleModalUserDataUpdate(false, true);
                    this.props.toggleModalUserDataManagement(false, true);
                    this.props.history.push(GET_USER_ROUTE_TO_DASHBOARD(this.props.user));
                });
            }else {
                this.props.updateUserDataError();
                this.setState({loading: false});
            }
        });
    };
    
    getBody = () => {
        switch (this.props.modalType) {
            case 1:
                return (
                    <RoleUserDataManagementModalInterpretContent
                        user={this.props.user} 
                        loading={this.state.loading}
                        errors={this.state.updateModalErrors} 
                        onSubmit={(user) => this.submitUpdate(user)} 
                    />
                );
            case 2:
                return (
                    <RoleUserDataManagementModalClubContent
                        user={this.props.user}
                        loading={this.state.loading}
                        errors={this.state.updateModalErrors} 
                        onSubmit={(user) => this.submitUpdate(user)} 
                    />
                );
            case 3:
                return (
                    <RoleUserDataManagementModalPromoterContent 
                        user={this.props.user}
                        loading={this.state.loading}
                        errors={this.state.updateModalErrors} 
                        onSubmit={(user) => this.submitUpdate(user)} 
                    />
                );
            case 4:
                return (
                    <RoleUserDataManagementModalUserContent 
                        user={this.props.user}
                        loading={this.state.loading}
                        errors={this.state.updateModalErrors} 
                        onSubmit={(user) => this.submitUpdate(user)} 
                    />
                );
            default:
                return null;
        }
    };

    onUserDataManagementClose = () => {
        this.props.toggleModalUserDataManagement (false, true);
    }

    render() {
        return (
            <Dialog
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                open={this.props.userDataManagementModal}
                onClose={this.onUserDataManagementClose}
                className='user-data-update-modal'
            >
                {
                !this.props.user ?  
                <Fade duration={500} delay={250} >
                    <div className="photo-update-modal__content">
                        <div className="photo-update-modal__content-loading-wrapper">
                            <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                            <p className="photo-update-modal__loading-content">{LOADING_CONTENT}</p>
                        </div>
                    </div>
                </Fade> :
                <div>
                    <Link to={Config.UPDATE_USER_DATA_ROOT} onClick={this.onUserDataManagementClose} className="user-data-update-modal__close-button">
                        <img src={'/img/icons/close-cross.svg'} alt={CLOSE_MODAL}/>
                        <span className="modal__close-button__text">Ukončiť úpravy užívateľa</span>
                    </Link>
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="user-data-update-modal__header-wrapper">
                                        <p className="user-data-management-modal__header-title"><span>{USER_DATA_MANAGEMENT_UPDATE_MODAL}</span></p>
                                        <p className="user-data-management-modal__header-subtitle">Údaje si môžeš zmeniť jednoducho prostredníctvom nasledujúcich odkazov.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <form action="post">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 padding-left-right-0-media">
                                    <div className="user-data-update-modal__content-wrapper row">
                                        <div className="user-data-update-modal__content-wrapper__user-data-update-modal-content">
                                            { this.getBody()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form> 
                </div>               
            }
            </Dialog> 
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modalType: getUserTypeNumber(state.userReducer.user),
        user: state.userReducer.user,
        userDataManagementModal: state.modalReducer.userDataManagementModal
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fullLogout,
        getUserHandling,
        updateUserData,
        updateUserDataSuccess,
        updateUserDataError,
        toggleModalUserDataManagement,
        toggleModalUserDataUpdate
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(UserDataManagementModal));
