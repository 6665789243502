import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {CLOSE_MODAL, USER_DATA_UPDATE_TITLE, CLOSE_USER_DATA_UPDATE_MODAL} from '../../../../config/TextContent';
import {getUserTypeNumber} from '../../../../client/Helper';
import {toggleModalUserDataUpdate} from '../../../../actions/ModalActions';
import './UserDataUpdateModal.css';
import {GET_USER_ROUTE_TO_DASHBOARD} from '../../../../config/RoutesConstants';
import {Link} from "react-router-dom";
import UserDataUpdateModalInterpretContent from './UserDataUpdateModalRolesContent/UserDataUpdateModalInterpretContent';
import UserDataUpdateModalClubContent from './UserDataUpdateModalRolesContent/UserDataUpdateModalClubContent';
import UserDataUpdateModalPromoterContent from './UserDataUpdateModalRolesContent/UserDataUpdateModalPromoterContent';
import UserDataUpdateModalUserContent from './UserDataUpdateModalRolesContent/UserDataUpdateModalUserContent';

class UserDataUpdateModal extends Component {
    getBody = () => {
        switch (this.props.modalType) {
            case 1:
                return (
                    <UserDataUpdateModalInterpretContent/>
                );
            case 2:
                return (
                    <UserDataUpdateModalClubContent/>
                );
            case 3:
                return (
                    <UserDataUpdateModalPromoterContent/>
                );
            case 4:
                return (
                    <UserDataUpdateModalUserContent/>
                );
            default:
                return null;
        }
    };
    render(){
        return (
            <Dialog
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                open={this.props.userDataUpdateModal}
                onClose={() => this.props.toggleModalUserDataUpdate(false, true)}
                className='user-data-update-modal'
            > 
                <Link to={GET_USER_ROUTE_TO_DASHBOARD(this.props.user)} onClick={() => this.props.toggleModalUserDataUpdate(false, true)} className="user-data-update-modal__close-button">
                    <img src={'/img/icons/close-cross.svg'} alt={CLOSE_MODAL}/>
                    <span className="modal__close-button__text">{CLOSE_USER_DATA_UPDATE_MODAL}</span>
                </Link>
                <form action="post">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 no-padding-left-right-mobile">
                                <div className="user-data-update-modal__header-wrapper">
                                    <p className="user-data-update-modal__header-title"><span>{USER_DATA_UPDATE_TITLE}</span></p>
                                    <p className="user-data-update-modal__header-text">Údaje si môžeš zmeniť jednoducho prostredníctvom nasledujúcich odkazov.</p>
                                </div>
                            </div>
                        </div> 
                        { this.getBody()}
                    </div>
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modalType: getUserTypeNumber(state.userReducer.user),
        user: state.userReducer.user,
        userDataUpdateModal: state.modalReducer.userDataUpdateModal
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalUserDataUpdate,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(UserDataUpdateModal);
