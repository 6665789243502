import React, {Component} from 'react';
import ElementCalendar from './MenuElementContent/ElementCalendar/ElementCalendar';
import ElementDashboard from './MenuElementContent/ElementDashboard/ElementDashboard';
import ElementClubs from './MenuElementContent/ElementClubs/ElementClubs';
import ElementBands from './MenuElementContent/ElementBands/ElementBands';
import MenuElementHOC from '../MenuElementHOC/MenuElementHOC';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import { isLoggedInUserInLocalStorage } from '../../../../actions/UserActions';
import {getUserTypeNumber} from '../../../../client/Helper';

import {GET_USER_ROUTE_TO_CALENDAR, GET_USER_ROUTE, ROUTE_DASHBOARD, GET_USER_ROUTE_TO_CLUBS, GET_USER_ROUTE_TO_BANDS, GET_USER_ROUTE_TO_USER_INFO} from '../../../../config/RoutesConstants';
import './MenuElementContent.css';
import ElementInfo from './MenuElementContent/ElementInfo/ElementInfo';

class MenuElementContent extends Component {
    constructor(props, context){
        super(props, context);

        this.state = {
            items: this.loadItems(props)
        };
    }

    loadItems = (props) => {
        let items = [];
        if(isLoggedInUserInLocalStorage()){
            if(props.user && props.loggedUserDash){
                if(getUserTypeNumber(props.user) === 1){
                    items = [
                        {
                            name: ElementDashboard,
                            paths: [GET_USER_ROUTE(props.user), ROUTE_DASHBOARD],
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementCalendar,
                            paths: [GET_USER_ROUTE_TO_CALENDAR(props.user)], 
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementClubs,
                            paths: [GET_USER_ROUTE_TO_CLUBS(props.user)],
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementInfo,
                            paths: [GET_USER_ROUTE_TO_USER_INFO(props.user)],
                            user: props.user,
                            active: 0
                        }
                    ];
                }else if(getUserTypeNumber(props.user) === 2){
                    items = [
                        {
                            name: ElementDashboard,
                            paths: [GET_USER_ROUTE(props.user), ROUTE_DASHBOARD],
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementCalendar,
                            paths: [GET_USER_ROUTE_TO_CALENDAR(props.user)], 
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementBands,
                            paths: [GET_USER_ROUTE_TO_BANDS(props.user)],
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementInfo,
                            paths: [GET_USER_ROUTE_TO_USER_INFO(props.user)],
                            user: props.user,
                            active: 0
                        }
                    ];
                } else{
                    items = [
                        {
                            name: ElementDashboard,
                            paths: [GET_USER_ROUTE(props.user), ROUTE_DASHBOARD],
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementCalendar,
                            paths: [GET_USER_ROUTE_TO_CALENDAR(props.user)], 
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementBands,
                            paths: [GET_USER_ROUTE_TO_BANDS(props.user)],
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementClubs,
                            paths: [GET_USER_ROUTE_TO_CLUBS(props.user)],
                            user: props.user,
                            active: 0
                        },
                        {
                            name: ElementInfo,
                            paths: [GET_USER_ROUTE_TO_USER_INFO(props.user)],
                            user: props.user,
                            active: 0
                        }
                    ];
                }
            }else{
                if(getUserTypeNumber(props.selectedUser) === 1){
                    items = [
                        {
                            name: ElementDashboard,
                            paths: [GET_USER_ROUTE(props.selectedUser), ROUTE_DASHBOARD],
                            user: props.selectedUser,
                            active: 0
                        },
                        {
                            name: ElementCalendar,
                            paths: [GET_USER_ROUTE_TO_CALENDAR(props.selectedUser)], 
                            user: props.selectedUser,
                            active: 0
                        },
                        {
                            name: ElementInfo,
                            paths: [GET_USER_ROUTE_TO_USER_INFO(props.selectedUser)],
                            user: props.selectedUser,
                            active: 0
                        }
                    ];
                }else if(getUserTypeNumber(props.selectedUser) === 2){
                    items = [
                        {
                            name: ElementDashboard,
                            paths: [GET_USER_ROUTE(props.selectedUser), ROUTE_DASHBOARD],
                            user: props.selectedUser,
                            active: 0
                        },
                        {
                            name: ElementCalendar,
                            paths: [GET_USER_ROUTE_TO_CALENDAR(props.selectedUser)], 
                            user: props.selectedUser,
                            active: 0
                        },
                        {
                            name: ElementInfo,
                            paths: [GET_USER_ROUTE_TO_USER_INFO(props.selectedUser)],
                            user: props.selectedUser,
                            active: 0
                        }
                    ];
                }else{
                    items = [
                        {
                            name: ElementDashboard,
                            paths: [GET_USER_ROUTE(props.selectedUser), ROUTE_DASHBOARD],
                            user: props.selectedUser,
                            active: 0
                        },
                        {
                            name: ElementCalendar,
                            paths: [GET_USER_ROUTE_TO_CALENDAR(props.selectedUser)], 
                            user: props.selectedUser,
                            active: 0
                        },
                        {
                            name: ElementInfo,
                            paths: [GET_USER_ROUTE_TO_USER_INFO(props.selectedUser)],
                            user: props.selectedUser,
                            active: 0
                        }
                    ];
                }
            }
        }else{
            items = [
                {
                    name: ElementDashboard,
                    paths: [GET_USER_ROUTE(props.selectedUser), ROUTE_DASHBOARD],
                    user: props.selectedUser,
                    active: 0
                },
                {
                    name: ElementCalendar,
                    paths: [GET_USER_ROUTE_TO_CALENDAR(props.selectedUser)], 
                    user: props.selectedUser,
                    active: 0
                },
                {
                    name: ElementInfo,
                    paths: [GET_USER_ROUTE_TO_USER_INFO(props.selectedUser)],
                    user: props.selectedUser,
                    active: 0
                }
            ]
        }
        
        items.forEach((value, index) => {
            items[index].active = 0;
            value.paths.forEach((path_value) => {
                if(path_value === props.location.pathname)
                    items[index].active = 1;
            });
        }); 

        return items;
    }

    componentWillReceiveProps(props){
        this.setState(prevState => {
            let items = this.loadItems(props);
            return { ...prevState, items }
        });
    }

    changeMenu = (index) => {
        this.setState((prevState) => {
            let elements = prevState.items;
            elements.forEach((value, value_index) => {
                elements[value_index].active = 0;
                if(index === value_index)
                    elements[value_index].active = 1;
            })
            return {...prevState, items: elements}
        });
    }

    render(){
        return (
            <div className={this.props.loggedUserDash ? "menu-element-content menu-element-content__logged-user" : "menu-element-content"}>
                {
                    this.state.items.map((element, index) => {
                        const TagName = element.name;
                        return (
                            <MenuElementHOC key={index} >
                                <TagName user={element.user} active={element.active} onClick={() => this.changeMenu(index)} link={element.paths[0]} />
                            </MenuElementHOC>
                        );
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        selectedUser: state.userReducer.selectedUser
    }
}

export default withRouter(connect(mapStateToProps)(MenuElementContent));
