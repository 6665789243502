import React from 'react';
import './CoverUpdateUserProfile.css';
import {ALT_ICON, CHANGE_USER_DATA} from '../../../../config/TextContent';
import {toggleModalUserDataUpdate} from '../../../../actions/ModalActions';
import {bindActionCreators} from "redux";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import { Config } from '../../../../config/Constants';
import { Link } from 'react-router-dom';

const CoverUpdateUserProfile = (props) => {
    return (
        <div className="dashboard-cover-update-user-profile">
            <Link to={Config.UPDATE_USER_DATA_ROOT} className="cover__desktop-element__updates-in-user-profile__user-data">
                <img src={'/img/icons/resume.svg'} alt={ALT_ICON}/>
                <p>{CHANGE_USER_DATA}</p>
            </Link>
        </div>
    );
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalUserDataUpdate
    }, dispatch);
}

export default withRouter(connect(null, matchDispatchToProps)(CoverUpdateUserProfile));
