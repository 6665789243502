import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toggleModalLogin} from '../../../actions/ModalActions';
import {withRouter} from 'react-router-dom';
import LoginContent from '../../LoginContent/LoginContent';
import TableEvent from '../../TableEvent/TableEvent';
import {CLOSE_MODAL} from '../../../config/TextContent';
import './LoginModal.css';
import {Link} from 'react-router-dom';


class LoginModal extends Component {
    render(){
        let event_classess = ["small__table__event left-small-event table__event__login__page", "small__table__event right-small-event table__event__login__page"];
        return (
            <Dialog
                fullWidth={true}
                fullScreen={true}
                open={this.props.loginModalOpened}
                onClose={() => this.props.toggleModalLogin(false, true)}
                className='login-modal'
                scroll='paper'
            >
                <Link to="/" onClick={() => this.props.toggleModalLogin(false, true)} className="login-modal__close-button">
                    <img src={'/img/icons/close-cross.svg'} alt={CLOSE_MODAL}/>
                    {CLOSE_MODAL}
                </Link>
                <div className="container"> 
                    <div className="row">
                        <div className="col-md-6 col-xs-0">
                            <div className="login-modal__tables-events-login-page">
                                {
                                    this.props.premiumEventsList.slice(0,2).map((event, index) => {
                                        return (
                                            <TableEvent 
                                                key={index}
                                                classEvent={event_classess[index]}
                                                event={event}
                                                loginModal={true}
                                                toggleModalLogin={this.props.toggleModalLogin}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <LoginContent 
                                isLoginModal={true}/>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        loginModalOpened: state.modalReducer.loginModal,
        premiumEventsList: state.eventReducer.premiumEventsList,
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleModalLogin,
    }, dispatch);
}


export default withRouter(connect(mapStateToProps, matchDispatchToProps)(LoginModal));
