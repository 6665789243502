const customReactSelectStyles = {
    control: () => ({
        width: 290,
        height: 39,
        paddingTop: "2px",
        background: "#161929",
        borderRadius: "3px",
        border: "1.5px solid #07b26c"
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 10ms';
        return { 
            ...provided, 
            opacity,
            color: "white",
            fontWeight: "500",       
            fontSize: "16px",     
            transition 
        };
    }
};

export default customReactSelectStyles;
