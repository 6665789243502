
import React, {Component, Fragment} from 'react';
import {GOOGLE_MAP_URL, Config} from '../../config/Constants';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import PropTypes from 'prop-types';

class MainMapBlock extends Component {

  onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    /* eslint-disable no-undef */
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'location': {lat, lng}}, (results, status) => {
      if(status === 'OK'){
        this.props.onSelectAddressChange(results[0].formatted_address, {lat, lng});
        this.props.onSelectAddressComponents(results[0].address_components);
      }
    });

    this.props.markerPositionChanged(lat, lng);
  };

  mymap = withScriptjs(withGoogleMap(props => {
    const centerLat = this.props.centerLatLng.lat ? this.props.centerLatLng.lat : Config.defaultLat;
    const centerLng = this.props.centerLatLng.lng ? this.props.centerLatLng.lng : Config.defaultLng;

    return (
      <GoogleMap
        draggable={true}
        defaultZoom={17}
        center={{ lat: centerLat, lng: centerLng }}>
          {props.children}
      </GoogleMap>)}));

  render() {
    return (
      <Fragment>
        <this.mymap
          googleMapURL={GOOGLE_MAP_URL}
          loadingElement={<div className="fullheight-container-map" />}
          containerElement={<div className="fullheight-container-map" />}
          mapElement={<div className="fullheight-container-map" />}
        >
          {
            this.props.markerLatLng.lat && this.props.markerLatLng.lng ? 
            <Marker
              className="marker-icon"
              icon={'/img/icons/location-pin.svg'}
              draggable={true}
              onDragEnd={(e) => this.onMarkerDragEnd(e)}
              position={{lat: this.props.markerLatLng.lat, lng: this.props.markerLatLng.lng}}
            /> : null
           }
        </this.mymap>
      </Fragment>);
  }
}

MainMapBlock.propTypes = {
  markerLatLng: PropTypes.object.isRequired,
  centerLatLng: PropTypes.object.isRequired,
  markerPositionChanged: PropTypes.func.isRequired
}

MainMapBlock.defaultProps = {
  markerLatLng: {
    lat: Config.defaultLat,
    lng: Config.defaultLng
  },
  centerLatLng: {
    lat: Config.defaultLat,
    lng: Config.defaultLng
  } 
}

export default MainMapBlock;
