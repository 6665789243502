import React, {Component} from 'react';
import './EventSlider.css';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import EventSmall from '../EventSmall/EventSmall';
import {toggleModalEventDataManagement} from '../../actions/ModalActions';
import {setLoadingSelectedEventToUpdate, getSelectedEventToUpdate, getSelectedEventToUpdateSuccess, getSelectedEventToUpdateError} from '../../actions/EventActions';
import Slider from 'react-slick';

class EventSlider extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};

        this.handleSelect = this.handleSelect.bind(this);
        this.nextAction = this.nextAction.bind(this);
        this.prevAction = this.prevAction.bind(this);
    }
    
    handleSelect(selectedIndex, e) {
        this.setState({
            index: selectedIndex,
            direction: e.direction
        });
    }

    nextAction() {
        this.slider.slickNext();
    }

    prevAction() {
        this.slider.slickPrev();
    }

    render() {
        let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: Number(this.props.slidesToShow),
            slidesToScroll: Number(this.props.slidesToShow),
            draggable: false,
            initialSlide: 0, 
            responsive: [
                {
                breakpoint: 1292,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: false
                    }
                },
                {
                breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false
                    }
                }
            ]
        };

        return (
            <div className="section-event-slider">
            {this.props.category && (!this.props.specifier || (this.props.specifier && this.props.specifier !== 'similar-events')) ? (
                <div className="section-event-slider__info">
                    <h3 className="section-event-slider__info__title">{this.props.category.name}</h3>
                    <p className="section-event-slider__info__description">{this.props.category.description}</p>
                </div>
            ) : null}
                <div className="event-slider">
                    <div className="row-fluid clearfix">
                        <div className="event-slider__content">
                            <Slider {...settings} ref={c => this.slider = c}>
                                {this.props.events && this.props.events.length > 0 ? 
                                    this.props.events.map((value) => {
                                        return (
                                            <div key={value.id}>
                                                {
                                                    this.props.specifier && this.props.specifier === "eventManagement" && (
                                                        <EventSmall 
                                                            event={value}
                                                            category={this.props.category}
                                                            event_id ={value.id}
                                                            specifier={this.props.specifier}
                                                        />
                                                    )
                                                }
                                                {
                                                    this.props.specifier && this.props.specifier === "eventPerformerRemoval" && (
                                                        <div className="event-small-performer-removal">
                                                            <EventSmall 
                                                                event={value}
                                                                category={this.props.category}
                                                                event_id ={value.id}
                                                                specifier={this.props.specifier}
                                                                userAsEventPerformerRemoval={this.props.userAsEventPerformerRemoval}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    !this.props.specifier || this.props.specifier === "similar-events" ? (
                                                        <EventSmall 
                                                            event={value}
                                                            category={this.props.category}
                                                        />
                                                    ) : null
                                                }
                                            </div>     
                                        );
                                    }) : null}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalEventDataManagement,
        setLoadingSelectedEventToUpdate: setLoadingSelectedEventToUpdate,
        getSelectedEventToUpdate: getSelectedEventToUpdate,
        getSelectedEventToUpdateSuccess: getSelectedEventToUpdateSuccess,
        getSelectedEventToUpdateError: getSelectedEventToUpdateError
    }, dispatch);
}

export default connect(null, matchDispatchToProps)(EventSlider);
