import React from 'react';
import {MENU_EVENTS} from '../../../../../../config/TextContent';
import MenuItem from '../MenuItem';

const ElementDashboard = props => {
    return (
        <MenuItem 
            name={MENU_EVENTS}
            link={props.link}
            img='/img/icons/presentation-board-with-graph.svg'
            active={props.active}
            onClick={props.onClick}
        />
    );
}

export default ElementDashboard;
