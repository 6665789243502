import React, {Component} from 'react';
import './Footer.css';
import {Link} from "react-router-dom";
import {FOOTER_PROJECT_NAME_PART_1, FOOTER_PROJECT_NAME_PART_2, FOOTER_REGISTRATION_TITLE, FOOTER_LINK_USER, FOOTER_LINK_PROMOTER,
    FOOTER_LINK_CLUB, FOOTER_IMPORTANT_LINKS, FOOTER_BUSINESS_CONDITIONS, FOOTER_PRIVACY_POLICIES, 
    FOOTER_LINK_INTERPRET, FOOTER_COPYRIGHT_YEAR, FOOTER_ALL_RIGHTS_RESERVED, ALT_ICON} from '../../config/TextContent';
import {EMAIL_ADDRESS} from '../../config/Config';
import FooterStats from '../Footer/FooterStats/FooterStats';
import {Config} from '../../config/Constants';
import { isLoggedInUserInLocalStorage } from '../../actions/UserActions';

class Footer extends Component {
    render() {
        return (
            <div>
                <FooterStats />
                <div className="footer">
                    <div className="container">
                        <div className="footer__header row">
                            <div className="col-md-12">
                                <h3 className="footer__header-title">
                                    {FOOTER_PROJECT_NAME_PART_1}
                                    <span>{FOOTER_PROJECT_NAME_PART_2}</span>
                                </h3>
                            </div>
                        </div>
                        {isLoggedInUserInLocalStorage() ? (
                        <div className="footer__content row">
                            <div className="col-md-6">
                                <div className="footer__content-intro">
                                    <p className="footer__content-title">{FOOTER_IMPORTANT_LINKS}</p>
                                    <ul className="footer__content-list-of-links">
                                        <li>
                                            <div className="footer__content-list-of-links__link-wrapper">
                                                <Link to={Config.GENERAL_STATEMENT_ROOT} target="_blank" className="footer__content-list-of-links__link-wrapper__link">
                                                    <span>{FOOTER_BUSINESS_CONDITIONS}</span>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="footer__content-list-of-links__link-wrapper">
                                                <Link to={Config.PRIVACY_POLICIES_ROOT} target="_blank" className="footer__content-list-of-links__link-wrapper__link">
                                                    <span>{FOOTER_PRIVACY_POLICIES}</span>
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="footer__content-map">
                                    <img src={'/img/map-svk-cze-bold.svg'} alt={ALT_ICON}/>
                                </div>
                            </div>
                        </div>
                        ) : (
                        <div className="footer__content row">
                            <div className="col-md-3">
                                <div className="footer__content-intro">
                                    <p className="footer__content-title">{FOOTER_REGISTRATION_TITLE}</p>
                                    <ul className="footer__content-list-of-links">
                                        <li>
                                            <div className="footer__content-list-of-links__link-wrapper">
                                                <Link to={Config.REGISTRATION_USER_ROOT} className="footer__content-list-of-links__link-wrapper__link">
                                                    <span>{FOOTER_LINK_USER}</span>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="footer__content-list-of-links__link-wrapper">
                                                <Link to={Config.REGISTRATION_INTERPRET_ROOT} className="footer__content-list-of-links__link-wrapper__link">
                                                    <span>{FOOTER_LINK_INTERPRET}</span>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="footer__content-list-of-links__link-wrapper">
                                                <Link to={Config.REGISTRATION_CLUB_ROOT} className="footer__content-list-of-links__link-wrapper__link">
                                                    <span>{FOOTER_LINK_CLUB}</span>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="footer__content-list-of-links__link-wrapper">
                                                <Link to={Config.REGISTRATION_PROMOTER_ROOT} className="footer__content-list-of-links__link-wrapper__link">
                                                    <span>{FOOTER_LINK_PROMOTER}</span>
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="footer__content-intro">
                                    <p className="footer__content-title">{FOOTER_IMPORTANT_LINKS}</p>
                                    <ul className="footer__content-list-of-links">
                                        <li>
                                            <div className="footer__content-list-of-links__link-wrapper">
                                                <Link to={Config.GENERAL_STATEMENT_ROOT} target="_blank" className="footer__content-list-of-links__link-wrapper__link">
                                                    <span>{FOOTER_BUSINESS_CONDITIONS}</span>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="footer__content-list-of-links__link-wrapper">
                                                <Link to={Config.PRIVACY_POLICIES_ROOT} target="_blank" className="footer__content-list-of-links__link-wrapper__link">
                                                    <span>{FOOTER_PRIVACY_POLICIES}</span>
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="footer__content-map">
                                    <img src={'/img/map-svk-cze-bold.svg'} alt={ALT_ICON}/>
                                </div>
                            </div>
                        </div>
                        )}
                        <div className="footer__contact-email row">
                            <div className="col-md-12">
                                <button className="default_transparent_button">{EMAIL_ADDRESS}</button>
                            </div>
                        </div>
                        <div className="footer__end-section row">
                            <div className="col-md-12">
                                <div className="footer__end-section__part-1">
                                    <p className="footer__end-section__part-1__text">{FOOTER_COPYRIGHT_YEAR} </p>
                                    <p className="footer__end-section__part-1__text">&nbsp;© {FOOTER_ALL_RIGHTS_RESERVED}</p>
                                </div>
                                <div className="footer__end-section__part-2">
                                    <span>Icons made by</span>
                                    <a rel="noopener noreferrer" href="http://www.freepik.com" title="Freepik" target="_blank">Freepik,</a>
                                    <a rel="noopener noreferrer" href="https://www.flaticon.com/authors/smashicons" title="Smashicons" target="_blank">Smashicons,</a> 
                                    <a rel="noopener noreferrer" href="https://www.flaticon.com/authors/pixel-buddha" title="Pixel Buddha">Pixel Buddha, </a>
                                    <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a>
                                    <span>from</span>
                                    <a rel="noopener noreferrer" href="https://www.flaticon.com/" target="_blank" title="Flaticon">www.flaticon.com </a> 
                                    <span>is licensed by</span>
                                    <a rel="noopener noreferrer" href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" className="footer__end-section__part-2__last-link">CC 3.0 BY</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Footer;
