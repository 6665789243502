import React, {Component} from 'react';
import {connect} from "react-redux";
import {LOADING_LIST_OF_BANDS, BANDS, ALT_ICON, THERE_ARE_NO_BANDS_AVAILABLE, RESET_FILTERS} from '../../../config/TextContent';
import ListOfBands from '../ProfileListOfBands/ListOfBands/ListOfBands';
import {setLoadingGetListOfBands, getListOfBands, getListOfBandsSuccess, getListOfBandsError} from '../../../actions/UserActions';
import Fade from 'react-reveal/Fade';
import './ProfileListOfBands.css';
import {bindActionCreators} from "redux";
import ReactLoading from 'react-loading';
import ReactPaginate from 'react-paginate';
import { GET_USER_ROUTE_TO_BANDS } from '../../../config/RoutesConstants';
import ProfileBandFilters from './ProfileBandFilters/ProfileBandFilters';
import { Config } from '../../../config/Constants';
import {getFilterEventMusicGenres} from '../../../components/Header/CreateEventModal/filters/filters';

class ProfileListOfBands extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            offset: props.match.params && props.match.params.page ? (parseInt(props.match.params.page)-1)*Config.listBandsPerPage : 0,
            countPages: 1,
            currentPage: props.match.params.page ? parseInt(props.match.params.page)-1 : 0,
            typingTimeout: 0,
            filter: {
                name: '',
                city: '',
                genre: null,
                childrenGenre: null,
            }
        }
    }

    componentDidMount = () => {
        this.loadListOfBands(this.state.offset ? this.state.offset : 0);
    }

    isNotActiveFiltering = () => {
        if(this.state.filter.name === '' && this.state.filter.city === '' && this.state.filter.genre === null){
            return true;
        }
        return false;
    }

    onGenreChange = (value) => {
        this.setState((prevState) => {
            let filter = {...prevState.filter};
            filter['genre'] = value;
            filter['childrenGenre'] = null;
            return {
                ...prevState,
                filter
            };
        }, () => {
            this.loadListOfBands();
        });
    };

    loadListOfBands = (offset = 0) => {
        let countBands = 0;
        let countPages = 0;
        let filter = this.state.filter;
        let query_params = {};
        query_params.name = filter.name;
        query_params.city = filter.city;
        query_params.offset = offset;
        if (filter.childrenGenre) {
            query_params.genre = filter.childrenGenre;
        } else {
            query_params.genre = filter.genre;
        }
        query_params.perPage = Config.listBandsPerPage;
        query_params.user_id = this.props.user.username;
        this.props.setLoadingGetListOfBands();
        this.props.getListOfBands(query_params).then((data) => {
            this.setState(() => {
                countBands = parseInt(data.payload.data.count_bands);
                countPages = Math.ceil(countBands / Config.listBandsPerPage);
                return {
                    countPages
                }
            }, () => {
                this.props.getListOfBandsSuccess();
            }); 
        }).catch(() => {
            this.props.getListOfBandsError();
        })
    }

    handleClickPage = (data) => {
        let offset = Math.ceil(data.selected * Config.listBandsPerPage);
        this.props.history.push(GET_USER_ROUTE_TO_BANDS(this.props.user) + '/' + (data.selected+1));
        this.setState({
            offset: offset,
            currentPage: data.selected
        }, () => {
            this.loadListOfBands(offset);
        });
    }

    onClickResetFilters = () => {
        this.setState((prevState) => {
            let filter = {
                name: '',
                city: '',
                genre: null,
                childrenGenre: null,
            };
            return{
                ...prevState,
                filter
            }
        }, () => {
            this.loadListOfBands();
        })
    }

    handleNameChange = (event) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        let filter = this.state.filter;
        filter.name = event.target.value;

        this.setState({
            filter,
            typingTimeout: setTimeout(() => {
                this.loadListOfBands();
            }, 500)
        });
    };

    handleCityChange = (event) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        let filter = this.state.filter;
        filter.city = event.target.value;

        this.setState({
            filter,
            typingTimeout: setTimeout(() => {
                this.loadListOfBands();
            }, 500)
        });
    };

    onEventMusicChildrenGenresChange = (value) => {
        this.setState((prevState) => {
            let filter = {...prevState.filter};
            filter['childrenGenre'] = value;
            return {
                ...prevState,
                filter
            };
        }, () => {
            this.loadListOfBands();
        });
    };

    render() {
        return ( 
            <div className="profile-list-of-bands">
                <div className="container">
                    <div className="profile-section-filters">
                        {!this.isNotActiveFiltering() ? (
                            <div className="profile-section-filters-reset">
                                <button onClick={this.onClickResetFilters}>{RESET_FILTERS}</button>
                            </div>
                        ) : null}
                        <ProfileBandFilters 
                            handleNameChange={this.handleNameChange}
                            handleCityChange={this.handleCityChange}
                            onGenreChange={this.onGenreChange}
                            onEventMusicChildrenGenresChange={this.onEventMusicChildrenGenresChange}
                            genres={this.props.genres}
                            filter={this.state.filter}
                        />
                    </div>
                    <div className="profile-list-of-bands__header">
                        <h1 className="section-event-slider__info__title">{BANDS}</h1>
                    </div>
                    {this.props.loadingListOfBands ?
                        <Fade duration={500} delay={250} >
                            <div className="photo-update-modal__content">
                                <div className="photo-update-modal__content-loading-wrapper">
                                    <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                                    <p className="photo-update-modal__loading-content loading-content__black">{LOADING_LIST_OF_BANDS}</p>
                                </div>
                            </div> 
                        </Fade>
                    : null}
                    {!this.props.loadingListOfBands && this.props.listOfBands && this.props.listOfBands.length > 0 ? (
                        <div>
                            <ListOfBands /> 
                        </div>
                    ) : null}
                    {(!this.props.loadingListOfBands && this.props.listOfBands === null) || (this.props.listOfBands && this.props.listOfBands.length === 0) ? (
                        <div className="collection_event_lists__no-events">
                            <img src="/img/icons/close-cross-green.svg" alt={ALT_ICON} />
                            <p>{THERE_ARE_NO_BANDS_AVAILABLE}</p>
                        </div>
                    ) : null}
                    {!this.props.loadingListOfBands && this.props.listOfBands && this.props.listOfBands.length > 0 && this.state.countPages > 1  ? (
                        <div className="profile-list-of-bands__pagination">
                            <ReactPaginate
                                pageCount={this.state.countPages}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={6}
                                onPageChange={this.handleClickPage}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                breakLabel={'...'}
                                forcePage={this.state.currentPage}
                                activeClassName={'active'}
                                breakClassName={'break-me'}
                                previousLabel={<img src="/img/icons/arrow-pointing-to-right-blue.svg" className="list-pagination__prev-icon" alt={ALT_ICON} />}
                                nextLabel={<img src="/img/icons/arrow-pointing-to-right-blue.svg" className="list-pagination__next-icon" alt={ALT_ICON} />}
                            />
                        </div>
                    ) : null} 
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loadingListOfBands: state.userReducer.loadingListOfBands,
        listOfBands: state.userReducer.listOfBands,
        user: state.userReducer.user,
        selectedUser: state.userReducer.selectedUser,
        genres: getFilterEventMusicGenres(state.eventReducer.eventMusicGenresList),
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setLoadingGetListOfBands,
        getListOfBands,
        getListOfBandsSuccess,
        getListOfBandsError
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ProfileListOfBands);
