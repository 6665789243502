import React from 'react';
import './AuthenticatedDesktopNavigation.css';
import { Link } from 'react-router-dom';
import {getFullname, getUserTypeNumber} from '../../../../../client/Helper';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import { fullLogout } from '../../../../../actions/AuthActions';
import {GET_USERTYPE_IN_NOMINATIV, ALT_ICON, FOOTER_PROJECT_NAME_PART_1, FOOTER_PROJECT_NAME_PART_2, UPDATE_PROFILE, MENU_LOGOUT, ADD_EVENT} from '../../../../../config/TextContent';
import { GET_USER_ROUTE_TO_DASHBOARD } from '../../../../../config/RoutesConstants';
import { Config } from '../../../../../config/Constants';
import ReactTooltip from 'react-tooltip';

const logout = (props) => {
    props.fullLogout();
    window.location.replace("/");
}

const AuthenticatedDesktopNavigation = (props) => {
    return (
        <div className="header__navigation__authenticated-navigation__desktop">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-xs-4">
                        <Link to="/" className="authenticated-navigation__desktop__logo-wrapper header__navigation">
                            {FOOTER_PROJECT_NAME_PART_1}
                            <span>{FOOTER_PROJECT_NAME_PART_2}</span>
                        </Link>
                    </div>
                    <div className="col-md-8 col-xs-8">
                        <div className="authenticated-navigation__desktop-buttons-wrapper">
                            <Link to={GET_USER_ROUTE_TO_DASHBOARD(props.user)} className="authenticated-navigation__desktop-buttons-wrapper__image-wrapper">
                                <img src={Config.getUserPhoto(props.user, Config.CONSTANT_TITLE_PICTURE)} alt={ALT_ICON}/>
                            </Link>
                            <div className="authenticated-navigation__desktop-buttons-wrapper__text">
                                <Link to={GET_USER_ROUTE_TO_DASHBOARD(props.user)} className="authenticated-navigation__desktop-buttons-wrapper__name">{props.fullname}</Link>
                                <p className="authenticated-navigation__desktop-buttons-wrapper__subtitle">{GET_USERTYPE_IN_NOMINATIV(getUserTypeNumber(props.user))}</p>
                            </div>
                            <Link to={Config.UPDATE_USER_DATA_ROOT} className="authenticated-navigation__user-data-update">
                                <img src={'/img/icons/resume-bold.svg'} alt={ALT_ICON} data-tip data-for="header-user-data-update-image" />
                                <ReactTooltip id="header-user-data-update-image" place="bottom" effect="solid">{UPDATE_PROFILE}</ReactTooltip>
                            </Link>
                            {
                                /*
                            <div className="authenticated-navigation__desktop-buttons-wrapper_icons authenticated-navigation__desktop-buttons-wrapper__icon-messages">
                                <img src={'/img/icons/email-green.svg'} className="logged-in-buttons__messages" alt={ALT_ICON}/>
                                <span className="authenticated-navigation__desktop-buttons-wrapper-icons__number-of-messages">12</span>
                            </div>
                            <div className="authenticated-navigation__desktop-buttons-wrapper_icons">
                                <img src={'/img/icons/notification.svg'} className="logged-in-buttons__messages" alt={ALT_ICON}/>
                            </div>
                                */
                            }
                            {props.role !== null && props.role !== 4 ? (
                                <Link to={Config.ADD_EVENT_ROOT} className="authenticated-navigation__desktop__button-add-event">
                                    <img src="/img/icons/close-cross.svg" alt={ALT_ICON} />
                                    <span>{ADD_EVENT}</span>
                                </Link>
                            ) : null }
                            <button onClick={() => logout(props)} className="authenticated-navigation__desktop-buttons-wrapper_icons">
                                <img src={'/img/icons/turn-off.svg'} className="logged-in-buttons__messages" alt={ALT_ICON} data-tip data-for="header-logout-image"/>
                                <ReactTooltip id="header-logout-image" place="bottom" effect="solid">{MENU_LOGOUT}</ReactTooltip>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        fullname: getFullname(state.userReducer.user, state.userReducer.user.user_data),
        user: state.userReducer.user,
        role: getUserTypeNumber(state.userReducer.user)
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fullLogout
    }, dispatch);
}

AuthenticatedDesktopNavigation.propTypes = {
    fullname: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(AuthenticatedDesktopNavigation));
