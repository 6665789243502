import React, {Component} from "react";
import {deleteImageClubGallery} from '../../../actions/ClubGalleryActions';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {PHOTOGRAPHY_TRANSLATE, ALT_ICON} from '../../../config/TextContent';
import '../Event/EventDetailStyle.css';

class ClubGalleryImageControls extends Component{

  constructor(props, context){
    super(props, context);

    let html_photo = Object.assign({}, props.photo);
    delete html_photo.uuid;
    delete html_photo.index;
    delete html_photo.qquuid;
    delete html_photo.callbacks;

    this.state = {
        html_photo: html_photo,
        photo: props.photo,
        index: props.index,
        onClick: props.onClick,
        deleteImageClubGallery: props.deleteImageClubGallery
    }

    this.deletePhotoFromGallery = this.deletePhotoFromGallery.bind(this);
  }

  deletePhotoFromGallery(state){
    return this.props.deleteImageClubGallery(state.photo)
    .then((res) => {
      if(res.payload.data){
        let success_indicator = res.payload.data.success;
        if(state.photo.hasOwnProperty("callbacks")){
          if(success_indicator === true)
            state.photo.callbacks.successDeleteImage();
          else
            state.photo.callbacks.errorDeleteImage();
        }
      }
    })
  }

  render() {
    return (
        <div className={"photo-gallery-img-wrapper"}>
          <div className={"photo-gallery-img-wrapper__delete-wrapper"} onClick={() => this.deletePhotoFromGallery(this.state)}>
              <img src={'/img/icons/close-cross.svg'} alt={ALT_ICON}/>
          </div>
          <img
            alt={PHOTOGRAPHY_TRANSLATE}
            {...this.state.html_photo}
            onClick={e => this.state.onClick(e, { index: this.state.index, photo: this.state.photo })}
          />
        </div>
      );
  }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        deleteImageClubGallery: deleteImageClubGallery
    }, dispatch);
}

export default connect(null, matchDispatchToProps)(ClubGalleryImageControls);

