import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {CLOSE_MODAL, LOADING_CONTENT, ACCOUNT_SETTINGS_MODAL_HEADER, ACCOUNT_SETTINGS_MODAL_TEXT, DELETE_ACCOUNT, 
    SUCCESS_WITH_DELETE_ACCOUNT, CONTENT_LOADING, SUCCESS_USER_REMOVAL, ERROR_WHEN_REMOVING_USER} from '../../../config/TextContent';
import {toggleModalAccountSettings, toggleModalUserDataUpdate} from '../../../actions/ModalActions';
import {setLoadingRemoveUser, removeUser, removeUserError, removeUserSuccess} from '../../../actions/UserActions';
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import Fade from 'react-reveal/Fade';
import ReactLoading from 'react-loading';
import { fullLogout } from '../../../actions/AuthActions';
import Alert from 'react-s-alert';
import {ALERT_SETTINGS} from '../../../config/Config';
import LoadingButton from '../../LoadingButton/LoadingButton';
import {Redirect} from 'react-router-dom';
import './AccountSettingsModal.css';
import {withRouter} from 'react-router-dom';
import { Config } from '../../../config/Constants';

class AccountSettingsModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            clickedButtonDeleteAccount: false
        }
    }
    
    onAccountSettingsModalClose = () => {
        this.props.toggleModalAccountSettings(false, true);

    }

    onClickButtonDeleteAccount = () => {
        if(!this.state.clickedButtonDeleteAccount){
            this.setState({clickedButtonDeleteAccount: true});
        }
    }

    onClickFinalButtonDeleteAccount = () => {
        this.props.setLoadingRemoveUser();
        this.props.removeUser().then(data => {
            if(data.payload.data.result === true){
                this.props.removeUserSuccess();
                Alert.success(SUCCESS_USER_REMOVAL, ALERT_SETTINGS);
            }else{
                this.props.removeUserError();
                Alert.error(ERROR_WHEN_REMOVING_USER, ALERT_SETTINGS);
            }
            this.onAccountSettingsModalClose();
            this.props.fullLogout();
            return <Redirect to="/" />
        }).catch(err => {
            Alert.error(ERROR_WHEN_REMOVING_USER, ALERT_SETTINGS);
            this.props.removeUserError();
            this.props.fullLogout();
            this.onAccountSettingsModalClose();
            return <Redirect to="/" />
        });
    }

    render() {
        return (
            <Dialog
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                open={this.props.accountSettingsModalOpened}
                onClose={this.onAccountSettingsModalClose}
                className='user-data-update-modal'
            >
            {
            !this.props.user ?  
            <Fade duration={500} delay={250}> 
                <div className="photo-update-modal__content">
                    <div className="photo-update-modal__content-loading-wrapper">
                        <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                        <p className="photo-update-modal__loading-content">{LOADING_CONTENT}</p>
                    </div>
                </div>
            </Fade> :
            <div className="account-settings-modal">
                <Link to={Config.UPDATE_USER_DATA_ROOT} onClick={this.onAccountSettingsModalClose} className="user-data-update-modal__close-button">
                    <img src={'/img/icons/close-cross.svg'} alt={CLOSE_MODAL}/>
                    <span className="modal__close-button__text">Zavrieť</span>
                </Link>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="user-data-update-modal__header-wrapper">
                                    <p className="user-data-management-modal__header-title"><span>{ACCOUNT_SETTINGS_MODAL_HEADER}</span></p>
                                    <p className="user-data-management-modal__header-subtitle">{ACCOUNT_SETTINGS_MODAL_TEXT}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <form action="post">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="user-data-update-modal__content-wrapper row">
                                    {
                                        !this.state.clickedButtonDeleteAccount ?
                                        <button className="default_transparent_button_important" onClick={this.onClickButtonDeleteAccount}>{DELETE_ACCOUNT}</button>
                                        : <LoadingButton
                                            classes="default_transparent_button_important"
                                            paragraphClass="pre-register-content__form__button__intro-title"
                                            loaderClass="pre-register-content__form__button__react-loading"
                                            content={SUCCESS_WITH_DELETE_ACCOUNT}
                                            loadingContent={CONTENT_LOADING}
                                            loading={this.props.loadingRemoveUser}
                                            onClick={this.onClickFinalButtonDeleteAccount}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            }
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        accountSettingsModalOpened: state.modalReducer.accountSettingsModalOpened,
        loadingRemoveUser: state.userReducer.loadingRemoveUser,
    } 
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalAccountSettings,
        toggleModalUserDataUpdate, 
        setLoadingRemoveUser,
        removeUser,
        removeUserSuccess,
        removeUserError,
        fullLogout
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(AccountSettingsModal));
