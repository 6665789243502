import React from 'react';
import { Link } from 'react-router-dom';
import { ADD_EVENT, ALT_ICON } from '../../config/TextContent';
import {Config} from '../../config/Constants';
import './FixedButtonAddEvent.css';
import { connect } from 'react-redux';
import {getUserTypeNumber} from '../../client/Helper';

const FixedButtonAddEvent = (props) => {
    return(
        props.role !== null && props.role !== 4 ? (
        
            <div className="fixed-button-add-event">
                <div className="fixed-button-add-event__content">
                    <Link to={Config.ADD_EVENT_ROOT} className="fixed-button-add-event__content__button">
                        <img src="/img/icons/close-cross.svg" alt={ALT_ICON} />
                        <span>{ADD_EVENT}</span>
                    </Link>
                </div>
            </div>
        ) : null
    )
}

const mapStateToProps = (state) => {
    return {
        role: getUserTypeNumber(state.userReducer.user)
    }
}

export default connect(mapStateToProps, null)(FixedButtonAddEvent);
