import React from 'react';
import './Action.css';
import { Link } from 'react-router-dom';

const Action = () => {
    return (
        <div className="newest-actions-table__event row">
            <div className="col-md-3">
                <Link to="">
                    <div className="newest-actions-table__small-photo">
                        <img src={'/img/interpret.jpeg'} alt="" />
                    </div>
                </Link>                                        
            </div>
            <div className="col-md-9">
                <div className="newest-actions-table__content">
                    <Link to="">
                        <p className="newest-actions-table__content__title">Martina Peťová</p>
                    </Link>
                    <div className="newest-actions-table__content__time-date">
                        <p className="newest-actions-table__content__time">20:11</p>
                        <p className="newest-actions-table__content__date">20.09.2018</p>
                    </div>
                    <div className="newest-actions-table__content__event">
                        <p>Vytvorila novú udalosť</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Action;
