import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import LoadingButton from '../LoadingButton/LoadingButton';
import './LoginContent.css';
import {HashLink} from 'react-router-hash-link';
import {withRouter} from 'react-router-dom';
import {loginSuccessfull} from '../../actions/UserActions';
import {bindActionCreators} from "redux";
import {ALERT_SETTINGS} from '../../config/Config';
import Alert from 'react-s-alert';
import {getFullname} from '../../client/Helper';
import {login, loginError} from "../../actions/AuthActions";
import {isValidEmail, isValidPassword} from '../../client/Validation';
import {isLoggedInUserInLocalStorage, getUserHandling} from '../../actions/UserActions';
import {BAD_DATA_LOGIN_FORM, REGISTRATION_HELLO, LOGIN_CONTENT_INTRO_PAGE_DESCRIPTION_1, LOGIN_CONTENT_INTRO_PAGE_DESCRIPTION_2, 
    LOGIN_CONTENT_INTRO_PAGE_DESCRIPTION_3, LOGIN_CONTENT_LOGIN, LOGIN_CONTENT_JOIN_US, 
    LOGIN_CONTENT_TITLE_2, LOGIN_CONTENT_SUBTITLE_1, LOGIN_CONTENT_SUBTITLE_2, LOGIN_CONTENT_SUBTITLE_3,
    LOGIN_FLOATING_LABEL_EMAIL, LOGIN_FLOATING_LABEL_PASSWORD, CONTENT_LOADING, MORE_ABOUT_PORTAL, 
    INFO_BAD_LOGIN_DATA, FORGOTTEN_PASSWORD} from '../../config/TextContent';

class LoginContent extends Component {

    constructor(props, context){
        super(props, context);

        this.state = {
            user: {
                email: '',
                password: ''
            },
            errors: {
                email: null,
                password: null
            },
            loading: false,
            submitClicked: false,
        };

        this.onChange = this.onChange.bind(this);
        this.submitLoginModal = this.submitLoginModal.bind(this);
    }
    
    onChange(event) {
        let event_target_name = event.target.name;
        let event_target_value = event.target.value;
        this.setState(prevState => {
            const user = prevState.user;
            const errors = prevState.errors;
            user[event_target_name] = event_target_value;
            errors[event_target_name] = null;
            return {
                user,
                errors
            }
        });
    }

    clearInputs() {
        this.setState({
            user: {
                email: '',
                password: ''
            },
            errors: {
                email: null,
                password: null
            }
        });
    }

    componentWillReceiveProps() {
        if (!this.props.loginModalOpened)
            this.clearInputs();
    }

    submitLoginModal = () => {
        const user = { email: this.state.user.email, password: this.state.user.password };  
        let errors = {};
        errors.email = isValidEmail(user.email);
        errors.password = isValidPassword(user.password);
    
        if(!errors.email && !errors.password){
            this.setState({
                loading: true
            }, () => {
                this.props.login(user).then(response => {
                    if(!response.error){
                        this.props.getUserHandling((data) => {
                            this.props.loginSuccessfull(response);
                            this.setState({ loading: false });
                            this.props.history.replace("/");
                        }, () => {
                            Alert.error(INFO_BAD_LOGIN_DATA, ALERT_SETTINGS);
                            this.props.loginError();
                            this.setState({ loading: false, errors: { email: BAD_DATA_LOGIN_FORM, password: BAD_DATA_LOGIN_FORM }});
                        });
                    }else{
                        Alert.error(INFO_BAD_LOGIN_DATA, ALERT_SETTINGS);
                        this.props.loginError();
                        this.setState({ loading: false, errors: { email: BAD_DATA_LOGIN_FORM, password: BAD_DATA_LOGIN_FORM }});
                    }
                }).catch(() => {
                    Alert.error(INFO_BAD_LOGIN_DATA, ALERT_SETTINGS);
                    this.props.loginError();
                    this.setState({ loading: false, errors: { email: BAD_DATA_LOGIN_FORM, password: BAD_DATA_LOGIN_FORM }});
                });                
            });
        }else
            this.setState({ errors });
    };

    render() {
        return (
            !this.props.isLoginModal && isLoggedInUserInLocalStorage() ? (
                <div className="login login__intro-page">
                    <div className="login__header login__header__intro-page">
                        <p className="login__header-title"><span>{REGISTRATION_HELLO}</span><span>{this.props.fullname}</span></p>
                        <p className="login__header-subtitle">{LOGIN_CONTENT_INTRO_PAGE_DESCRIPTION_1} <span className="login__header-subtitle__green">{LOGIN_CONTENT_INTRO_PAGE_DESCRIPTION_2}</span> {LOGIN_CONTENT_INTRO_PAGE_DESCRIPTION_3}</p>
                    </div>
                    <HashLink smooth to="/#events" className="default_transparent_link">{MORE_ABOUT_PORTAL}</HashLink>
                </div>
            ) : (
                <div className="login">
                    <div className="login__header">
                        <p className="login__header-title"><span>{LOGIN_CONTENT_JOIN_US}</span><span dangerouslySetInnerHTML={{__html: LOGIN_CONTENT_TITLE_2}}></span></p>
                        <p className="login__header-subtitle">{LOGIN_CONTENT_SUBTITLE_1} <span className="login__header-subtitle__green">{LOGIN_CONTENT_SUBTITLE_2}</span> {LOGIN_CONTENT_SUBTITLE_3}</p>
                    </div>
                    <div className="login__form">
                        <form action="post" autoComplete="off">
                            <div className="login__form__inputs">
                                <div className="login__form__inputs-part">
                                    <TextField
                                        required
                                        id={this.props.isLoginModal ? "email_loginModal" : "email"}
                                        spellCheck="false"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        name="email"
                                        placeholder="@"
                                        className={this.state.errors.email ? 'error-textfield' : ''}
                                        label={this.state.errors.email ? this.state.errors.email : LOGIN_FLOATING_LABEL_EMAIL}
                                        value={this.state.user.email}
                                        type="text"
                                        fullWidth={true}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="login__form__inputs-part">
                                    <TextField
                                        required
                                        autoComplete="off"
                                        id={this.props.isLoginModal ? "password_loginModal" : "password"}
                                        name="password"
                                        value={this.state.user.password}
                                        className={this.state.errors.password ? 'error-textfield' : ''}
                                        placeholder="*****"
                                        label={this.state.errors.password ? this.state.errors.password : LOGIN_FLOATING_LABEL_PASSWORD}
                                        type="password"
                                        fullWidth={true}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                            <div className="login__forgotten-password">
                                <a href="https://event-portal-backend.eventeeko.com/resetting/request">{FORGOTTEN_PASSWORD}</a>
                            </div>
                            <div className="">
                                <LoadingButton 
                                    classes="default_transparent_button_important"
                                    paragraphClass="pre-register-content__form__button__intro-title"
                                    loaderClass="pre-register-content__form__button__react-loading"
                                    loading={this.state.loading}
                                    content={LOGIN_CONTENT_LOGIN}
                                    loadingContent={CONTENT_LOADING}
                                    onClick={() => this.submitLoginModal()}
                                /> 
                            </div>
                            {/*
                                this.props.withFAQ ? (
                                    <div className="login__faq">
                                        <Link to="" className="login__faq__link">
                                            <img src={'/img/icons/discuss-green.svg'} alt={ALT_ICON} />
                                            {LOGIN_CONTENT_FAQ}
                                        </Link>
                                    </div>
                                ) : null
                            */}
                        </form>
                    </div>
                </div>
            )
        )
    }
}

const mapStateToProps = (state) => {
    return {
        fullname: getFullname(state.userReducer.user, (state.userReducer.user && state.userReducer.user.user_data ? state.userReducer.user.user_data : null)),
        user: state.userReducer.user,
        loginModalOpened: state.modalReducer.loginModal,
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        login,
        loginSuccessfull,
        loginError,
        getUserHandling
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(LoginContent));
