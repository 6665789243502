import React, { Component } from 'react';
import {ALT_ICON, ADD, LOADING, MUSIC_GENRE, REQUIRED_TEXT_FIELD, NOTES, CHANGE_PHOTO,
    EVENT_MODAL_CONTENT_CHANGE_PHOTO_EVENT, ORGANIZER_NAME, START_DATE_TIME_EVENT, 
    END_DATE_TIME_EVENT, PHOTOGRAPHY_TRANSLATE, EVENT_MODAL_CONTENT_ADD_PHOTO_EVENT, 
    EVENT_STREET_TITLE, EVENT_CITY_TITLE, EVENT_COUNTRY_TITLE, EVENT_CHANGE_MAP_LOCATION, 
    EVENT_ADD_MAP_LOCATION, CHANGE_PHOTOS_GALLERY, ADD_PHOTOS_GALLERY, UPLOAD_DELETE_ERROR, 
    UPLOAD_DELETE, REQUIRED_TEXT_FIELD_LOWERCASE, REQUIRED_STREET_STRING, REQUIRED_COUNTRY_STRING, 
    REQUIRED_PLACE_STRING, EVENT_END_DATE_INVALID_FIRST_START_DATE, TYPES_OF_FINANCE, DELETE_EVENT_FINANCE,
    EVENT_END_DATE_INVALID_SMALLER_THAN_START_DATE, EVENT_START_DATE_INVALID_SMALLER_THAN_CURRENT_DATE,
    EVENT_START_DATE_INVALID_BIGGER_THAN_END_DATE, PUBLISH_TIMES_OF_PERFORMANCES, EVENT_INTERPRET_TIME_INVALID,
    FIELD_IS_REQUIRED, CREATE_EVENT_BUTTON, CONTENT_LOADING, TRANSLATE_ENTRY, DELETE, PERFORMER, PROGRAM, OFFER_PRICE} from '../../../../config/TextContent';
import {connect} from "react-redux";
import {PropTypes} from 'prop-types';
import Select from 'react-select';
import {bindActionCreators} from "redux";
import TextField from '@material-ui/core/TextField';
import { listToTreeUniqueValues, generateRandomString, getComposedStreet, getUserTypeNumber } from '../../../../client/Helper';
import TreeSelect from 'rc-tree-select';
import ModalStep from '../../../ModalStep/ModalStep';
import Alert from 'react-s-alert';
import {ALERT_SETTINGS} from '../../../../config/Config';
import PerformersList from '../../../Dashboard/Event/PerformersList/PerformersList';
import FinanceList from '../../../Dashboard/Event/CreateEventForm/FinanceList/FinanceList';
import {toggleModalMapEvent, toggleModalEventGallery, toggleModalCreateEvent} from '../../../../actions/ModalActions';
import {setLoadingGetUsersFilter, getUsersFilter, getUsersFilterSuccess} from '../../../../actions/EventActions';
import {getFilterEventCategories, getFilterEventMusicGenres} from '../../CreateEventModal/filters/filters'; 
import { cloneObject } from '../../../../client/Helper';
import './CreateEventModalContent.css';
import EventModalMapDialog from '../../../Dashboard/Event/EventModalMapDialog/EventModalMapDialog';
import EventModalGallery from '../../../Dashboard/Event/EventModalGallery/EventModalGallery';
import GalleryLightBox from 'react-photo-gallery';
import Lightbox from 'react-images';
import {Config} from "../../../../config/Constants";
import GalleryImageControls from '../../../Dashboard/Gallery/EventGalleryImageControls';
import DatePicker, {registerLocale} from "react-datepicker";
import sk from "date-fns/locale/sk";
import LoadingButton from '../../../LoadingButton/LoadingButton';
import "react-datepicker/dist/react-datepicker.css";
import {isAttributeInAddressArray, getAttributeFromAddressArrayWithSeparator, 
    getAttributeFromAddressArray} from '../../../../components/Header/RegistrationModal/RegistrationModal/RegistrationModalHelper';
import {isNotBlankAndNotNull} from '../../../../client/Validation';
import Checkbox from '@material-ui/core/Checkbox';
import NewPerformerItem from '../../../Dashboard/Event/PerformersList/NewPerformerItem/NewPerformerItem';
import customReactSelectStyles from '../../../../config/ReactSelectStyle';
import ProgramList from '../../../Dashboard/Event/ProgramList/ProgramList';
import NewProgramItem from '../../../Dashboard/Event/ProgramList/NewProgramItem/NewProgramItem';

registerLocale("sk", sk);

class CreateEventModalContent extends Component {
    constructor(props, context) {
        super(props, context);
 
        this.state = {
            availableUsers: [],
            currentImage: 0,
            lightboxIsOpen: false,
            tsCategoriesOpen: false,
            tsMusicGenresOpen: false,
            finished: false,
            stepIndex: 0,
            errors: {},
            uploaderFiles: [],
            showNewFinanceItem: true,
            showButtonNewFinanceItem: false,
            showNewPerformerItem: true,
            showNewProgramItem: true,
            type_of_finance_obj: {
                id: '', 
                label: ''
            }, 
            tsAvailableUsersOpen: false,
            typingTimeout: 0,
            eventCategoryParentId: null,
            performer: {
                id: generateRandomString(10),
                selectValue: null,
                user_id: '',
                guestName: '',
                guestDate: null,
                is_main_band: false,
                disableSelect: false,
                errors: {
                    guestName: null,
                    guestDate: null   
                }
            },
            newFinanceItem: {
                name: '',
                price: '',
                errors: {
                    name: null,
                    price: null
                }
            },
            newProgramItem: {
                id: generateRandomString(10),
                date: null,
                name: '',
                errors: {
                    name: null,
                    date: null
                }
            },
            form: {
                form_id: generateRandomString(35),
                isFlagMusicGenres: false,
                eventName: '',
                offerName: '',
                offerPrice: '',
                organizerName: this.getOrganizerName(),
                eventDate: null,
                eventDateEnd: null,
                eventCategory: null,
                eventCategoryName: '',
                eventMusicGenre: null,
                lat: '',
                lng: '',
                performersList: props.modalType === 1 ? [{
                    id: generateRandomString(10),
                    selectValue: {
                        label: props.user.user_data.name,
                        value: props.user.id,
                    },
                    user_id: props.user.id,
                    guestName: props.user.user_data.name,
                    guestDate: null,
                    is_main_band: false,
                    disableSelect: true,
                    errors: {
                        guestName: null,
                        guestDate: null   
                    }
                }] : [],
                programList: [],
                finance_type_id: null,
                financeList: [],
                additionalInformation: '',
                main_photo: null,
                offer_photo_1: null,
                offer_photo_2: null,
                offer_photo_3: null,
                address_components: [],
                place: '',
                place_string: '',
                country_string: '',
                street_string: '',
                place_full_string: '',
                publish_performances_times: false
            },
            hiddenFields:{
                'eventName': false,
                'category': false,
                'file': false,
                'event_date': false,
                'organizer': false,
                'offer': false,
                'performersList': false,
                'financeList': false,
                'gallery': false,
                'submit': false,
            }
        };
        this.markerPositionChanged = this.markerPositionChanged.bind(this);
        this.onSelectAddressChange = this.onSelectAddressChange.bind(this);
        this.onSelectAddressComponents = this.onSelectAddressComponents.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.changeTsAvailableUsersOpen = this.changeTsAvailableUsersOpen.bind(this);
        this.deletePerformer = this.deletePerformer.bind(this);
        this.deleteProgramItem = this.deleteProgramItem.bind(this);
        this.handlePerformerInputChange = this.handlePerformerInputChange.bind(this);
        this.handlePerformerItemChange = this.handlePerformerItemChange.bind(this);
        this.onDateOfPerformerChange = this.onDateOfPerformerChange.bind(this);
        this.onDateOfProgramItemChange = this.onDateOfProgramItemChange.bind(this);
        this.onNameOfProgramItemChange = this.onNameOfProgramItemChange.bind(this);
        this.onDateOfCreatedPerformerChange = this.onDateOfCreatedPerformerChange.bind(this);
        this.onDateOfNewProgramItemChange = this.onDateOfNewProgramItemChange.bind(this);
        this.onNameOfNewProgramItemChange = this.onNameOfNewProgramItemChange.bind(this);
        this.handlePerformerCreateItemChange = this.handlePerformerCreateItemChange.bind(this);
        this.performerIsMainBandChange = this.performerIsMainBandChange.bind(this);
        this.onFinanceListItemTextChange = this.onFinanceListItemTextChange.bind(this);
        this.onShowNewFinanceItem = this.onShowNewFinanceItem.bind(this);
        this.onSinglePriceAsFinanceItemChange = this.onSinglePriceAsFinanceItemChange.bind(this);
        this.onNewFinanceItemTextChange = this.onNewFinanceItemTextChange.bind(this);
        this.deleteFinanceItem = this.deleteFinanceItem.bind(this);
    }

    getOrganizerName(){
        if(this.props.user){
            if(this.props.user.roles.includes("ROLE_CLUB") || this.props.user.roles.includes("ROLE_BAND") || this.props.user.roles.includes("ROLE_PROMOTER"))
                return this.props.user.user_data.name;
            else
                return this.props.user.user_data.name + " " + this.props.user.user_data.surname;
        }
    }

    handleValueChange = value => {
        this.setState({ value });
    };
    
    setVisibleSteps(...inputs){
        const diff = inputs.filter(input => {
            for(let view in this.state.hiddenFields){
                if(view === input)
                    return this.state.hiddenFields[view] !== input
            }
            return null;
        });
        if(diff.length > 0){
            this.setState((prevState) => {
                let hiddenFields = cloneObject(prevState.hiddenFields);
                diff.forEach(el => {
                    hiddenFields[el] = false;
                });
                return {...prevState, hiddenFields};
            });
        }
    }

    getNextDay = () => {
        let date = new Date();
        let next_day = date.setDate(date.getDate()+1);
        return next_day;
    }

    onSelectAddressChange = (value, latlng) => {
        this.setState((prevState) => {
            let form = prevState.form;
            let errors = prevState.errors;
            form.lat = latlng.lat;
            form.lng = latlng.lng;
            form.place_full_string = value;
            errors.lat = null;
            errors.lng = null;

            if(form.street_string.length !== 0)
                errors.street_string = false;
            else    
                errors.street_string = REQUIRED_STREET_STRING; 

            if(form.place_string.length !== 0)
                errors.place_string = false;
            else    
                errors.place_string = REQUIRED_PLACE_STRING; 

            if(form.country_string.length !== 0)
                errors.country_string = false;
            else    
                errors.country_string = REQUIRED_COUNTRY_STRING; 
            return {errors, form};
        });
    }

    onSelectAddressComponents = (address_components) => {
        this.setState(prevState => {
            const form = cloneObject(prevState.form);
            const errors = cloneObject(prevState.errors);
            form.address_components = address_components;
            form.street_string = getComposedStreet(address_components);
            form.country_string = getAttributeFromAddressArray(address_components, 'country');
            form.place_string = getAttributeFromAddressArrayWithSeparator(
                address_components, 
                ',', 
                'sublocality', 
                'locality', 
                'postal_town', 
                'administrative_area_level_3',
                'administrative_area_level_2', 
                'administrative_area_level_1');
            errors.place_string = null;
            errors.street_string = null;
            errors.country_string = null;
            return {
                form,
                errors
            }
        })
    }
    
    onFieldChange = (event, field_name, required=true) => {
        const event_value = event.target.value;
        this.setState((prevState) => {
            let form = {...prevState.form};
            let errors = {...prevState.errors};
            form[field_name] = event_value;
            if(required){
                if(event_value.length !== 0)
                    errors[field_name] = null;
                else
                    errors[field_name] = REQUIRED_TEXT_FIELD_LOWERCASE;
            }else{
                errors[field_name] = null;
            }
            return {errors, form};
        });
    }
    
    onEventMusicGenresChange = (value) => {
        this.setState((prevState) => {
            const form = prevState.form;
            form['eventMusicGenre'] = value;
            return {form};
        });
    };
    
    getCategoryByCategoryId = (id) => {
        if(!id)
            return null;

        let found = null;
        this.props.eventCategories.forEach((element) => {
            if(element.id === id)
                found = element;
        });
        return found;
    }

    onEventCategoryChange = (value) => {
        this.setState((prevState) => {
            const form = prevState.form;
            const category = this.getCategoryByCategoryId(value);
            let newPerformer = {...prevState.performer};
            let newProgramItem = {...prevState.newProgramItem};
            let event_category_parent_id = null;
            if(category && category.parent_id !== null){
                event_category_parent_id = category.parent_id;
            }
            
            form['eventCategory'] = value; 
            form['eventCategoryName'] = '';
            if(category && category.flag === 1)
                form['isFlagMusicGenres'] = true;
            else{
                form['eventMusicGenre'] = null; 
                form['isFlagMusicGenres'] = false;
            }

            if(form['eventCategory'] === 9){
                form['finance_type_id'] = null;
                form['financeList'] = [];
                form['performersList'] = [];
                newPerformer = {
                    id: generateRandomString(10),
                    selectValue: null,
                    user_id: '',
                    guestName: '',
                    guestDate: null,
                    is_main_band: false,
                    disableSelect: false,
                    errors: {
                        guestName: null,
                        guestDate: null   
                    }
                };
                form['programList'] = [];
                newProgramItem = {
                    id: generateRandomString(10),
                    date: null,
                    name: '',
                    errors: {
                        name: null,
                        date: null
                    }
                };
            }else if(form['eventCategory'] === 1 || category.parent_id === 1){
                form['finance_type_id'] = null;
                form['financeList'] = [];
                form['offerName'] = '';
                form['offerPrice'] = '';
                form['offer_photo_1'] = null;
                form['offer_photo_2'] = null;
                form['offer_photo_3'] = null;

                form['programList'] = [];
                newProgramItem = {
                    id: generateRandomString(10),
                    date: null,
                    name: '',
                    errors: {
                        name: null,
                        date: null
                    }
                };
            }else{
                form['finance_type_id'] = null;
                form['financeList'] = [];
                form['performersList'] = [];
                newPerformer = {
                    id: generateRandomString(10),
                    selectValue: null,
                    user_id: '',
                    guestName: '',
                    guestDate: null,
                    is_main_band: false,
                    disableSelect: false,
                    errors: {
                        guestName: null,
                        guestDate: null   
                    }
                };

                form['offerName'] = '';
                form['offerPrice'] = '';
                form['offer_photo_1'] = null;
                form['offer_photo_2'] = null;
                form['offer_photo_3'] = null;
            }

            return {
                ...prevState,
                performer: newPerformer,
                newProgramItem: newProgramItem,
                eventCategoryParentId: event_category_parent_id, 
                form
            };
        });
    };

    onChangePhoto = (event, field_name) => {
        let file = event.target.files[0];
        let reader = new FileReader();

        reader.onloadend = () => {
            let img_src = reader.result
            this.setState((prevState) => {
                let _form = prevState.form;
                _form[field_name] = file;
                _form[field_name]['src'] = img_src;
                return {
                    form: _form
                };
            });
        }
        
        if (file && file.type.match('image.*')) {
            reader.readAsDataURL(file);
        }
    }

    onDateChange = (date, field_name) => {
        let current_date = new Date();
        let current_hour = current_date.getHours();
        let current_minute = current_date.getMinutes();
        let tmstmp_current_date = current_date.setHours(current_hour, current_minute, 0, 0);
        let tmstmp_start_date, tmstmp_end_date;
        this.setState(prevState => {
            let form = {...prevState.form};
            let errors = {...prevState.errors};
            let performer = prevState.performer;
            let newProgramItem = prevState.newProgramItem;
            form[field_name] = date;

            if(form.eventDate)
                tmstmp_start_date = form.eventDate.getTime();
            if(form.eventDateEnd)
                tmstmp_end_date = form.eventDateEnd.getTime();

            if(field_name === "eventDate"){
                if(form.eventDate){
                    if(tmstmp_start_date >= tmstmp_current_date){
                        errors[field_name] = null;
                    }else if(tmstmp_start_date < tmstmp_current_date){
                        errors[field_name] = EVENT_START_DATE_INVALID_SMALLER_THAN_CURRENT_DATE;
                    }else if(tmstmp_end_date && tmstmp_start_date > tmstmp_end_date){
                        errors[field_name] = EVENT_START_DATE_INVALID_BIGGER_THAN_END_DATE;
                    }else
                        errors[field_name] = null;
                }else
                    errors[field_name] = FIELD_IS_REQUIRED;
            }else if(field_name === "eventDateEnd" && form.eventDateEnd){
                if(!form.eventDate)
                    errors[field_name] = EVENT_END_DATE_INVALID_FIRST_START_DATE;
                else if(tmstmp_start_date > tmstmp_end_date)
                    errors[field_name] = EVENT_END_DATE_INVALID_SMALLER_THAN_START_DATE;
                else 
                    errors[field_name] = null;
            }else{
                errors[field_name] = null;
            }

            if(performer.guestDate){
                performer.errors.guestDate = this.eventInterpretTimeError(performer.guestDate, form.eventDate, form.eventDateEnd);
            }
            if(newProgramItem.date){
                newProgramItem.errors.date = this.eventInterpretTimeError(newProgramItem.date, form.eventDate, form.eventDateEnd);
            }

            if(form.performersList.length > 0){
                form.performersList.map((element) => {
                    element.errors.guestDate = this.eventInterpretTimeError(element.guestDate, form.eventDate, form.eventDateEnd);
                    return element;
                });
            }

            if(form.programList.length > 0){
                form.programList.map((element) => {
                    element.errors.date = this.eventInterpretTimeError(element.date, form.eventDate, form.eventDateEnd);
                    return element;
                });
            }

            return {form, errors, performer, newProgramItem}
        }, () => {
            if(this.state.performer.errors.guestName === null && this.state.performer.errors.guestDate === null && this.state.performer.guestName && this.state.performer.guestName !== '' && this.state.performer.guestDate){
                this.createPerformer(cloneObject(this.state.performer));
                this.nullValues();
            }
        });
    }


    eventInterpretTimeError = (event_interpret_time, event_date, event_date_end) => {
        if(event_interpret_time && event_date){
            let event_date_tmsp = event_date.getTime();
            let event_interpret_time_tmsp = event_interpret_time.getTime();
            if(event_interpret_time_tmsp < event_date_tmsp){
                return EVENT_INTERPRET_TIME_INVALID;
            }else if(event_date_end){
                let event_date_end_tmsp = event_date_end.getTime();
                if(event_date_tmsp <= event_date_end_tmsp && event_interpret_time_tmsp > event_date_end_tmsp){
                    return EVENT_INTERPRET_TIME_INVALID;
                }
            }
        }
        return null;
    }

    onAddressChange = (address) => {
        this.setState((prevState) => {
            let _form = prevState.form
            _form.place = address.city
            _form.lat = parseFloat(address.lat)
            _form.lng = parseFloat(address.lng)
            return {form:_form}
        })
    };

    onChange = (event) => {
        const field = event.target.name;
        const field_value = event.target.value;
        this.setState((prevState) => {
            const form = {...prevState.form};
            const errors = prevState.errors;

            form[field] = field_value;
            errors[field] = null;

            return {form,errors};
        });
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage - 1
            }
        });
    }

    gotoNext() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage + 1
            }
        });
    }

    successDeleteImage(removed_img_id){
        this.setState((prevState) => {
            let uploaderFiles = prevState.uploaderFiles
            uploaderFiles.forEach((element, index) => {
                if(removed_img_id === element.id)
                    uploaderFiles.splice(index, 1);
            });
            return { uploaderFiles }
        }, () => {
            Alert.info(UPLOAD_DELETE, ALERT_SETTINGS);
        });
    }

    errorDeleteImage(){
        Alert.info(UPLOAD_DELETE_ERROR, ALERT_SETTINGS);
    }

    updateUploaderFiles(response) {
        this.setState((prevState) => {
            let new_uploaderFiles = prevState.uploaderFiles;
            new_uploaderFiles.push(
                {
                    src: response.link, 
                    uuid: response.uuid, 
                    id: response.id, 
                    qquuid: response.uuid,
                    successDeleteImage: () => this.successDeleteImage(response.id),
                    errorDeleteImage: () => this.errorDeleteImage()
                }
            );
            return {
                uploaderFiles: new_uploaderFiles
            }
        });
    }

    onCreateEventMapModal = () => {
        this.props.toggleModalMapEvent(true, true);
    }

    markerPositionChanged = (lat, lng) => {
        this.setState(prevState => {
            const form = cloneObject(prevState.form);
            form.lat = lat;
            form.lng = lng;
            return {
                form
            }
        })
    }

    onPublishTimesOfPerformancesChange = () => {
        this.setState(prevState => {
            let form = {...prevState.form};
            form.publish_performances_times = !prevState.form.publish_performances_times;
            return {...prevState, form};
        });
    }

    performerIsMainBandChange = (event) => {
        const event_target_value = event.target.value;
        this.setState(prevState => {
            let form = {...prevState.form};
            let performer = {...prevState.performer};
            if(performer.id === event_target_value){
                performer.is_main_band = true;    
            }else{
                performer.is_main_band = false;    
            }

            form.performersList.map((element) => {
                if(element.id === event_target_value){
                    element.is_main_band = true;
                }else{
                    element.is_main_band = false;
                }
                return element;
            });

            return {
                ...prevState,
                form,
                performer
            };
        });
    }

    deletePerformer = (id) => {
        this.setState(prevState => {
            let form = {...prevState.form};
            const removeIndex = form.performersList.findIndex((element) => element.id === id);
            if(removeIndex !== -1)
                form.performersList.splice(removeIndex, 1);
            return {
                ...prevState, 
                form
            };
        });
    }

    changeTsAvailableUsersOpen = (value) => {
        this.setState(prevState => {
            let tsAvailableUsersOpen = false;
            if(value === true)
                tsAvailableUsersOpen = true;

            return {
                ...prevState,
                tsAvailableUsersOpen
            }
        });
    }

    setPerformer = (actId, item) => {
        this.setState(prevState => {
            let form = {...prevState.form};
            form.performersList.forEach(element => {
                if(element.id === actId){
                    element.id = item.id;
                    element.guestName = item.guestName;
                    element.guestDate = item.guestDate;
                    element.errors = item.errors;
                }
            });
            return {
                ...prevState,
                form
            }
        });
    }

    handlePerformerItemChange = (newValue, element) => {
        let newObj = element;
        newObj.selectValue = {};
        newObj.user_id = newValue && newValue.hasOwnProperty("user_id") ? newValue.user_id : null;
        newObj.guestName = newValue && newValue.hasOwnProperty("guestName") ? newValue.guestName : '';
        if(!newObj.guestName)
            newObj.guestName = newValue && newValue.hasOwnProperty("label") ? newValue.label : '';
        newObj.selectValue.label = newObj.guestName;
        newObj.id = newValue && newValue.hasOwnProperty("id") ? newValue.id : generateRandomString(10);
        newObj.selectValue.value = newObj.id;
        
        if(newValue && newValue.hasOwnProperty("label") && newValue.hasOwnProperty("value") && newValue.value === newValue.label){
            //case when typing without choosing from DB
            //change ID to unique
            newObj.id = generateRandomString(10);
            newObj.selectValue.value = newObj.id;
        }
    
        //set null if newValue is null
        newObj.selectValue = newValue === null ? null : newObj.selectValue;
        newObj.errors.guestName = !isNotBlankAndNotNull(newObj.guestName) ? REQUIRED_TEXT_FIELD : null;
        this.setPerformer(element.id, newObj);
    };    

    handlePerformerInputChange = (inputValue) => {
        if (this.state.typingTimeout)
            clearTimeout(this.state.typingTimeout);
        let availableUsers = this.state.availableUsers;
        const typingTimeout = setTimeout(() => {
            this.props.setLoadingGetUsersFilter();
            this.props.getUsersFilter(inputValue).then((data) => {
                availableUsers = data.payload.data; 
                this.setState({availableUsers});   
                this.props.getUsersFilterSuccess();
            });
        }, 300);
        this.setState({typingTimeout});        
    }

    onDateOfCreatedPerformerChange = (date) => {
        this.setState(prevState => {          
            let form = {...prevState.form};
            let performer = prevState.performer;
            performer.guestDate = date;
            performer.errors.guestDate = this.eventInterpretTimeError(date, form.eventDate, form.eventDateEnd);

            return {
                ...prevState,
                performer
            }
        }, () => {
            if(this.state.performer.errors.guestName === null && this.state.performer.errors.guestDate === null && this.state.performer.guestName && this.state.performer.guestName !== ''){
                this.createPerformer(cloneObject(this.state.performer));
                this.nullValues();
            }
        });
    }

    onDateOfPerformerChange = (date, id) => {
        this.setState(prevState => {          
            let performersList = prevState.form.performersList;
            if(performersList && performersList.length > 0){
                performersList.map((element) => {
                    if(element.id === id){
                        element.guestDate = date; 
                        element.errors.guestDate = this.eventInterpretTimeError(date, prevState.form.eventDate, prevState.form.eventDateEnd);
                    }
                    return element;
                });
            }
            return {
                ...prevState,
                form: {
                    ...prevState.form,
                    performersList
                }
            }
        });
    }
    

    handlePerformerCreateItemChange = (newValue) => {
        this.setState(prevState => {
            let newObj = {...prevState.performer};
            newObj.selectValue = {};
            newObj.user_id = newValue && newValue.hasOwnProperty("user_id") ? newValue.user_id : null;
            newObj.guestName = newValue && newValue.hasOwnProperty("guestName") ? newValue.guestName : '';
            if(!newObj.guestName)
                newObj.guestName = newValue && newValue.hasOwnProperty("label") ? newValue.label : '';
            newObj.selectValue.label = newObj.guestName;
            newObj.selectValue.value = newObj.id;
            newObj.id = newValue && newValue.hasOwnProperty("id") ? newValue.id : generateRandomString(10);
            
            if(newValue && newValue.hasOwnProperty("label") && newValue.hasOwnProperty("value") && newValue.value === newValue.label){
                //case when typing without choosing from DB
                //change ID to unique
                newObj.id = generateRandomString(10);
                newObj.selectValue.value = newObj.id;
            }
    
            //set null if newValue is nullonPerformerCreateItemSubmit
            newObj.selectValue = newValue === null ? null : newObj.selectValue;
            newObj.errors.guestName = !isNotBlankAndNotNull(newObj.guestName) ? REQUIRED_TEXT_FIELD : null;
            
            return {
                ...prevState,
                performer: newObj
            }
        }, () => {
            if(this.state.performer.errors.guestName === null && this.state.performer.errors.guestDate === null){
                this.createPerformer(cloneObject(this.state.performer));
                this.nullValues();
            }
        });
    };

    createPerformer = (item) => {
        this.setState(prevState => {
            let form = {...prevState.form};
            if(form.performersList.length > 0)
                form.performersList.push(item);
            else
                form.performersList = [item];
            return { 
                ...prevState,
                form,
                showNewPerformerItem: true,
            };
        });
    }

    nullValues = () => {
        this.setState(prevState =>{
            let performer = {...prevState.performer};
            performer.guestName = '';
            performer.guestDate = '';
            performer.errors.guestName = null;
            performer.errors.guestDate = null;
            performer.selectValue = null;
            performer.is_main_band = false;
            performer.id = generateRandomString(10);
            performer.user_id = '';
            performer.disableSelect = false;
            return {...prevState, performer};
        });
    }

    /* finance items */
    onSinglePriceAsFinanceItemChange(event){
        const event_target_value = event.target.value;
        this.setState(prevState => {
            let form = {...prevState.form};
            form.financeList[0].price = event_target_value;
            form.financeList[0].errors = {price: !Config.isFinanceItemValid(event_target_value) ? REQUIRED_TEXT_FIELD : null}

            return {
                ...prevState,
                form
            }
        });
    }

    setFinanceItem(item){
        this.setState(prevState => {
            let form = {...prevState.form};
            form.financeList.forEach(element => {
                if(element.id === item.id){
                    element.name = item.name;
                    element.price = item.price;
                    element.errors = item.errors;
                }
            });
            return {
                ...prevState,
                form
            }
        });
    }

    updateFinanceItem(id, input_name, input_value){
        let element = {};
        this.state.form.financeList.forEach((el) => {
            if(el.id === id){
                element = el;
            }
        });
        
        element[input_name] = input_value;
        element.errors[input_name] = !Config.isFinanceItemValid(element[input_name]) ? REQUIRED_TEXT_FIELD : null;
        this.setFinanceItem(element);
    }

    onFinanceListItemTextChange = (event, id) => {
        this.updateFinanceItem(id, event.target.name, event.target.value);
    }

    deleteFinanceItem = (id) => {
        this.setState(prevState => {
            let form = {...prevState.form};
            const removeIndex = form.financeList.findIndex((element) => element.id === id);
            if(removeIndex !== -1){
                form.financeList.splice(removeIndex, 1);
            }
            return {
                ...prevState,
                form
            }
        });
    }

    onNewFinanceItemTextChange = (event) => {
        const event_target_name = event.target.name;
        const event_target_value = event.target.value;
        this.setState(prevState => {
            let newFinanceItem = {...prevState.newFinanceItem};
            newFinanceItem[event_target_name] = event_target_value;
            newFinanceItem.errors[event_target_name] = !Config.isFinanceItemValid(event_target_value) ? REQUIRED_TEXT_FIELD : null;
            return {
                ...prevState,
                newFinanceItem
            }
        }, () => {
            if(this.state.newFinanceItem.errors.name === null && this.state.newFinanceItem.errors.price === null && 
                this.state.newFinanceItem.name.length > 0 && this.state.newFinanceItem.price.length > 0) {
                    this.addFinanceItemToFinanceList(this.state.newFinanceItem); 
            }
        });
    }

    addFinanceItemToFinanceList = (newObject) => {
        if(!newObject.errors.price && !newObject.errors.name){
            this.setState(prevState => {
                let form = {...prevState.form};
                let newFinanceItem = {...prevState.newFinanceItem};
                newFinanceItem.name = '';
                newFinanceItem.price = '';
                newFinanceItem.errors = { name: null, price: null };

                let createdFinanceItem = {
                    id: generateRandomString(25), 
                    name: newObject.name,
                    price: newObject.price, 
                    errors: { name: null, price: null }
                };

                form.financeList.push(createdFinanceItem);
                
                return {
                    ...prevState,
                    form, 
                    newFinanceItem,
                    showButtonNewFinanceItem: true,
                    showNewFinanceItem: false
                };
            });
        }
    }

    onShowNewFinanceItem = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                showNewFinanceItem: true,
                showButtonNewFinanceItem: false
            }
        });
    }

    onTypeOfFinanceChange = (value) => {
        this.setState((prevState) => {
            let type_of_finance_obj = value;
            let form = {...prevState.form};
            let newFinanceItem = {...prevState.newFinanceItem};
            if(form.finance_type_id !== value.id){
                form.finance_type_id = value.id;
                newFinanceItem = {
                    name: '',
                    price: '',
                    errors: {
                        name: null,
                        price: null,
                    }
                };
                if(form.finance_type_id === 2){
                    form.financeList = [
                        {
                            id: generateRandomString(25), 
                            price: '', 
                            errors: { price: null }
                        }
                    ];
                }else{
                    form.financeList = [];
                }
            }
            
            return {
                ...prevState,
                type_of_finance_obj,
                newFinanceItem,
                form
            }
        });
    }

    deleteEventFinance = () => {
        this.setState((prevState) => {
            let form = {...prevState.form};
            form.financeList = [];
            form.finance_type_id = null;
            return {
                ...prevState,
                type_of_finance_obj: {
                    id: '', 
                    label: ''
                },
                newFinanceItem: {
                    name: '',
                    price: '',
                    errors: {
                        name: null,
                        price: null
                    }   
                },
                form
            }
        });
    }

    deleteChosenOfferPhoto = (field_name) => {
        this.setState((prevState) => {
            let form = {...prevState.form};
            form[field_name] = null;
            return {form};
        })
    }

    onDateOfProgramItemChange = (date, id) => {
        this.setState(prevState => {          
            let programList = prevState.form.programList;
            if(programList && programList.length > 0){
                programList.map((element) => {
                    if(element.id === id){
                        element.date = date; 
                        element.errors.date = this.eventInterpretTimeError(date, prevState.form.eventDate, prevState.form.eventDateEnd);
                    }
                    return element;
                });
            }

            return {
                ...prevState,
                form: {
                    ...prevState.form,
                    programList
                }
            }
        });
    }

    onNameOfProgramItemChange = (event, id) => {
        const event_value = event.target.value;
        this.setState((prevState) => {
            let programList = prevState.form.programList;
            if(programList && programList.length > 0){
                programList.map((element) => {
                    if(element.id === id){
                        element.name = event_value; 

                        if(event_value.length > 0)
                            element.errors.name = null;
                        else
                            element.errors.name = REQUIRED_TEXT_FIELD_LOWERCASE;
                    }
                    return element;
                });
            }
            return {
                ...prevState,
                form: {
                    ...prevState.form,
                    programList
                }
            }
        });
    }

    createProgramItem = () => {
        this.setState(prevState => {
            let form = {...prevState.form};
            let newProgramItem = cloneObject(prevState.newProgramItem);
            if(form.programList.length > 0)
                form.programList.push(newProgramItem);
            else
                form.programList = [newProgramItem];
            return { 
                ...prevState,
                form,
                showNewProgramItem: true,
            };
        }, () => {
            this.nullProgramValues();
        });
    }

    deleteProgramItem = (id) => {
        this.setState(prevState => {
            let form = {...prevState.form};
            const removeIndex = form.programList.findIndex((element) => element.id === id);
            if(removeIndex !== -1)
                form.programList.splice(removeIndex, 1);
            return {
                ...prevState, 
                form
            };
        });
    }

    nullProgramValues = () => {
        this.setState(prevState =>{
            let newProgramItem = {...prevState.newProgramItem};
            newProgramItem.id = generateRandomString(10);
            newProgramItem.name = '';
            newProgramItem.date = null;
            newProgramItem.errors.name = null;
            newProgramItem.errors.date = null;
            return {...prevState, newProgramItem};
        });
    }

    onDateOfNewProgramItemChange = (date) => {
        this.setState(prevState => {          
            let newProgramItem = prevState.newProgramItem;
            newProgramItem.date = date;
            newProgramItem.errors.date = this.eventInterpretTimeError(date, prevState.form.eventDate, prevState.form.eventDateEnd);

            return {
                ...prevState,
                newProgramItem
            }
        });
    }

    onNameOfNewProgramItemChange = (event) => {
        const event_value = event.target.value;
        this.setState((prevState) => {
            let newProgramItem = prevState.newProgramItem;
            newProgramItem.name = event_value;
            if(event_value.length > 0)
                newProgramItem.errors.name = null;
            else
                newProgramItem.errors.name = REQUIRED_TEXT_FIELD_LOWERCASE;

            return {
                ...prevState,
                newProgramItem
            }
        });
    }

    render(){
        let formStepsCounter = 5;

        return (
            <div className="create-event-modal-content">
                <EventModalMapDialog 
                    step={5}
                    place={this.state.form.place_full_string}
                    onSelectAddressChange={this.onSelectAddressChange}
                    onSelectAddressComponents={this.onSelectAddressComponents}
                    markerPositionChanged={this.markerPositionChanged}
                    markerLatLng={{ lat: this.state.form.lat, lng: this.state.form.lng}}
                    centerLatLng={{ lat: this.state.form.lat, lng: this.state.form.lng}}
                />
                <div>
                    <ModalStep
                        step={1}
                        stepTextClass="create-event-modal-content__modal-step"
                        stepImgClass="create-event-modal-content__modal-img-step"
                        content={"Názov eventu"}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <TextField
                            required
                            spellCheck="false"
                            autoComplete="off"
                            autoCorrect="off"
                            type="text"
                            name="eventName"
                            value={this.state.form.eventName}
                            className={this.state.errors.eventName ? "create-event-modal-content__modal-step__input-name label_color_red" : "create-event-modal-content__modal-step__input-name"}
                            label={this.state.errors.eventName ? "Názov (" + this.state.errors.eventName + ")" : "Názov"}
                            fullWidth={false}
                            placeholder="..."
                            onChange={(event) => this.onFieldChange(event, "eventName")}
                        />
                    </ModalStep>
                    <ModalStep
                        step={2}
                        stepTextClass="create-event-modal-content__modal-step"
                        stepImgClass="create-event-modal-content__modal-img-step"
                        content={"Kategória eventu"}
                        hiddenFields={this.state.hiddenFields}
                        >
                           <div className="tree-select-wrapper auto-width">
                                <TreeSelect
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    transitionName="rc-tree-select-dropdown-slide-up"
                                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                    dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                    placeholder="..."
                                    showSearch={false} 
                                    inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate-white.svg'} className="treeselect-arrow-down" alt={ALT_ICON} />}
                                    allowClear treeLine
                                    value={this.state.form.eventCategory}
                                    treeData={listToTreeUniqueValues(this.props.eventCategories)}
                                    treeNodeFilterProp="title"
                                    filterTreeNode={false}
                                    treeDefaultExpandAll={true}
                                    open={this.state.tsCategoriesOpen}
                                    notFoundContent={LOADING}
                                    onChange={(value, ...args) => {
                                        if (value === this.state.form.eventCategory)
                                            this.setState({ tsCategoriesOpen: true });
                                        else
                                            this.setState({ tsCategoriesOpen: false });

                                        this.onEventCategoryChange(value);
                                    }}
                                    onDropdownVisibleChange={(v, info) => {

                                        this.setState({
                                            tsCategoriesOpen: v,
                                        });

                                        return true;
                                    }}
                                /> 
                                { 
                                    this.state.errors.eventCategory ? 
                                    <div>
                                        <span className="error-style-input">{REQUIRED_TEXT_FIELD}</span>
                                    </div>
                                    : ''
                                }
                            </div>
                            {this.state.form.eventCategory === 12 ? (
                                <TextField
                                    spellCheck="false"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    type="text"
                                    name="eventCategoryName"
                                    value={this.state.form.eventCategoryName}
                                    className={this.state.errors.eventCategoryName ? "create-event-modal-content__modal-step__input-name label_color_red" : "create-event-modal-content__modal-step__input-name"}
                                    label={this.state.errors.eventCategoryName ? "Názov (" + this.state.errors.eventCategoryName + ")" : "Iné"}
                                    fullWidth={false}
                                    placeholder="..."
                                    onChange={(event) => this.onFieldChange(event, "eventCategoryName", false)}
                                />
                            ) : null}
                            <div className={"tree-select-wrapper " + (!this.state.form.isFlagMusicGenres ? 'display-none' : '')}>
                                <TreeSelect
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    transitionName="rc-tree-select-dropdown-slide-up"
                                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                    dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                    placeholder={MUSIC_GENRE}
                                    showSearch={false} 
                                    allowClear treeLine
                                    value={this.state.form.eventMusicGenre}
                                    inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate-white.svg'} className="treeselect-arrow-down" alt={ALT_ICON} />}
                                    treeData={listToTreeUniqueValues(this.props.eventMusicGenres)}
                                    treeNodeFilterProp="title"
                                    filterTreeNode={false}
                                    treeDefaultExpandAll={false}
                                    open={this.state.tsMusicGenresOpen}
                                    notFoundContent={LOADING}
                                    onChange={(value) => {
                                        if (value === this.state.form.eventMusicGenres)
                                            this.setState({ tsMusicGenresOpen: true });
                                        else
                                            this.setState({ tsMusicGenresOpen: false });

                                        this.onEventMusicGenresChange(value);
                                    }}
                                    onDropdownVisibleChange={(v) => {
                                        this.setState({
                                            tsMusicGenresOpen: v,
                                        });
                                        return true;
                                    }}
                                /> 
                                { 
                                    this.state.errors.eventMusicGenre ? 
                                    <div>
                                        <span className="error-style-input">{REQUIRED_TEXT_FIELD}</span>
                                    </div>
                                    : ''
                                }
                            </div>
                    </ModalStep>
                    <ModalStep
                        step={3}
                        stepTextClass="create-event-modal-content__modal-step"
                        stepImgClass="create-event-modal-content__modal-img-step"
                        content={"Fotografia eventu"}
                        hiddenFields={this.state.hiddenFields}
                    >
                        { 
                            this.state.errors.main_photo ? 
                            <div>
                                <span className="error-style-input">{REQUIRED_TEXT_FIELD}</span>
                            </div>
                            : ''
                        }
                        {
                            this.state.form.main_photo && this.state.form.main_photo.src ?
                                <img accept="image/jpeg,image/jpg,image/png" alt={PHOTOGRAPHY_TRANSLATE} src={this.state.form.main_photo.src} className="createEventModal__main-photo"/>
                            : 
                                null
                        }
                        <label htmlFor="photo_event"
                            className="default_transparent_white_border_button">
                            {
                                this.state.form.main_photo && this.state.form.main_photo.src ?
                                    <div>
                                        <img src={'/img/icons/add-image.svg'} alt={ALT_ICON}/>
                                        <span>{EVENT_MODAL_CONTENT_CHANGE_PHOTO_EVENT}</span>
                                    </div>
                                :
                                    <div>
                                        <img src={'/img/icons/add-image.svg'} alt={ALT_ICON}/>
                                        <span>{EVENT_MODAL_CONTENT_ADD_PHOTO_EVENT}</span>
                                    </div>
                            }
                            <input type="file" name="file" id="photo_event" onChange={(event) => {this.onChangePhoto(event, 'main_photo')}} style={{display: 'none'}}/>
                        </label>
                    </ModalStep>
                    <ModalStep
                        step={4}
                        stepTextClass="create-event-modal-content__modal-step"
                        stepImgClass="create-event-modal-content__modal-img-step"
                        content={"Dátum a čas eventu"}
                        hiddenFields={this.state.hiddenFields}
                    >
                        <div className="createEventModalContent__dateTime">
                            <div>
                                <p className={this.state.errors.eventDate ? "createEventModalContent__textfield label_color_red" : "createEventModalContent__textfield"}>
                                    {this.state.errors.eventDate ? START_DATE_TIME_EVENT + " (" + this.state.errors.eventDate + ")" : START_DATE_TIME_EVENT}
                                </p>
                                <DatePicker
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    selected={this.state.form.eventDate}
                                    disabledKeyboardNavigation
                                    minDate={this.getNextDay()}
                                    onChange={(date) => {this.onDateChange(date, "eventDate")}}
                                    showTimeSelect
                                    timeIntervals="60"
                                    timeFormat="HH:mm"
                                    locale="sk"
                                />
                            </div>
                        </div>
                        <div className="createEventModalContent__dateTime">
                            <div>
                                <p className={this.state.errors.eventDateEnd ? "createEventModalContent__textfield label_color_red" : "createEventModalContent__textfield"}>
                                    {this.state.errors.eventDateEnd ? END_DATE_TIME_EVENT + " (" + this.state.errors.eventDateEnd + ")" : END_DATE_TIME_EVENT}
                                </p>
                                <DatePicker
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    selected={this.state.form.eventDateEnd}
                                    minDate={this.state.form.eventDate ? this.state.form.eventDate : this.getNextDay()}
                                    onChange={(date) => {this.onDateChange(date, "eventDateEnd")}}
                                    showTimeSelect
                                    disabledKeyboardNavigation
                                    timeIntervals="60"
                                    timeFormat="HH:mm"
                                    locale="sk"
                                />
                            </div>
                        </div>
                    </ModalStep>
                    {
                        this.props.modalType !== 2 && (
                            <ModalStep
                                step={formStepsCounter++}
                                stepTextClass="create-event-modal-content__modal-step"
                                stepImgClass="create-event-modal-content__modal-img-step"
                                content={"Miesto eventu a organizátor"}
                                hiddenFields={this.state.hiddenFields}
                            >
                                <div className="createEventModalContent__eventPlace createEventModalContent__organizer">
                                    <div className="createEventModalContent__organizer-name">
                                        <TextField
                                            name="organizerName"
                                            className={this.state.errors.organizerName ? "createEventModalContent__organizer__textfield label_color_red" : "createEventModalContent__organizer__textfield"}
                                            disabled={true}
                                            label={this.state.errors.organizerName ? ORGANIZER_NAME + " (" + this.state.errors.organizerName + ")" : ORGANIZER_NAME}
                                            value={this.state.form.organizerName}
                                            error={this.state.errors.organizerName ? true : false}
                                            onChange={(event) => this.onFieldChange(event, "organizerName")}
                                        />
                                    </div>
                                </div>
                                
                                <div>
                                    <div className="create-event-modal-content__modal-step__map-content">
                                        <div className="row padding-bottom-10">
                                            <div className="col-md-4 col-xs-2">
                                                <img src={'/img/icons/street.svg'} alt={ALT_ICON} className="create-event-modal-content__modal-step__map-content__icon"/>
                                                <p className="create-event-modal-content__modal-step__map-content__place">{EVENT_STREET_TITLE} </p>
                                            </div>
                                            <div className="col-md-8 col-xs-10 no-padding-left">
                                                { 
                                                    !this.state.errors.street_string ? (
                                                        !isAttributeInAddressArray(this.state.form.address_components, 'route', 'premise', 'street_number') ?
                                                            <div className="create-event-modal-content__modal-step__map-content__block-border"></div>:
                                                            <p className="create-event-modal-content__modal-step__map-content__location-name">
                                                                { getComposedStreet(this.state.form.address_components) }
                                                            </p>
                                                    ) : 
                                                    <p className="create-event-modal-content__modal-step__map-content__error">{this.state.errors.street_string} *</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="row padding-bottom-10">
                                            <div className="col-md-4 col-xs-2">
                                                <img src={'/img/icons/city.svg'} alt={ALT_ICON} className="create-event-modal-content__modal-step__map-content__icon"/>
                                                <p className="create-event-modal-content__modal-step__map-content__place">{EVENT_CITY_TITLE} </p>
                                            </div>
                                            <div className="col-md-8 col-xs-10 no-padding-left">
                                                { 
                                                    !this.state.errors.place_string ? (
                                                        !isAttributeInAddressArray(this.state.form.address_components, 'administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3', 'postal_town', 'locality', 'sublocality') ?
                                                            <div className="create-event-modal-content__modal-step__map-content__block-border"></div>:
                                                            <p className="create-event-modal-content__modal-step__map-content__location-name">
                                                                { getAttributeFromAddressArrayWithSeparator(this.state.form.address_components, ',', 'sublocality', 'locality', 'postal_town', 'administrative_area_level_3', 'administrative_area_level_2', 'administrative_area_level_1') }
                                                            </p>
                                                    ) : 
                                                    <p className="create-event-modal-content__modal-step__map-content__error">{this.state.errors.place_string} *</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="row padding-bottom-10">
                                            <div className="col-md-4 col-xs-2">
                                                <img src={'/img/icons/flag.svg'} alt={ALT_ICON} className="create-event-modal-content__modal-step__map-content__icon"/>
                                                <p className="create-event-modal-content__modal-step__map-content__place">{EVENT_COUNTRY_TITLE} </p>
                                            </div>
                                            <div className="col-md-8 col-xs-10 no-padding-left">
                                                { 
                                                    !this.state.errors.country_string ? (
                                                        !isAttributeInAddressArray(this.state.form.address_components, 'country') ?
                                                            <div className="create-event-modal-content__modal-step__map-content__block-border"></div>:
                                                            <p className="create-event-modal-content__modal-step__map-content__location-name">
                                                                { getAttributeFromAddressArray(this.state.form.address_components, 'country')}
                                                            </p>
                                                    ) : 
                                                    <p className="create-event-modal-content__modal-step__map-content__error">{this.state.errors.country_string} *</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <button 
                                        type="button"
                                        onClick={this.onCreateEventMapModal} 
                                        className="default_transparent_white_border_button">
                                            <img src={'/img/icons/marker-green.svg'} alt={ALT_ICON}/>
                                            <span>{ this.state.form.place_full_string ? EVENT_CHANGE_MAP_LOCATION : EVENT_ADD_MAP_LOCATION }</span>
                                    </button>
                                </div>
                            </ModalStep>
                        )
                    }
                    {this.state.form.eventCategory && this.state.form.eventCategory === 9 ? (
                        <ModalStep
                            step={formStepsCounter++}
                            stepTextClass="create-event-modal-content__modal-step"
                            stepImgClass="create-event-modal-content__modal-img-step"
                            content={"Ponuka"}
                            hiddenFields={this.state.hiddenFields}
                        >
                            <TextField
                                spellCheck="false"
                                autoComplete="off"
                                autoCorrect="off"
                                type="text"
                                name="Názov ponuky"
                                value={this.state.form.offerName}
                                className="create-event-modal-content__modal-step__input-name"
                                label="Názov ponuky"
                                fullWidth={false}
                                placeholder="..."
                                onChange={(event) => this.onFieldChange(event, "offerName", false)}
                            />
                            <div className="row createEventModal__offer-photos">
                                <div className="col-md-4">
                                    <label htmlFor="offer_photo_1" className="default_transparent_white_border_button">
                                        {this.state.form.offer_photo_1 && this.state.form.offer_photo_1.src ? (
                                            <div>
                                                <span>{CHANGE_PHOTO}</span>
                                            </div>
                                        ) : (
                                            <div>
                                                <span>{ADD}</span>
                                            </div>
                                        )}
                                        <input type="file" name="file" id="offer_photo_1" onChange={(event) => {this.onChangePhoto(event, 'offer_photo_1')}} style={{display: 'none'}}/>
                                    </label>
                                    {this.state.form.offer_photo_1 && this.state.form.offer_photo_1.src ?
                                        <img accept="image/jpeg,image/jpg,image/png" alt={PHOTOGRAPHY_TRANSLATE} src={this.state.form.offer_photo_1.src} className="createEventModal__offer-photo"/>
                                    : null}
                                    {this.state.form.offer_photo_1 && this.state.form.offer_photo_1.src ? (
                                        <button type="button" onClick={() => this.deleteChosenOfferPhoto('offer_photo_1')} className="createEventModal__offer-photos__delete">{DELETE}</button>
                                    ) : null}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="offer_photo_2" className="default_transparent_white_border_button">
                                        {this.state.form.offer_photo_2 && this.state.form.offer_photo_2.src ? (
                                            <div>
                                                <span>{CHANGE_PHOTO}</span>
                                            </div>
                                        ) : (
                                            <div>
                                                <span>{ADD}</span>
                                            </div>
                                        )}
                                        <input type="file" name="file" id="offer_photo_2" onChange={(event) => {this.onChangePhoto(event, 'offer_photo_2')}} style={{display: 'none'}}/>
                                    </label>
                                    {this.state.form.offer_photo_2 && this.state.form.offer_photo_2.src ?
                                        <img accept="image/jpeg,image/jpg,image/png" alt={PHOTOGRAPHY_TRANSLATE} src={this.state.form.offer_photo_2.src} className="createEventModal__offer-photo"/>
                                    : null}
                                    {this.state.form.offer_photo_2 && this.state.form.offer_photo_2.src ? (
                                        <button type="button" onClick={() => this.deleteChosenOfferPhoto('offer_photo_2')} className="createEventModal__offer-photos__delete">Odstrániť</button>
                                    ) : null}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="offer_photo_3" className="default_transparent_white_border_button">
                                        {this.state.form.offer_photo_3 && this.state.form.offer_photo_3.src ? (
                                            <div>
                                                <span>{CHANGE_PHOTO}</span>
                                            </div>
                                        ) : (
                                            <div>
                                                <span>{ADD}</span>
                                            </div>
                                        )}
                                        <input type="file" name="file" id="offer_photo_3" onChange={(event) => {this.onChangePhoto(event, 'offer_photo_3')}} style={{display: 'none'}}/>
                                    </label>
                                    {this.state.form.offer_photo_3 && this.state.form.offer_photo_3.src ?
                                        <img accept="image/jpeg,image/jpg,image/png" alt={PHOTOGRAPHY_TRANSLATE} src={this.state.form.offer_photo_3.src} className="createEventModal__offer-photo"/>
                                    : null}
                                    {this.state.form.offer_photo_3 && this.state.form.offer_photo_3.src ? (
                                        <button type="button" onClick={() => this.deleteChosenOfferPhoto('offer_photo_3')} className="createEventModal__offer-photos__delete">Odstrániť</button>
                                    ) : null}
                                </div>
                            </div>
                        </ModalStep>
                    ) : null}
                    {this.state.form.eventCategory && (this.state.form.eventCategory === 1 || (this.state.eventCategoryParentId !== null && this.state.eventCategoryParentId === 1)) ? (
                        <ModalStep
                            step={formStepsCounter++}
                            stepTextClass="create-event-modal-content__modal-step"
                            stepImgClass="create-event-modal-content__modal-img-step"
                            content={PERFORMER}
                            hiddenFields={this.state.hiddenFields}
                        >
                            <div className="createEventModalContent__list create-event-modal-content__modal-step__performers">
                                {this.state.form.eventDate ? ( 
                                    <div>
                                        <div className="performer-list__publish-times">
                                            <Checkbox
                                                checked={this.state.form.publish_performances_times}
                                                value={this.state.form.publish_performances_times ? "true" : "false"}
                                                disableRipple={true}
                                                name="publish_performances_times"
                                                className="performer-list__publish-times__checkbox"
                                                onChange={this.onPublishTimesOfPerformancesChange}
                                                color='secondary'
                                            />
                                            <label>{PUBLISH_TIMES_OF_PERFORMANCES}</label>
                                        </div>
                                        <hr className="performer-list__separator" />
                                        <PerformersList
                                            performersList={this.state.form.performersList}
                                            changeTsAvailableUsersOpen={this.changeTsAvailableUsersOpen}
                                            tsAvailableUsersOpen={this.state.tsAvailableUsersOpen}
                                            eventDate={this.state.form.eventDate} 
                                            eventDateEnd={this.state.form.eventDateEnd}
                                            deletePerformer={this.deletePerformer}
                                            availableUsers={this.state.availableUsers}
                                            performer={this.state.performer}
                                            handlePerformerInputChange={this.handlePerformerInputChange}
                                            handlePerformerItemChange={this.handlePerformerItemChange}
                                            onDateOfPerformerChange={this.onDateOfPerformerChange}
                                            performerIsMainBandChange={this.performerIsMainBandChange}
                                        />
                                        {this.state.showNewPerformerItem ? (
                                            <NewPerformerItem
                                                onDateOfCreatedPerformerChange={this.onDateOfCreatedPerformerChange}
                                                performer={this.state.performer}
                                                eventDate={this.state.form.eventDate} 
                                                eventDateEnd={this.state.form.eventDateEnd}
                                                handlePerformerInputChange={this.handlePerformerInputChange}
                                                changeTsAvailableUsersOpen={this.changeTsAvailableUsersOpen}
                                                tsAvailableUsersOpen={this.state.tsAvailableUsersOpen}
                                                loadingAvailableUsers={this.props.loadingAvailableUsers}
                                                availableUsers={this.state.availableUsers}
                                                handlePerformerCreateItemChange={this.handlePerformerCreateItemChange}
                                                performerIsMainBandChange={this.performerIsMainBandChange}
                                            />
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="performer-list__blank-dates">
                                        <p>{"Pre pridanie vystupujúch je potrebné vyplniť dátum a čas začiatku eventu prípadne dátum a čas konca eventu"}</p>
                                    </div>
                                )} 
                            </div>
                        </ModalStep>
                    ) : null}
                    {this.state.form.eventCategory && this.state.form.eventCategory !== 1 && this.state.eventCategoryParentId !== 1 && this.state.form.eventCategory !== 9 ? (
                        <ModalStep
                            step={formStepsCounter++}
                            stepTextClass="create-event-modal-content__modal-step"
                            stepImgClass="create-event-modal-content__modal-img-step"
                            content={PROGRAM}
                            hiddenFields={this.state.hiddenFields}
                        >
                            <div className="createEventModalContent__list create-event-modal-content__modal-step__program">
                                <ProgramList
                                    programList={this.state.form.programList}
                                    eventDate={this.state.form.eventDate}
                                    eventDateEnd={this.state.form.eventDateEnd}
                                    onDateOfProgramItemChange={this.onDateOfProgramItemChange}
                                    onNameOfProgramItemChange={this.onNameOfProgramItemChange}
                                    deleteProgramItem={this.deleteProgramItem}
                                />
                                {this.state.showNewProgramItem ? (
                                    <NewProgramItem 
                                        newProgramItem={this.state.newProgramItem}
                                        eventDate={this.state.form.eventDate}
                                        eventDateEnd={this.state.form.eventDateEnd}
                                        onDateOfNewProgramItemChange={this.onDateOfNewProgramItemChange}
                                        onNameOfNewProgramItemChange={this.onNameOfNewProgramItemChange}
                                        createProgramItem={this.createProgramItem}
                                    />
                                ) : null}
                            </div>
                        </ModalStep>
                    ) : null}
                    {this.state.form.eventCategory && this.state.form.eventCategory === 9 ? (
                         <ModalStep
                            step={formStepsCounter++}
                            stepTextClass="create-event-modal-content__modal-step"
                            stepImgClass="create-event-modal-content__modal-img-step"
                            content={OFFER_PRICE}
                            hiddenFields={this.state.hiddenFields}
                        > 
                            <TextField
                                spellCheck="false"
                                autoComplete="off"
                                autoCorrect="off"
                                type="text"
                                name="offerPrice"
                                value={this.state.form.offerPrice}
                                className="create-event-modal-content__modal-step__input-name"
                                label={OFFER_PRICE}
                                fullWidth={false}
                                placeholder="..."
                                onChange={(event) => this.onFieldChange(event, "offerPrice", false)}
                            />
                        </ModalStep>
                    ) : (
                        <ModalStep
                            step={formStepsCounter++}
                            stepTextClass="create-event-modal-content__modal-step"
                            stepImgClass="create-event-modal-content__modal-img-step"
                            content={TRANSLATE_ENTRY}
                            hiddenFields={this.state.hiddenFields}
                        >
                            <div className="createEventModalContent__list create_event_form_finance_list">
                                <div>
                                    <Select
                                        styles={customReactSelectStyles}
                                        name="type_of_finance"
                                        placeholder={TYPES_OF_FINANCE}
                                        duration={200}
                                        classNamePrefix="styled-tree-select"
                                        className="styled-tree-select finance-list__select-type"
                                        value={this.state.type_of_finance_obj}
                                        onChange={this.onTypeOfFinanceChange}
                                        options={Config.getTypesOfFinance()}
                                    />
                                    {this.state.form.finance_type_id ? (
                                        <button type="button" className="finance-list__button-delete-event-finance" onClick={this.deleteEventFinance}>
                                            <span className="finance-list-item__add-button-text">{DELETE_EVENT_FINANCE}</span>
                                            <span className="finance-list-item__delete-button-icon">
                                                <img src="/img/icons/close-cross.svg" alt={ALT_ICON}/>
                                            </span>
                                        </button>
                                    ) : null}
                                </div>
                                {this.state.form.finance_type_id === 2 || this.state.form.finance_type_id === 3 ? (
                                    <FinanceList 
                                        onShowNewFinanceItem={this.onShowNewFinanceItem}
                                        onSinglePriceAsFinanceItemChange={this.onSinglePriceAsFinanceItemChange}
                                        financeList={this.state.form.financeList}
                                        showNewFinanceItem={this.state.showNewFinanceItem}
                                        showButtonNewFinanceItem={this.state.showButtonNewFinanceItem}
                                        newFinanceItem={this.state.newFinanceItem}
                                        type_of_finance={this.state.form.finance_type_id}
                                        onFinanceListItemTextChange={this.onFinanceListItemTextChange}
                                        onNewFinanceItemTextChange={this.onNewFinanceItemTextChange}
                                        deleteFinanceItem={this.deleteFinanceItem}
                                    />
                                ) : null}
                            </div>
                        </ModalStep>
                    )}
                    {this.state.form.eventCategory && this.state.form.eventCategory !== 9 ? (
                        <ModalStep
                            step={formStepsCounter++}
                            stepTextClass="create-event-modal-content__modal-step"
                            stepImgClass="create-event-modal-content__modal-img-step"
                            content={"Galéria"}
                            hiddenFields={this.state.hiddenFields}
                        >
                            {
                                this.state.uploaderFiles.length > 0 ? 
                                <div>
                                    {
                                        <div> 
                                            <GalleryLightBox 
                                                photos={Config.processUploadFilesBeforeGallery(this.state.uploaderFiles)} 
                                                direction={"row"} 
                                                onClick={this.openLightbox} 
                                                ImageComponent={GalleryImageControls}
                                            />
                                            <Lightbox 
                                                images={Config.processUploadFilesBeforeGallery(this.state.uploaderFiles)}
                                                onClose={this.closeLightbox}
                                                onClickPrev={this.gotoPrevious}
                                                onClickNext={this.gotoNext}
                                                currentImage={this.state.currentImage}
                                                isOpen={this.state.lightboxIsOpen}
                                            />
                                        </div>
                                    }
                                </div>
                                : null
                            }  
                            <button 
                                type="button"
                                onClick={() => this.props.toggleModalEventGallery(true, true)} 
                                className="default_transparent_white_border_button">
                                    <img src={'/img/icons/image.svg'} alt={ALT_ICON}/>
                                    <span>{ this.state.form.uploaderFiles ? CHANGE_PHOTOS_GALLERY : ADD_PHOTOS_GALLERY }</span>
                            </button>
                        </ModalStep>
                    ) : null}
                    <EventModalGallery 
                        step={formStepsCounter-1}
                        uploaderFiles={this.state.uploaderFiles}
                        updateUploaderFiles={(response) => this.updateUploaderFiles(response)}
                        form_id={this.state.form.form_id}
                    />
                    <ModalStep
                        step={formStepsCounter}
                        stepTextClass="create-event-modal-content__modal-step"
                        stepImgClass="create-event-modal-content__modal-img-step"
                        content={"Doplňujúce informácie"}
                        hiddenFields={this.state.hiddenFields}
                    >
                        <div className="createEventModalContent__eventPlace createEventModalContent__organizer">
                            <TextField
                                name="additionalInformation"
                                className="createEventModalContent__organizer__textfield__note"
                                label={NOTES}
                                multiline
                                rows={6}
                                value={this.state.form.additionalInformation}
                                error={this.state.errors.additionalInformation ? true : false}
                                onChange={(event) => this.onChange(event)}
                            />
                        </div>
                    </ModalStep>
                </div> 
                <div className="createEventModalContent__button_submit">
                    <LoadingButton
                        classes="default_transparent_button_important"
                        paragraphClass="pre-register-content__form__button__intro-title"
                        loaderClass="pre-register-content__form__button__react-loading"
                        content={CREATE_EVENT_BUTTON}
                        loadingContent={CONTENT_LOADING}
                        loading={this.props.loading}
                        onClick={() => this.props.submitEvent(this.state.form)}
                    />
                </div>
            </div>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        modalType: getUserTypeNumber(state.userReducer.user),
        userReducer: state.userReducer,
        eventReducer: state.eventReducer,
        user: state.userReducer.user,
        eventCategories: getFilterEventCategories(state.eventReducer.eventCategoriesList),
        eventMusicGenres: getFilterEventMusicGenres(state.eventReducer.eventMusicGenresList),
        loadingAvailableUsers: state.eventReducer.loadingAvailableUsers
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalMapEvent,
        toggleModalEventGallery,
        toggleModalCreateEvent,
        setLoadingGetUsersFilter,
        getUsersFilter,
        getUsersFilterSuccess,
    }, dispatch);
}

CreateEventModalContent.propTypes = {
    eventReducer: PropTypes.shape({
        eventCategoriesList: PropTypes.array.isRequired,
        eventMusicGenresList: PropTypes.array.isRequired,
    })
};

export default connect(mapStateToProps, matchDispatchToProps)(CreateEventModalContent);
