import React from 'react';
import Auth from '../../../client/Auth';
import AuthenticatedNavigation from '../../Header/Navigation/AuthenticatedNavigation/AuthenticatedNavigation';
import AnonymousNavigation from '../../Header/Navigation/AnonymousNavigation/AnonymousNavigation';
import {connect} from "react-redux";
import './Navigation.css';

const Navigation = (props) => {
    return (
        <div className="header__navigation">
            {Auth.isUserAuthenticated(props.userReducer) ? <AuthenticatedNavigation /> : <AnonymousNavigation />}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userReducer: state.userReducer,
    }
}

export default connect(mapStateToProps)(Navigation);
