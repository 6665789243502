import React, { Component } from 'react';
import './EventDataManagementModal.css';
import {toggleModalEventDataManagement, toggleModalEventsByUser, toggleModalUserDataUpdate} from '../../../actions/ModalActions';
import {CLOSE_AND_REJECT_MODAL, CLOSE_MODAL, LOADING_EVENT_DATA} from '../../../config/TextContent';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from 'react-router-dom';
import {PropTypes} from 'prop-types';
import {getUserHandling} from '../../../actions/UserActions';
import EventDataManagementModalContent from './EventDataManagementModalContent/EventDataManagementModalContent';
import EventDataManagementModalHOC from './EventDataManagementModalHOC/EventDataManagementModalHOC';
import {editEvent, editEventSuccess, editEventError, setLoadingGetTopEvents, getTopEvents, getTopEventsSuccess, getTopEventsError, 
    getEventCategoriesSuccess, getEventCategories, getEventCategoriesError} from '../../../actions/EventActions';
import Alert from 'react-s-alert';
import {TEXT_SUCCESSFULLY_EDITED_EVENT, TEXT_ERROR_EDITED_EVENT, TEXT_EDITING_EVENT_WAIT, EVENT_DATA_MANAGEMENT_MODAL_TITLE} from '../../../config/TextContent';
import {ALERT_SETTINGS} from '../../../config/Config';
import {GET_USER_ROUTE_TO_SPECIFIC_EVENT} from '../../../config/RoutesConstants';
import {getUserTypeNumber} from '../../../client/Helper';
import ReactLoading from 'react-loading';
import Fade from 'react-reveal/Fade';
import Button from "@material-ui/core/Button";

class EventDataManagementModal extends Component {
    constructor(props, context){
        super(props, context);

        this.state = {
            loading: false,
        }
    }

    componentDidMount(){
        this.props.getEventCategories().then(() => {
            this.props.getEventCategoriesSuccess();
        }).catch(() => {
            this.props.getEventCategoriesError();
        });
    }

    onEventDataManagementModalClose = () => {
        this.props.toggleModalEventDataManagement(false, true);
        this.props.history.goBack();
    }

    deleteForm = () => {
        this.setState(prevState => {
            return {prevState: {}};
        });
    }

    submitEventForm = (form) => {
        this.setState({loading: true});
        Alert.success(TEXT_EDITING_EVENT_WAIT, ALERT_SETTINGS);
        form.program_items = form.programList;
        this.props.editEvent(form).then(response => {
            if(!response.error){
                this.props.setLoadingGetTopEvents();
                this.props.getTopEvents().then(() => {
                    this.props.getTopEventsSuccess();
                }).catch(() => {
                    this.props.getTopEventsError();
                });
                this.deleteForm();
                this.props.toggleModalEventDataManagement(false, true);
                this.props.toggleModalEventsByUser(false, true);
                this.props.toggleModalUserDataUpdate(false, true);
                this.props.editEventSuccess();
                Alert.success(TEXT_SUCCESSFULLY_EDITED_EVENT, ALERT_SETTINGS);
                this.setState({loading: false});
                this.props.history.replace(GET_USER_ROUTE_TO_SPECIFIC_EVENT(response.payload.data));
            }else {
                Alert.success(TEXT_ERROR_EDITED_EVENT, ALERT_SETTINGS);
                this.props.editEventError();
                this.setState({loading: false});
            }
        });
    };

    render(){
        return (
            <Dialog
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                open={this.props.eventDataManagementModalOpened}
                onClose={this.onEventDataManagementModalClose}
                className='photo-update-modal'
            >
            {
                this.props.loadingSelectedEventToUpdate &&
                <Fade duration={500} delay={250} >
                    <div className="photo-update-modal__content">
                        <div className="photo-update-modal__content-loading-wrapper">
                            <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                            <p className="photo-update-modal__loading-content">{LOADING_EVENT_DATA}</p>
                        </div>
                    </div>
                </Fade> 
            }
            {!this.props.loadingSelectedEventToUpdate && this.props.selectedEventToUpdate &&
                <EventDataManagementModalHOC>
                    <Button onClick={() => this.onEventDataManagementModalClose()} className="photo-update-modal__close-button">
                        <img src={'/img/icons/close-cross.svg'} alt={CLOSE_AND_REJECT_MODAL}/>
                        <span className="modal__close-button__text">{CLOSE_MODAL}</span>
                    </Button>
                    <form action="post">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="update_event_modal__content-wrapper updateEventModal row">
                                    <div className="row">
                                        <div className="col-md-12 padding-left-right-0">
                                            <p className="updateEventModal__left-side__header">{EVENT_DATA_MANAGEMENT_MODAL_TITLE} {this.props.selectedEventToUpdate.event_name}</p>                                         
                                        </div>
                                    </div>

                                    <div className="updateEventModal__content">
                                        <EventDataManagementModalContent 
                                            submitEvent={this.submitEventForm}
                                            loading={this.state.loading}
                                            availableUsers={this.state.availableUsers}
                                            selectedEventToUpdate={this.props.selectedEventToUpdate}
                                            userTypeNumber={getUserTypeNumber(this.props.user)}
                                        /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </EventDataManagementModalHOC>
                }
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        eventDataManagementModalOpened: state.modalReducer.eventDataManagementModalOpened,
        userDataManagementModal: state.modalReducer.userDataManagementModal,
        selectedEventToUpdate: state.eventReducer.selectedEventToUpdate,
        loadingSelectedEventToUpdate: state.eventReducer.loadingSelectedEventToUpdate
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalEventDataManagement,
        toggleModalEventsByUser,
        toggleModalUserDataUpdate,
        getUserHandling,
        editEvent, 
        editEventError, 
        editEventSuccess, 
        setLoadingGetTopEvents,
        getTopEvents,
        getTopEventsSuccess,
        getTopEventsError,
        getEventCategoriesSuccess, 
        getEventCategories,
        getEventCategoriesError
    }, dispatch);
}

EventDataManagementModal.propTypes = {
    toggleModalEventDataManagement: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(EventDataManagementModal));
