import EndpointsUrl from "../client/EndpointsUrl";
import axiosManager from '../client/AxiosManager/AxiosManager';
import { GET } from '../client/AxiosManager/AxiosManagerConstants';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';

export function getCities() {
    return {
        type: GET_CITIES,
        payload: axiosManager(GET, EndpointsUrl.GET_CITIES)
    }
}

export function getCitiesSuccess() {
    return {
        type: GET_CITIES_SUCCESS,
        payload: "Cities successfully loaded."
    }
}

export function getCitiesError() {
    return {
        type: GET_CITIES_ERROR,
        payload: "Error while loading cities."
    }
}