import React, {Component} from 'react';
import './CoverElement.css';
import CoverUserData from './CoverUserData/CoverUserData';
import CoverUpdateUserProfile from './CoverUpdateUserProfile/CoverUpdateUserProfile';
import {Config} from '../../../config/Constants';
import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import {GET_USER_ROUTE_TO_DASHBOARD} from '../../../config/RoutesConstants';
import { withRouter } from 'react-router-dom';
import MenuElementContent from '../MenuElement/MenuElementContent/MenuElementContent';
import {Link} from "react-router-dom";

class CoverElement extends Component {
    constructor(props, context){
        super(props, context);

        this.state = {
            currentImage: 0,
            lightboxIsOpen: false
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
    }
    
    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        }, () => {
            window.location.replace(GET_USER_ROUTE_TO_DASHBOARD(this.props.user));
        });
    }

    gotoPrevious() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage - 1
            }
        });
    }

    gotoNext() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage + 1
            }
        });
    }

    getMainGenre = (genre) => {
        if(genre){
            if(genre.hasOwnProperty("main_category") && genre.main_category === false && genre.hasOwnProperty("main_category_id") && genre.main_category_id !== genre.id){
                let main_genre = null;
                this.props.eventMusicGenresList.forEach(element => {
                    if(element.id === genre.main_category_id){
                        main_genre = element.name;
                    }
                });
                return main_genre;
            }
        }
        return null;
    }

    render(){
        let cover_photo = null;
        let genre = null;
        let category = null;
        let main_genre = null;

        if(this.props.user){
            cover_photo = Config.getUserPhoto(this.props.user, Config.CONSTANT_COVER_PICTURE);
            if(this.props.user.hasOwnProperty('user_data') && this.props.user.user_data && this.props.user.user_data.genre){
                genre = this.props.user.user_data.genre.name;
                main_genre = this.getMainGenre(this.props.user.user_data.genre);
            }
            category = (this.props.user.hasOwnProperty('user_data') && this.props.user.user_data && this.props.user.user_data.type) ? this.props.user.user_data.type.name : null;
        }

        return (
            <div className="dashboard-cover">
                <div className="cover__desktop">
                    {
                        this.props.loggedUserDash && !this.props.selectedUserDash && !this.props.loadingUser && cover_photo ?
                        <Link to={Config.CHANGE_COVER_PHOTO_ROOT}>
                            <div className="cover__desktop-element cover-element-background" 
                                style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.9) 100%), url('+ cover_photo +')'}}>   
                                </div>
                        </Link>
                        : <div className="cover__desktop-element cover-element-background__loading"></div>
                    }
                    <div className="cover__desktop-element__user-info">
                        <div className="container">
                            <CoverUserData user={this.props.user} genre={genre} main_genre={main_genre} category={category} />
                            { 
                                this.props.loggedUserDash && !this.props.selectedUserDash ? <CoverUpdateUserProfile user={this.props.user} /> : "" 
                            }
                        </div>
                    </div>
                </div> 
                <div className="cover__mobile">
                    {
                        this.props.loggedUserDash && !this.props.selectedUserDash && !this.props.loadingUser && cover_photo ?
                        <Link to={Config.CHANGE_COVER_PHOTO_ROOT}>
                             <div className="cover__mobile-element cover-element-background" style={{backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,.0) 10%, rgba(0,0,0,.9) 75%), url('+ cover_photo +')'}}></div>
                        </Link>
                        : <div className="cover__mobile-element cover-element-background__loading"></div>
                    } 
                    <div className="container dashboard-cover__mobile__user-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="cover__mobile-element__content">
                                    <div className="cover__mobile-element__photo-data">
                                        {
                                            this.props.loggedUserDash && !this.props.selectedUserDash ?
                                            <ProfilePhoto selectedUserDash={false} loggedUserDash={true} user={this.props.user}  loadingUser={this.props.loadingUser} />
                                            : <ProfilePhoto selectedUserDash={true} loggedUserDash={false} user={this.props.user} loadingUser={this.props.loadingUser} />
                                        }
                                        <CoverUserData user={this.props.user} genre={genre} main_genre={main_genre} category={category} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="menu-element">
                                    {this.props.loggedUserDash && !this.props.selectedUserDash ?
                                        <MenuElementContent user={this.props.user} loggedUserDash={true} />
                                    : <MenuElementContent user={this.props.user} loggedUserDash={false} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default withRouter(CoverElement);
