import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ReactLoading from 'react-loading';

const LoadingButton = (props) => {
    return (
        <Button className={props.classes + " " + (props.loading ? 'disable_pointer' : '')} onClick={props.onClick}>
            {
                props.loading ? (
                    <div className="loading-button_loading">
                        <ReactLoading type="bubbles" color="#fff" className={props.loaderClass} />
                    </div>
                ) : null
            }
            <p className={props.paragraphClass}>{ !props.loading ? props.content : props.loadingContent}</p>
        </Button>
    );
}

LoadingButton.propTypes = {
    loading: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
}

LoadingButton.defaultProps = {
    classes: '',
    loading: false,
    contentLoading: '',
    content: '',
    loaderClass: '',
    paragraphClass: ''
}

export default LoadingButton;
