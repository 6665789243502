import React, {Component} from 'react';
import LoginContent from '../../LoginContent/LoginContent';
import TableEvent from '../../TableEvent/TableEvent';
import './IntroPage.css';
import {connect} from "react-redux";
import {isLoggedInUserInLocalStorage} from '../../../actions/UserActions';
import {setLoadingGetPremiumEvents,getPremiumEventsSuccess, getPremiumEventsError, getPremiumEvents} from '../../../actions/EventActions';
import {bindActionCreators} from "redux";

class IntroPage extends Component {
    constructor(props, context){
        super(props, context);

        this.state = {
            clicked_forgot_password: false
        };

        this.onChangeClickedForgotPassword = this.onChangeClickedForgotPassword.bind(this);
    }

    componentDidMount = () => {
        this.props.setLoadingGetPremiumEvents();
        this.props.getPremiumEvents().then(() => {
            this.props.getPremiumEventsSuccess();
        }).catch(() => {
            this.props.getPremiumEventsError();
        });
    }

    onChangeClickedForgotPassword = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                clicked_forgot_password: !prevState.clicked_forgot_password
            }
        });
    }
    
    render() {
        let event_big_classes = ['left-event', 'middle-event', 'right-event'];
        return (
            <div>
                <div className={isLoggedInUserInLocalStorage() ? "intro intro__logged-in" : "intro"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 col-sm-7">
                                <LoginContent 
                                    isLoginModal={false} 
                                    onChangeClickedForgotPassword={this.onChangeClickedForgotPassword}
                                />
                            </div>
                            <div className="col-md-7 col-sm-5">
                                <div className="intro__tables-of-events">
                                    {
                                        this.props.premiumEventsList.map((event, index) => {
                                            return (
                                                <TableEvent 
                                                    key={index}
                                                    classEvent="big__table__event"
                                                    classesBigEvents={event_big_classes[index]}
                                                    event={event}
                                                    LoginModal={false}
                                                />
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        premiumEventsList: state.eventReducer.premiumEventsList,
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setLoadingGetPremiumEvents,
        getPremiumEvents,
        getPremiumEventsSuccess,
        getPremiumEventsError
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(IntroPage);
