import React from 'react';
import './ArticleList.css';
import ArticleElement from './ArticleElement/ArticleElement';
import {NEW_ARTICLES} from '../../../config/TextContent';

const ArticleList = () => {
    return (
        <div className="article-list">
            <div className="title-icon-part-page">
                <img src={'/img/icons/identification.svg'} alt="" />
                <h2 className="title-icon-part-page__title">{NEW_ARTICLES}</h2>
            </div>
            <ArticleElement />
            <ArticleElement />
            <ArticleElement />
            <ArticleElement />
            <ArticleElement />
            <ArticleElement />
            <ArticleElement />
        </div>
    )
}

export default ArticleList;
