import React from 'react';
import './ActionElement.css';
import Action from './Action/Action';

const ActionElement = () => {
    return (
        <div className="newest-actions-table">
            <Action />
        </div>
    )
}

export default ActionElement;
