import React, { Component } from 'react';
import './CreateEventModal.css';
import {toggleModalCreateEvent} from '../../../actions/ModalActions';
import {CLOSE_AND_REJECT_MODAL, CLOSE_MODAL, TEXT_INSERTING_EVENT_WAIT} from '../../../config/TextContent';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from 'react-router-dom';
import {PropTypes} from 'prop-types';
import {getUserHandling} from '../../../actions/UserActions';
import CreateEventModalContent from './CreateEventModalContent/CreateEventModalContent';
import CreateEventModalHOC from './CreateEventModalHOC/CreateEventModalHOC';
import {saveEvent, saveOrdinaryEventSuccess, saveOrdinaryEventError, setLoadingGetTopEvents, getTopEvents, getTopEventsSuccess, getTopEventsError,
    getEventCategoriesSuccess, getEventCategories, getEventCategoriesError} from '../../../actions/EventActions';
import Alert from 'react-s-alert';
import {TEXT_SUCCESSFULLY_INSERTED_EVENT, TEXT_ERROR_INSERTED_EVENT, LOADING} from '../../../config/TextContent';
import {ALERT_SETTINGS} from '../../../config/Config';
import {GET_USER_ROUTE_TO_SPECIFIC_EVENT} from '../../../config/RoutesConstants';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import ReactLoading from 'react-loading';

class CreateEventModal extends Component {
    constructor(props, context){
        super(props, context);

        this.state = {
            loading: false
        }
    }

    componentDidMount(){
        this.props.getEventCategories().then(() => {
            this.props.getEventCategoriesSuccess();
        }).catch(() => {
            this.props.getEventCategoriesError();
        });
    }

    deleteForm = () => {
        this.setState({loading: false});
    }

    submitEventForm = (form) => {
        this.setState({loading: true});
        Alert.success(TEXT_INSERTING_EVENT_WAIT, ALERT_SETTINGS);
        form.event_prices = form.financeList; 
        form.participants = form.performersList; 
        form.program_items = form.programList;
        this.props.saveEvent(form, true).then(response => {
            if (!response.error){
                this.props.setLoadingGetTopEvents();
                this.props.getTopEvents().then(() => {
                    this.props.getTopEventsSuccess();
                }).catch(() => {
                    this.props.getTopEventsError();
                });
                this.deleteForm();
                this.props.toggleModalCreateEvent(false, true);
                Alert.success(TEXT_SUCCESSFULLY_INSERTED_EVENT, ALERT_SETTINGS);
                this.props.saveOrdinaryEventSuccess();
                this.setState({loading: false});
                this.props.history.replace(GET_USER_ROUTE_TO_SPECIFIC_EVENT(response.payload.data));
            }else {
                Alert.error(TEXT_ERROR_INSERTED_EVENT, ALERT_SETTINGS);
                this.props.saveOrdinaryEventError();
                this.setState({loading: false});
            }
        });
    };

    render(){
        return (
            <Dialog
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                open={this.props.createEventModal}
                onClose={() => this.props.toggleModalCreateEvent(false, true)}
                className='photo-update-modal'
            >
            {this.props.loading ?  
                <Fade duration={500} delay={250} >
                    <div className="photo-update-modal__content">
                        <div className="photo-update-modal__content-loading-wrapper">
                            <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                            <p className="photo-update-modal__loading-content">{LOADING}</p>
                        </div>
                    </div>
                </Fade> : (
                <CreateEventModalHOC>
                    <Link to="/" onClick={() => {this.props.toggleModalCreateEvent(false, true)}} className="photo-update-modal__close-button">
                        <img src={'/img/icons/close-cross.svg'} alt={CLOSE_AND_REJECT_MODAL}/>
                        <span className="modal__close-button__text">{CLOSE_MODAL}</span>
                    </Link>
                    <form action="post">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="create_event_modal__content-wrapper createEventModal row">
                                    <div className="row">
                                        <div className="col-md-12 padding-left-right-0-media">
                                            <p className="create_event_modal__left-side-wrapper__header">Vytvorenie eventu</p>                                         
                                            <div className="create_event_modal__left-side-wrapper__intro-title">
                                                <p>Vytvor si vlastný event vyplnením povinných polí a inzeruj ihneď.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="create_event_modal__content-wrapper__create_event_modal-content">
                                        <CreateEventModalContent 
                                            submitEvent={this.submitEventForm}
                                            loading={this.state.loading}
                                        /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </CreateEventModalHOC>
            )}
        </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        createEventModal: state.modalReducer.createEventModal,
        user: state.userReducer.user,
        loading: state.userReducer.loading
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalCreateEvent,
        getUserHandling,
        saveEvent, 
        saveOrdinaryEventError, 
        saveOrdinaryEventSuccess,
        setLoadingGetTopEvents,
        getTopEvents,
        getTopEventsSuccess,
        getTopEventsError,
        getEventCategoriesSuccess, 
        getEventCategories,
        getEventCategoriesError
    }, dispatch);
}

CreateEventModal.propTypes = {
    toggleModalCreateEvent: PropTypes.func.isRequired,
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(CreateEventModal));
