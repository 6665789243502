import React from 'react';
import Auth from '../../client/Auth';
import {connect} from "react-redux";
import LoginModal from "./LoginModal/LoginModal";
import RegisterLeaveQuestionModal from "./RegisterLeaveQuestionModal/RegisterLeaveQuestionModal";
import PhotoUpdateModal from "./PhotoUpdateModal/PhotoUpdateModal";
import CoverPhotoUpdateModal from "./CoverPhotoUpdateModal/CoverPhotoUpdateModal";
import CreateEventModal from "./CreateEventModal/CreateEventModal";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";
import AccountSettingsModal from "./AccountSettingsModal/AccountSettingsModal";
import PropTypes from 'prop-types';
import './Header.css';
import {withRouter} from 'react-router-dom';
import Navigation from './Navigation/Navigation';
import UserDataManagementModal from './UserDataManagementModal/UserDataManagementModal';
import EventsByUserManagementModal from './EventsByUserManagementModal/EventsByUserManagementModal';
import EventDataManagementModal from './EventDataManagementModal/EventDataManagementModal';
import UserDataUpdateModal from './UserDataUpdateModal/UserDataUpdateModal/UserDataUpdateModal';
import UserAsEventPerformerRemovalModal from './UserAsEventPerformerRemovalModal/UserAsEventPerformerRemovalModal';
import AddClubPhotosModal from './AddClubPhotosModal/AddClubPhotosModal';
import UserHasNotPermissionModal from './UserHasNotPermissionModal/UserHasNotPermissionModal';
import PhotosModal from './PhotosModal/PhotosModal';

const Header = props => {
    return (
        <div className="header">
            <LoginModal/>
            <RegisterLeaveQuestionModal />
            {Auth.isUserAuthenticated(props.userReducer) ? (
                <div>
                    <PhotoUpdateModal />
                    <CoverPhotoUpdateModal />
                    <CreateEventModal />
                    <ChangePasswordModal />
                    <AccountSettingsModal />
                    <UserDataUpdateModal />
                    <UserAsEventPerformerRemovalModal />
                    <UserDataManagementModal />
                    <EventsByUserManagementModal />
                    <EventDataManagementModal />
                    <AddClubPhotosModal />
                    <UserHasNotPermissionModal />
                    <PhotosModal />
                </div>
            ) : null }
            <div>
                <Navigation/>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userReducer: state.userReducer,
        loggedUserRoles: Auth.getUserRoles(state.userReducer.user),
        selectedEventToUpdate: state.eventReducer.selectedEventToUpdate,
    }
}

Header.propTypes = {
    userPath: PropTypes.string.isRequired,
};

Header.defaultProps = {
    userPath: '',
};

export default withRouter(connect(mapStateToProps)(Header));
