import EndpointsUrl from "../client/EndpointsUrl";
import axiosManager from '../client/AxiosManager/AxiosManager';
import { POST } from '../client/AxiosManager/AxiosManagerConstants';

export const DELETE_CLUB_GALLERY_IMAGE_ID = 'DELETE_CLUB_GALLERY_IMAGE_ID';
export const DELETE_CLUB_GALLERY_IMAGE_ID_SUCCESS = 'DELETE_CLUB_GALLERY_IMAGE_ID_SUCCESS';
export const DELETE_CLUB_GALLERY_IMAGE_ID_ERROR = 'DELETE_CLUB_GALLERY_IMAGE_ID_ERROR';

export function deleteImageClubGallery(photo) {
    let form_data = new FormData();
    form_data.append('id', photo.id);
    form_data.append('qquuid', photo.qquuid);

    return {
        type: DELETE_CLUB_GALLERY_IMAGE_ID,
        payload: axiosManager(POST, EndpointsUrl.DELETE_CLUB_GALLERY_IMAGE_ID, form_data)
    }
}

export function deleteImageClubGallerySuccess() {
    return {
        type: DELETE_CLUB_GALLERY_IMAGE_ID_SUCCESS,
        payload: "Club photo successfully deleted."
    }
}

export function deleteImageClubGalleryError() {
    return {
        type: DELETE_CLUB_GALLERY_IMAGE_ID_ERROR,
        payload: "Error while deleting club photo."
    }
}
