import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import './EventFilters.css';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FILTERING_OPTIONS, MORE_FILTERS} from '../../../config/TextContent';
import {setLoadingGetTopEvents, getTopEvents, getTopEventsSuccess, getTopEventsError,
    setLoadingGetEventsByUser, getEventsByUser, getEventsByUserSuccess, getEventsByUserError} from '../../../actions/EventActions';
import { Link } from 'react-router-dom';
import { GET_USER_ROUTE_TO_DASHBOARD } from '../../../config/RoutesConstants';


class EventFilters extends Component {
    constructor(props, context){
        super(props, context);
        
        this.state = {
            typingTimeout: 0,
            filter: {
                name: '',
            },
        }
    }

    componentDidMount() {
        if(!this.props.eventsByUserManagement){
            this.getTopEvents();
        }
    }

    handleNameChange = (event) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        let value = event.target.value;
        this.setState((prevState) => {
            let filter = {...prevState.filter};
            filter.name = value;
            return {
                ...prevState,
                filter,
                typingTimeout: setTimeout(() => {
                    this.props.eventsByUserManagement ? this.getUserEvents() : this.getTopEvents();
                }, 500)
            }
        });
    };

    getTopEvents = () => {
        let filter = this.state.filter;
        let query_params = {};
        query_params.name = filter.name;
        this.props.setLoadingGetTopEvents();
        this.props.getTopEvents(query_params).then(() => {
            this.props.getTopEventsSuccess();
        }).catch(() => {
            this.props.getTopEventsError();
        });
    }

    getUserEvents = () => {
        let filter = this.state.filter;
        let query_params = {};
        query_params.name = filter.name;
        this.props.setLoadingGetEventsByUser();
        this.props.getEventsByUser(query_params).then(() => {
            this.props.getEventsByUserSuccess();
        }).catch(() => {
            this.props.getEventsByUserError();
        });
    }

    render() {
        return(
            <div className="filter-content filter-content-homepage">
                <p className="filter-content__title">{FILTERING_OPTIONS}</p>
                <div className="filters-content__elements">
                    <div className="filters-content__elements__filter-element">
                        <div className="custom-green-input-wrapper black-color-input">
                            <TextField
                                label="Názov eventu"
                                name="name"
                                value={this.state.filter.name}
                                onChange={this.handleNameChange}
                            />
                        </div>
                    </div>
                    {!this.props.eventsByUserManagement ? (
                        <div className="filters-content__elements__filter-element filter-element__display-block">
                            <div className="filters-content__more-events">
                                <Link to={GET_USER_ROUTE_TO_DASHBOARD(this.props.user)}>{MORE_FILTERS}</Link>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );   
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        eventsByUserList: state.userReducer.eventsByUserList,
        loadingTopEvents: state.eventReducer.loadingTopEvents
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setLoadingGetTopEvents,
        getTopEvents,
        getTopEventsSuccess,
        getTopEventsError,
        setLoadingGetEventsByUser,
        getEventsByUser,
        getEventsByUserSuccess,
        getEventsByUserError
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(EventFilters);
