import React from 'react';
import './InfoAlertTemplate.css';
import Slide from 'react-reveal/Slide';
import { ALT_ICON } from '../../config/TextContent';

const InfoAlertTemplate = (props) => {
    return (
        <Slide right>
            <div className="alert-template">
            <div className="row">
                <div className="col-md-12">
                    <img src={'/img/icons/info-green.svg'} className="alert-template__info-image" alt={ALT_ICON}/>
                    <p className="alert-template__content">{props.message ? props.message : ''}</p>
                </div>
            </div>
        </div>
    </Slide>
    )
}

export default InfoAlertTemplate;
