export const addDataAxiosManager = (data) => {
    if(!data)
        return null;

    for(var property in data) {
        if(data[property] === null || data[property] === undefined)
            delete data[property];
    }

    return data;
}