import React, {Component} from 'react';
import {connect} from "react-redux";
import {LOADING_LIST_OF_CLUBS, CLUBS, ALT_ICON, THERE_ARE_NO_CLUBS_AVAILABLE, RESET_FILTERS} from '../../../config/TextContent';
import ListOfClubs from '../ProfileListOfClubs/ListOfClubs/ListOfClubs';
import {setLoadingGetListOfClubs, getListOfClubs, getListOfClubsSuccess, getListOfClubsError} from '../../../actions/UserActions';
import Fade from 'react-reveal/Fade';
import './ProfileListOfClubs.css';
import {bindActionCreators} from "redux";
import ReactLoading from 'react-loading';
import ReactPaginate from 'react-paginate';
import { GET_USER_ROUTE_TO_CLUBS } from '../../../config/RoutesConstants';
import { Config } from '../../../config/Constants';
import ProfileClubFilters from './ProfileClubFilters/ProfileClubFilters';
import {getFilterEventMusicGenres, getFilterClubTypesGenres} from '../../../components/Header/CreateEventModal/filters/filters';

class ProfileListOfClubs extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            offset: props.match.params && props.match.params.page ? (parseInt(props.match.params.page)-1)*Config.listClubsPerPage : 0,
            countPages: 1,
            currentPage: props.match.params.page ? parseInt(props.match.params.page)-1 : 0, 
            typingTimeout: 0,
            tsGenresOpen: false,
            filter: {
                title: '',
                city: '',
                genre: null,
                type: null,
                childrenGenre: null,
            }
        }
    }

    componentDidMount = () => {
        this.loadListOfClubs(this.state.offset ? this.state.offset : 0);
    }

    isNotActiveFiltering = () => {
        if(this.state.filter.title === '' && this.state.filter.city === '' && this.state.filter.genre === null && this.state.filter.type === null){
            return true;
        }
        return false;
    }

    loadListOfClubs = () => {
        let countClubs = 0;
        let countPages = 0;
        let filter = this.state.filter;
        let query_params = {};
        query_params.title = filter.title;
        query_params.city = filter.city;
        if (filter.childrenGenre) {
            query_params.genre = filter.childrenGenre;
        } else {
            query_params.genre = filter.genre;
        }
        query_params.type = filter.type;
        query_params.offset = this.state.offset;
        query_params.perPage = Config.listClubsPerPage;
        query_params.user_id = this.props.user.username;
        this.props.setLoadingGetListOfClubs();
        this.props.getListOfClubs(query_params).then((data) => {
            this.setState(() => {
                countClubs = parseInt(data.payload.data.count_clubs);
                countPages = Math.ceil(countClubs / Config.listClubsPerPage);
                return {
                    countPages
                }
            }, () => {
                this.props.getListOfClubsSuccess();
            });
        }).catch(() => {
            this.props.getListOfClubsError();
        })
    }

    handleClickPage = (data) => {
        let offset = Math.ceil(data.selected * Config.listClubsPerPage);
        this.props.history.push(GET_USER_ROUTE_TO_CLUBS(this.props.user) + '/' + (data.selected+1));
        this.setState({
            offset: offset,
            currentPage: data.selected
        }, () => {
            this.loadListOfClubs();
        });
    }

    onClickResetFilters = () => {
        this.setState((prevState) => {
            let filter = {
                title: '',
                city: '',
                genre: null,
                type: null,
                childrenGenre: null,
            };
            return{
                ...prevState,
                filter
            }
        }, () => {
            this.loadListOfClubs();
        })
    }

    handleTitleChange = (event) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        let filter = this.state.filter;
        filter.title = event.target.value;

        this.setState({
            filter,
            typingTimeout: setTimeout(() => {
                this.loadListOfClubs();
            }, 500)
        });
    };

    handleCityChange = (event) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        let filter = this.state.filter;
        filter.city = event.target.value;

        this.setState({
            filter,
            typingTimeout: setTimeout(() => {
                this.loadListOfClubs();
            }, 500)
        });
    };

    onClubGenreChange = (value) => {
        this.setState((prevState) => {
            let filter = {...prevState.filter};
            filter['genre'] = value;
            filter['childrenGenre'] = null;
            return {
                ...prevState,
                filter
            };
        }, () => {
            this.loadListOfClubs();
        });
    };

    onClubTypeChange = (value) => {
        this.setState((prevState) => {
            let filter = {...prevState.filter};
            filter['type'] = value;
            return {
                ...prevState,
                filter
            };
        }, () => {
            this.loadListOfClubs();
        });
    };

    onEventMusicChildrenGenresChange = (value) => {
        this.setState((prevState) => {
            let filter = {...prevState.filter};
            filter['childrenGenre'] = value;
            return {
                ...prevState,
                filter
            };
        }, () => {
            this.loadListOfClubs();
        });
    };

    render(){
        return (
            <div className="profile-list-of-clubs">
                <div className="container">
                    <div className="profile-section-filters">
                        {!this.isNotActiveFiltering() ? (
                            <div className="profile-section-filters-reset">
                                <button onClick={this.onClickResetFilters}>{RESET_FILTERS}</button>
                            </div>
                        ) : null}
                        <ProfileClubFilters 
                            handleTitleChange={this.handleTitleChange}
                            handleCityChange={this.handleCityChange} 
                            onClubGenreChange={this.onClubGenreChange}
                            onClubTypeChange={this.onClubTypeChange}
                            onEventMusicChildrenGenresChange={this.onEventMusicChildrenGenresChange}
                            genres={this.props.genres}
                            types={this.props.types}
                            filter={this.state.filter}
                        />
                    </div>
                    <div className="profile-list-of-clubs__header">
                        <h1 className="section-event-slider__info__title">{CLUBS}</h1>
                    </div>
                    {this.props.loadingListOfClubs ?
                        <Fade duration={500} delay={250} >
                            <div className="photo-update-modal__content">
                                <div className="photo-update-modal__content-loading-wrapper">
                                    <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                                    <p className="photo-update-modal__loading-content loading-content__black">{LOADING_LIST_OF_CLUBS}</p>
                                </div>
                            </div>
                        </Fade>
                    : null}
                    {!this.props.loadingListOfClubs && this.props.listOfClubs && this.props.listOfClubs.length > 0 ? (
                        <div>
                            <ListOfClubs />
                        </div>
                    ) : null}
                    {(!this.props.loadingListOfClubs && this.props.listOfClubs === null) || (this.props.listOfClubs && this.props.listOfClubs.length === 0) ? (
                        <div className="collection_event_lists__no-events">
                            <img src="/img/icons/close-cross-green.svg" alt={ALT_ICON} />
                            <p>{THERE_ARE_NO_CLUBS_AVAILABLE}</p>
                        </div>
                    ) : null}
                    {this.props.listOfClubs !== null && this.props.listOfClubs.length > 0 && this.state.countPages > 1 ? (
                        <div className="profile-list-of-clubs__pagination">
                            <ReactPaginate
                                pageCount={this.state.countPages}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={6}
                                onPageChange={this.handleClickPage}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                forcePage={this.state.currentPage}
                                breakLabel={'...'}
                                activeClassName={'active'}
                                breakClassName={'break-me'}
                                previousLabel={<img src="/img/icons/arrow-pointing-to-right-blue.svg" className="list-pagination__prev-icon" alt={ALT_ICON} />}
                                nextLabel={<img src="/img/icons/arrow-pointing-to-right-blue.svg" className="list-pagination__next-icon" alt={ALT_ICON} />}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loadingListOfClubs: state.userReducer.loadingListOfClubs,
        listOfClubs: state.userReducer.listOfClubs,
        user: state.userReducer.user,
        selectedUser: state.userReducer.selectedUser,
        genres: getFilterEventMusicGenres(state.eventReducer.eventMusicGenresList),
        types: getFilterClubTypesGenres(state.eventReducer.clubTypeList),
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setLoadingGetListOfClubs,
        getListOfClubs,
        getListOfClubsSuccess,
        getListOfClubsError
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ProfileListOfClubs);
