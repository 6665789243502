import React from 'react';
import EventUserSection from './EventUserSection/EventUserSection';
import './TableEvent.css';
import {Link} from "react-router-dom";
import { GET_USER_ROUTE_TO_SPECIFIC_EVENT } from '../../config/RoutesConstants';
import {formatDate} from '../../client/Helper';
import {HISTORIC_EVENT} from '../../config/TextContent';

const TableEvent = props => {
    let classEvent = props.classEvent ? props.classEvent : '';
    let classesBigEvents = props.classesBigEvents ? props.classesBigEvents : '';
    let title = props.event.event_name ? props.event.event_name : '';
    let event_image = '/img/main_photos/'+ props.event.main_photo_path;
    let date_start_string = formatDate(new Date(props.event.event_date));
    let additional_information_string = props.event.additional_information ? (props.event.additional_information.length > 80 ? props.event.additional_information.slice(0, 80) + "..." : props.event.additional_information) : '';
    let category_name = props.event.category && props.event.category.name ? (props.event.category.name.length > 50 ? props.event.category.name.slice(0, 50) + "..." : props.event.category.name) : '';
    return (
        <div className={"table-event " + classEvent + " " + classesBigEvents}>
            {props.loginModal ?
                <Link to={GET_USER_ROUTE_TO_SPECIFIC_EVENT(props.event)} onClick={() => props.toggleModalLogin(false, true)} className="table-event__content__part-title__title">
                    <div className="table-event__image" style={{ backgroundImage: `url(${event_image})` }}>
                        {
                            new Date(props.event.event_date) < new Date() ? 
                            <div className='event__image__history_event_tag'>{ HISTORIC_EVENT }</div>
                            : 
                            null
                        }
                    </div>
                </Link>
            : <Link to={GET_USER_ROUTE_TO_SPECIFIC_EVENT(props.event)} className="table-event__content__part-title__title">
                <div className="table-event__image" style={{ backgroundImage: `url(${event_image})` }}>
                    {
                        new Date(props.event.event_date) < new Date() ? 
                        <div className='event__image__history_event_tag'>{ HISTORIC_EVENT }</div>
                        : 
                        null
                    }
                </div>
            </Link>
            }
             
            <div className="table-event__content">
                <div className="row table-event__content__part-title">
                    <div className="col-md-12">
                    {props.loginModal ?
                        <Link to={GET_USER_ROUTE_TO_SPECIFIC_EVENT(props.event)} onClick={() => props.toggleModalLogin(false, true)} className="table-event__content__part-title__title">
                            <span>{title}</span>
                        </Link>  
                        : <Link to={GET_USER_ROUTE_TO_SPECIFIC_EVENT(props.event)} className="table-event__content__part-title__title">
                            <span>{title}</span>
                        </Link>
                    }
                    </div>
                </div>
                <div className="row event-small__content__info padding-top-5">
                    <div className="col-md-2 col-xs-2"> 
                        <img src={'/img/icons/passage-of-time.svg'} className="event-small__content__info-icon-date" alt="icon clock"/>
                    </div>
                    <div className="col-md-10 col-xs-10 padding-right-0-media padding-left-5-media padding-left-right-0"> 
                        <p className="table-event__content__info-date">{date_start_string}</p>
                    </div>
                </div>
                <div className="row event-small__content__info">
                    <div className="col-md-2 col-xs-2"> 
                        <img src={'/img/icons/list.svg'} className="event-small__content__info-icon-genre" alt="icon clock"/>
                    </div>
                    <div className="col-md-10 col-xs-10 padding-right-0-media padding-left-5-media padding-left-right-0"> 
                        <p className="event-small__content__info-genre">{category_name}</p>
                    </div>
                </div>
                <div className="row table-event__content__text">
                    <div className="col-md-12">
                        <p className="table-event__content__text__description">{additional_information_string}</p>
                    </div>
                </div>
            </div>
            {
                props.event.user ? 
                <EventUserSection
                    classEvent={classEvent}
                    event={props.event}
                    user={props.event.user}
                    loginModal={props.loginModal}
                    toggleModalLogin={props.toggleModalLogin}
                /> : ''
            }
        </div>
    );
}

export default TableEvent;
