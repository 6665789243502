class Club {
    constructor(name, lat, lng, type, genre, place_string, country_string, street_string, place_full_string, club_description) {
        return (
            {
                'modalType': 2,
                'name': name,
                'lat': lat,
                'lng': lng,
                'type': type,
                'genre': genre,
                'place_string': place_string,
                'country_string': country_string,
                'street_string': street_string,
                'place_full_string': place_full_string,
                'club_description': club_description
            }
        )
    }
}

export default Club;
