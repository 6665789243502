import React, {Component} from 'react';
import {connect} from "react-redux";
import { ALT_ICON } from '../../../../config/TextContent';
import './ListOfBands.css';
import { Link } from 'react-router-dom';
import {GET_USER_ROUTE} from '../../../../config/RoutesConstants';
import {Config} from '../../../../config/Constants';

class listOfBands extends Component {
    render() {
        return (
            <div className="list-of-bands"> 
                <div className="row">
                    {this.props.listOfBands ? this.props.listOfBands.map((band, index) => {
                        let title_photo = Config.getClubBandTitlePhoto(band);
                        return (
                            <div className={index % 2 === 0 ? "list-of-bands__col-band pr-7-desktop" : "list-of-bands__col-band pl-7-desktop"} key={index}>
                                <div className="list-of-bands__band">
                                    <Link to={GET_USER_ROUTE(band.user)} className="list-of-bands__band__link-photo">
                                        <div className="list-of-bands__band__photo" style={{backgroundImage: 'url('+ title_photo +')'}}></div>
                                    </Link>
                                    <div className="list-of-bands__band__content">
                                        <Link to={GET_USER_ROUTE(band.user)} className="list-of-bands__band__content__title">{band.name}</Link>
                                        {band.genre && band.genre.name ? (
                                            <p className="list-of-bands__band__content__place">
                                                <img src={'/img/icons/play-button.svg'} className="event-small__content__info-icon-date" alt={ALT_ICON} />
                                                <span>{band.genre.name}</span>
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        )
                    }) : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listOfBands: state.userReducer.listOfBands
    }
}

export default connect(mapStateToProps)(listOfBands);
