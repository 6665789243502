import {getUserTypeNumber} from '../client/Helper';

export const GOOGLE_MAP_KEY = "AIzaSyB0Xd-OR7pclW-T69rPLSrA0oZR9ij-JbM";
export const SLOVAK_COUNTRY_ID = 152;
export const FINANCE_LIST_ITEM_NAME_HASH = 66462;
export const FINANCE_LIST_ITEM_PRICE_HASH = 74256;
export const GOOGLE_MAP_URL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyB0Xd-OR7pclW-T69rPLSrA0oZR9ij-JbM&v=3.exp&libraries=geometry,drawing,places";

const configs = {
    images: {
        validation: {
            allowedExtensions: ['jpeg', 'jpg', 'png']
        }
    },
    SearchBoxInput: {
        lat: 48.68,
        lng: 19.7,
        radius: 2000,
    }
};

class Config {
    static IMAGE_GALLERY_ROOT = '/img/files/';
    static IMAGE_USERS_PICTURE_ROOT = '/img/';
    static IMAGE_USERS_DEFAULT_PICTURE_ROOT = '/img/default_user_profile_images/';
    static IMAGE_MAIN_PHOTOS_ROOT = '/img/main_photos/';
    static IMAGE_USERS_PROFILE_PICTURE_ROOT = '/img/user-profile-images/';
    static IMAGE_USERS_COVER_PICTURE_ROOT = '/img/user-cover-images/';
    static IMAGE_EVENT_OFFER_PHOTO_ROOT = '/img/event-offer-photos/';
    static LOGIN_ROOT = '/prihlasenie';
    static REGISTRATION_USER_ROOT = '/registracia/uzivatel';
    static REGISTRATION_CLUB_ROOT = '/registracia/klub';
    static REGISTRATION_INTERPRET_ROOT = '/registracia/interpret';
    static REGISTRATION_PROMOTER_ROOT = '/registracia/promoter';
    static ADD_EVENT_ROOT = '/pridat-event';
    static EDIT_EVENT_ROOT = '/uprava-eventu/';
    static UPDATE_USER_DATA_ROOT = '/zmena-uzivatelskych-dat';
    static CHANGE_COVER_PHOTO_ROOT = '/zmena-uzivatelskych-dat/zmena-titulnej-fotografie'; 
    static CHANGE_PROFILE_PHOTO_ROOT = '/zmena-uzivatelskych-dat/zmena-profilovej-fotografie'; 
    static USER_DATA_MANAGEMENT_ROOT = '/zmena-uzivatelskych-dat/uprava-osobnych-udajov'; 
    static ATTENDING_EVENTS_ROOT = '/zmena-uzivatelskych-dat/ucast-na-eventoch'; 
    static EVENTS_BY_USER_MANAGEMENT_ROOT = '/zmena-uzivatelskych-dat/uprava-udajov-eventov';
    static CHANGE_PASSWORD_ROOT = '/zmena-uzivatelskych-dat/zmena-hesla';
    static ACCOUNT_SETTINGS_ROOT = '/zmena-uzivatelskych-dat/vseobecne-nastavenia-uctu';
    static ADD_CLUB_PHOTOS_ROOT = '/zmena-uzivatelskych-dat/pridat-fotografie-klubu';
    static ADD_PHOTOS_ROOT = '/zmena-uzivatelskych-dat/pridat-fotografie';
    static GENERAL_STATEMENT_ROOT = '/obchodne-podmienky';
    static PRIVACY_POLICIES_ROOT = '/ochrana-osobnych-udajov';
    static USER_HAS_NOT_PERMISSION_ROOT = '/nepovolena-akcia';

    static defaultLat = 48.68;
    static defaultLng = 19.7;
    static listClubsPerPage = 3;
    static listBandsPerPage = 3;
    static CONSTANT_TITLE_PICTURE = 'title_picture';
    static CONSTANT_COVER_PICTURE = 'cover_picture';
    
    static PLACEHOLDER_PHOTO_PROFILE = 'image-placeholder-photo_profile.jpg';
    static PLACEHOLDER_PHOTO_INTERPRET = 'img_interpret.jpeg';
    static PLACEHOLDER_PHOTO_PROMOTER = 'img_promoter.jpeg';
    static PLACEHOLDER_PHOTO_CLUB = 'img_club.jpeg';
    static PLACEHOLDER_PHOTO_USER = 'img_user.jpeg';
    static PLACEHOLDER_PHOTO_EVENT = 'img_event.jpeg';

    static PLACEHOLDER_COVER_PHOTO_INTERPRET = 'img_interpret_cover.jpeg';
    static PLACEHOLDER_COVER_PHOTO_CLUB = 'img_club_cover.jpeg';
    static PLACEHOLDER_COVER_PHOTO_PROMOTER = 'img_promoter_cover.jpeg';
    static PLACEHOLDER_COVER_PHOTO_USER = 'img_user_cover.jpeg';

    static isFinanceItemValid = (input) => {
        if(input !== "" && input !== null)
            return true;
        return false;
    }

    static transformUsers = users => {
        users.map((element) => {
            if(element){
                const name =  element.name  + ( element.surname ? " " + element.surname : "");
                element.value = element.user_id + "";
                element.label = name;
            }
            return element;
        });
        return users;
    }
    
    static addRootDirToGallery(gallery){
        let photos = [];
        gallery.forEach((item) => {
            let new_item = Object.assign({}, item);
            new_item.src = this.IMAGE_GALLERY_ROOT + (item.src || item.link);
            photos.push(new_item);
        });
        return photos;
    }

    static processPhotosToGallery(photos){
        let gallery_new = this.addRootDirToGallery(photos);
        let new_items = [];
        gallery_new.forEach((item, index) => {
            new_items.push(
                {
                    src: item.src, 
                    index: index, 
                    width: 1, 
                    height: 1, 
                    id: item.id + index, 
                });
                    
        });
        return new_items;
    }

    static processUploadFilesBeforeGallery(gallery){
        let gallery_new = this.addRootDirToGallery(gallery);
        let new_items = [];
        gallery_new.forEach((item, index) => {
            new_items.push(
                {
                    src: item.src, 
                    index: index, 
                    width: 1, 
                    height: 1, 
                    id: item.id, 
                    uuid: item.uuid, 
                    qquuid: item.uuid,
                    callbacks: 
                        { 
                            successDeleteImage: item.successDeleteImage, 
                            errorDeleteImage: item.errorDeleteImage 
                        },
                });
                    
        });
        return new_items;
    }

    static spliceArrayBySrc(uuid, array_of_elements){
        let element = null;
        array_of_elements.forEach((item)=>{
            if(item.uuid === uuid)
                element = item;
        });
        
        if(element){
            var index = array_of_elements.indexOf(element);
            if (index > -1) {
                array_of_elements.splice(index, 1);
            }
        }
    }

    static get images() {
        return configs.images;
    }

    static get SearchBoxInput() {
        return configs.SearchBoxInput;
    }

    static getEventMainPhoto(event) {
        if(!event)
            return null;
        
        return Config.IMAGE_MAIN_PHOTOS_ROOT + event.main_photo_path;
    }

    static getTypesOfFinance(){
        let types = [
            {id: 1, label: "Vstupné zdarma"},
            {id: 2, label: "Jednotné vstupné"},
            {id: 3, label: "Viac možností vstupu"}
        ];
        return types;
    }

    static getLabelOfTypeOfFinanceById(type_id){
        let types = this.getTypesOfFinance();
        let label = "";
        types.forEach(type => {
            if(type.id === type_id){
                label = type.label;
            }
        });
        return label;
    }

    static getClubBandTitlePhoto(user){
        if(!user){
            return Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT;
        }

        if(user.title_picture_path){
            return Config.IMAGE_USERS_PROFILE_PICTURE_ROOT + user.title_picture_path
        }

        return this.getUserPhoto(user.user, Config.CONSTANT_TITLE_PICTURE);
    }

    static getUserPhoto(user, attribute_string){
        if(!user){
            return Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT;
        }

        let url = '';
        const user_type = getUserTypeNumber(user);

        switch(attribute_string){
            case Config.CONSTANT_TITLE_PICTURE:{
                if(user.user_data && user.user_data.title_picture_path){
                    url = Config.IMAGE_USERS_PROFILE_PICTURE_ROOT + user.user_data.title_picture_path;
                }else{
                    switch(user_type){
                        case 1: 
                            url = Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT + Config.PLACEHOLDER_PHOTO_INTERPRET;
                            break;
                        case 2: 
                            url = Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT + Config.PLACEHOLDER_PHOTO_CLUB;
                            break;
                        case 3: 
                            url = Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT + Config.PLACEHOLDER_PHOTO_PROMOTER;
                            break;
                        case 4: 
                            url = Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT + Config.PLACEHOLDER_PHOTO_USER;
                            break;
                        default:
                            break;
                    }
                }
                break;
            }
        
            case Config.CONSTANT_COVER_PICTURE: {
                if(user.user_data && user.user_data.cover_picture_path){
                    url = Config.IMAGE_USERS_COVER_PICTURE_ROOT + user.user_data.cover_picture_path;
                }else{
                    switch(user_type){
                        case 1: 
                            url = Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT + Config.PLACEHOLDER_COVER_PHOTO_INTERPRET;
                            break;
                        case 2: 
                            url = Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT + Config.PLACEHOLDER_COVER_PHOTO_CLUB;
                            break;
                        case 3: 
                            url = Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT + Config.PLACEHOLDER_COVER_PHOTO_PROMOTER;
                            break;
                        case 4: 
                            url = Config.IMAGE_USERS_DEFAULT_PICTURE_ROOT + Config.PLACEHOLDER_COVER_PHOTO_USER;
                            break;
                        default:
                            break;
                    }
                }
                break;
            }
            default:
                break;
        }

        return url;
    }
}

export {
    Config
}
