import React from 'react';
import {MENU_CALENDAR} from '../../../../../../config/TextContent';
import MenuItem from '../MenuItem';

const ElementCalendar = props => {
    return (
        <MenuItem 
            name={MENU_CALENDAR}
            link={props.link}
            img='/img/icons/calendar-star-green.svg'
            active={props.active}
            onClick={props.onClick}
        />
    );
}

export default ElementCalendar;
