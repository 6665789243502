import EndpointsUrl from "../client/EndpointsUrl";
import axiosManager from '../client/AxiosManager/AxiosManager';
import { GET } from '../client/AxiosManager/AxiosManagerConstants';

export const GET_CLUB_TYPES = 'GET_CLUB_TYPES';
export const GET_CLUB_TYPES_ERROR = 'GET_CLUB_TYPES_ERROR';
export const GET_CLUB_TYPES_SUCCESS = 'GET_CLUB_TYPES_SUCCESS';

export function getClubTypes() {
    return {
        type: GET_CLUB_TYPES,
        payload: axiosManager(GET, EndpointsUrl.GET_CLUB_TYPES)
    }
}

export function getClubTypesSuccess() {
    return {
        type: GET_CLUB_TYPES_SUCCESS,
        payload: "Club types successfully loaded."
    }
}

export function getClubTypesError() {
    return {
        type: GET_CLUB_TYPES_ERROR,
        payload: "Error while loading club types."
    }
}
