import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {toggleModalEventGallery} from '../../../../actions/ModalActions';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import './EventModalGallery.css';
//import {TEXT_LOADING} from '../../../../config/TextContent';
import {ALT_ICON, PLACE_FOR_ADDING_PHOTO, EVENT_MODAL_GALLERY_INTRO_TEXT, UPLOAD_RETRYING_UPLOAD, TEXT_INSERT_FILE,
    EVENT_MODAL_GALLERY_INTRO_QUESTION_1, EVENT_MODAL_GALLERY_INTRO_QUESTION_2, UPLOAD_ERROR, UPLOAD_CANCEL, GALLERY_PHOTOS, UPLOAD_SUBMITTING, 
    UPLOAD_REJECTED, UPLOAD_DELETE, UPLOAD_SUCCESS, TEXT_FINISH_UPLOADING_EVENT_IMAGES} from '../../../../config/TextContent';
import {ALERT_SETTINGS} from '../../../../config/Config';
import FileInput from 'react-fine-uploader/file-input';
import Alert from 'react-s-alert';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import EndpointsUrl from "../../../../client/EndpointsUrl";
import Auth from "../../../../client/Auth";
import GalleryLightBox from 'react-photo-gallery';
import GalleryImageControls from '../../../Dashboard/Gallery/EventGalleryImageControls';
import {Config} from "../../../../config/Constants";
import Lightbox from 'react-images';
import Dropzone from 'react-fine-uploader/dropzone';
import ProgressBar from 'react-fine-uploader/progress-bar';

class EventModalGallery extends Component {
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            currentImage: 0,
            uploader: null,
            lightboxIsOpen: false,
        }

        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    componentDidMount() {
        let uploader = new FineUploaderTraditional({
            options: {
                multiple: true,
                autoUpload: true,
                chunking: {
                    enabled: false
                },
                retry: {
                    enableAuto: false
                },
                deleteFile: {
                    enabled: false,
                },
                request: {
                    endpoint: EndpointsUrl.getEndpointUrl(EndpointsUrl.INSERT_GALLERY_IMAGE),
                    customHeaders: { "Authorization": "Bearer " + Auth.getToken()},
                    params: { form_id: this.props.form_id }
                },
                ...Config.images,
            }
        }); 

        uploader.on('complete', (id, name, response)=>{
            if(response.success === true){
                Alert.info(UPLOAD_SUCCESS, ALERT_SETTINGS);
                this.props.updateUploaderFiles(response);
            }else if(response.error){
                Alert.info(UPLOAD_ERROR, ALERT_SETTINGS);
            }
        });

        uploader.on('statusChange', (id, oldStatus, newStatus) => {
            switch(newStatus){
                case "submitting":
                    Alert.info(UPLOAD_SUBMITTING, ALERT_SETTINGS);
                    break;
                case "deleted":
                    Alert.info(UPLOAD_DELETE, ALERT_SETTINGS);
                    break;
                case "canceled":
                    Alert.info(UPLOAD_CANCEL, ALERT_SETTINGS);
                    break;
                case "rejected":
                    Alert.info(UPLOAD_REJECTED, ALERT_SETTINGS);
                    break;
                case "retrying upload":
                    Alert.info(UPLOAD_RETRYING_UPLOAD, ALERT_SETTINGS);
                    break;
                default: 
                    break;
            }
        });

        this.setState({uploader});
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage - 1
            }
        });
    }

    gotoNext() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage + 1
            }
        });
    }

    render() {
        return (
            <Dialog
                open={this.props.eventGalleryModalOpened}
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                className='event-modal-gallery'
                onClose={() => this.props.toggleModalEventGallery(false, true)}
            >
                <div className="row event-modal-gallery__modal">
                    <div className="col-md-10 col-md-offset-1 event-modal-gallery__left-side-wrapper">
                        <div className="row">
                            <div className="col-md-1 col-xs-1 event-modal-gallery__left-side-wrapper__img-number">
                                <img src={'/img/icons/numbers/'+ this.props.step +'.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-11 col-xs-11 event-modal-gallery__left-side-wrapper__location">
                                <p>{PLACE_FOR_ADDING_PHOTO}</p>                                         
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1">
                                <div className="event-modal-gallery__left-side-wrapper__intro-title">
                                    <p>{EVENT_MODAL_GALLERY_INTRO_TEXT}</p>
                                </div>
                                <div className="event-modal-gallery__left-side-wrapper__question-icon-wrapper">
                                    <img src={'/img/icons/information-green.svg'} alt={ALT_ICON} />
                                    <p>{EVENT_MODAL_GALLERY_INTRO_QUESTION_1}</p>
                                </div>
                                <div className="event-modal-gallery__left-side-wrapper__question-icon-wrapper">
                                    <img src={'/img/icons/discuss-green.svg'} alt={ALT_ICON} />
                                    <p>{EVENT_MODAL_GALLERY_INTRO_QUESTION_2}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row event-modal-gallery__modal">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                {
                                    this.state.uploader !== null ?
                                    <div className="event-modal-gallery__photo-upload-wrapper">
                                        <div>
                                            <ProgressBar 
                                                className="event-modal-gallery__progress-bar"
                                                uploader={ this.state.uploader }
                                                />
                                        </div>

                                        <FileInput 
                                            multiple 
                                            accept='image/*'
                                            uploader={ this.state.uploader }
                                            className="event-modal-gallery__file-input">
                                            <button 
                                                className="default_transparent_white_border_button">
                                                    <img src={'/img/icons/marker-green.svg'} alt={ALT_ICON}/>
                                                    <span>{ TEXT_INSERT_FILE }</span>
                                            </button>
                                        </FileInput>
                                        <Dropzone
                                            className="event-modal-gallery__dropzone"
                                            dropActiveClassName="event-modal-gallery__dropzone-active"
                                            multiple
                                            uploader={ this.state.uploader }>
                                        </Dropzone>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="col-md-5">
                                <div className="event-modal-gallery__photo-upload-wrapper">
                                    <div className="event-modal-gallery__file-input">
                                        <Button 
                                            onClick={() => this.props.toggleModalEventGallery(false, true)} 
                                            className="event-modal-gallery__finish-uploading">
                                                <span>{ TEXT_FINISH_UPLOADING_EVENT_IMAGES }</span>
                                        </Button>
                                        <div className="event-modal-gallery__dropzone"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                {this.props.uploaderFiles.length > 0 ?
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 event-modal-gallery__list_photos">
                            <div className="event-modal-gallery__right-side">
                                <p className="event-modal-gallery__right-side__title">{GALLERY_PHOTOS}</p>                                         
                                <div className="event-modal-gallery__right-side__list-photos">
                                    <div> 
                                        <GalleryLightBox 
                                            photos={Config.processUploadFilesBeforeGallery(this.props.uploaderFiles)} 
                                            direction={"row"} 
                                            onClick={this.openLightbox} 
                                            ImageComponent={GalleryImageControls}
                                            />
                                        <Lightbox images={Config.processUploadFilesBeforeGallery(this.props.uploaderFiles)}
                                            onClose={this.closeLightbox}
                                            onClickPrev={this.gotoPrevious}
                                            onClickNext={this.gotoNext}
                                            currentImage={this.state.currentImage}
                                            isOpen={this.state.lightboxIsOpen}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null
                }  
            </Dialog>
        )
    }
}

EventModalGallery.propTypes = {
    eventGalleryModalOpened: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
    return {
        eventGalleryModalOpened: state.modalReducer.eventGalleryModalOpened,
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalEventGallery,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(EventModalGallery);
