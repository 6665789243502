import {
    CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_ERROR,
    GET_USER, GET_USER_SUCCESS, GET_USER_ERROR,
    GET_USER_DATA, GET_USER_DATA_SUCCESS, GET_USER_DATA_ERROR,
    SAVE_USER_DATA, SAVE_USER_DATA_SUCCESS, SAVE_USER_DATA_ERROR,
    UPDATE_USER_DATA, UPDATE_USER_DATA_SUCCESS, UPDATE_USER_DATA_ERROR, SET_SELECTED_USER,
    DELETE_USER_DATA, DELETE_USER_DATA_ERROR, DELETE_USER_DATA_SUCCESS,
    SET_SELECTED_USER_SUCCESS, SET_SELECTED_USER_ERROR, SAVE_TITLE_IMAGE_SUCCESS, SAVE_TITLE_IMAGE_ERROR,
    SAVE_TITLE_IMAGE, SAVE_COVER_IMAGE_SUCCESS, SAVE_COVER_IMAGE_ERROR, SAVE_COVER_IMAGE,
    SET_LOADING_REMOVE_USER, REMOVE_USER, REMOVE_USER_ERROR, REMOVE_USER_SUCCESS, SET_LOADING_SET_SELECTED_USER,
    SET_LOADING_GET_LIST_OF_CLUBS, GET_LIST_OF_CLUBS, GET_LIST_OF_CLUBS_SUCCESS, GET_LIST_OF_CLUBS_ERROR,
    SET_LOADING_GET_LIST_OF_BANDS, GET_LIST_OF_BANDS, GET_LIST_OF_BANDS_SUCCESS, GET_LIST_OF_BANDS_ERROR
} from '../actions/UserActions';

const INITIAL_STATE = {
    selectedUser: null,
    user: null, 
    listOfClubs: null,
    listOfBands: null,
    userExists: false,
    status: null, 
    loading: false,
    isAuthenticated: false,
    loadingSelectedUser: false,
    loadingTitlePicture: false,
    loadingCoverPicture: false,
    loadingRemoveUser: false,
    loadingUpdateUserData: false,
    loadingListOfClubs: false,
    loadingListOfBands: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CREATE_USER: {
            const userAlreadyExists = action.payload.data.hasOwnProperty('email_exists') && action.payload.data.email_exists;
            let user = action.payload.data;
            let userLoggedIn = ( user && user.id );
            return { ...state, user: !userAlreadyExists && userLoggedIn ? user : null, isAuthenticated: userLoggedIn, status:'register', loading: true, userExists: userAlreadyExists};
        }
        case CREATE_USER_SUCCESS:
            return { ...state, status: action.payload, loading: false, userExists: false};
        case CREATE_USER_ERROR:
            return { ...state, status: action.payload, loading: false, userExists: false};

        case GET_USER: {
            return {...state, user: transformUser(action), isAuthenticated: isUserAvailable(action), status:'getuser', loading: true};
        }
        case GET_USER_SUCCESS:
            return {...state, status: action.payload, loading: false};
        case GET_USER_ERROR:
            return { ...state, status: action.payload, loading: false};

        case SAVE_USER_DATA: {
            let user = state.user;
            user.user_data = action.payload.data;
            return {...state, user, status:'setuserdata', loading: true};
        }
        case SAVE_USER_DATA_SUCCESS:
            return {...state, status: action.payload, loading: false};
        case SAVE_USER_DATA_ERROR:
            return { ...state, status: action.payload, loading: false};

        case UPDATE_USER_DATA: {
            return {...state, loadingUpdateUserData: true};
        }
        case UPDATE_USER_DATA_SUCCESS:
            return {...state, status: action.payload, loadingUpdateUserData: false};
        case UPDATE_USER_DATA_ERROR:
            return { ...state, status: action.payload, loadingUpdateUserData: false};

        case GET_USER_DATA:
            return {...state, user: action.payload, status:'getuserdata', loading: true};
        case GET_USER_DATA_SUCCESS:
            return {...state, status: action.payload, loading: false};
        case GET_USER_DATA_ERROR:
            return { ...state, status: action.payload, loading: false};

        case DELETE_USER_DATA:
            return {...INITIAL_STATE, status:'deleteuserdata', loading: true};
        case DELETE_USER_DATA_SUCCESS:
            return {...INITIAL_STATE};
        case DELETE_USER_DATA_ERROR:
            return { ...INITIAL_STATE};

        case SET_LOADING_SET_SELECTED_USER: 
            return {...state, selectedUser: null, loadingSelectedUser: true};
        case SET_SELECTED_USER:
            return {...state, selectedUser: transformUser(action), loadingSelectedUser: false};
        case SET_SELECTED_USER_SUCCESS:
            return {...state, loadingSelectedUser: false};
        case SET_SELECTED_USER_ERROR:
            return {...state, loadingSelectedUser: false};
            
        case SAVE_TITLE_IMAGE:
            return {...state, loadingTitlePicture: true}
        case SAVE_TITLE_IMAGE_SUCCESS:
            return {...state, loadingTitlePicture: false}
        case SAVE_TITLE_IMAGE_ERROR:
            return {...state, loadingTitlePicture: false}

        case SAVE_COVER_IMAGE:
            return {...state, loadingCoverPicture: true}
        case SAVE_COVER_IMAGE_SUCCESS:
            return {...state, loadingCoverPicture: false}
        case SAVE_COVER_IMAGE_ERROR:
            return {...state, loadingCoverPicture: false}
            
        case SET_LOADING_REMOVE_USER: 
            return {...state, loadingRemoveUser: true};
        case REMOVE_USER:
            return {...state, loadingRemoveUser: false};
        case REMOVE_USER_SUCCESS:
            return {...state};
        case REMOVE_USER_ERROR:
            return {...state};

        case SET_LOADING_GET_LIST_OF_CLUBS:
            return {...state, loadingListOfClubs: true};
        case GET_LIST_OF_CLUBS:
            return {...state, listOfClubs: action.payload.data.clubs || [], loadingListOfClubs: false};
        case GET_LIST_OF_CLUBS_SUCCESS:
            return {...state, loadingListOfClubs: false};
        case GET_LIST_OF_CLUBS_ERROR:
            return {...state, loadingListOfClubs: false};
        
        case SET_LOADING_GET_LIST_OF_BANDS:
            return {...state, loadingListOfBands: true};
        case GET_LIST_OF_BANDS:
            return {...state, listOfBands: action.payload.data.bands || [], loadingListOfBands: false};
        case GET_LIST_OF_BANDS_SUCCESS:
            return {...state, loadingListOfBands: false};
        case GET_LIST_OF_BANDS_ERROR:
            return {...state, loadingListOfBands: false};

        default:
            return state;
    }
}

const transformUser = (action) => {
    let user = null;
    if(isUserAvailable(action)){
        user = {};
        user = action.payload.data.user; 
        user.user_data = action.payload.data.user_data;
        user.roles = action.payload.data.roles;
        user.logged_in = action.payload.data.actually_logged_in;
        user.user_events = action.payload.data.events;
    }
    return user;
}

const isUserAvailable = (action) => {
    return action.payload.data && action.payload.data.hasOwnProperty("user") && action.payload.data.user && action.payload.data.user.hasOwnProperty("id");
}
