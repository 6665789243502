import React, { Component } from 'react';
import './PhotoUpdateModal.css';
import Fade from 'react-reveal/Fade';
import Alert from 'react-s-alert';
import {ALERT_SETTINGS} from '../../../config/Config';
import {toggleModalPhotoUpdate} from '../../../actions/ModalActions';
import {ALT_ICON, CLOSE_AND_REJECT_MODAL, IMAGE_INSERTION, PROFILE_PICTURE_CHANGE_HEADER, 
    PHOTO_UPDATE_MODAL_CONFIRMATION, PHOTO_UPLOAD_DESCRIPTION, INSERT_ANOTHER_IMAGE, PREPARING_PHOTO_PLEASE_WAIT,
    UPDATE_PROFILE_PHOTO_SUCCESS} from '../../../config/TextContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from 'react-router-dom';
import {PropTypes} from 'prop-types';
import { Link } from 'react-router-dom';
import {getFullname} from '../../../client/Helper';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {saveTitlePhoto, getUserHandling, saveTitlePhotoSuccess, saveTitlePhotoError} from '../../../actions/UserActions';
import { Config } from '../../../config/Constants';
import ReactLoading from 'react-loading';
import PhotoUpdateModalHOC from './PhotoUpdateModalHOC/PhotoUpdateModalHOC';
import {GET_USER_ROUTE_TO_DASHBOARD} from '../../../config/RoutesConstants';


class PhotoUpdateModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
          src: null,
          cropResult: null,
          loading: false
        };

        this.cropAndSave = this.cropAndSave.bind(this);
        this.onChange = this.onChange.bind(this);
        this.useDefaultImage = this.useDefaultImage.bind(this);
    }

    onChange(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer)
            files = e.dataTransfer.files;
        else if (e.target)
            files = e.target.files;
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ src: reader.result, file: files[0] });
        };
        reader.readAsDataURL(files[0]);
    }

    cropAndSave() {
        if (typeof this.cropper.getCroppedCanvas() === 'undefined' || !this.cropper.getCroppedCanvas())
            return;

        this.setState({
            cropResult: this.cropper.getCroppedCanvas().toDataURL('image/jpeg')
        }, () => {
            fetch(this.state.cropResult)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], this.state.file.name)
                this.saveTitlePhoto(file);
            })
        });
    }

    useDefaultImage() {
        this.setState({ src: null, cropResult: null, file: null, loading: false });
    }

    saveTitlePhoto(file){
        this.setState({
            loading: true
        }, () => {
            this.props.saveTitlePhoto(file).then((data) => {
                this.props.saveTitlePhotoSuccess();
                this.props.getUserHandling().then(() => {
                    this.useDefaultImage();
                    Alert.success(UPDATE_PROFILE_PHOTO_SUCCESS, ALERT_SETTINGS);
                    this.props.toggleModalPhotoUpdate(false, true);
                    this.props.history.push(GET_USER_ROUTE_TO_DASHBOARD(this.props.user));
                });
            }).catch((err) => {
                this.props.saveTitlePhotoError();
            });
        });
    }

    onClosePhotoUpdateModal = () => {
        this.useDefaultImage(); 
        this.props.toggleModalPhotoUpdate(false, true);
    }
    
    render(){
        const user_fullname = getFullname(this.props.user, this.props.user && this.props.user.hasOwnProperty('user_data') ? this.props.user.user_data : null);
  
        return (
            <Dialog
            scroll='body'
            fullWidth={true}
            fullScreen={true}
            open={this.props.photoUpdateModal}
            onClose={() => this.props.toggleModalPhotoUpdate(false, true)}
            className='photo-update-modal'
        >
            {
                this.state.loading ? 
                <Fade duration={500} delay={250} >
                    <div className="photo-update-modal__content">
                        <div className="photo-update-modal__content-loading-wrapper">
                            <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                            <p className="photo-update-modal__loading-content">{PREPARING_PHOTO_PLEASE_WAIT}</p>
                        </div>
                    </div>
                </Fade> :
                <PhotoUpdateModalHOC>
                    <Link to={GET_USER_ROUTE_TO_DASHBOARD(this.props.user)} onClick={this.onClosePhotoUpdateModal} className="photo-update-modal__close-button">
                        <img src={'/img/icons/close-cross.svg'} alt={CLOSE_AND_REJECT_MODAL}/>
                        <span className="modal__close-button__text">{CLOSE_AND_REJECT_MODAL}</span>
                    </Link>
                    <Fade duration={500} delay={250}>
                        <div className="photo-update-modal__content">
                            <div className="photo-update-modal__wrapper"> 
                                <div className="container"> 
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-5 photo-update-modal__left-side-wrapper">
                                            <div className="row photo-update-modal__left-side-wrapper__title">
                                                <div className="col-md-1 col-xs-1">
                                                    <img className="photo-update-modal__step-icon" src={'/img/icons/image.svg'} alt={ALT_ICON}/>
                                                </div>
                                                <div className="col-md-11 col-xs-11">
                                                    <p className="photo-update-modal__left-side__header">{PROFILE_PICTURE_CHANGE_HEADER}</p>                                         
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="photo-update-modal__left-side__intro-title">
                                                        <p>{PHOTO_UPLOAD_DESCRIPTION}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="padding-left-right-15">
                                                        {
                                                            this.state.src ? 
                                                                <Cropper
                                                                    style={{ height: 300, 
                                                                        width: '100%',
                                                                        borderRadius: '4px',
                                                                        overflow: 'hidden',
                                                                        marginTop: '20px',
                                                                        maxWidth: '400px',
                                                                        marginLeft: 'auto',
                                                                        marginRight: 'auto',
                                                                        boxShadow: '0px 0px 0px 7px rgba(255,255,255, .1)' 
                                                                    }}
                                                                    aspectRatio={1}
                                                                    preview=".photo__user-element__intro__background-preview"
                                                                    guides={false}
                                                                    src={this.state.src}
                                                                    ref={cropper => { this.cropper = cropper; }}
                                                                />
                                                            :
                                                        <label className="file-input"> 
                                                            <span>{IMAGE_INSERTION}</span>
                                                            <input type="file" className="file" onChange={this.onChange}  />
                                                        </label>
                                                        }
                                                    </div>
                                                    {
                                                        this.state.src ? 
                                                        (
                                                            <Button onClick={() => this.cropAndSave()} className="photo-update-modal__button">
                                                                <p>{PHOTO_UPDATE_MODAL_CONFIRMATION}</p>
                                                            </Button>
                                                        ) : null
                                                    }
                                                    {
                                                        this.state.src ? 
                                                        <Button onClick={() => {this.useDefaultImage()}} className="photo-update-modal__reset-button">
                                                            <p>{INSERT_ANOTHER_IMAGE}</p>
                                                        </Button> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 photo-update-modal__right-side-wrapper" + (this.state.src ? 'photo__cover-user-element-wrapper-active' : '') }>
                                            <div className="photo__cover-user-element">
                                                <div className="photo__cover-user-element__intro">
                                                    <Link to="#" className="photo__cover-user-element__intro__link">
                                                        <div className="photo__user-element__intro__background-preview">
                                                            {
                                                                <img src={Config.getUserPhoto(this.props.user, Config.CONSTANT_TITLE_PICTURE)} className="photo__user-element__intro__image" alt={ALT_ICON}/>
                                                            }
                                                        </div>
                                                    </Link>
                                                    <div className="photo__cover-user-data">
                                                        <div className="photo__cover-user-data__info">
                                                            <div className="photo__cover-user-data__user-name-type">
                                                                <p className="photo__cover-user-data__user-name">{user_fullname}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </PhotoUpdateModalHOC>
            }
        </Dialog>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        photoUpdateModal: state.modalReducer.photoUpdateModal,
        user: state.userReducer.user
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalPhotoUpdate,
        saveTitlePhoto,
        getUserHandling,
        saveTitlePhotoSuccess,
        saveTitlePhotoError
    }, dispatch);
}

PhotoUpdateModal.propTypes = {
    toggleModalPhotoUpdate: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(PhotoUpdateModal));
