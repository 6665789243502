import React, {Component} from 'react';
import './CollectionOfEventSliders.css';
import EventSlider from '../EventSlider/EventSlider';
import {connect} from "react-redux";
import Fade from 'react-reveal/Fade';
import ReactLoading from 'react-loading';
import {LOADING_TOP_EVENTS, ALT_ICON, THERE_ARE_NO_TOP_EVENTS_AVAILABLE} from '../../config/TextContent';

class CollectionOfEventSliders extends Component {   
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    render() {
        return ( 
            <div className="collection_event_sliders" id="events">
                {
                    this.props.loadingTopEvents &&
                    <Fade duration={500} delay={250} >
                        <div className="photo-update-modal__content">
                            <div className="photo-update-modal__content-loading-wrapper">
                                <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                                <p className="photo-update-modal__loading-content loading-content__black">{LOADING_TOP_EVENTS}</p>
                            </div>
                        </div>
                    </Fade> 
                }
                {    
                    !this.props.loadingTopEvents && this.props.topEventsList && this.props.topEventsList.map((value, index) => {
                        return (
                            value.category ? (
                                <div id={"category-" + value.category.id} key={index}>
                                    <EventSlider 
                                        key={index} 
                                        category={value.category}
                                        events={value.events}
                                        slidesToShow="4"
                                />
                                </div>
                            ) : ""
                        )
                    }) 
                }
                {!this.props.loadingTopEvents && this.props.topEventsList.length === 0 ? (
                    <div className="collection_event_lists__no-events">
                        <img src="/img/icons/close-cross-green.svg" alt={ALT_ICON} />
                        <p>{THERE_ARE_NO_TOP_EVENTS_AVAILABLE}</p>
                    </div>
                ) : null}
            </div>
        )
    }
} 

const mapStateToProps = (state) => {
    return {
        topEventsList: state.eventReducer.topEventsList,
        loadingTopEvents: state.eventReducer.loadingTopEvents
    }
}

export default connect(mapStateToProps)(CollectionOfEventSliders);
