import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {toggleModalAddPhotos} from '../../../actions/ModalActions';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import './PhotosModal.css';
import { generateRandomString, getUserTypeNumber } from '../../../client/Helper';
import {
    ALT_ICON,
    UPLOAD_DELETE_ERROR,
    PLACE_FOR_ADDING_CLUB_PHOTO,
    ADD_PHOTOS_MODAL_INTRO_TEXT,
    UPLOAD_RETRYING_UPLOAD,
    TEXT_INSERT_FILE,
    UPLOAD_ERROR,
    UPLOAD_CANCEL,
    GALLERY_PHOTOS,
    UPLOAD_SUBMITTING,
    UPLOAD_REJECTED,
    UPLOAD_DELETE,
    UPLOAD_SUCCESS
} from '../../../config/TextContent';
import {ALERT_SETTINGS} from '../../../config/Config';
import FileInput from 'react-fine-uploader/file-input'
import Alert from 'react-s-alert';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import EndpointsUrl from "../../../client/EndpointsUrl";
import Auth from "../../../client/Auth";
import GalleryLightBox from 'react-photo-gallery';
import GalleryImageControls from '../../Dashboard/Gallery/ClubGalleryImageControls';
import {Config} from "../../../config/Constants";
import Lightbox from 'react-images';
import Dropzone from 'react-fine-uploader/dropzone';
import ProgressBar from 'react-fine-uploader/progress-bar';
import {Link} from 'react-router-dom';

class PhotosModal extends Component {
    constructor(props, context) {
        super(props);

        this.state = {
            form_id: generateRandomString(20),
            currentImage: 0,
            uploader: null,
            uploaderFiles: props.user && props.user.user_data && props.user.user_data.photos && props.user.user_data.photos.length > 0 ? this.setUploadedFiles(props.user.user_data.photos) : [],
            lightboxIsOpen: false,
        }

        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    componentDidMount() {
        if(this.props.modalType !== 2 && this.props.addPhotosModalOpened && window.location.pathname !== Config.USER_HAS_NOT_PERMISSION_ROOT){
            this.props.toggleModalAddPhotos(false, true);
            window.location.replace(Config.USER_HAS_NOT_PERMISSION_ROOT);
        }

        let uploader = new FineUploaderTraditional({
            options: {
                multiple: true,
                autoUpload: true,
                chunking: {
                    enabled: false
                },
                retry: {
                    enableAuto: false
                },
                deleteFile: {
                    enabled: false,
                },
                request: {
                    endpoint: EndpointsUrl.getEndpointUrl(EndpointsUrl.ADD_PHOTO),
                    customHeaders: { "Authorization": "Bearer " + Auth.getToken()},
                    params: { form_id: this.state.form_id }
                },
                ...Config.images,
            }
        }); 

        uploader.on('complete', (id, name, response)=>{
            if(response.success === true){
                Alert.info(UPLOAD_SUCCESS, ALERT_SETTINGS);
                this.updateUploaderFiles(response);
            }else if(response.error){
                Alert.info(UPLOAD_ERROR, ALERT_SETTINGS);
            }
        });

        uploader.on('statusChange', (id, oldStatus, newStatus) => {
            switch(newStatus){
                case "submitting":
                    Alert.info(UPLOAD_SUBMITTING, ALERT_SETTINGS);
                    break;
                case "deleted":
                    Alert.info(UPLOAD_DELETE, ALERT_SETTINGS);
                    break;
                case "canceled":
                    Alert.info(UPLOAD_CANCEL, ALERT_SETTINGS);
                    break;
                case "rejected":
                    Alert.info(UPLOAD_REJECTED, ALERT_SETTINGS);
                    break;
                case "retrying upload":
                    Alert.info(UPLOAD_RETRYING_UPLOAD, ALERT_SETTINGS);
                    break;
                default: 
                    break;
            }
        });

        this.setState({uploader});
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage - 1
            }
        });
    }

    gotoNext() {
        this.setState((prevState) => {
            return {
                currentImage: prevState.currentImage + 1
            }
        });
    }

    successDeleteImage(removed_img_id){
        this.setState((prevState) => {
            let uploaderFiles = prevState.uploaderFiles
            uploaderFiles.forEach((element, index) => {
                if(removed_img_id === element.id)
                    uploaderFiles.splice(index, 1);
            });
            return { uploaderFiles }
        }, () => {
            Alert.info(UPLOAD_DELETE, ALERT_SETTINGS);
        });
    }

    errorDeleteImage(){
        Alert.info(UPLOAD_DELETE_ERROR, ALERT_SETTINGS);
    }

    setUploadedFiles(uploader_files){
        let new_uploaderFiles = [];
        uploader_files.forEach((element) => {
            new_uploaderFiles.push(
                {
                    src: element.link, 
                    uuid: element.uuid, 
                    id: element.id,
                    qquuid: element.uuid,
                    successDeleteImage: () => this.successDeleteImage(element.id),
                    errorDeleteImage: () => this.errorDeleteImage()
                }
            );
        });

        return new_uploaderFiles;
    }

    updateUploaderFiles(response) {
        this.setState((prevState) => {
            let new_uploaderFiles = prevState.uploaderFiles;
            new_uploaderFiles.push(
                {
                    src: response.link, 
                    uuid: response.uuid, 
                    id: response.id, 
                    qquuid: response.uuid,
                    successDeleteImage: () => this.successDeleteImage(response.id),
                    errorDeleteImage: () => this.errorDeleteImage()
                }
            );
            return {
                uploaderFiles: new_uploaderFiles
            } 
        });
    }

    render() {
        return (
            <Dialog
                open={this.props.addPhotosModalOpened}
                scroll='body'
                fullWidth={true}
                fullScreen={true}
                className='event-modal-gallery'
                onClose={() => this.props.toggleModalAddPhotos(false, true)}
            >
                <Link to={Config.UPDATE_USER_DATA_ROOT} className="modal-left-link-back"
                    onClick={() => this.props.toggleModalAddPhotos(false, true)}>
                    <img src={'/img/icons/arrow-pointing-to-right.svg'} alt={ALT_ICON}/>
                </Link>
                <div className="row event-modal-gallery__modal">
                    <div className="col-md-10 col-md-offset-1 event-modal-gallery__left-side-wrapper">
                        <div className="row">
                            <div className="col-md-12 event-modal-gallery__left-side-wrapper__location">
                                <p>{PLACE_FOR_ADDING_CLUB_PHOTO}</p>                                         
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="event-modal-gallery__left-side-wrapper__intro-title">
                                    <p>{ADD_PHOTOS_MODAL_INTRO_TEXT}</p>
                                </div>
                                <br/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                            {
                                this.state.uploader !== null ?
                                <div className="event-modal-gallery__photo-upload-wrapper">
                                    <div>
                                        <ProgressBar 
                                            className="event-modal-gallery__progress-bar"
                                            uploader={ this.state.uploader }
                                            />
                                    </div>

                                    <FileInput 
                                        multiple 
                                        accept='image/*'
                                        uploader={ this.state.uploader }
                                        className="event-modal-gallery__file-input">
                                        <button 
                                            className="default_transparent_white_border_button">
                                                <img src={'/img/icons/marker-green.svg'} alt={ALT_ICON}/>
                                                <span>{ TEXT_INSERT_FILE }</span>
                                        </button>
                                    </FileInput>
                                    <Dropzone
                                        className="event-modal-gallery__dropzone"
                                        dropActiveClassName="event-modal-gallery__dropzone-active"
                                        multiple
                                        uploader={ this.state.uploader }>
                                    </Dropzone>
                                </div>
                                : ''
                            }
                            </div>
                            <div className="col-md-5">
                                <div className="event-modal-gallery__photo-upload-wrapper">
                                    <Link to={Config.UPDATE_USER_DATA_ROOT} className="event-modal-gallery__finish-uploading"
                                        onClick={() => this.props.toggleModalAddPhotos(false, true)}>
                                        <div className="event-modal-gallery__file-input">
                                            <span>Dokončiť</span>
                                        </div>
                                    </Link>
                                    <div className="event-modal-gallery__dropzone"></div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </div>
                {this.state.uploaderFiles.length > 0 ?
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 event-modal-gallery__list_photos">
                            <div className="event-modal-gallery__right-side">
                                <p className="event-modal-gallery__right-side__title">{GALLERY_PHOTOS}</p>                                         
                                <div className="event-modal-gallery__right-side__list-photos">
                                    <div> 
                                        <GalleryLightBox 
                                            photos={Config.processUploadFilesBeforeGallery(this.state.uploaderFiles)} 
                                            direction={"row"} 
                                            onClick={this.openLightbox} 
                                            ImageComponent={GalleryImageControls}
                                            />
                                        <Lightbox images={Config.processUploadFilesBeforeGallery(this.state.uploaderFiles)}
                                            onClose={this.closeLightbox}
                                            onClickPrev={this.gotoPrevious}
                                            onClickNext={this.gotoNext}
                                            currentImage={this.state.currentImage}
                                            isOpen={this.state.lightboxIsOpen}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null
                }  
            </Dialog>
        )
    }
}

PhotosModal.propTypes = {
    addPhotosModalOpened: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
    return {
        addPhotosModalOpened: state.modalReducer.addPhotosModalOpened,
        user: state.userReducer.user,
        modalType: getUserTypeNumber(state.userReducer.user)
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalAddPhotos,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(PhotosModal);
