import React from 'react';
import './ActionsAdList.css';
import ActionElement from './ActionElement/ActionElement';
import AdElement from './AdElement/AdElement';

const ActionsAdList = () => {
    return (
        <div>
            <div className="title-icon-part-page">
                <img src={'/img/icons/clapperboard.svg'} alt="" />
                <h2 className="title-icon-part-page__title">Najnovšie akcie</h2>
            </div>
            <ActionElement />
            <AdElement />
        </div>
    )
}

export default ActionsAdList;
