import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { CLUB_NAME, FLOATING_GENRE_LABEL,
    REGISTRATION_STEP_1_CLUB, REGISTRATION_STEP_2_CLUB, REGISTRATION_STEP_3_CLUB,
    REGISTRATION_STEP_4_CLUB, NO_CONTENT_AVAILABLE, CLUB_TYPE, ALT_ICON, LOADING,
    REGISTRATION_CHANGE_MAP_LOCATION, REGISTRATION_ADD_MAP_LOCATION,
    REGISTRATION_STREET_TITLE, REGISTRATION_CITY_TITLE, REGISTRATION_COUNTRY_TITLE, 
    REQUIRED_TEXT_FIELD_LOWERCASE
} from '../../../../../config/TextContent';
import {transformPropsWithLabelAndValue, onForeignObjectChange,
    onChangeHelper, getAttributeFromAddressArray, isAttributeInAddressArray,
    getAttributeFromAddressArrayWithSeparator} from '../RegistrationModalHelper';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { cloneObject, getComposedStreet } from '../../../../../client/Helper';
import PropTypes from 'prop-types';
import RegistrationSubmitButton from '../RegistrationSubmitButton';
import RegistrationStep from '../../RegistrationStep/RegistrationStep';
import RegistrationModalMapDialog from '../../RegistrationModalMapDialog/RegistrationModalMapDialog';
import {toggleModalMapRegister} from '../../../../../actions/ModalActions';
import { Button } from '@material-ui/core';
import customReactSelectStyles from '../../../../../config/ReactSelectStyle';
import { listToTreeUniqueValues } from '../../../../../client/Helper';
import TreeSelect from 'rc-tree-select';
import {getFilterEventMusicGenres} from '../../../CreateEventModal/filters/filters';

class RoleRegisterModalClubContent extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            user: {
                name: '',
                genre: '',
                type: '',
                type_obj: null,
                lng: null,
                lat: null,
                address_components: [],
                place_string: '',
                country_string: '',
                street_string: '',
                place_full_string: '',
            },
            errors: props.errors ? props.errors : {},
            hiddenFields:{
                'name': false,
                'genre': true,
                'type': true,
                'country': true,
                'submit': true
            },
            tsGenresOpen: false
        };

        this.markerPositionChanged = this.markerPositionChanged.bind(this);
        this.onSelectAddressChange = this.onSelectAddressChange.bind(this);
        this.onSelectAddressComponents = this.onSelectAddressComponents.bind(this);
    }

    componentWillReceiveProps(props){
        this.setState({
            errors: props.errors
        });
    }
    
    onFieldChange = (event, field_name) => {
        const event_value = event.target.value;
        this.setState((prevState) => {
            let user = {...prevState.user};
            let errors = {...prevState.errors};
            user[field_name] = event_value;
            if(event_value.length === 0){
                errors[field_name] = REQUIRED_TEXT_FIELD_LOWERCASE;
            }else{
                errors[field_name] = null;
            }
            return {errors, user};
        });
    }

    onGenreChange = (value) => {
        this.setState((prevState) => {
            let user = {...prevState.user};
            let errors = {...prevState.errors};
            user['genre'] = value;
            if(value.length === 0){
                errors['genre'] = REQUIRED_TEXT_FIELD_LOWERCASE;
            }else{
                errors['genre'] = null;
            }
            return {errors, user};
        });
    }

    onChange = (event, name) => {
        onChangeHelper(this, event, name);
    };

    onClubTypeChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'type');
    }

    onCityChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'city');
    }

    setVisibleSteps(...inputs){
        const diff = inputs.filter(input => {
            for(let view in this.state.hiddenFields){
                if(view === input)
                    return this.state.hiddenFields[view] !== input
            }
            return null;
        });
        if(diff.length > 0){
            this.setState((prevState) => {
                let hiddenFields = cloneObject(prevState.hiddenFields);
                diff.forEach(el => {
                    hiddenFields[el] = false;
                });
                return {...prevState, hiddenFields};
            });
        }
    }

    onSelectAddressChange = (value, latlng) => {
        this.setState((prevState) => {
            let user = prevState.user;
            let errors = prevState.errors;
            user.lat = latlng.lat;
            user.lng = latlng.lng;
            user.place_full_string = value;
            errors.lat = null;
            errors.lng = null;

            return {
                errors,
                user
            };
        });
    }

    onSelectAddressComponents = (address_components) => {
        this.setState(prevState => {
            const user = cloneObject(prevState.user);
            const errors = cloneObject(prevState.errors);
            user.address_components = address_components;
            user.street_string = getComposedStreet(address_components);
            user.country_string = getAttributeFromAddressArray(address_components, 'country');
            user.place_string = getAttributeFromAddressArrayWithSeparator(
                address_components, 
                ',', 
                'sublocality', 
                'locality', 
                'postal_town', 
                'administrative_area_level_3',
                'administrative_area_level_2', 
                'administrative_area_level_1');
            errors.place_string = null;
            errors.street_string = null;
            errors.country_string = null;

            return {
                user,
                errors
            }
        })
    }

    markerPositionChanged = (lat, lng) => {
        this.setState(prevState => {
            const user = cloneObject(prevState.user);
            user.lat = lat;
            user.lng = lng;
            return {
                user
            }
        })
    }

    render() {
        return (
            <div>
                <div>
                    <RegistrationModalMapDialog 
                        step={4}
                        place={this.state.user.place_full_string}
                        onSelectAddressChange={this.onSelectAddressChange}
                        onSelectAddressComponents={this.onSelectAddressComponents}
                        markerPositionChanged={this.markerPositionChanged}
                        markerLatLng={{ lat: this.state.user.lat, lng: this.state.user.lng}}
                        centerLatLng={{ lat: this.state.user.lat, lng: this.state.user.lng}}
                    />
                    <RegistrationStep
                        step={1}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_1_CLUB}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <TextField
                            required
                            name="name"
                            value={this.state.user.name}
                            className={this.state.errors.name ? 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input error-textfield' : 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input'}
                            label={this.state.errors.name ? this.state.errors.name : CLUB_NAME}
                            placeholder="..."
                            onChange={(event) => {this.setVisibleSteps('genre'); this.onFieldChange(event, "name")}}
                            spellCheck="false"
                            autoComplete="off"
                            autoCorrect="off"
                        />
                    </RegistrationStep>
                    <RegistrationStep
                        step={2}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_2_CLUB}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <div className={this.state.errors.genre ? 'error-selectfield' : ''}>
                            <p className="selectfield-label required">{this.state.errors.genre ? this.state.errors.genre : FLOATING_GENRE_LABEL}</p>
                            <div className="tree-select-wrapper">
                                <TreeSelect
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    transitionName="rc-tree-select-dropdown-slide-up"
                                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                    dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                    placeholder="..."
                                    showSearch={false} 
                                    allowClear treeLine
                                    value={this.state.user.genre}
                                    inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate-white.svg'} className="treeselect-arrow-down" alt={ALT_ICON} />}
                                    treeData={listToTreeUniqueValues(this.props.genres)}
                                    treeNodeFilterProp="genre"
                                    filterTreeNode={false}
                                    treeDefaultExpandAll={false}
                                    open={this.state.tsGenresOpen}
                                    notFoundContent={LOADING}
                                    onChange={(value) => {
                                        if (value === this.state.user.genre)
                                            this.setState({ tsGenresOpen: true });
                                        else 
                                            this.setState({ tsGenresOpen: false });
                                        
                                        this.setVisibleSteps('type');
                                        this.onGenreChange(value);
                                    }}
                                    onDropdownVisibleChange={(v) => {
                                        this.setState({
                                            tsGenresOpen: v,
                                        });
                                        return true;
                                    }}
                                /> 
                            </div>
                        </div>
                    </RegistrationStep>
                    <RegistrationStep
                        step={3}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_3_CLUB}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <div className={this.state.errors.type ? 'error-selectfield' : ''}>
                            <p className="selectfield-label required">{this.state.errors.type ? this.state.errors.type : CLUB_TYPE}</p>
                            <Select
                                styles={customReactSelectStyles}
                                name="type"
                                placeholder="..."
                                duration={200}
                                classNamePrefix="styled-tree-select"
                                className="styled-tree-select"
                                value={this.state.user.type_obj}
                                onChange={selectedOption => {this.setVisibleSteps('country'); this.onClubTypeChange(selectedOption)}}
                                options={this.props.club_types}
                                noOptionsMessage={() => {return NO_CONTENT_AVAILABLE}}
                            />
                        </div>
                    </RegistrationStep>
                    <RegistrationStep
                        step={4}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_4_CLUB}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <div>
                            <div className="register-modal__content-wrapper__register-modal-content__map-content">
                                <div className="row ">
                                    <div className="col-md-4 col-xs-2">
                                        <img src={'/img/icons/street.svg'} alt={ALT_ICON} className="register-modal__content-wrapper__register-modal-content__map__map-icon"/>
                                        <p className="register-modal__content-wrapper__register-modal-content__map-button__map-title">{REGISTRATION_STREET_TITLE} </p>
                                    </div>
                                    <div className="col-md-7 col-xs-10 no-padding-left">
                                        {
                                            !this.state.errors.street_string ? (
                                                !isAttributeInAddressArray(this.state.user.address_components, 'route', 'premise', 'street_number') ? 
                                                <div className="register-modal__content-wrapper__register-modal-content__map-content__block-border"></div>:
                                                <p className="register-modal__content-wrapper__register-modal-content__map-content__location-name">
                                                    { getComposedStreet(this.state.user.address_components) }
                                                </p>
                                            ) : 
                                                <p className="register-modal__content-wrapper__register-modal-content__map-content__error">{this.state.errors.street_string} *</p>
                                        }
                                    </div>
                                </div>
                                <div className="row padding-top-10">
                                    <div className="col-md-4 col-xs-2">
                                        <img src={'/img/icons/city.svg'} alt={ALT_ICON} className="register-modal__content-wrapper__register-modal-content__map__map-icon"/>
                                        <p className="register-modal__content-wrapper__register-modal-content__map-button__map-title">{REGISTRATION_CITY_TITLE} </p>
                                    </div>
                                    <div className="col-md-7 col-xs-10 no-padding-left">
                                        {
                                            !this.state.errors.place_string ? (
                                                !isAttributeInAddressArray(this.state.user.address_components, 'administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3', 'postal_town', 'locality', 'sublocality') ? 
                                                <div className="register-modal__content-wrapper__register-modal-content__map-content__block-border"></div>:
                                                <p className="register-modal__content-wrapper__register-modal-content__map-content__location-name">
                                                    { getAttributeFromAddressArrayWithSeparator(this.state.user.address_components, ',', 'sublocality', 'locality', 'postal_town', 'administrative_area_level_3', 'administrative_area_level_2', 'administrative_area_level_1') }
                                                </p> ) :
                                            <p className="register-modal__content-wrapper__register-modal-content__map-content__error">{this.state.errors.place_string} *</p>
                                        }
                                    </div>
                                </div>
                                <div className="row padding-top-10">
                                    <div className="col-md-4 col-xs-2">
                                        <img src={'/img/icons/flag.svg'} alt={ALT_ICON} className="register-modal__content-wrapper__register-modal-content__map__map-icon"/>
                                        <p className="register-modal__content-wrapper__register-modal-content__map-button__map-title">{REGISTRATION_COUNTRY_TITLE} </p>
                                    </div>
                                    <div className="col-md-7 col-xs-10 no-padding-left">
                                        {
                                            !this.state.errors.country_string ? (
                                                !isAttributeInAddressArray(this.state.user.address_components, 'country') ? 
                                                <div className="register-modal__content-wrapper__register-modal-content__map-content__block-border"></div>:
                                                <p className="register-modal__content-wrapper__register-modal-content__map-content__location-name">
                                                    {getAttributeFromAddressArray(this.state.user.address_components, 'country')}
                                                </p> ) :
                                            <p className="register-modal__content-wrapper__register-modal-content__map-content__error">{this.state.errors.country_string} *</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Button 
                                onClick={() => {this.setVisibleSteps('submit'); this.props.toggleModalMapRegister(true, true)}} 
                                className="register-modal__content-wrapper__register-modal-content__map-button__map-button"
                            >
                                <div>
                                    <img src={'/img/icons/marker-green.svg'} alt={ALT_ICON} className="register-modal__content-wrapper__register-modal-content__map-button__map-icon"/>
                                </div>
                                { this.state.user.place_full_string ? REGISTRATION_CHANGE_MAP_LOCATION : REGISTRATION_ADD_MAP_LOCATION}
                            </Button>
                        </div>
                    </RegistrationStep>
                </div>
                <div>
                    <RegistrationSubmitButton 
                        hidden={this.state.hiddenFields.submit}
                        loading={this.props.loading}
                        errors={this.props.errors} 
                        onClick={() => 
                            this.props.onSubmit(this.state.user)}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        genres: getFilterEventMusicGenres(state.eventReducer.eventMusicGenresList),
        club_types: transformPropsWithLabelAndValue(state.clubTypesReducer.clubTypeList)
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleModalMapRegister,
    }, dispatch);
}

RoleRegisterModalClubContent.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    club_types: PropTypes.array.isRequired,
    genres: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired
};

export default connect(mapStateToProps, matchDispatchToProps)(RoleRegisterModalClubContent);
