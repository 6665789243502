import React, {Component} from 'react';
import './EventList.css';
import {connect} from "react-redux";
import {EVENT_LIST_TITLE_SECTION, EVENT_MY_LIST_TITLE_SECTION, EVENT_LIST_DESCRIPTION_SECTION, ALT_ICON, THERE_ARE_NO_EVENTS_AVAILABLE, EVENT_OF_USER} from '../../config/TextContent';
import TableEvent from '../TableEvent/TableEvent';
import {isSelectedUserProfile} from '../../client/Helper';

class EventList extends Component {
    render(){
        return(
            !this.props.isSelectedUserProfile ? (
                this.props.events.length > 0 ? (
                    <div>
                        <div className="collection_event_lists">
                            {
                                this.props.chosenEvents.length > 0 ? (
                                    <div>
                                        <div className="section-event-slider__info">
                                            <h3 className="section-event-slider__info__title">{EVENT_MY_LIST_TITLE_SECTION}</h3>
                                            <p className="section-event-slider__info__description">{EVENT_LIST_DESCRIPTION_SECTION}</p>
                                        </div>
                                        {
                                            this.props.chosenEvents.map((event, index) => {
                                                return(
                                                    <TableEvent 
                                                        key={index}
                                                        classEvent="big__table__event"
                                                        event={event}
                                                        loginModal={false}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                ): ''
                            }
                        </div>
                        <div className="collection_event_lists">
                            <div className="section-event-slider__info">
                                <h3 className="section-event-slider__info__title">{EVENT_LIST_TITLE_SECTION}</h3>
                                <p className="section-event-slider__info__description">{EVENT_LIST_DESCRIPTION_SECTION}</p>
                            </div>
                            {
                                this.props.events.map((event, index) => {
                                    return(
                                        <TableEvent 
                                            key={index}
                                            classEvent="big__table__event"
                                            event={event}
                                            loginModal={false}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : (
                    <div className="collection_event_lists">
                        <div className="collection_event_lists__no-events">
                            <img src="/img/icons/close-cross-green.svg" alt={ALT_ICON} />
                            <p>{THERE_ARE_NO_EVENTS_AVAILABLE}</p>
                        </div>
                    </div>
                )
            ) : (
                this.props.chosenEvents.length > 0 ? (
                    <div>
                        <div className="collection_event_lists">
                            {
                                <div>
                                    <div className="section-event-slider__info">
                                        <h3 className="section-event-slider__info__title">{EVENT_OF_USER}</h3>
                                        <p className="section-event-slider__info__description">{EVENT_LIST_DESCRIPTION_SECTION}</p>
                                    </div>
                                    {
                                        this.props.chosenEvents.map((event, index) => {
                                            return(
                                                <TableEvent 
                                                    key={index}
                                                    classEvent="big__table__event"
                                                    event={event}
                                                    loginModal={false}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                ) : (
                    <div className="collection_event_lists">
                        <div className="collection_event_lists__no-events">
                            <img src="/img/icons/close-cross-green.svg" alt={ALT_ICON} />
                            <p>{THERE_ARE_NO_EVENTS_AVAILABLE}</p>
                        </div>
                    </div>
                )
            )
        )
    }
}

function mapStateToProps(state) {
    return {
        chosenEvents: state.eventReducer.chosenEvents,
        events: state.eventReducer.events,
        isSelectedUserProfile: isSelectedUserProfile(state.userReducer.user, state.userReducer.selectedUser),
    }
}


export default connect(mapStateToProps)(EventList);
