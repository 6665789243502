class Interpret {
    constructor(name, country, city, city_string, genre, date_started) {
        return (
            {
                'modalType': 1,
                'name': name,
                'country': country,
                'city': city,
                'city_string': city_string,
                'genre': genre,
                'date_started': date_started
            }
        )
    }
}

export default Interpret;
