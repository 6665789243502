import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ALT_ICON, REGISTER_LEAVE_QUESTION_MODAL_TITLE_1, REGISTER_LEAVE_QUESTION_MODAL_TITLE_2,
    CONTENT_LOADING, REGISTER_LEAVE_QUESTION_MODAL_SUBTITLE_1, REGISTER_LEAVE_QUESTION_MODAL_POINT_1_SEC_2,
    REGISTER_LEAVE_QUESTION_MODAL_POINT_1, REGISTER_LEAVE_QUESTION_MODAL_POINT_2, REGISTER_LEAVE_QUESTION_MODAL_LOGOUT,
    REGISTER_LEAVE_QUESTION_MODAL_DELETE_ACCOUNT, REMOVING_ACCOUNT_PLEASE_WAIT,
    BACK_TO_REGISTER_FORM, ERROR_WHEN_REMOVING_USER, SUCCESS_USER_REMOVAL
} from '../../../config/TextContent';
import {fullLogout} from '../../../actions/AuthActions';
import './RegisterLeaveQuestionModal.css';
import {toggleModalRegisterLeaveQuestion} from '../../../actions/ModalActions';
import {setLoadingRemoveUser, removeUser, removeUserSuccess, removeUserError} from '../../../actions/UserActions';
import LoadingButton from '../../LoadingButton/LoadingButton';
import {withRouter} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Button from '@material-ui/core/Button';
import {ALERT_SETTINGS} from '../../../config/Config';
import Alert from 'react-s-alert';
import ReactLoading from 'react-loading';
import {Redirect} from 'react-router-dom';

class RegisterLeaveQuestionModal extends Component {

    onLogout = () => {
        this.props.toggleModalRegisterLeaveQuestion(false, true);
        this.props.fullLogout();
        this.props.history.push('/');
    }
    
    removeUser = () => {
        this.props.setLoadingRemoveUser();
        this.props.removeUser()
        .then(data => {
            if(data.payload.data.result === true){
                this.props.removeUserSuccess();
                Alert.success(SUCCESS_USER_REMOVAL, ALERT_SETTINGS);
            }else{
                this.props.removeUserError();
                Alert.error(ERROR_WHEN_REMOVING_USER, ALERT_SETTINGS);
            }
            this.props.toggleModalRegisterLeaveQuestion(false, true);
            this.props.fullLogout();
            return <Redirect to="/" />
        }).catch(err => {
            Alert.error(ERROR_WHEN_REMOVING_USER, ALERT_SETTINGS);
            this.props.removeUserError();
            this.props.fullLogout();
            this.props.toggleModalRegisterLeaveQuestion(false, true);
            return <Redirect to="/" />
        });
    }
    
    render(){
        return (
            <Dialog
                fullWidth={true}
                fullScreen={true}
                open={this.props.registerLeaveQuestionModalOpened}
                onClose={() => this.onLogout()}
                className='register-leave-question-modal'
                scroll='paper'
            >
                <Button onClick={() => {this.props.toggleModalRegisterLeaveQuestion(false, true)}} className="register-leave-question-modal__close-button">
                    {BACK_TO_REGISTER_FORM}
                    <img src={'/img/icons/arrow-pointing-to-right.svg'} alt={BACK_TO_REGISTER_FORM}/>
                </Button>
                {
                    this.props.loadingRemoveUser ? 
                    <Fade duration={500} delay={250} >
                        <div className="register-leave-question-modal__content">
                            <div className="register-leave-question-modal__content-loading-wrapper">
                                <ReactLoading type="bubbles" color="#fff" className="register-leave-question-modal__loading-bubbles" />
                                <p className="register-leave-question-modal__loading-content">{REMOVING_ACCOUNT_PLEASE_WAIT}</p>
                            </div>
                        </div>
                    </Fade> :
                    <Fade duration={1000} delay={500}>
                        <div className="container register-leave-question-modal__wrapper"> 
                            <div className="row">
                                <div className="col-md-6 col-md-offset-3">
                                    <div className="register-leave-question-modal">
                                        <img src={'/img/icons/information-green.svg'} alt={ALT_ICON} className="register-leave-question-modal__image" />
                                        <p className="register-leave-question-modal__title"><span>{REGISTER_LEAVE_QUESTION_MODAL_TITLE_1}</span><span>{REGISTER_LEAVE_QUESTION_MODAL_TITLE_2}</span></p>
                                    </div>
                                    <ul className="register-leave-question-modal__ul">
                                        <li className="register-leave-question-modal__ul__li">
                                            <img src={'/img/icons/favorites.svg'} alt={ALT_ICON} className="register-leave-question-modal__ul__li__image" />
                                            <p className="register-leave-question-modal__ul__li__p">{REGISTER_LEAVE_QUESTION_MODAL_POINT_1} <span className="greenColor">{REGISTER_LEAVE_QUESTION_MODAL_POINT_1_SEC_2}</span></p>
                                        </li>
                                        <li className="register-leave-question-modal__ul__li">
                                            <img src={'/img/icons/team-green.svg'} alt={ALT_ICON} className="register-leave-question-modal__ul__li__image" />
                                            <p className="register-leave-question-modal__ul__li__p">{REGISTER_LEAVE_QUESTION_MODAL_POINT_2}</p>
                                        </li>
                                    </ul>
                                    <p className="register-leave-question-modal__subtitle">
                                        <span className="greenColor">*</span>
                                        { REGISTER_LEAVE_QUESTION_MODAL_SUBTITLE_1}
                                    </p>
                                    <div className="register-leave-question-modal__buttons-wrapper">
                                        <LoadingButton 
                                            classes="register-leave-question-modal__button-delete"
                                            paragraphClass="register-leave-question-modal__button__intro-title"
                                            loaderClass="register-leave-question-modal__button__react-loading"
                                            loading={false}
                                            content={REGISTER_LEAVE_QUESTION_MODAL_DELETE_ACCOUNT}
                                            loadingContent={CONTENT_LOADING}
                                            onClick={() => this.removeUser()}
                                        /> 
                                        <LoadingButton 
                                            classes="register-leave-question-modal__button-success"
                                            paragraphClass="register-leave-question-modal__button__intro-title"
                                            loaderClass="register-leave-question-modal__button__react-loading"
                                            loading={false}
                                            content={REGISTER_LEAVE_QUESTION_MODAL_LOGOUT}
                                            loadingContent={CONTENT_LOADING}
                                            onClick={() => this.onLogout()}
                                        /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                }
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loadingRemoveUser: state.userReducer.loadingRemoveUser,
        registerLeaveQuestionModalOpened: state.modalReducer.registerLeaveQuestionModal
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        toggleModalRegisterLeaveQuestion,
        fullLogout,
        setLoadingRemoveUser,
        removeUser,
        removeUserSuccess,
        removeUserError,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(RegisterLeaveQuestionModal));
