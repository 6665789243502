import { TOGGLE_MODAL_LOGIN, TOGGLE_MODAL_REGISTER, TOGGLE_MODAL_MAP_REGISTER, TOGGLE_MODAL_REGISTER_LEAVE_QUESTION,
    TOGGLE_MODAL_PHOTO_UPDATE, TOGGLE_MODAL_COVER_PHOTO_UPDATE, TOGGLE_MODAL_CREATE_EVENT, TOGGLE_MODAL_EVENT_MAP,
    TOGGLE_MODAL_EVENT_GALLERY, TOGGLE_MODAL_USER_DATA_UPDATE, TOGGLE_MODAL_MAP_USER_DATA_UPDATE,
    TOGGLE_MODAL_USER_DATA_UPDATE_LEAVE_QUESTION, TOGGLE_MODAL_USER_DATA_MANAGEMENT, TOGGLE_MODAL_EVENTS_BY_USER, TOGGLE_MODAL_EVENT_DATA_MANAGEMENT,
    TOGGLE_MODAL_USER_AS_EVENT_PERFORMER_REMOVAL, TOGGLE_MODAL_CHANGE_PASSWORD, TOGGLE_MODAL_ACCOUNT_SETTINGS,
    TOGGLE_MODAL_ADD_CLUB_PHOTOS, TOGGLE_MODAL_USER_HAS_NOT_PERMISSION, TOGGLE_MODAL_ADD_PHOTOS} from '../actions/ModalActions';

const INITIAL_STATE = {
    loginModal: false,
    registerModal: false,
    registerModalType: 0,
    registerMapModal: false,
    registerLeaveQuestionModal: false,
    photoUpdateModal: false,
    coverPhotoUpdateModal: false, 
    createEventModal: false,
    eventMapModalOpened: false,
    eventGalleryModalOpened: false,
    userDataUpdateModal: false,
    userDataUpdateMapModal: false,
    userDataUpdateLeaveQuestionModal: false,
    userDataManagementModal: false,
    eventsByUserManagementModal: false,
    userAsEventPerformerRemovalModalOpened: false,
    eventDataManagementModalOpened: false, 
    changePasswordModalOpened: false, 
    accountSettingsModalOpened: false, 
    addClubPhotosModalOpened: false,
    userHasNotPermissionModalOpened: false,
    addPhotosModalOpened: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {  
        case TOGGLE_MODAL_LOGIN:
            return {...state, loginModal: action.payload.force ? action.payload.open : !state.loginModal};
        case TOGGLE_MODAL_REGISTER:
            return {...state, registerModal: action.payload.force ? action.payload.open : !state.registerModal, registerModalType: action.payload.modalType};
        case TOGGLE_MODAL_MAP_REGISTER:
            return {...state, registerMapModal: action.payload.force ? action.payload.open : !state.registerMapModal};
        case TOGGLE_MODAL_REGISTER_LEAVE_QUESTION:
            return {...state, registerLeaveQuestionModal: action.payload.force ? action.payload.open : !state.registerLeaveQuestionModal};
        case TOGGLE_MODAL_PHOTO_UPDATE:
            return {...state, photoUpdateModal: action.payload.force ? action.payload.open : !state.photoUpdateModal};
        case TOGGLE_MODAL_COVER_PHOTO_UPDATE:
            return {...state, coverPhotoUpdateModal: action.payload.force ? action.payload.open : !state.coverPhotoUpdateModal};
        case TOGGLE_MODAL_CREATE_EVENT:
            return {...state, createEventModal: action.payload.force ? action.payload.open : !state.createEventModal};
        case TOGGLE_MODAL_EVENT_MAP:
            return {...state, eventMapModalOpened: action.payload.force ? action.payload.open : !state.eventMapModalOpened};
        case TOGGLE_MODAL_EVENT_GALLERY:
            return {...state, eventGalleryModalOpened: action.payload.force ? action.payload.open : !state.eventGalleryModalOpened};
        case TOGGLE_MODAL_USER_DATA_UPDATE:
            return {...state, userDataUpdateModal: action.payload.force ? action.payload.open : !state.userDataUpdateModal};
        case TOGGLE_MODAL_MAP_USER_DATA_UPDATE:
            return {...state, userDataUpdateMapModal: action.payload.force ? action.payload.open : !state.userDataUpdateMapModal};
        case TOGGLE_MODAL_USER_DATA_UPDATE_LEAVE_QUESTION:
            return {...state, userDataUpdateLeaveQuestionModal: action.payload.force ? action.payload.open : !state.userDataUpdateLeaveQuestionModal};    
        case TOGGLE_MODAL_USER_DATA_MANAGEMENT:
            return {...state, userDataManagementModal: action.payload.force ? action.payload.open : !state.userDataManagementModal};    
        case TOGGLE_MODAL_EVENT_DATA_MANAGEMENT:
            return {...state, eventDataManagementModalOpened: action.payload.force ? action.payload.open : !state.eventDataManagementModalOpened};             
        case TOGGLE_MODAL_EVENTS_BY_USER:
            return {...state, eventsByUserManagementModal: action.payload.force ? action.payload.open : !state.eventsByUserManagementModal};             
        case TOGGLE_MODAL_USER_AS_EVENT_PERFORMER_REMOVAL:
            return {...state, userAsEventPerformerRemovalModalOpened: action.payload.force ? action.payload.open : !state.userAsEventPerformerRemovalModalOpened};             
        case TOGGLE_MODAL_CHANGE_PASSWORD:
            return {...state, changePasswordModalOpened: action.payload.force ? action.payload.open : !state.changePasswordModalOpened};             
        case TOGGLE_MODAL_ACCOUNT_SETTINGS:
            return {...state, accountSettingsModalOpened: action.payload.force ? action.payload.open : !state.accountSettingsModalOpened};             
        case TOGGLE_MODAL_ADD_CLUB_PHOTOS:
            return {...state, addClubPhotosModalOpened: action.payload.force ? action.payload.open : !state.addClubPhotosModalOpened};
        case TOGGLE_MODAL_USER_HAS_NOT_PERMISSION:
            return {...state, userHasNotPermissionModalOpened: action.payload.force ? action.payload.open : !state.userHasNotPermissionModalOpened};
        case TOGGLE_MODAL_ADD_PHOTOS:
            return {...state, addPhotosModalOpened: action.payload.force ? action.payload.open : !state.addPhotosModalOpened};
            
        default:
            return state;
    }
}
