import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { 
    FLOATING_SURNAME_LABEL, REGISTRATION_STEP_1_USER, REGISTRATION_STEP_2_USER,
    FLOATING_COUNTRY_LABEL, REGISTRATION_STEP_3_USER, REGISTRATION_STEP_4_USER, REGISTRATION_STEP_5_USER,
    LOADING, ALT_ICON, REQUIRED_TEXT_FIELD_LOWERCASE,
    FLOATING_CITY_LABEL, FLOATING_GENRE_LABEL, FLOATING_NAME_LABEL,
    REGISTRATION_STEP_6_USER, FLOATING_GENDER_LABEL, MAN_TRANSLATE, WOMAN_TRANSLATE, NO_CONTENT_AVAILABLE
} from '../../../../../config/TextContent';
import {SLOVAK_COUNTRY_ID} from '../../../../../config/Constants';
import {transformPropsWithLabelAndValue, onForeignObjectChange,
    onChangeHelper} from '../RegistrationModalHelper';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { cloneObject } from '../../../../../client/Helper';
import RegistrationSubmitButton from '../RegistrationSubmitButton';
import RegistrationStep from '../../RegistrationStep/RegistrationStep'; 
import customReactSelectStyles from '../../../../../config/ReactSelectStyle';
import { listToTreeUniqueValues } from '../../../../../client/Helper';
import TreeSelect from 'rc-tree-select';
import {getFilterEventMusicGenres} from '../../../CreateEventModal/filters/filters';

class RoleRegisterModalUserContent extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            user: {
                name: '',
                surname: '',
                gender: '',
                gender_obj: null,
                city: '',
                city_string: '',
                city_obj: null,
                genre: '',
                country: '',
                country_obj: null,
            },
            errors: props.errors ? props.errors : {},
            tsGenresOpen: false,
            hiddenFields:{
                'name': false,
                'surname': true,
                'gender': true,
                'genre': true,
                'country': true,
                'city': true,
                'submit': true
            }
        };

        this.setVisibleSteps = this.setVisibleSteps.bind(this);
    }   

    componentWillReceiveProps(props){
        this.setState({
            errors: props.errors,
        });
    }

    onFieldChange = (event, field_name, required) => {
        const event_value = event.target.value;
        this.setState((prevState) => {
            let user = prevState.user;
            let errors = prevState.errors;
            user[field_name] = event_value;
            if(event_value.length === 0 && required === true){
                errors[field_name] = REQUIRED_TEXT_FIELD_LOWERCASE;
            }else{
                errors[field_name] = null;
            }
            return {
                errors,
                user
            };
        });
    }

    onGenreChange = (value) => {
        this.setState((prevState) => {
            let user = {...prevState.user};
            let errors = {...prevState.errors};
            user['genre'] = value;
            if(value.length === 0){
                errors['genre'] = REQUIRED_TEXT_FIELD_LOWERCASE;
            }else{
                errors['genre'] = null;
            }
            return {errors, user};
        });
    }

    onChange = (event, name) => {
        onChangeHelper(this, event, name);
    };

    onCountryChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'country');
    }

    onCityChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'city');
    }

    onGenderChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'gender');
    };

    setVisibleSteps(...inputs){
        const diff = inputs.filter(input => {
            for(let view in this.state.hiddenFields){
                if(view === input)
                    return this.state.hiddenFields[view] !== input
            }
            return null;
        });
        if(diff.length > 0){
            this.setState((prevState) => {
                let hiddenFields = cloneObject(prevState.hiddenFields);
                diff.forEach(el => {
                    hiddenFields[el] = false;
                });
                return {...prevState, hiddenFields};
            });
        }
    }

    render() {
        return (
            <div>
                <div>
                    <RegistrationStep
                        step={1}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_1_USER}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <TextField
                            name="name" 
                            required
                            value={this.state.user.name}
                            className={this.state.errors.name ? 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input error-textfield' : 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input'}
                            label={this.state.errors.name ? this.state.errors.name : FLOATING_NAME_LABEL}
                            placeholder="..."
                            onChange={(event) => {this.setVisibleSteps('surname'); this.onFieldChange(event, 'name', true)}}
                            autoComplete="off"
                            autoCorrect="off"
                        />
                    </RegistrationStep>
                    <RegistrationStep
                        step={2}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_2_USER}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <TextField
                            required
                            name="surname"
                            value={this.state.user.surname}
                            className={this.state.errors.surname ? 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input error-textfield' : 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input'}
                            label={this.state.errors.surname ? this.state.errors.surname : FLOATING_SURNAME_LABEL}
                            placeholder="..."
                            onChange={(event) => {this.setVisibleSteps('username', 'gender'); this.onFieldChange(event, 'surname', false)}}
                            spellCheck="false"
                            autoComplete="off"
                            autoCorrect="off"
                        />
                    </RegistrationStep>
                    <RegistrationStep
                        step={3}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_3_USER}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <div className={this.state.errors.gender ? 'error-selectfield' : ''}>
                            <p className="selectfield-label required">{this.state.errors.gender ? this.state.errors.gender : FLOATING_GENDER_LABEL}</p>
                            <Select
                                styles={customReactSelectStyles}
                                classNamePrefix="styled-tree-select"
                                className="styled-tree-select"
                                name="gender"
                                value={this.state.user.gender_obj}
                                onChange={(genderChange) =>  {this.setVisibleSteps('genre'); this.onGenderChange(genderChange)}}
                                options={this.props.genders}
                                noOptionsMessage={() => {return NO_CONTENT_AVAILABLE}}
                                placeholder="..."
                            />
                        </div>
                    </RegistrationStep>
                    <RegistrationStep
                        step={4}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_4_USER}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <div className={this.state.errors.genre ? 'error-selectfield' : ''}>
                            <p className="selectfield-label required">{this.state.errors.genre ? this.state.errors.genre : FLOATING_GENRE_LABEL}</p>
                            <div className="tree-select-wrapper">
                                <TreeSelect
                                    required
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    transitionName="rc-tree-select-dropdown-slide-up"
                                    choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                    dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                    placeholder="..."
                                    showSearch={false} 
                                    allowClear treeLine
                                    value={this.state.user.genre}
                                    inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate-white.svg'} className="treeselect-arrow-down" alt={ALT_ICON} />}
                                    treeData={listToTreeUniqueValues(this.props.genres)}
                                    treeNodeFilterProp="genre"
                                    filterTreeNode={false}
                                    treeDefaultExpandAll={false}
                                    open={this.state.tsGenresOpen}
                                    notFoundContent={LOADING}
                                    onChange={(value) => {
                                        if (value === this.state.user.genre)
                                            this.setState({ tsGenresOpen: true });
                                        else
                                            this.setState({ tsGenresOpen: false });
                                        
                                        this.setVisibleSteps('country');
                                        this.onGenreChange(value);
                                    }}
                                    onDropdownVisibleChange={(v) => {
                                        this.setState({
                                            tsGenresOpen: v,
                                        });
                                        return true;
                                    }}
                                /> 
                            </div>
                        </div>
                    </RegistrationStep>
                    <RegistrationStep
                        step={5}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_5_USER}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <div className={this.state.errors.country ? 'error-selectfield' : ''}>
                            <p className="selectfield-label required">{this.state.errors.country ? this.state.errors.country : FLOATING_COUNTRY_LABEL}</p>
                            <Select
                                styles={customReactSelectStyles}
                                classNamePrefix="styled-tree-select"
                                className="styled-tree-select"
                                name="country"
                                value={this.state.user.country_obj}
                                onChange={(countryChange) =>  {this.setVisibleSteps('city'); this.onCountryChange(countryChange)}}
                                options={this.props.countries}
                                placeholder="..."
                                noOptionsMessage={() => {return NO_CONTENT_AVAILABLE}}
                            />
                        </div>
                    </RegistrationStep>
                    { 
                        this.state.user.country ? (
                            <RegistrationStep
                            step={6}
                            stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                            stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                            content={REGISTRATION_STEP_6_USER}
                            hiddenFields={this.state.hiddenFields}
                            >
                               {this.state.user.country === SLOVAK_COUNTRY_ID ? (

                                        <div className={this.state.errors.city ? 'error-selectfield' : ''}>
                                            <p className="selectfield-label required">{this.state.errors.city ? this.state.errors.city : FLOATING_CITY_LABEL}</p>
                                            <Select
                                                styles={customReactSelectStyles}
                                                classNamePrefix="styled-tree-select"
                                                className="styled-tree-select"
                                                name="city"
                                                placeholder="..."
                                                value={this.state.user.city_obj}
                                                onChange={selectedValue => {this.setVisibleSteps('submit'); this.onCityChange(selectedValue)}}
                                                options={this.props.cities}
                                                noOptionsMessage={() => {return NO_CONTENT_AVAILABLE}}
                                            />
                                        </div>
                                ) : ( 
                                        <TextField
                                            required
                                            name="city_string"
                                            value={this.state.user.city_string}
                                            className={this.state.errors.city_string ? 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input error-textfield' : 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input'}
                                            placeholder="..."
                                            onChange={event => {this.setVisibleSteps('submit'); this.onChange(event, null)}}
                                            label={this.state.errors.city_string ? this.state.errors.city_string : FLOATING_CITY_LABEL}
                                            autoComplete="off"
                                            autoCorrect="off"
                                        />
                                    )
                                }
                        </RegistrationStep> ) : ''
                    }
                </div>
                <div>
                    <RegistrationSubmitButton 
                        hidden={this.state.hiddenFields.submit}
                        loading={this.props.loading}
                        errors={this.props.errors}
                        onClick={() => {this.props.onSubmit(this.state.user)}}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        countries: transformPropsWithLabelAndValue(state.countryReducer.countryList),
        cities: transformPropsWithLabelAndValue(state.citiesReducer.cityList),
        genres: getFilterEventMusicGenres(state.eventReducer.eventMusicGenresList),
        genders: transformPropsWithLabelAndValue([{ id: 1, name: MAN_TRANSLATE}, {id: 2, name: WOMAN_TRANSLATE}])
    }
}

RoleRegisterModalUserContent.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired 
};

export default connect(mapStateToProps)(RoleRegisterModalUserContent);
