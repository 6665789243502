import React from 'react';
import './CustomPageHeader.css';

const CustomPageHeader = (props) => {
    return(
        <div className="custom-page-header">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="custom-page-header__content">
                            <p className="custom-page-header__content__title">{props.title}</p>
                            <p className="custom-page-header__content__text">{props.text}</p>
                        </div>
                    </div>
                    <div className="col-md-7"></div>
                </div>
            </div>
        </div>
    )
}

export default CustomPageHeader;
