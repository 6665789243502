import React from 'react';
import { ALT_ICON } from '../../../../config/TextContent';
import Fade from 'react-reveal/Fade';

const RegistrationStep = (props) => {
    let counter = 1;
    let act_element = null;
    let before_element = null;
    let act_element_value = null;
    let before_element_value = null;
    for (const field in props.hiddenFields) {
        if(!act_element){
            if(counter++ === props.step){
                act_element = field;
                act_element_value = props.hiddenFields[field]
            }
        }
    }

    let before_element_index = counter - 2;
    counter = 1;
    for(const field in props.hiddenFields){
        if(!before_element){
            if(counter++ === before_element_index){
                before_element = field;
                before_element_value = props.hiddenFields[field];
            }
        }
    }

    return (
        <div>
            <Fade
                timeout={300}
                when={before_element_value === false && act_element_value} bottom>
                <div className={before_element_value === false && act_element_value ? 'register-modal__dots' : ''}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </Fade>
            <Fade 
                timeout={700}
                when={act_element_value === false} bottom>
                <div className={(props.hidden ? 'display_none_important' : '') +  " row register-modal__step " + props.stepClass}>
                    <div className="col-md-1 col-xs-1 register-modal__max-width-425-padding-right-0">
                        <img className={props.stepImgClass} src={'/img/icons/numbers/'+ props.step +'.svg'} alt={ALT_ICON}/>
                    </div>
                    <div className="col-md-11 col-xs-11 register-modal__max-width-425-padding-left-20">
                        <p className={props.stepTextClass}>{props.content}</p>                                         
                    </div>
                    <div className="row">
                        <div className="col-md-11 col-md-offset-1 col-xs-11 col-xs-offset-1 register-modal__content-wrapper__register-modal-content__inputs-part">
                            { props.children }
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    )
}

export default RegistrationStep;

