import React from 'react';
import Calendar from "../../../views/Dashboard/Calendar";
import {Route, Switch} from 'react-router-dom';
import {GET_USER_ROUTE_TO_DASHBOARD, ROUTE_DASHBOARD, GET_USER_ROUTE_TO_CLUBS, GET_USER_ROUTE_TO_BANDS, GET_USER_ROUTE_TO_CALENDAR} from '../../../config/RoutesConstants';
import News from '../News/News';
import { isLoggedInUserInLocalStorage } from '../../../actions/UserActions';
import FixedButtonAddEvent from '../../FixedButtonAddEvent/FixedButtonAddEvent';
import ProfileEventsContent from '../../../views/Dashboard/ProfileEventsContent';
import ProfileListOfClubs from '../ProfileListOfClubs/ProfileListOfClubs';
import ProfileListOfBands from '../ProfileListOfBands/ProfileListOfBands';

const LoggedUserRoutesDashboard = (props) => {
    return (
        <div>
            {isLoggedInUserInLocalStorage() ? <FixedButtonAddEvent/> : null}
            <Switch>
                <Route path={GET_USER_ROUTE_TO_DASHBOARD(props.user) + '/category/:category_id'} component={ProfileEventsContent} {...props} />
                <Route path={GET_USER_ROUTE_TO_CALENDAR(props.user)} name="Calendar" component={Calendar} {...props} />
                <Route path={GET_USER_ROUTE_TO_CLUBS(props.user) + '/:page'} name="Clubs" component={ProfileListOfClubs} {...props} />
                <Route path={GET_USER_ROUTE_TO_CLUBS(props.user)} name="Clubs" component={ProfileListOfClubs} {...props} />
                <Route path={GET_USER_ROUTE_TO_BANDS(props.user) + '/:page'} name="Bands" component={ProfileListOfBands} {...props} />
                <Route path={GET_USER_ROUTE_TO_BANDS(props.user)} name="Bands" component={ProfileListOfBands} {...props} />
                <Route path={GET_USER_ROUTE_TO_DASHBOARD(props.user) + '/:chosen_date'} component={ProfileEventsContent} {...props} />
                <Route path={GET_USER_ROUTE_TO_DASHBOARD(props.user)} name="Events" component={ProfileEventsContent} />
                {[GET_USER_ROUTE_TO_DASHBOARD(props.user), ROUTE_DASHBOARD].map((path, index) =>
                    <Route path={path} component={News} key={index} name="News" />
                )}
            </Switch>
        </div>
    )    
}

export default LoggedUserRoutesDashboard;
