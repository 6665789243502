import {combineReducers} from 'redux';
import UserReducer from "./UserReducer";
import AuthReducer from "./AuthReducer";
import EventReducer from "./EventReducer";
import CountryReducer from "./CountryReducer";
import CitiesReducer from "./CitiesReducer";
import ClubTypesReducer from "./ClubTypesReducer";
import ModalReducer from './ModalReducer';

const rootReducer = combineReducers({
    authReducer: AuthReducer,
    userReducer: UserReducer,
    eventReducer: EventReducer,
    countryReducer: CountryReducer,
    citiesReducer: CitiesReducer,
    clubTypesReducer: ClubTypesReducer,
    modalReducer: ModalReducer,
});

export default rootReducer;
