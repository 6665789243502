class User {
    constructor(name, surname, gender, country, city, city_string, genre) {
        return (
            {
                'modalType': 4,
                'name': name,
                'surname': surname,
                'gender': gender,
                'country': country,
                'city': city,
                'city_string': city_string,
                'genre': genre
            }
        )
    }
}

export default User;