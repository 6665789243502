import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import DatePicker, {registerLocale} from "react-datepicker";
import sk from "date-fns/locale/sk";
import TreeSelect from 'rc-tree-select';
import { listToTreeUniqueValues, filterBasicGenres, filterChildrenGenres, hasChildrenGenres } from '../../../../client/Helper';
import './ProfileEventFilters.css';
import {FILTERING_OPTIONS, ALT_ICON, DATE, EVENT_TYPE} from '../../../../config/TextContent';

registerLocale("sk", sk);

class ProfileEventFilters extends Component {
    constructor(props, context){
        super(props, context);
        
        this.state = {
            tsCategoriesOpen: false,
            tsGenresOpen: false,
            tsChildrenGenresOpen: false,
            isGeolocationEnabled: false,
        }
    }

    render() {
        return(
            <div className="filter-content">
                <p className="filter-content__title">{FILTERING_OPTIONS}</p>
                <div className="filters-content__elements">
                    <div className="filters-content__elements__filter-element">
                        <div className="custom-green-input-wrapper black-color-input">
                            <TextField
                                label="Názov eventu"
                                name="name"
                                value={this.props.filter.name}
                                onChange={this.props.handleNameChange}
                            />
                        </div>
                    </div>
                    <div className="filters-content__elements__filter-element">
                        <div className="tree-select-wrapper custom-white-tree-select-dropdown tree-select-black tree-select-wrapper-calendar no-margin-left createEventModalContent__dateTime custom-green-input-wrapper">
                            <div className="createEventModalContent__dateTime">
                                <div>
                                    <p className="createEventModalContent__textfield">{DATE}</p>
                                    <DatePicker
                                        dateFormat="dd.MM.yyyy"
                                        selected={this.props.filter.chosen_date}
                                        disabledKeyboardNavigation
                                        onChange={(date) => this.props.onDateChange(date)}
                                        locale="sk"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> 
                    {
                        this.props.isGeolocationAvailable && this.state.isGeolocationEnabled ?
                            <div className="filters-content__elements__filter-element">
                                <div className="custom-green-input-wrapper black-color-input">
                                    <TextField
                                        label={this.props.isGeolocationEnabled && !this.props.coords ? "Zisťovanie polohy ..." : "Rádius od mojej polohy (km)"}
                                        name="radius"
                                        type="number"
                                        min="0"
                                        disabled={!this.props.isGeolocationEnabled || !this.props.coords}
                                        value={this.props.filter.radius}
                                        fullWidth={true}
                                        onChange={(event) => {
                                            if(this.props.coords){
                                                this.props.handleRadiusChange(event, this.props.coords.latitude, this.props.coords.longitude)
                                            }
                                        }}
                                    />
                                    {
                                        !this.props.isGeolocationEnabled ? <p>Pre funkčné vyhľadávanie podľa polohy je potrebné povoliť polohu v prehliadači.</p> : null
                                    }
                                </div>
                            </div> 
                            : 
                            null
                    }
                    <div className="filters-content__elements__filter-element filter-element__category">
                        <div className="tree-select-wrapper custom-white-tree-select-dropdown no-margin-top tree-select-black tree-select-wrapper-calendar no-margin-left">
                            <p className="selectfield-label required">{EVENT_TYPE}</p>
                            <br/>
                            <TreeSelect
                                placeholder='Všetky'
                                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                transitionName="rc-tree-select-dropdown-slide-up"
                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                showSearch={false} 
                                allowClear
                                treeLine
                                value={this.props.filter.category}
                                treeData={listToTreeUniqueValues(this.props.eventCategoriesList)}
                                inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate.svg'} className="treeselect-arrow-down" alt={ALT_ICON} />}
                                treeNodeFilterProp="title"
                                filterTreeNode={false}
                                treeDefaultExpandAll={true}
                                open={this.state.tsCategoriesOpen}
                                notFoundContent='Načítavam ...'
                                onChange={(value) => {
                                    if (value === this.props.eventCategoriesList)
                                        this.setState({ tsCategoriesOpen: true });
                                    else
                                        this.setState({ tsCategoriesOpen: false });

                                    this.props.onEventCategoryChange(value);
                                }}
                                onDropdownVisibleChange={(v) => {
                                    this.setState({
                                        tsCategoriesOpen: v,
                                    });

                                    return true;
                                }}
                            />
                        </div> 
                        {this.props.filter.isFlagMusicGenres ? (
                            <div>
                                <div className="tree-select-wrapper custom-white-tree-select-dropdown tree-select-black tree-select-wrapper-calendar no-margin-left">
                                    <TreeSelect
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        transitionName="rc-tree-select-dropdown-slide-up"
                                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                        dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                        placeholder='Všetky žánre'
                                        showSearch={false} 
                                        allowClear
                                        treeLine
                                        value={this.props.filter.genre}
                                        treeData={filterBasicGenres(listToTreeUniqueValues(this.props.eventMusicGenresList))}
                                        inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate.svg'} className="treeselect-arrow-down" alt={ALT_ICON} />}
                                        treeNodeFilterProp="title"
                                        filterTreeNode={false}
                                        treeDefaultExpandAll={true}
                                        open={this.state.tsGenresOpen}
                                        notFoundContent='Načítavam ...'
                                        onChange={(value) => {
                                            if (value === this.props.eventMusicGenresList)
                                                this.setState({ tsGenresOpen: true });
                                            else
                                                this.setState({ tsGenresOpen: false });

                                            this.props.onEventMusicGenresChange(value);
                                        }}
                                        onDropdownVisibleChange={(v) => {
                                            this.setState({
                                                tsGenresOpen: v,
                                            });

                                            return true;
                                        }}
                                    />
                                </div> 
                                {
                                    hasChildrenGenres(listToTreeUniqueValues(this.props.eventMusicGenresList), this.props.filter.genre) ? (
                                        <div className="tree-select-wrapper custom-white-tree-select-dropdown tree-select-black tree-select-wrapper-calendar no-margin-left">
                                            <TreeSelect
                                                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                                transitionName="rc-tree-select-dropdown-slide-up"
                                                choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                                dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                                placeholder='Podžáner'
                                                showSearch={false} 
                                                allowClear
                                                treeLine
                                                value={this.props.filter.childrenGenre}
                                                treeData={filterChildrenGenres(listToTreeUniqueValues(this.props.eventMusicGenresList), this.props.filter.genre)}
                                                inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate.svg'} className="treeselect-arrow-down" alt={ALT_ICON} />}
                                                treeNodeFilterProp="title"
                                                filterTreeNode={false}
                                                treeDefaultExpandAll={true}
                                                open={this.state.tsChildrenGenresOpen}
                                                notFoundContent='Načítavam ...'
                                                onChange={(value) => {
                                                    if (value === this.props.eventMusicGenresList)
                                                        this.setState({ tsChildrenGenresOpen: true });
                                                    else
                                                        this.setState({ tsChildrenGenresOpen: false });

                                                    this.props.onEventMusicChildrenGenresChange(value);
                                                }}
                                                onDropdownVisibleChange={(v) => {
                                                    this.setState({
                                                        tsChildrenGenresOpen: v,
                                                    });

                                                    return true;
                                                }}
                                            />
                                        </div> 
                                    ) : ''
                                }
                            </div> 
                        ) : null}
                    </div>
                </div>
            </div>
        );   
    }
}

//REMOVED because radius commented out (geolocated(geolocated_object)(ProfileEventFilters))
/*let geolocated_object = {
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
};*/

export default ProfileEventFilters;
