export const getFilterEventCategories = eventCategories => {
    eventCategories.map((element) => {
        if(element){
            element.title = element.name;
            element.value = element.id;
            element.key = element.id;
        }
        return element;
    });
    return eventCategories;
} 

export const getFilterEventClubTypes = clubTypes => {
    clubTypes.map((element) => {
        if(element){
            element.title = element.name;
            element.value = element.id;
            element.key = element.id;
        }
        return element;
    });
    return clubTypes;
}

export const getFilterEventMusicGenres = eventMusicGenres => {
    eventMusicGenres.map((element) => {
        if(element){
            element.title = element.name;
            element.value = element.id;
            element.key = element.id;
            if(element.main_category_id)
                element.parent_id = element.main_category_id;
            if(element.main_category_id === element.id)
                delete element.parent_id;
        }
        return element;
    });
    return eventMusicGenres;
}

export const getFilterClubTypesGenres = clubTypes => {
    clubTypes.map((element) => {
        if(element){
            element.title = element.name;
            element.value = element.id;
            element.key = element.id;
        }
        return element;
    });
    return clubTypes;
}

export const getFilterEventsForBigCalendar = events => {
    return events;
}
