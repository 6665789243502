import {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toggleModalLogin, toggleModalRegister, toggleModalCreateEvent, toggleModalEventDataManagement, toggleModalUserDataManagement,
    toggleModalUserDataUpdate, toggleModalEventsByUser, toggleModalCoverPhotoUpdate, toggleModalPhotoUpdate, toggleModalUserAsEventPerformerRemoval,
    toggleModalChangePassword, toggleModalAccountSettings, toggleModalAddClubPhotos, toggleModalUserHasNotPermission, toggleModalAddPhotos} from '../../actions/ModalActions';
import {withRouter} from 'react-router-dom';
import {Config} from '../../config/Constants';
import {isLoggedInUserInLocalStorage} from '../../actions/UserActions';
import {getUserTypeNumber} from '../../client/Helper';
import {setLoadingSelectedEventToUpdate, getSelectedEventToUpdate, getSelectedEventToUpdateSuccess, getSelectedEventToUpdateError, 
    setLoadingGetEventsByUser, getEventsByUser, getEventsByUserSuccess, getEventsByUserError, setLoadingGetEventsByPerformer, 
    getEventsByPerformer, getEventsByPerformerError, getEventsByPerformerSuccess} from '../../actions/EventActions';

class RouteProcessor extends Component {
    isAffected = false;

    componentDidMount = () => { 
        this.processQuery();
    }

    componentDidUpdate = () => {
        this.processQuery();
    }

    processQuery = () => {
        if(this.isAffected)
            return false;
        switch(this.props.location.pathname){
            case Config.LOGIN_ROOT:
                if(!isLoggedInUserInLocalStorage()){
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.REGISTRATION_USER_ROOT:
                if(!isLoggedInUserInLocalStorage()){
                    this.props.toggleModalRegister(true, true, 4);
                }
                break;
            case Config.REGISTRATION_CLUB_ROOT:
                if(!isLoggedInUserInLocalStorage()){
                    this.props.toggleModalRegister(true, true, 2);
                }
                break;
            case Config.REGISTRATION_INTERPRET_ROOT:
                if(!isLoggedInUserInLocalStorage()){
                    this.props.toggleModalRegister(true, true, 1);
                }
                break;
            case Config.REGISTRATION_PROMOTER_ROOT:
                if(!isLoggedInUserInLocalStorage()){
                    this.props.toggleModalRegister(true, true, 3);
                }
                break;
            case Config.ADD_EVENT_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.toggleModalCreateEvent(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.EDIT_EVENT_ROOT + this.props.match.params.id: 
                if(isLoggedInUserInLocalStorage()){
                    const query_param = {id: this.props.match.params.id} 
                    this.props.setLoadingSelectedEventToUpdate();        
                    this.props.getSelectedEventToUpdate(query_param).then(() => {
                        this.props.getSelectedEventToUpdateSuccess();
                    }).catch(() => {
                        this.props.getSelectedEventToUpdateError();
                    });
                    this.props.toggleModalEventDataManagement(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }    
                break;
            case Config.UPDATE_USER_DATA_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.toggleModalUserDataUpdate(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.USER_DATA_MANAGEMENT_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.toggleModalUserDataManagement(true, true, this.props.modalType);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.EVENTS_BY_USER_MANAGEMENT_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.setLoadingGetEventsByUser();
                    this.props.getEventsByUser().then(() => {
                        this.props.getEventsByUserSuccess();
                    }).catch(() => {
                        this.props.getEventsByUserError();
                    });        
                    this.props.toggleModalEventsByUser(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.CHANGE_COVER_PHOTO_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.toggleModalCoverPhotoUpdate(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.CHANGE_PROFILE_PHOTO_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.toggleModalPhotoUpdate(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.ATTENDING_EVENTS_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.setLoadingGetEventsByPerformer();
                    this.props.getEventsByPerformer().then(() => {
                        this.props.getEventsByPerformerSuccess();
                    }).catch(() => {
                        this.props.getEventsByPerformerError();
                    });
                    this.props.toggleModalUserAsEventPerformerRemoval(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.CHANGE_PASSWORD_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.toggleModalChangePassword(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.ACCOUNT_SETTINGS_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.toggleModalAccountSettings(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.ADD_CLUB_PHOTOS_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.toggleModalAddClubPhotos(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            case Config.USER_HAS_NOT_PERMISSION_ROOT:
                this.props.toggleModalUserHasNotPermission(true, true);
                break;
            case Config.ADD_PHOTOS_ROOT:
                if(isLoggedInUserInLocalStorage()){
                    this.props.toggleModalAddPhotos(true, true);
                }else{
                    this.props.toggleModalLogin(true, true);
                }
                break;
            default:
                break;
        }
        this.isAffected = true;
    }
    
    render(){
        return "";
    }
}

const mapStateToProps = state => {
    return {
        modalType: getUserTypeNumber(state.userReducer.user),
        loginModalOpened: state.modalReducer.loginModal,
        premiumEventsList: state.eventReducer.premiumEventsList,
        user: state.userReducer.user
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleModalLogin,
        toggleModalRegister,
        toggleModalCreateEvent,
        toggleModalEventDataManagement,
        setLoadingSelectedEventToUpdate,
        getSelectedEventToUpdate,
        getSelectedEventToUpdateSuccess,
        getSelectedEventToUpdateError,
        toggleModalUserDataManagement,
        toggleModalUserDataUpdate,
        setLoadingGetEventsByUser, 
        getEventsByUser, 
        getEventsByUserSuccess, 
        getEventsByUserError,
        toggleModalEventsByUser,
        toggleModalCoverPhotoUpdate,
        toggleModalPhotoUpdate,
        toggleModalUserAsEventPerformerRemoval,
        toggleModalChangePassword,
        toggleModalAccountSettings,
        setLoadingGetEventsByPerformer,
        getEventsByPerformer,
        getEventsByPerformerSuccess,
        getEventsByPerformerError,
        toggleModalAddClubPhotos,
        toggleModalUserHasNotPermission,
        toggleModalAddPhotos
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(RouteProcessor));
