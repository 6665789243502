import React, {Component} from "react";
import {PHOTOGRAPHY_TRANSLATE} from '../../../../config/TextContent';

class SimpleGalleryImageControls extends Component{

  constructor(props, context){
    super(props, context);

    let html_photo = Object.assign({}, props.photo);

    this.state = {
        html_photo: html_photo,
        photo: props.photo,
        index: props.index,
        onClick: props.onClick,
    }
  }

  render() {
    return (
        <div className={"photo-gallery-img-wrapper"}>
          <img
            alt={PHOTOGRAPHY_TRANSLATE}
            {...this.state.html_photo}
            onClick={e => this.state.onClick(e, { index: this.state.index + 1, photo: this.state.photo })}
          />
        </div>
      );
  }
}

export default SimpleGalleryImageControls;

