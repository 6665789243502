import React from 'react';
import './ArticleBody.css';

const ArticleBody = () => {
    return (
        <div>
            <div className="contribution__table__content__text">
                <p></p>
            </div>
        </div>
    )
}

export default ArticleBody;
