import React from 'react';
import EventUserActions from './EventUserActions/EventUserActions';
import './EventUserSection.css';
import {Link} from 'react-router-dom';
import {Config} from '../../../config/Constants';
import {GET_USER_ROUTE} from '../../../config/RoutesConstants'; 
import {GET_NUMBER_OF_EVENTS_IN_SLOVAK} from '../../../config/TextContent';

const EventUserSection = (props) => {
    const user_route = GET_USER_ROUTE(props.user);
    const publisher_image = Config.getUserPhoto(props.user, Config.CONSTANT_TITLE_PICTURE);
    return (
        props.loginModal ? (
        <div className="table-event__publisher row">
            <div className="col-md-3 col-xs-3">
                <Link to={user_route} onClick={() => props.toggleModalLogin(false, true)} className="table-event__publisher__image">
                <div className="table-event__publisher__image-intro" style={{ backgroundImage: `url(${publisher_image})` }}></div>
                </Link>  
            </div>
            <div className="col-md-9 col-xs-6">
                <Link to={user_route} onClick={() => props.toggleModalLogin(false, true)} className="table-event__publisher-name">{props.user && props.user.user_data && props.user.user_data.name ? props.user.user_data.name : null}</Link>  
                {
                    props.user.events ? (
                        <p className="table-event__publisher-number-actions">{GET_NUMBER_OF_EVENTS_IN_SLOVAK(props.user.events.length + 1)}</p>
                    ) : ''
                }
            </div>
        </div>
        ) : (
        <div className="table-event__publisher row">
            <div className="col-md-3 col-xs-3">
                <Link to={user_route} className="table-event__publisher__image">
                    <div className="table-event__publisher__image-intro" style={{ backgroundImage: `url(${publisher_image})` }}></div>
                </Link>
            </div>
            <div className="col-md-5 col-xs-6">
                <Link to={user_route} className="table-event__publisher-name">{props.user && props.user.user_data && props.user.user_data.name ? props.user.user_data.name : null}</Link>
                {
                    props.user.events ? (
                        <p className="table-event__publisher-number-actions">{GET_NUMBER_OF_EVENTS_IN_SLOVAK(props.user.events.length + 1)}</p>
                    ) : ''
                }
            </div>
            <div className="col-md-4 col-xs-3 padding-left-0-media">
                <EventUserActions
                    event={props.event}
                    loginModal={props.loginModal}
                />
            </div>
        </div>
        )
    );
}

export default EventUserSection; 
