import React from 'react';
import './AnonymousNavigation.css';
import AnonymousDesktopNavigation from './AnonymousDesktopNavigation/AnonymousDesktopNavigation';
import MobileNavigation from '../MobileNavigation/MobileNavigation';

const AnonymousNavigation = () => {
    return (
        <div className="header__navigation__anonymous-navigation">
            <AnonymousDesktopNavigation />
            <MobileNavigation isAuthenticatedNavigation={false} />
        </div>
    );
}

export default AnonymousNavigation;
