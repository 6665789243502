import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import DatePicker, {registerLocale} from "react-datepicker";
import sk from "date-fns/locale/sk";
import TextField from '@material-ui/core/TextField';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import './NewProgramItem.css';
import {ADD} from '../../../../../config/TextContent';

registerLocale("sk", sk);

const NewProgramItem = (props) => {
    return(
        <ListItem className="performer-list__item new-program-list__item">
            <TextField
                required
                spellCheck="false"
                autoComplete="off"
                autoCorrect="off"
                type="text"
                name="programTitle"
                value={props.newProgramItem.name}
                className={props.newProgramItem.errors.name ? "create-event-modal-content__modal-step__input-name label_color_red" : "create-event-modal-content__modal-step__input-name"}
                label={props.newProgramItem.errors.name ? "Názov (" + props.newProgramItem.errors.name + ")" : "Názov"}
                fullWidth={false}
                placeholder="..."
                onChange={props.onNameOfNewProgramItemChange}
            />
            <p className={props.newProgramItem.errors && props.newProgramItem.errors.date ? "createEventModalContent__textfield label_color_red" : "createEventModalContent__textfield"}>
                {props.newProgramItem.errors && props.newProgramItem.errors.date ? "Od (" + props.newProgramItem.errors.date + ")" : "Od"}
            </p>
            <DatePicker
                openToDate={props.newProgramItem.date ? props.newProgramItem.date : props.eventDate}
                dateFormat="dd.MM.yyyy HH:mm"
                disabledKeyboardNavigation
                selected={props.newProgramItem.date}
                minDate={props.eventDate}
                maxDate={props.eventDateEnd ? props.eventDateEnd : null}
                onChange={props.onDateOfNewProgramItemChange}
                showTimeSelect
                timeIntervals="30"
                timeFormat="HH:mm"
                locale="sk"
            />
            {props.newProgramItem.errors.name === null && props.newProgramItem.errors.date === null && props.newProgramItem.name && props.newProgramItem.name !== '' ? (
                <ListItemIcon className="performer-list__item__button">
                    <button type="button" className="performer-list__item__add-button" onClick={() => props.createProgramItem()}>
                        {ADD}
                    </button>
                </ListItemIcon>
            ) : null}
        </ListItem>
    )
}

export default NewProgramItem;
