import React, { Component } from 'react';
import CustomPageHeader from './CustomPageHeader/CustomPageHeader';
import { isLoggedInUserInLocalStorage } from '../../actions/UserActions';
import FixedButtonAddEvent from '../FixedButtonAddEvent/FixedButtonAddEvent';
import './CustomPages.css';
import { PRIVACY_POLICIES, PRIVACY_POLICIES_INTRO_TEXT } from '../../config/TextContent';

class PrivacyPolicies extends Component {
    componentDidMount(){
        window.scrollTo(0,0);
    }
    componentWillReceiveProps(){
        window.scrollTo(0,0);
    }

    render(){
        return(
            <div className="custom-page">
                {isLoggedInUserInLocalStorage() ? <FixedButtonAddEvent/> : null}
                <CustomPageHeader
                    title={PRIVACY_POLICIES}
                    text={PRIVACY_POLICIES_INTRO_TEXT}
                />
                <div className="custom-page__body">
                    <div className="container">
                        <div className="row custom-page__content">
                            <div className="col-md-12">
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        )
    }
}   

export default PrivacyPolicies;
