import React, {Component} from 'react';
import {connect} from "react-redux";
import { ALT_ICON } from '../../../../config/TextContent';
import './ListOfClubs.css';
import { Link } from 'react-router-dom';
import {GET_USER_ROUTE} from '../../../../config/RoutesConstants';
import {Config} from '../../../../config/Constants';

class listOfClubs extends Component {
    render() {
        return (
            <div className="list-of-clubs"> 
                <div className="row">
                    {this.props.listOfClubs ? this.props.listOfClubs.map((club, index) => {
                        const title_photo = Config.getClubBandTitlePhoto(club);
                        return (
                            <div className={index % 2 === 0 ? "list-of-clubs__col-club pr-7-desktop" : "list-of-clubs__col-club pl-7-desktop"} key={index}>
                                <div className="list-of-clubs__club">
                                    <Link to={GET_USER_ROUTE(club.user)} className="list-of-clubs__club__link-photo">
                                        <div className="list-of-clubs__club__photo" style={{backgroundImage: 'url(' + title_photo + ')'}}></div>
                                    </Link>
                                    <div className="list-of-clubs__club__content">
                                        <Link to={GET_USER_ROUTE(club.user)} className="list-of-clubs__club__content__title">{club.name}</Link>
                                        <p className="list-of-clubs__club__content__place">
                                            <img src={'/img/icons/place.svg'} className="event-small__content__info-icon-date" alt={ALT_ICON} />
                                            <span>{club.place_full_string}</span>
                                        </p>
                                        {club.club_description ? 
                                            <p className="list-of-clubs__club__content__description">{club.club_description.length > 150 ? club.club_description.substr(0, 150) + "..." : club.club_description}</p>:
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }) : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listOfClubs: state.userReducer.listOfClubs
    }
}

export default connect(mapStateToProps)(listOfClubs);
