import React from 'react';
import {MENU_CLUBS} from '../../../../../../config/TextContent';
import MenuItem from '../MenuItem';

const ElementClubs = props => {
    return (
        <MenuItem 
            name={MENU_CLUBS}
            link={props.link}
            img='/img/icons/disco-ball-green.svg' 
            active={props.active}
            onClick={props.onClick}
        />
    );
}
 
export default ElementClubs;
